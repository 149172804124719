import $ from 'jquery';
import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import { validationParser1 } from '../ReusableComponents/validationParser';
import { validationParser } from '../ReusableComponents/validationParser2';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import Topnav, { AppTopNav } from './Topnav';

import { LabelField } from '../ReusableComponents/LabelnInput';
import { selectOptionsGenerator } from '../ReusableComponents/OptionsGenerator';
import { formObjDataMapper2 } from '../ReusableComponents/formObjDataMapper';
//Tooltip imports

import moment from 'moment-timezone';
import DataTable, { defaultThemes } from 'react-data-table-component';
import DateTime from 'react-datetime';
import ReactTooltip from "react-tooltip";
import '../../node_modules/react-datetime/css/react-datetime.css';
import { can_add } from '../ReusableComponents/urlGenerator';
import '../global.js';
import { Button } from './Modals/Buttons/index';
import Modal from './Modals/Modal/index';
// import TimePicker from 'rc-time-picker';
// import 'rc-time-picker/assets/index.css';
import { encryptStorage } from './LocalStorageInterceptor';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


var validationObj = {};
var context;


const customStyles = {
    table: {
        style: {
            color: defaultThemes.default.text.primary,
            backgroundColor: defaultThemes.default.background.default,
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "#F9F9F9"
        },
    },
    header: {
        style: {
            minHeight: '1px',
        },
    },
    headRow: {
        style: {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: defaultThemes.default.divider.default,
            backgroundColor: "#F9F9F9",
            minHeight: "40px"
        },
    },
    headCells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,
                fontWeight: "bold",
                fontSize: "0.91rem"
            },
        },
    },
    row: {
        style: {
            minHeight: '20px',
            borderRightStyle: 'solid',
            borderRightWidth: '10px',
            borderRightColor: defaultThemes.default.divider.default,
        }
    },
    cells: {
        style: {
            '&:not(:last-of-type)': {
                // borderStyle: 'solid',
                // borderWidth: '1px',
                // borderColor: defaultThemes.default.divider.default,
                borderLeftStyle: 'solid',
                borderLeftWidth: '1px',
                borderLeftColor: defaultThemes.default.divider.default,
                fontSize: '0.9rem'
            },
        },
    },
};

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? "green"
                    : isFocused
                        ? "#0074F0"
                        : null,
            color: isDisabled
                ? '#ccc'
                : isFocused
                    ? "white"
                    : isSelected
                        ? "white"
                        : "black",

            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? "grey" : "red"),
            },
        };
    },
    multiValue: (styles, { data }) => {
        // const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: "#007BFF",
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "white",
        whiteSpace: "inherit"
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: "white",
        ':hover': {
            backgroundColor: "red",
            color: 'white',
        },
    }),
};

class ChangeParticipation extends Component {

    constructor() {
        super();
        context = this;

        this.state = {
            ...this.getModal("changeParticipationsData"),
            changeParticipationsData: this.getModal("changeParticipationsData"),
            mandatoryInfo: this.getModal("changeParticipationsData"),
            labelText: this.getModal("changeParticipationsData"),
            tooltips: this.getModal("changeParticipationsData"),
            tooltipsVisibility: this.getModal("changeParticipationsData"),
            fieldName: this.getModal("changeParticipationsData"),
            fieldType: this.getModal("changeParticipationsData"),
            //SelectOptions
            referralTypeSelectOptions: [],
            wasAssmntCompletedSelectOptions: [],
            wasClientDeemForMatSelectOptions: [],
            whichMatProgramSelectOptions: [],
            wereFamilyReferToServiceSelectOptions: [],
            whichParticipationChangeServiceSelectOptions: [],
            //MultiSelectOption
            clientRefferedAgencyMultiOptions: [],
            changeParticipantionsHistoryData: [],
            changeParticipationsHistoryColumns: this.getModal("changeParticipationsHistoryColumns"),
            //Modals
            showAddParticipationChangesModal: sessionStorage.getItem('modalOpen') ? true : false,
            showNextActionModal: false,
            showParticipationChangeDetailsModal: false
        };
        this.handleParticipationChangeSelectChange = this.handleParticipationChangeSelectChange.bind(this);
    }

    getModal(type) {
        let dataModel = '';
        // eslint-disable-next-line default-case
        switch (type) {
            case "changeParticipationsData":
                dataModel = {
                    note_section: '',
                    reason_for_change: '',
                    treatment_type: '',
                    agency: '',
                    other_agency: '',
                    follow_up_date: '',
                    have_client_accessed_referral: '',
                    which_mat_clinic: '',
                    other_mat_clinic: '',
                    has_received_mat_medi: '',
                    discharged_date: '',
                    why_refused_contact: '',
                    other_refused_reason: '',
                    why_unable_to_contact: '',
                    other_unable_contact_reason: '',
                }
                break;

            case "resetData":
                dataModel = {
                    note_section: '',
                    reason_for_change: '',
                    treatment_type: '',
                    agency: '',
                    other_agency: '',
                    follow_up_date: '',
                    have_client_accessed_referral: '',
                    which_mat_clinic: '',
                    other_mat_clinic: '',
                    has_received_mat_medi: '',
                    discharged_date: '',
                    why_refused_contact: '',
                    other_refused_reason: '',
                    why_unable_to_contact: '',
                    other_unable_contact_reason: '',
                }
                break;

            case "changeParticipationsHistoryColumns":
                dataModel = [
                    {
                        name: 'Change On',
                        selector: (row) => row.date,
                        sortable: true,
                        cell: (row) => {
                            return moment.unix(row.date).format("MM/DD/YYYY hh:mm a");
                        },

                    },
                    {
                        name: 'Agency',
                        selector: (row) => row.agency,
                        sortable: true,
                    },
                    {
                        name: 'Change By',
                        selector: (row) => row.updated_by,
                        sortable: true,
                    },
                    {
                        name: 'View',
                        sortable: false,
                        cell: (row) => (
                            <div>
                                <Link to="#"><i id={row.id} lang="none" className="fas fa-eye" aria-hidden="true" data-toggle="tooltip" title="view" onClick={this.handleParticipationChangeDetails}></i></Link>
                            </div>
                        ),
                        ignoreRowClick: true,
                        allowOverflow: true,
                        button: true,
                    },
                ];
                break;
        }
        return dataModel;
    }

    componentWillMount() {
        sessionStorage.setItem('responceId', '');
        /* for updating client status */
        if (sessionStorage.getItem('clientId') !== '') {
            axios.get(global.restApiURL + 'webservice/rest/object-list?apikey=' + sessionStorage.getItem('__TOKEN__') + '&limit=1&objectClass=ClientInformation&q={"client_id":"' + sessionStorage.getItem('clientId') + '"}')
                .then(res => {
                    const responce = res.data.data; // get the data array instead of object
                    if (responce?.id) {
                        axios.get(global.restApiURL + 'webservice/rest/object/id/' + responce.id + '?apikey=' + sessionStorage.getItem('__TOKEN__'))
                            .then(res => {
                                sessionStorage.setItem('responceId', res.data.data.id);
                            }
                            )
                    }
                }
                )
        }
        // for fetching layout definition
        axios.get(global.restApiURL + 'webservice/rest/class/id/19?apikey=' + sessionStorage.getItem('__TOKEN__'))
            .then(res => {
                var response = res.data;
                if (response.success && res.status == 200) {
                    validationObj = validationParser(response.data.layoutDefinitions.children[0].children);
                    let validationObj_old = validationParser1(response.data.layoutDefinitions.children[0].children);
                    console.log("validation : ", validationObj);
                    this.setState({
                        labelText: validationObj.title,
                        mandatoryInfo: validationObj.mandatory,
                        tooltips: validationObj.tooltip,
                        tooltipsVisibility: validationObj.tooltipVisibility,
                        fieldName: validationObj.name,
                        fieldType: validationObj.fieldtype,
                        ...validationObj_old
                    });
                    this.createSelectOptions();
                }
                else if ("error_code" in response) {
                    alert(response.msg);
                    window.location.href = global.domain + global.traversArray['out'];
                }
                else {
                    alert(response.msg);
                }
            })
            .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err);
            }
            );
    }

    componentDidMount() {
        this.getChangeParticipantionsList();
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    getChangeParticipantionsList = () => {
        if (sessionStorage.getItem('clientId') !== '') {
            let getChangeParticipantionsListBody = {
                apikey: sessionStorage.getItem('__TOKEN__'),
                client_id: sessionStorage.getItem('clientId'),
            };
            axios.post(global.restApiURL + 'customRest/getChangeParticipantionsList', JSON.stringify(getChangeParticipantionsListBody))
                .then(res => {
                    if (res.data.success && res.status == 200) {
                        // const response = res.data.data.elements;
                        setTimeout(function () {
                            context.setState({
                                changeParticipantionsHistoryData: context.parseChangeParticipationHistory(res.data.data)
                            });
                            console.log("obj his data : ", context.state.changeParticipantionsHistoryData);
                            sessionStorage.setItem('changeParticipantionsListData_length', res.data.data.length)
                        }, 50)
                    }
                    else if ("error_code" in res) {
                        alert(res.msg);
                        window.location.href = global.domain + global.traversArray['out'];
                    }
                    else {
                        alert(res.msg);
                    }
                })
                .catch(err => { // log request error and prevent access to undefined state
                    this.setState({ loading: false, error: true });
                    console.error(err);
                }
                )
        }
    }

    parseChangeParticipationHistory(historyDataArray) {
        let parsedData = [];
        parsedData = historyDataArray.length > 0 && historyDataArray.map((item, i) => {
            var op = {};
            item.map((val) => {
                op[val.name] = val.value;
            })
            return op;
        }, this);
        return parsedData;
    }

    createSelectOptions() {
        if (validationObj !== "") {
            this.setState({
                //Select options
                reason_for_changeSelectOptions: selectOptionsGenerator(validationObj.options.reason_for_change, context.state.changeParticipationsData.reason_for_change),
                treatment_typeSelectOptions: selectOptionsGenerator(validationObj.options.treatment_type, context.state.changeParticipationsData.treatment_type),
                agencySelectOptions: selectOptionsGenerator(validationObj.options.agency, context.state.changeParticipationsData.agency),
                have_client_accessed_referralSelectOptions: selectOptionsGenerator(validationObj.options.have_client_accessed_referral, context.state.changeParticipationsData.have_client_accessed_referral),
                which_mat_clinicSelectOptions: selectOptionsGenerator(validationObj.options.which_mat_clinic, context.state.changeParticipationsData.which_mat_clinic),
                has_received_mat_mediSelectOptions: selectOptionsGenerator(validationObj.options.has_received_mat_medi, context.state.changeParticipationsData.has_received_mat_medi),
                why_refused_contactSelectOptions: selectOptionsGenerator(validationObj.options.why_refused_contact, context.state.changeParticipationsData.why_refused_contact),
                why_unable_to_contactSelectOptions: selectOptionsGenerator(validationObj.options.why_unable_to_contact, context.state.changeParticipationsData.why_unable_to_contact),
            });

        }
    }

    handleParticipationChangeDetails(event) {
        let historyID = event.target.id;
        context.setState({
            changeParticipationsData: context.getModal("changeParticipationsData")
        })
        axios.get(global.restApiURL + 'webservice/rest/object/id/' + historyID + '?apikey=' + sessionStorage.getItem('__TOKEN__'))
            .then(res => {
                const response = res.data.data.elements;
                let parsedParticipationChangeDetails = formObjDataMapper2(response, context.getModal("changeParticipationsData"));
                context.setState({
                    changeParticipationsData: parsedParticipationChangeDetails,
                    showParticipationChangeDetailsModal: true
                })
                context.createSelectOptions();
            })
            .catch(err => { // log request error and prevent access to undefined state
                context.setState({ loading: false, error: true });
                console.error(err);
            }
            )
    }

    openAddParticipationChangeModal() {
        context.setState({
            showAddParticipationChangesModal: true,
            showParticipationChangeDetailsModal: false,
            showNextActionModal: false
        })
    }

    closeAddParticipationChangeModal = () => {
        context.getChangeParticipantionsList();
        context.setState({
            changeParticipationsData: context.getModal('resetData'),
            showAddParticipationChangesModal: false
        })
        setTimeout(() => context.createSelectOptions(), 100);
        sessionStorage.removeItem('modalOpen');
    }

    handleParticipationChangeSelectChange(event) {
        console.log("selectchange : ", event.target.name);
        let name = event.target.name;
        let value = event.target.value;
        this.setState((prevState) => ({
            ...prevState,
            changeParticipationsData: {
                ...prevState.changeParticipationsData,
                [name]: value
            }
        }));
    }

    handleMultiSelectChanges = (selectedOption, event) => {
        var updatedState = this.state.changeParticipationsData;
        updatedState[event.name] = selectedOption;
        this.setState(prevState => ({
            ...prevState,
            changeParticipationsData: updatedState
        }));
    };

    saveParticipationChangesdetails = (event) => {
        if (sessionStorage.getItem('clientId') !== '') {
            var jsonData = '';
            if (sessionStorage.getItem('changeParticipantionsListData_length') === 0) {
                jsonData = { 'className': 'ParticipationChangeInformation', 'key': (sessionStorage.getItem('clientId') + "-1"), 'published': true };
            } else {
                jsonData = { 'className': 'ParticipationChangeInformation', 'key': (sessionStorage.getItem('clientId') + "-" + (parseInt(sessionStorage.getItem('changeParticipantionsListData_length')) + 1)), 'published': true };
            }
            jsonData.elements = [];
            let jsonData_validation = [];
            let keys = Object.keys(context.getModal("changeParticipationsData"));
            keys.map((key) => {
                if (key === 'follow_up_date' || key === 'discharged_date') {
                    let dateStr = '';
                    if (context.state.changeParticipationsData[key] !== '') {
                        dateStr = moment(context.state.changeParticipationsData[key]).format('YYYY-MM-DD');//getFormattedDate(context.state.changeParticipationsData[key])
                    }
                    jsonData.elements.push({ name: context.state.fieldName[key], value: dateStr });

                } else {
                    jsonData.elements.push({ name: context.state.fieldName[key], value: context.state.changeParticipationsData[key] });
                }
                if (context.state.changeParticipationsData[key] !== '') {
                    jsonData_validation.push(key);
                }
            })
            var client_id = sessionStorage.getItem('clientId');
            jsonData.elements.push({ "name": 'client_id', 'value': client_id });
            if (jsonData_validation.length === 0) {
                sessionStorage.setItem('error_msg', 'Empty Information is not saved');
                window.location.href = "";
            } else {
                var json = JSON.stringify(jsonData);
                axios.post(global.save_url, json)
                    .then((res) => {
                        var response = res.data;
                        if (response.success && res.status == 200) {
                            sessionStorage.setItem('success_msg', "Participation Change added successfully.");
                            sessionStorage.setItem('changeParticipantionsListData_length', (parseInt(sessionStorage.getItem('changeParticipantionsListData_length')) + 1));

                            let client_status = '';
                            if (context.state.changeParticipationsData.reason_for_change === 'Client being discharged') {
                                client_status = 'Inactive';
                            } else if (context.state.changeParticipationsData.reason_for_change === 'Client moving to long-term follow-up') {
                                client_status = 'Inactive';
                            } else if (context.state.changeParticipationsData.reason_for_change === 'Client is banned') {
                                client_status = 'Suspended';

                                //  =========== Code added by Kuldeep Singh ============================= for deceased client deactivete  ==========================  Start  ====================================================================================

                            } else if (context.state.changeParticipationsData.reason_for_change === 'Deceased') {
                                client_status = 'Suspended';
                            }

                            //  =========== Code added by Kuldeep Singh ============================= for deceased client deactivete  ==========================  End  ====================================================================================

                            if (client_status !== '') {
                                let client_status_json = { 'className': 'ClientInformation', 'key': sessionStorage.getItem('clientId'), 'id': sessionStorage.getItem('responceId'), 'published': true };
                                client_status_json.elements = [];
                                client_status_json.elements.push({ name: 'client_status', value: client_status });
                                client_status_json.elements.push({ name: 'last_updated_by', value: sessionStorage.getItem('loggedinUserID') });
                                client_status_json.elements.push({ name: 'last_updated_on', value: moment().format('YYYY-MM-DD HH:mm') });
                                axios.post(global.save_url, JSON.stringify(client_status_json)).then(res => {
                                    var respns = res.data;
                                    if (respns.success && res.status == 200) {
                                        sessionStorage.setItem('success_msg', "Client(" + sessionStorage.getItem('clientId') + ") is " + client_status + " successfully.");
                                        // window.location.href = "/clients";
                                        setTimeout(() => {
                                            this.props.navigate("/clients");
                                        }, 2000);
                                    } else {
                                        sessionStorage.setItem('error_msg', "Failed to update Client status to " + client_status);
                                        context.openNextActionModal();
                                    }
                                })
                            } else {
                                context.openNextActionModal();
                            }
                        }
                        else if ("error_code" in response) {
                            alert(response.msg);
                            window.location.href = global.domain + global.traversArray['out'];
                        }
                        else {
                            alert(response.msg);
                            context.closeAddParticipationChangeModal();
                        }
                    })
                    .catch(function (err) {
                        console.log(err.message);
                    }
                    );
            }
        }
        else {
            console.log("Please fill complete form to save ! ");
        }
        sessionStorage.removeItem('modalOpen');
    }

    closeNextActionModal() {
        context.getChangeParticipantionsList();
        context.setState({
            showNextActionModal: false
        });
    }

    openNextActionModal() {
        context.getChangeParticipantionsList();
        context.setState({
            showAddParticipationChangesModal: false,
            showParticipationChangeDetailsModal: false,
            showNextActionModal: true
        });
    }

    closeParticipationChangeDetailsModal() {
        context.setState({
            changeParticipationsData: context.getModal('resetData'),
            showParticipationChangeDetailsModal: false
        });
        setTimeout(() => context.createSelectOptions(), 100);
    }

    openParticipationChangeDetailsModal() {
        context.setState({
            showAddParticipationChangesModal: false,
            showParticipationChangeDetailsModal: true,
            showNextActionModal: false
        });
    }

    handleDateChange = name => date => {
        var stateCopy = Object.assign({}, this.state);
        stateCopy.changeParticipationsData[name] = date;
        this.setState(stateCopy);
    };

    route = (route) => {
        sessionStorage.setItem('modalOpen', true);
        switch (route) {
            case 'Follow-Up':
                window.location.href = global.domain + 'followup';
                break;

            case 'Routing':
                window.location.href = global.domain + 'routing';
                break;

            case 'Referral':
                window.location.href = global.domain + 'referrals';
                break;

        }
    }

    handleTimer(date, field) {
        console.log("timer", date, field);
        let changeParticipationsData_temp = this.state.changeParticipationsData;

        changeParticipationsData_temp[field] = date._d !== undefined ? moment(date._d).format('MM/DD/YYYY hh:mm A') : date;

        context.setState({ changeParticipationsData: changeParticipationsData_temp });
    };

    handleTimeronBlur(date, field) {
        date = date ? date : context.state.changeParticipationsData[field];
        let changeParticipationsData_temp = this.state.changeParticipationsData;
        changeParticipationsData_temp[field] = typeof date === 'string' && typeof date !== 'object' && date !== "" ? moment().format('MM/DD/YYYY') : (typeof date == 'object' && date !== null ? moment(date._d).format('MM/DD/YYYY') : "");
        context.setState({ changeParticipationsData: changeParticipationsData_temp, unSaved: true });
    };

    render() {

        $(document).ready(function () {
            $('#changeParticipationsForm').validate({
                submitHandler: function () {
                    context.saveParticipationChangesdetails();
                },
                errorElement: 'span',
                errorPlacement: function (error, element) {
                    error.addClass('invalid-feedback');
                    element.closest('.formfield').append(error);
                },
                highlight: function (element, errorClass, validClass) {
                    $(element).addClass('is-invalid');
                },
                unhighlight: function (element, errorClass, validClass) {
                    $(element).removeClass('is-invalid');
                }
            });
        });

        sessionStorage.setItem('formname', 'Participation Change');

        return (

            <div className="hold-transition sidebar-mini text-sm">
                <div className="wrapper">
                    <AppTopNav />
                    <div className="content-wrapper">
                        <Patientinfoheader clientId={sessionStorage.getItem('clientId')} dob={encryptStorage.getItem('dob')}
                            email={encryptStorage.getItem('email')} phone={encryptStorage.getItem('phone')}
                            peer_support_specialist={encryptStorage.getItem('peer_support_specialist')} next_followup_date={encryptStorage.getItem('month_1_followup_date')} intake_date={encryptStorage.getItem('intake_date')} initiated_by={encryptStorage.getItem('last_updated_by')} formname={sessionStorage.getItem('formname')} client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')} />
                        <section className="content">
                            <div className="row">
                                <Sidenav />
                                <div className="container-fluid col-md-9 col-lg-10">
                                    <div className="card card-default">
                                        <div className="card-header">
                                            <p className="lead float-left"><b className="text-green">Participation Change Information</b></p>
                                            <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() =>
                                                this.props.navigate(`${global.traversArray['change_participation']}`)

                                            }><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                                            {can_add('ParticipationChangeInformation') ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.openAddParticipationChangeModal} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add Participation Info</button> : ""}
                                            <input type="hidden" id="clicked" value="" readOnly />
                                        </div>
                                        <div className="card-body">
                                            {console.log("data...", this.state.changeParticipantionsHistoryData)}

                                            <DataTable
                                                className='table_Data'
                                                columns={this.state.changeParticipationsHistoryColumns}
                                                data={this.state.changeParticipantionsHistoryData}
                                                customStyles={global.customTableStyles}
                                                striped={true}
                                                pagination={this.state.changeParticipantionsHistoryData ? true : false}
                                                paginationComponentOptions={global.paginationComponentOptions}
                                                highlightOnHover
                                                pointerOnHover
                                                noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                                            />
                                        </div>
                                        <div className="card-footer ">
                                            <div className="float-right">
                                                <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() =>
                                                    this.props.navigate(`${global.traversArray['change_participation']}`)

                                                }><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>

                                                {can_add('ParticipationChangeInformation') ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.openAddParticipationChangeModal} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add Participation Info</button> : ""}
                                                <input type="hidden" id="clicked" value="" readOnly />

                                            </div>
                                        </div>

                                        <Modal
                                            showOverlay={true}
                                            show={this.state.showAddParticipationChangesModal}
                                            onClose={this.closeAddParticipationChangeModal}
                                            disableOverlayClick={true}
                                            showCloseButton={false}
                                            size="md"
                                            className="modal-dialog modal-lg"
                                        >
                                            <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                                <Modal.Title>
                                                    <div className="row" >
                                                        <div className="col-md-6">
                                                            <h4 className="modal-title float-left text-green">Change Participation</h4>
                                                        </div>
                                                        {/*<div className="col-md-6"  > 
                                                                <button id="Open" type="submit" className="btn btn-success float-right btnmargin"  htmlType="submit" form="changeParticipationsForm" > <i className="fas fa-save"></i> Save</button>
                                                            </div>*/}
                                                    </div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <form id="changeParticipationsForm" method="post" action="javascript:void(0)">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <LabelField {...context.state.reason_for_change} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 formfield">
                                                            <select required={context.state.reason_for_change.mandatory} name={context.state.fieldName.reason_for_change} className="custom-select" aria-hidden="true" onChange={this.handleParticipationChangeSelectChange} >
                                                                {this.state.reason_for_changeSelectOptions}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <LabelField {...context.state.treatment_type} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 formfield">
                                                            <select required={context.state.treatment_type.mandatory} name={context.state.fieldName.treatment_type} className="custom-select" aria-hidden="true" onChange={this.handleParticipationChangeSelectChange} >
                                                                {this.state.treatment_typeSelectOptions}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <LabelField {...context.state.agency} className='marginleft' />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 formfield">
                                                            <select required={context.state.mandatoryInfo.agency === true} name={context.state.fieldName.agency} className="custom-select" aria-hidden="true" onChange={this.handleParticipationChangeSelectChange} >
                                                                {this.state.agencySelectOptions}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {context.state.changeParticipationsData.agency === "Other (Please Specify Below)" ? (
                                                        <div className="row">
                                                            <div className="col-md-6" />
                                                            <div className="col-md-6 formfield">
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control" name={context.state.fieldName.other_agency} id={context.state.fieldName.other_agency} placeholder={this.state.labelText.other_agency} defaultValue={this.state.changeParticipationsData.other_agency} required={this.state.mandatoryInfo.other_agency} maxLength={this.state.other_agency.columnLength} onChange={this.handleParticipationChangeSelectChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : <input type="hidden" name="other_agency" value="" />}
                                                    {/* <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <LabelField {...context.state.follow_up_date} className='marginleft' />(MM/DD/YYYY)
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 formfield">
                                                            <div className="form-group">
                                                                <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                                                    <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                                </div>
                                                                <div className="input-group date timepicker" style={{ width: "calc(100% - 36px)" }}>
                                                                    <span style={{ width: '100%' }}>
                                                                        <DateTime
                                                                            onChange={(date) => this.handleTimer(date, 'follow_up_date')}
                                                                            onBlur={(date) => this.handleTimeronBlur(date, 'follow_up_date')}
                                                                            inputProps={{ value: typeof context.state.changeParticipationsData.follow_up_date === 'string' && context.state.changeParticipationsData.follow_up_date !== null && context.state.changeParticipationsData.follow_up_date !== undefined ? context.state.changeParticipationsData.follow_up_date : moment.utc(context.state.changeParticipationsData.follow_up_date).format('MM/DD/YYYY'), required: context.state.mandatoryInfo.follow_up_date, placeholder: '30 Day follow-up Date' }}
                                                                            defaultValue={moment.utc().format('MM/DD/YYYY')}
                                                                            input={true}

                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <LabelField {...context.state.have_client_accessed_referral} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 formfield">
                                                            <select required={context.state.have_client_accessed_referral.mandatory} name={context.state.fieldName.have_client_accessed_referral} className="custom-select" aria-hidden="true" onChange={this.handleParticipationChangeSelectChange} >
                                                                {this.state.have_client_accessed_referralSelectOptions}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {context.state.changeParticipationsData.have_client_accessed_referral === "Yes" ? (
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <LabelField {...context.state.which_mat_clinic} className='marginleft' />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 formfield">
                                                                    <select required={context.state.which_mat_clinic.mandatory} name={context.state.fieldName.which_mat_clinic} className="custom-select" aria-hidden="true" onChange={this.handleParticipationChangeSelectChange} >
                                                                        {this.state.which_mat_clinicSelectOptions}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            {context.state.changeParticipationsData.which_mat_clinic === "Other (Please Specify Below)" ? (
                                                                <div className="row">
                                                                    <div className="col-md-6" />
                                                                    <div className="col-md-6 formfield">
                                                                        <div className="form-group">
                                                                            <input type="text" className="form-control" name={context.state.fieldName.other_mat_clinic} id={context.state.fieldName.other_mat_clinic} placeholder={this.state.labelText.other_mat_clinic} defaultValue={this.state.changeParticipationsData.other_mat_clinic} required={this.state.mandatoryInfo.other_mat_clinic} maxLength={this.state.other_mat_clinic.columnLength} onChange={this.handleParticipationChangeSelectChange} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : <input type="hidden" name="other_mat_clinic" value="" />}
                                                            <div className="row">
                                                                <div className="col-md-6 ">
                                                                    <div className="form-group">
                                                                        <LabelField {...context.state.has_received_mat_medi} className='marginleft' />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 formfield">
                                                                    <select required={context.state.has_received_mat_medi.mandatory} name={context.state.fieldName.has_received_mat_medi} className="custom-select" aria-hidden="true" onChange={this.handleParticipationChangeSelectChange} >
                                                                        {this.state.has_received_mat_mediSelectOptions}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <LabelField {...context.state.discharged_date} className='marginleft' />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 formfield">
                                                                    <div className="form-group">

                                                                        <div className="input-group date">
                                                                            <div className="input-group-prepend">
                                                                                <span className="input-group-text">
                                                                                    <i className="far fa-calendar-alt"></i>
                                                                                </span>
                                                                            </div>
                                                                            <DatePicker dateFormat="MM/dd/yyyy" required={context.state.mandatoryInfo.discharged_date === true} className="form-control pull-right" selected={this.state.changeParticipationsData.discharged_date} onChange={this.handleDateChange('discharged_date')} id="discharged_date" name="follow_up_date" showYearDropdown />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : <input type="hidden" name="which_mat_clinic" value="" />}
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <LabelField {...context.state.why_refused_contact} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 formfield">
                                                            <select required={context.state.why_refused_contact.mandatory} name={context.state.fieldName.why_refused_contact} className="custom-select" aria-hidden="true" onChange={this.handleParticipationChangeSelectChange} >
                                                                {this.state.why_refused_contactSelectOptions}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {context.state.changeParticipationsData.why_refused_contact === "Other" ? (
                                                        <div className="row" style={{ marginBottom: '10px' }}>
                                                            <div className="col-md-6" />

                                                            <div className="col-md-6 formfield">
                                                                <input type="text" className="form-control" name={context.state.fieldName.other_refused_reason} id={context.state.fieldName.other_refused_reason} placeholder={this.state.labelText.other_refused_reason} defaultValue={this.state.changeParticipationsData.other_refused_reason} required={this.state.mandatoryInfo.other_refused_reason} maxLength={this.state.other_refused_reason.columnLength} onChange={this.handleParticipationChangeSelectChange} />
                                                            </div>
                                                        </div>
                                                    ) : <input type="hidden" name="other_refused_reason" value="" />}

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <LabelField {...context.state.why_unable_to_contact} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 formfield">
                                                            <select required={context.state.why_unable_to_contact.mandatory} name={context.state.fieldName.why_unable_to_contact} className="custom-select" aria-hidden="true" onChange={this.handleParticipationChangeSelectChange} >
                                                                {this.state.why_unable_to_contactSelectOptions}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {context.state.changeParticipationsData.why_unable_to_contact === "Other" ? (
                                                        <div className="row">
                                                            <div className="col-md-6" />

                                                            <div className="col-md-6 formfield">
                                                                <input type="text" className="form-control" name={context.state.fieldName.other_unable_contact_reason} id={context.state.fieldName.other_unable_contact_reason} placeholder={this.state.labelText.other_unable_contact_reason} defaultValue={this.state.changeParticipationsData.other_unable_contact_reason} required={this.state.mandatoryInfo.other_unable_contact_reason} maxLength={this.state.other_unable_contact_reason.columnLength} onChange={this.handleParticipationChangeSelectChange} />
                                                            </div>
                                                        </div>
                                                    ) : <input type="hidden" name="other_unable_contact_reason" value="" />}
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 formfield">
                                                            <div className="form-group">
                                                                <LabelField {...context.state.note_section} />
                                                                <textarea className="form-control" id={context.state.fieldName.note_section} name={context.state.fieldName.note_section} defaultValue={context.state.changeParticipationsData.note_section} rows="3" onChange={this.handleParticipationChangeSelectChange} ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </Modal.Body>
                                            <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="justify-content-between" >
                                                            <Button className="btn btn-default float-left" id="closeButton" onClick={this.closeAddParticipationChangeModal} type="button" form="myForm" key="submit" htmlType="submit"> Close</Button>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6" >
                                                        <button id="saveButton" type="submit" className="btn btn-success float-right btnmargin" htmlType="submit" form="changeParticipationsForm"><i className="fas fa-save"></i> Save</button>
                                                    </div>
                                                </div>
                                            </Modal.Footer>
                                        </Modal>
                                        <Modal
                                            showOverlay={true}
                                            show={this.state.showNextActionModal}
                                            onClose={this.closeNextActionModal}
                                            disableOverlayClick={true}
                                            showCloseButton={true}
                                            size="sm"
                                            className="modal-md modal-dialog"
                                        >
                                            <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                                <Modal.Title>
                                                    <div className="row" >
                                                        <div className="col-md-8">
                                                            <h4 className="modal-title float-left text-green">Next Actions you can take</h4>
                                                        </div>
                                                    </div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <p style={{ paddingLeft: "10px" }}>Change Participation is completed, you can choose to do any one of the following or click X above, to do nothing</p>
                                                    </div>
                                                    <div className="row" style={{ margin: "10px 0px 50px 0px", width: "100%" }}>


                                                        <div className="col-md-4">
                                                            <div className="btn btn-app btn-green btn-success" type="button" style={{ width: "133px" }} onClick={() => this.route('Follow-Up')}><i className="fas fa-calendar-check"></i> Add Follow-Up</div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="btn btn-app btn-green btn-success" type="button" style={{ width: "133px" }} onClick={() => this.route('Routing')} ><i className="fas fa-medkit"></i> Add Routing</div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="btn btn-app btn-success" type="button" style={{ width: "133px" }} onClick={() => this.route('Referral')}><i className="fas fa-prescription"></i> Add Referral</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>

                                        <Modal
                                            showOverlay={true}
                                            show={this.state.showParticipationChangeDetailsModal}
                                            onClose={this.closeParticipationChangeDetailsModal}
                                            disableOverlayClick={true}
                                            showCloseButton={false}
                                            size="sm"
                                            className="modal-md modal-dialog"
                                        >
                                            <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                                <Modal.Title>
                                                    <div className="row" >
                                                        <div className="col-md-12">
                                                            <h4 className="modal-title float-left text-green">Participation Change Info</h4>
                                                        </div>
                                                    </div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p className="boldText">{context.state.labelText.reason_for_change}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p >{context.state.changeParticipationsData.reason_for_change}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p className="boldText">{context.state.labelText.treatment_type}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p >{context.state.changeParticipationsData.treatment_type}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p className="boldText">{context.state.labelText.agency}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p >{context.state.changeParticipationsData.agency}</p>
                                                                {/* <p >{context.state.changeParticipationsData.other_agency !== '' ? context.state.changeParticipationsData.other_agency : context.state.changeParticipationsData.agency}</p> */}
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {/* <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p className="boldText">{context.state.labelText.follow_up_date}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p >{context.state.changeParticipationsData.follow_up_date}</p>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p className="boldText">{context.state.labelText.have_client_accessed_referral}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p>{context.state.changeParticipationsData.have_client_accessed_referral}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {context.state.changeParticipationsData.have_client_accessed_referral === 'Yes' ?
                                                        <>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <p className="boldText">{context.state.labelText.which_mat_clinic}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <p>{context.state.changeParticipationsData.other_mat_clinic !== '' ? context.state.changeParticipationsData.other_mat_clinic : context.state.changeParticipationsData.which_mat_clinic}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <p className="boldText">{context.state.labelText.has_received_mat_medi}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <p>{context.state.changeParticipationsData.has_received_mat_medi}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <p className="boldText">{context.state.labelText.discharged_date}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <p>{context.state.changeParticipationsData.discharged_date}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </> : ""
                                                    }
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p className="boldText">{context.state.labelText.why_refused_contact}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p>{context.state.changeParticipationsData.why_refused_contact}</p>
                                                                {/* <p>{context.state.changeParticipationsData.other_refused_reason !== '' ? context.state.changeParticipationsData.other_refused_reason : context.state.changeParticipationsData.why_refused_contact}</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p className="boldText">{context.state.labelText.why_unable_to_contact}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p>{context.state.changeParticipationsData.why_unable_to_contact}</p>
                                                                {/* <p>{context.state.changeParticipationsData.other_unable_contact_reason !== '' ? context.state.changeParticipationsData.other_unable_contact_reason : context.state.changeParticipationsData.why_unable_to_contact}</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12">
                                                            <div className="form-group">
                                                                <p className="boldText">{context.state.labelText.note_section}</p>
                                                            </div>
                                                            <div className="">
                                                                {context.state.changeParticipationsData.note_section}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        {/* <div >
                                                                <button type="submit" className="btn btn-primary float-right" value="proceed" ><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents : "none" }} /> Save &amp; Proceed </button>                                          
                                                                <button type="submit" value='save' className="btn btn-success float-right btnmargin" > <i className="fas fa-save" style={{ pointerEvents : "none" }}></i> Save</button>
                                                            </div> */}
                                                        <div className="justify-content-between" >
                                                            <button className="btn btn-default float-left" id="closeButton" onClick={this.closeParticipationChangeDetailsModal} type="button">Close</button>
                                                            {/* <button className="btn btn-success" type="button" style={{display : this.state.formAccessMode}} onClick={context.saveFollowUpdetails} ><i className="fas fa-save"></i>&nbsp;Save</button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export function AppwithChangeParticipation(props) {
    const navigate = useNavigate();
    return <ChangeParticipation navigate={navigate}></ChangeParticipation>;
}

export default ChangeParticipation;