import React , {Component} from 'react';
import $ from 'jquery';
import Topnav from './Topnav';	
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import {validationParser} from '../ReusableComponents/validationParser2' ;
import {formObjDataMapper} from '../ReusableComponents/formObjDataMapper' ;
//Tooltip imports
import ReactTooltip from 'react-tooltip';
import Tooltip from '../ReusableComponents/Tooltip' ;
import moment from 'moment';
import { showSuccessToast } from './toastService';
import axios from 'axios';


var validationObj = {} ;

var context ;

class QLESQSF extends Component{
	constructor () {
    super();

    context = this ;

    this.handleChange = this.handleChange.bind(this);
    this.overallResponseCalculator = this.overallResponseCalculator.bind(this);

    this.state = {

        qlesqsfData : this.getModal(),
        mandatoryInfo : this.getModal(),
        labelText : this.getModal(),
        tooltips : this.getModal(),
        tooltipsVisibility : this.getModal(),
        fieldName : this.getModal(),
        radioOptions : this.getModal(),
        qlesqsfHistoryData:0,
        overAllResponseCount : {
            counter1 : 0,
            counter2 : 0,
            counter3 : 0,
            counter4 : 0,
            counter5 : 0,
            totalScore : 0,
            percScore:0
        }
    };

    }

    handleChange(event) {

        console.log("event name : " , event.target.name , event.target.value) ;

        let tempOb = this.state.qlesqsfData ;

        tempOb[event.target.name] = event.target.value ;

        this.setState((prevState) => ({
            ...prevState,
            qlesqsfData : tempOb
        })
        );


        this.overallResponseCalculator();

        console.log("ql data : " , this.state.overAllResponseCount)

      }

    getModal()
    {
        var cssrDataModal = {
            physical_health : '',
            mood : '',
            work : '',
            household_activity : '',
            social_relationship : '',
            family_relationship : '',
            leis_time_activity : '',
            fun_in_daily_life : '',
            sexual_drive_inter : '',
            economic_status : '',
            living_situation : '',
            ability_to_get_physically : '',
            ability_to_do_work : '',
            sense_of_well_being1 : '',
            medication : '',
            sense_of_well_being2 : '',
        } ;

        return cssrDataModal ;
    }

    componentWillMount(){
        //for validation
        
        axios.get(global.restApiURL+'webservice/rest/class/id/13?apikey='+sessionStorage.getItem('__CL_TOKEN__'))
          .then(res => {
            var response = res.data;   
    
            if(response.success && res.status==200)
            {
              validationObj = validationParser(response.data.layoutDefinitions.children[0].children);
              console.log("validation" , validationObj) ;
    
              this.setState({
                labelText : validationObj.title,
                mandatoryInfo : validationObj.mandatory,
                tooltips : validationObj.tooltip,
                tooltipsVisibility : validationObj.tooltipVisibility,
                fieldName : validationObj.name,
                // radioOptions : validationObj.options
              });

            //   console.log("state : " , this.state.radioOptions.physical_health[0].value);
              
            }
            else if ( "error_code" in response )
            {
              alert( response.msg ) ;
              window.location.href = global.domain+global.traversArray['out'];
            }
            else
            {
                alert( response.msg ) ;
            }
           
            })
            .catch(err => { // log request error and prevent access to undefined state
              this.setState({ loading: false, error: true });
              console.error(err); 
            });

      }

    componentDidMount(){
      this.props.onRef(this)
        var prevExist = false;
        sessionStorage.setItem('ObectID','');
        
        if(sessionStorage.getItem('clientId')!=='')
        {
            axios.get(global.restApiURL + 'webservice/rest/object-list?apikey='+sessionStorage.getItem('__TOKEN__')+'&objectClass=QLESQSF&q={"client_id":"'+sessionStorage.getItem('clientId')+'"}')
            .then(res => {

                const response = res.data; // get the data array instead of object

                if(response.success && res.status==200)
                {
                    console.log("object fetcher : " , response) ;

                    if(response.data){
                        prevExist = true;
                        console.log("prevExist : " , prevExist);
                        
                        this.setState({
                            qlesqsfHistoryData : response.total 
                        })

                       
                    }else{
                        prevExist = false;
                    }
                }
                else if ( "error_code" in response )
                {
                    alert( response.msg ) ;
                    window.location.href = global.domain+global.traversArray['out'];
                }
                else
                {
                    alert( response.msg ) ;
                }

        })
        .catch(err => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err); 
        })

        if(this.props.obj_id && this.props.obj_id > 0){
          axios.get(global.restApiURL + 'webservice/rest/object/id/'+this.props.obj_id+'?apikey='+sessionStorage.getItem('__TOKEN__'))
                .then(res => {

                    sessionStorage.setItem('ObectID',this.props.obj_id);
                    sessionStorage.setItem('Obectkey', res.data.data.key);
                    const response = res.data.data.elements;
                    console.log("Obj data : " , response) ;

                    this.setState({
                    qlesqsfData : formObjDataMapper(response , this.getModal()) 
                    })
                    setTimeout(() => {
                        this.overallResponseCalculator();
                    }, 500);
                })
                .catch(err => { // log request error and prevent access to undefined state
                    this.setState({ loading: false, error: true });
                    console.error(err); 
                })
        }
        }
      }
        //Post data to save

        save = (event) => 
        {   
          /*let saveType =  event.target.value ;
          console.log( "save : " , saveType );*/
          let saveType = 'save';
      
            
          
          var jsonData ='';
          if(sessionStorage.getItem('ObectID') && sessionStorage.getItem('Obectkey')){
                jsonData = {'className':'QLESQSF','key':sessionStorage.getItem('Obectkey'),'id': sessionStorage.getItem('ObectID'),'published':true};
            }else{
                jsonData = {'className':'QLESQSF','key':sessionStorage.getItem('clientId')+'-'+this.state.qlesqsfHistoryData,'published':true};	
            }
            
            jsonData.elements = [];
            var formData = $("#qlesqsfform").serializeArray();    	
            
            console.log("formdata : " , formData) ;

            let keys = Object.keys(context.getModal());

            keys.map((key) => {
                jsonData.elements.push({name: context.state.fieldName[key] , value: context.state.qlesqsfData[key] }) ;
            })
          jsonData.elements.push({name: 'client_id' , value: sessionStorage.getItem('clientId') }) ;
          jsonData.elements.push({name: 'interviewer', value: sessionStorage.getItem('loggedinUserID') }) ;
          jsonData.elements.push({name: 'score' , value: context.state.overAllResponseCount.totalScore }) ;
          jsonData.elements.push({name: 'score' , value: context.state.overAllResponseCount.totalScore }) ;
          jsonData.elements.push({name: 'perc_score' , value: context.state.overAllResponseCount.percScore }) ;
          jsonData.elements.push({name: 'total_1' , value: context.state.overAllResponseCount.counter1 }) ;
          jsonData.elements.push({name: 'total_2' , value: context.state.overAllResponseCount.counter2 }) ;
          jsonData.elements.push({name: 'total_3' , value: context.state.overAllResponseCount.counter3 }) ;
          jsonData.elements.push({name: 'total_4' , value: context.state.overAllResponseCount.counter4 }) ;
          jsonData.elements.push({name: 'total_5' , value: context.state.overAllResponseCount.counter5 }) ;
          jsonData.elements.push({name: 'updated_time' , value: moment().format('MM/DD/YYYY HH:mm:ss') }) ;
          var json =  JSON.stringify(jsonData);
          
          console.log("Data to send : " , json) ;
                
              axios.post(global.save_url, json)
                  .then(function (res) {      	    
                var response = res.data;
                console.log("save response" , response.success );
                if( response.success )
                {
                  sessionStorage.setItem('success_msg',"Q-LES-Q-SF saved successfully.");
                  if($('#clicked').val()==='proceed')
                    {
                        window.location.href = global.domain+global.traversArray['qlesqsf'];
                    }
                    else
                    {
                        showSuccessToast(sessionStorage.getItem("success_msg"))
                    }
                }
                else
                {
                  sessionStorage.setItem( 'success_msg' , "Something went wrong!" );
                  
                }
                sessionStorage.removeItem('Obectkey');
                  sessionStorage.removeItem('ObectID');
                  console.log("Msg : " , sessionStorage.getItem( 'success_msg' ) );    
                })
                .catch(function (err) {
                  console.log(err.message);
                }
            );
      }

  //Handles save and save proceed buttons
  handleId = (e) => {

    console.log("handle id")

    $('#clicked').val(e.target.value);
    $('#clicked').val(e.currentTarget.value);
  }

  overallResponseCalculator()
  {
      let keyArray =  Object.keys(this.getModal()) ;
      let counter1 = 0 ;
      let counter2 = 0 ;
      let counter3 = 0 ;
      let counter4 = 0 ;
      let counter5 = 0 ;
      let totalScore = 0 ;
      let percScore = 0;

      keyArray.map((key) => {
        console.log("counter : " , key , )
        if(key !== "medication" && key !== "sense_of_well_being2"){
          if(Number(this.state.qlesqsfData[key]) === 1)
              counter1++ ;
          if(Number(this.state.qlesqsfData[key]) === 2)
              counter2++ ;
          if(Number(this.state.qlesqsfData[key]) === 3)
              counter3++ ;
          if(Number(this.state.qlesqsfData[key]) === 4)
              counter4++ ;
          if(Number(this.state.qlesqsfData[key]) === 5)
              counter5++ ;
        }
        
      });

      totalScore = (counter1 * 1) + (counter2 * 2) + (counter3 * 3) + (counter4 * 4) + (counter5 * 5) ;
      percScore = parseInt(((totalScore - 14)*100)/56);
      percScore = percScore > 0 ? percScore : 0;
 
      this.setState(prevState => ({
          ...prevState,
          overAllResponseCount:{
              counter1 : counter1,
              counter2 : counter2,
              counter3 : counter3,
              counter4 : counter4,
              counter5 : counter5,
              totalScore : totalScore,
              percScore : percScore
          }
      }))
      
  }

    render()
    {
        sessionStorage.setItem('formname','Q-LES-Q-SF');

        $(document).ready(function () {
      	
            $('#qlesqsfform').validate({
          rules: {
           
          },	    
          errorElement: 'span',
          errorPlacement: function (error, element) {
            error.addClass('invalid-feedback');
            element.closest('.input-group').append(error);
          },
          highlight: function (element, errorClass, validClass) {
            $(element).addClass('is-invalid');
          },
          unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass('is-invalid');
          }
        });
            });


        return(
            <form action="javascript:void(0);" method="post" id="qlesqsfform">
              <div className="row">
                    <div className="col-md-12">
                        <label className="card-title">Taking everything into consideration, during the past week how satisfied have you been with your... </label>
                        <br/><br/>
                        <div className="table-responsive p-0 form-group">
                          <table className="table table-bordered table-striped radioTableCSS table-center">
                            <thead>
                                <tr>
                                <th style={{width:"55%"}}></th>
                                <th>Very<br/>Poor (1)</th>
                                <th>Poor (2)</th>
                                <th>Fair (3)</th>
                                <th>Good (4)</th>
                                <th>Very<br/>Good (5)</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                <tr>
                                    <td>{this.state.labelText.physical_health} <Tooltip text={this.state.tooltips.physical_health} visibiity={this.state.tooltipsVisibility.physical_health}/></td>
                                    {/* {this.state.radioOptions.physical_health} */}
                                    <td>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name={this.state.fieldName.physical_health} value="1" checked={this.state.qlesqsfData.physical_health === "1"} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.physical_health} value="2" checked={this.state.qlesqsfData.physical_health === "2"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.physical_health} value="3" checked={this.state.qlesqsfData.physical_health === "3"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.physical_health} value="4" checked={this.state.qlesqsfData.physical_health === "4"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.physical_health} value="5" checked={this.state.qlesqsfData.physical_health === "5"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td>{this.state.labelText.mood} <Tooltip text={this.state.tooltips.mood} visibiity={this.state.tooltipsVisibility.mood}/></td>
                                    <td>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name={this.state.fieldName.mood} value="1" checked={this.state.qlesqsfData.mood === "1"} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.mood} value="2" checked={this.state.qlesqsfData.mood === "2"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.mood} value="3" checked={this.state.qlesqsfData.mood === "3"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.mood} value="4" checked={this.state.qlesqsfData.mood === "4"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.mood} value="5" checked={this.state.qlesqsfData.mood === "5"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td>{this.state.labelText.work} <Tooltip text={this.state.tooltips.work} visibiity={this.state.tooltipsVisibility.work}/></td>
                                    <td>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name={this.state.fieldName.work} value="1" checked={this.state.qlesqsfData.work === "1"} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.work} value="2" checked={this.state.qlesqsfData.work === "2"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.work} value="3" checked={this.state.qlesqsfData.work === "3"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.work} value="4" checked={this.state.qlesqsfData.work === "4"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.work} value="5" checked={this.state.qlesqsfData.work === "5"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{this.state.labelText.household_activity} <Tooltip text={this.state.tooltips.household_activity} visibiity={this.state.tooltipsVisibility.household_activity}/></td>
                                    <td>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name={this.state.fieldName.household_activity} value="1" checked={this.state.qlesqsfData.household_activity === "1"} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.household_activity} value="2" checked={this.state.qlesqsfData.household_activity === "2"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.household_activity} value="3" checked={this.state.qlesqsfData.household_activity === "3"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.household_activity} value="4" checked={this.state.qlesqsfData.household_activity === "4"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.household_activity} value="5" checked={this.state.qlesqsfData.household_activity === "5"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{this.state.labelText.social_relationship} <Tooltip text={this.state.tooltips.social_relationship} visibiity={this.state.tooltipsVisibility.social_relationship}/></td>
                                    <td>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name={this.state.fieldName.social_relationship} value="1" checked={this.state.qlesqsfData.social_relationship === "1"} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.social_relationship} value="2" checked={this.state.qlesqsfData.social_relationship === "2"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.social_relationship} value="3" checked={this.state.qlesqsfData.social_relationship === "3"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.social_relationship} value="4" checked={this.state.qlesqsfData.social_relationship === "4"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.social_relationship} value="5" checked={this.state.qlesqsfData.social_relationship === "5"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{this.state.labelText.family_relationship} <Tooltip text={this.state.tooltips.family_relationship} visibiity={this.state.tooltipsVisibility.family_relationship}/></td>
                                    <td>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name={this.state.fieldName.family_relationship} value="1" checked={this.state.qlesqsfData.family_relationship === "1"} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.family_relationship} value="2" checked={this.state.qlesqsfData.family_relationship === "2"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.family_relationship} value="3" checked={this.state.qlesqsfData.family_relationship === "3"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.family_relationship} value="4" checked={this.state.qlesqsfData.family_relationship === "4"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.family_relationship} value="5" checked={this.state.qlesqsfData.family_relationship === "5"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{this.state.labelText.leis_time_activity} <Tooltip text={this.state.tooltips.leis_time_activity} visibiity={this.state.tooltipsVisibility.leis_time_activity}/></td>
                                    <td>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name={this.state.fieldName.leis_time_activity} value="1" checked={this.state.qlesqsfData.leis_time_activity === "1"} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.leis_time_activity} value="2" checked={this.state.qlesqsfData.leis_time_activity === "2"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.leis_time_activity} value="3" checked={this.state.qlesqsfData.leis_time_activity === "3"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.leis_time_activity} value="4" checked={this.state.qlesqsfData.leis_time_activity === "4"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.leis_time_activity} value="5" checked={this.state.qlesqsfData.leis_time_activity === "5"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{this.state.labelText.fun_in_daily_life} <Tooltip text={this.state.tooltips.fun_in_daily_life} visibiity={this.state.tooltipsVisibility.fun_in_daily_life}/></td>
                                    <td>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name={this.state.fieldName.fun_in_daily_life} value="1" checked={this.state.qlesqsfData.fun_in_daily_life === "1"} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.fun_in_daily_life} value="2" checked={this.state.qlesqsfData.fun_in_daily_life === "2"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.fun_in_daily_life} value="3" checked={this.state.qlesqsfData.fun_in_daily_life === "3"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.fun_in_daily_life} value="4" checked={this.state.qlesqsfData.fun_in_daily_life === "4"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.fun_in_daily_life} value="5" checked={this.state.qlesqsfData.fun_in_daily_life === "5"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{this.state.labelText.sexual_drive_inter} <Tooltip text={this.state.tooltips.sexual_drive_inter} visibiity={this.state.tooltipsVisibility.sexual_drive_inter}/></td>
                                    <td>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name={this.state.fieldName.sexual_drive_inter} value="1" checked={this.state.qlesqsfData.sexual_drive_inter === "1"} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.sexual_drive_inter} value="2" checked={this.state.qlesqsfData.sexual_drive_inter === "2"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.sexual_drive_inter} value="3" checked={this.state.qlesqsfData.sexual_drive_inter === "3"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.sexual_drive_inter} value="4" checked={this.state.qlesqsfData.sexual_drive_inter === "4"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.sexual_drive_inter} value="5" checked={this.state.qlesqsfData.sexual_drive_inter === "5"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{this.state.labelText.economic_status} <Tooltip text={this.state.tooltips.economic_status} visibiity={this.state.tooltipsVisibility.economic_status}/></td>
                                    <td>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name={this.state.fieldName.economic_status} value="1" checked={this.state.qlesqsfData.economic_status === "1"} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.economic_status} value="2" checked={this.state.qlesqsfData.economic_status === "2"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.economic_status} value="3" checked={this.state.qlesqsfData.economic_status === "3"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.economic_status} value="4" checked={this.state.qlesqsfData.economic_status === "4"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.economic_status} value="5" checked={this.state.qlesqsfData.economic_status === "5"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{this.state.labelText.living_situation} <Tooltip text={this.state.tooltips.living_situation} visibiity={this.state.tooltipsVisibility.living_situation}/></td>
                                    <td>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name={this.state.fieldName.living_situation} value="1" checked={this.state.qlesqsfData.living_situation === "1"} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.living_situation} value="2" checked={this.state.qlesqsfData.living_situation === "2"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.living_situation} value="3" checked={this.state.qlesqsfData.living_situation === "3"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.living_situation} value="4" checked={this.state.qlesqsfData.living_situation === "4"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.living_situation} value="5" checked={this.state.qlesqsfData.living_situation === "5"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{this.state.labelText.ability_to_get_physically} <Tooltip text={this.state.tooltips.ability_to_get_physically} visibiity={this.state.tooltipsVisibility.ability_to_get_physically}/></td>
                                    <td>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name={this.state.fieldName.ability_to_get_physically} value="1" checked={this.state.qlesqsfData.ability_to_get_physically === "1"} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.ability_to_get_physically} value="2" checked={this.state.qlesqsfData.ability_to_get_physically === "2"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.ability_to_get_physically} value="3" checked={this.state.qlesqsfData.ability_to_get_physically === "3"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.ability_to_get_physically} value="4" checked={this.state.qlesqsfData.ability_to_get_physically === "4"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.ability_to_get_physically} value="5" checked={this.state.qlesqsfData.ability_to_get_physically === "5"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{this.state.labelText.ability_to_do_work} <Tooltip text={this.state.tooltips.ability_to_do_work} visibiity={this.state.tooltipsVisibility.ability_to_do_work}/></td>
                                    <td>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name={this.state.fieldName.ability_to_do_work} value="1" checked={this.state.qlesqsfData.ability_to_do_work === "1"} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.ability_to_do_work} value="2" checked={this.state.qlesqsfData.ability_to_do_work === "2"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.ability_to_do_work} value="3" checked={this.state.qlesqsfData.ability_to_do_work === "3"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.ability_to_do_work} value="4" checked={this.state.qlesqsfData.ability_to_do_work === "4"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.ability_to_do_work} value="5" checked={this.state.qlesqsfData.ability_to_do_work === "5"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{this.state.labelText.sense_of_well_being1} <Tooltip text={this.state.tooltips.sense_of_well_being1} visibiity={this.state.tooltipsVisibility.sense_of_well_being1}/></td>
                                    <td>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name={this.state.fieldName.sense_of_well_being1} value="1" checked={this.state.qlesqsfData.sense_of_well_being1 === "1"} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.sense_of_well_being1} value="2" checked={this.state.qlesqsfData.sense_of_well_being1 === "2"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.sense_of_well_being1} value="3" checked={this.state.qlesqsfData.sense_of_well_being1 === "3"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.sense_of_well_being1} value="4" checked={this.state.qlesqsfData.sense_of_well_being1 === "4"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.sense_of_well_being1} value="5" checked={this.state.qlesqsfData.sense_of_well_being1 === "5"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th>Total</th>
                                    <th>{this.state.overAllResponseCount.counter1 * 1}</th>
                                    <th>{this.state.overAllResponseCount.counter2 * 2}</th>
                                    <th>{this.state.overAllResponseCount.counter3 * 3}</th>
                                    <th>{this.state.overAllResponseCount.counter4 * 4}</th>
                                    <th>{this.state.overAllResponseCount.counter5 * 5}</th>
                                </tr>
                                <tr>
                                    <th>Total Score</th>
                                    <th colSpan="5" >{this.state.overAllResponseCount.totalScore}</th>
                                </tr>
                            </tfoot>
                          </table>
                        </div>

                        <div className="table-responsive p-0 form-group">
                        <p><strong className="form-group">Optional questions not included in final score, but may help assess overall quality of life enjoyment and satisfaction</strong></p>
                          <table className="table table-bordered table-striped radioTableCSS table-center">
                            <thead>
                                <tr>
                                <th style={{width:"55%"}}></th>
                                <th>Very<br/>Poor (1)</th>
                                <th>Poor (2)</th>
                                <th>Fair (3)</th>
                                <th>Good (4)</th>
                                <th>Very<br/>Good (5)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{this.state.labelText.medication} <Tooltip text={this.state.tooltips.medication} visibiity={this.state.tooltipsVisibility.medication}/></td>
                                    <td>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name={this.state.fieldName.medication} value="1" checked={this.state.qlesqsfData.medication === "1"} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.medication} value="2" checked={this.state.qlesqsfData.medication === "2"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.medication} value="3" checked={this.state.qlesqsfData.medication === "3"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.medication} value="4" checked={this.state.qlesqsfData.medication === "4"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.medication} value="5" checked={this.state.qlesqsfData.medication === "5"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{this.state.labelText.sense_of_well_being2} <Tooltip text={this.state.tooltips.sense_of_well_being2} visibiity={this.state.tooltipsVisibility.sense_of_well_being2}/></td>
                                    <td>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name={this.state.fieldName.sense_of_well_being2} value="1" checked={this.state.qlesqsfData.sense_of_well_being2 === "1"} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.sense_of_well_being2} value="2" checked={this.state.qlesqsfData.sense_of_well_being2 === "2"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.sense_of_well_being2} value="3" checked={this.state.qlesqsfData.sense_of_well_being2 === "3"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.sense_of_well_being2} value="4" checked={this.state.qlesqsfData.sense_of_well_being2 === "4"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-check">
                                        <input className="form-check-input" type="radio" name={this.state.fieldName.sense_of_well_being2} value="5" checked={this.state.qlesqsfData.sense_of_well_being2 === "5"} onChange={this.handleChange}/>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                            </div>
                    </div>
                    <div className=" col-12 col-md-12 ">
                      <div className="row bg-green" >
                        <div className="col-lg-6 col-md-6 col-sm-6" style={{textAlign:'center', borderRight:'1px solid #fff', /*paddingRight:'30px',*/ paddingBottom:'10px'}}>
                          <p className="lead" style={{marginBottom:'0px', paddingTop:"10px"}}><b>Raw Total Score</b> = <span style={{fontSize:'1.6rem', fontWeight:'400'}}>{this.state.overAllResponseCount.totalScore}</span></p>
                          <span>(Sum of score on questions 1-14)</span></div>
                        <div className="col-lg-6 col-md-6 col-sm-6" style={{textAlign:"center"}}>
                          <p className="lead" style={{marginBottom:"0px", paddingTop:"10px"}}><b>% Maximum Score</b> = <span style={{fontSize:'1.6rem', fontWeight:'400'}}>{this.state.overAllResponseCount.percScore}</span> </p>
                          <span>(Raw total score −14)/56</span></div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12" style={{marginTop:'30px', border:'1px dashed #cec5c5', padding: '20px 20px 0px 20px', backgroundColor: "#f2f2f2" }}>
            <p className=" text-gray"><strong>Copyright notice:</strong> The Quality of Life Enjoyment and Satisfaction Questionnaire – Short Form (Q-LES-Q-SF) is copyrighted by Jean Endicott, Ph.D. Permission has been granted to 
        reproduce the scale on this website for clinicians to use in their practice and for researchers to use in non-industry studies. For other uses of the scale, the owner of the copyright should be contacted. </p>
      <p className=" text-gray"><strong>Citation:</strong> Endicott J, Nee J, Harrison W, Blumenthal R. Quality of Life Enjoyment and Satisfaction Questionnaire: A New Measure. Psychopharmacology Bulletin 1993;29:321-326. </p>
      </div>
                </div> 


            </form>
                    

        );
    }
}

export default QLESQSF 