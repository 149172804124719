import { Base64 } from 'js-base64';
import React, { Component } from 'react';
import '../global.js';
import Loader from './Loader';
import axios from 'axios';

var context;



class CallbackToLogin extends Component {
  constructor(props) {
    super();
    context = this;
  }

  componentDidMount() {
    var uri_arr = document.location.pathname.split('/');
    var php_file_name = uri_arr[uri_arr.length - 1];
    let response = JSON.parse(Base64.decode(php_file_name));
    if (response.success && (response.data.roles.length > 0 || response.data.admin === true) && response.data.apiKey !== '' && response.data.full_name !== '' && response.data.id > 0) {
      let response_data = response;
      axios.post(global.restApiURL + 'customRest/wso2SessionCheck', JSON.stringify({ 'apikey': response.data.apiKey })).then(res => {
        var response = res.data;
        if (response.success && res.status == 200) {
          axios.post(global.restApiURL + 'customRest/sendOTP', JSON.stringify({ 'apikey': response_data.data.apiKey, 'username': response_data.data.username })).then(
            async (data) => {
              if (data.data.success == true && data.status == 200) {
                sessionStorage.setItem('__TOKEN__', response_data.data.apiKey);
                sessionStorage.setItem('__CL_TOKEN__', response_data.data.classes_token);
                console.log(response_data.data.roles[0],"response_data.data.roles")
                if (response_data.data.admin === true) {
                  sessionStorage.setItem('__Res__', 'admini');
                } else {
                  if (response_data.data.roles == 64) {
                    await sessionStorage.clear();
                    window.location.href = '/';
                  } else {
                    sessionStorage.setItem('__Res__', response_data.data.roles[0]);
                  }
                }
                localStorage.setItem('username', response_data.data.username);
                sessionStorage.setItem('loggedinusername', response_data.data.full_name);
                sessionStorage.setItem('loggedinuseremail', response_data.data.email);
                sessionStorage.setItem('username', response_data.data.username);
                sessionStorage.setItem('loggedinUserID', response_data.data.id);
                sessionStorage.setItem('permissions', JSON.stringify(response_data.data.permissions));
                sessionStorage.setItem('verified', false);
                if (data.data.msg == 'Verified') {
                  sessionStorage.setItem('verified', true);
                  window.location.href = "/myclients";
                } else {
                  window.location.href = "/otp";
                }
              }
            }).catch(err => {
              context.setState({ loading: false, error: true });
              console.error(err);
              window.location.href = '/';
            });
        } else if ("error_code" in response) {
          window.location.href = global.domain + global.traversArray['out'];
        } else {
          sessionStorage.setItem('error_msg', 'Invalid User. Contact Administrator.');
          window.location.href = '/';
        }
      })
        .catch(err => { // log request error and prevent access to undefined state
          context.setState({ loading: false, error: true });
          console.error(err);
        });
    } else if ("error_code" in response) {
      window.location.href = global.domain + global.traversArray['out'];
    } else {
      sessionStorage.setItem('error_msg', 'Invalid User. Contact Administrator.');
      window.location.href = '/';
    }
  }

  render() {
    return (
      <div><Loader /></div>
    )
  }
}


export default CallbackToLogin