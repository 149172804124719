import axios from 'axios';
import React, { Suspense, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { Route, Routes,useLocation, useNavigate } from 'react-router-dom';
import { customURLGen } from '../ReusableComponents/urlGenerator';
import '../global.js';
import '../globalvars.js';
import Assessments from './Assessments';
import Authorization from './Authorization';
import CallbackToLogin from './CallbackToLogin';
import ChangePassword, { AppChangePassword } from './ChangePassword';
import Cases from './Cases';
import ChangeParticipation, { AppwithChangeParticipation } from './ChangeParticipation';
import { AppwithRouter } from './Clients';
import Consent from './Consent';
import Consent_for_ROI from './Consent_for_ROI';
import Cssr from './Cssr';
import { Appwithdashboard } from './Dasboard';
import Emergencycontact from './Emergencycontact';
import Followups from './FollowUp';
import Forgotpass from './Forgotpass';
import GadScreening from './GadScreening';
import Hangout from './Hangout';
import HealthScreening from './HealthScreening';
import Homelessness from './Homelessness';
import Intakebasicinfo from './Intakebasicinfo.js';
import Lastnightstay from './Lastnightstay';
import Login, { AppLogin } from './Login';
import Logout, { AppLogout } from './Logout';
import MyClients from "./MyClients/index";
import Notes from './Notes';
import Otheragency from './Otheragency';
import Otp from './Otp';
import PHQ9 from './PHQ9';
import QLESQSF from './QLESQSF';
import Recoverygoals from './Recoverygoals';
import Referrals from './Referrals';
import Routings from './Routings';
import SessionOut from './SessionOut';
import Supplementaryinfo from './Supplementaryinfo';
import Test from './Test';
import UserManagement from './UserManagement/index';
import ClientManagement from './ClientManagement';
import VerbalConsent from './VerbalConsent';
import AdultPlacementAssesment from './adultPlacementAssesment';
import Consentform7 from './consentform7';
import UncopeScrrening from './uncopeScrrening';
import RequestSupport from './RequestSupport.js';
//import bioMedicalCondition from './bioMedicalCondition';
//import behavioralComplications from './behavioralComplications';
//import readinessChange from './readinessChange';
//import problemPotential from './problemPotential';
//import recovery from './recovery';

// Report section
import Charts from "./Charts";
import BjaReport from "./Charts/BjaReport";
import CoverReport from "./Charts/CoverReport";
import ReferralMonthReport from "./Charts/ReferralMonthReport";
import ReferralReport from "./Charts/ReferralReport";

// Print Assesment Component
import Printasias from './Print/Printasias';
import Printdischarge from './Print/Printdischarge';
import { AppwithRouteradmin } from './AdminClients.js';
// import("../../src/newrelic.js").then(el=>{});
import swal from "sweetalert";

import ProtectedRoutes from './ProtectedRoutes.js';

import { updateGlobalState } from '../global.js';



// const useUpdateGlobalStateOnNavigation = () => {
//   const location = useLocation();

//   useEffect(() => {
//       updateGlobalState();
//   }, [location]); // This will re-run the effect every time the route changes
// };



function Main() {


  // useUpdateGlobalStateOnNavigation();
  
  return (
    <>
      <Suspense fallback="loading">
        <Routes>
          <Route exact path='/' element={<AppLogin />} />
          <Route path="/callback_to_login/:id" element={<CallbackToLogin  />} />
          <Route exact path='/clients' element={<AppwithRouter />} />
          <Route exact path='/forgotpass' element={<Forgotpass />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/changePassword" element={<AppChangePassword />} />  
          <Route exact element= {<ProtectedRoutes/>}>
          <Route exact path="/myclients" element={<MyClients />} component_name={'index'} />
          <Route exact path='/logout' element={<AppLogout />} />
          <Route exact path='/session_timeout' element={<SessionOut />} />
          <Route exact path='/userManagement' element={<UserManagement />} component_name={'UserManagement'} />
          <Route exact path='/clientManagement' element={<ClientManagement />} component_name={'ClientManagement'} />
          <Route exact path="/uncopeScreening" element={<UncopeScrrening />} />
          <Route exact path="/adultPlacementAssesment" element={<AdultPlacementAssesment />} />
          <Route exact path='/dashboard' element={<Appwithdashboard />} component_name={'Dasboard'} />
          <Route exact path='/adminrequest' element={<AppwithRouteradmin />} />
          <Route exact path='/intake-basicinfo' element={<Intakebasicinfo />} element_name={'ClientInformation'} />
          <Route exact path='/emergencycontact' element={<Emergencycontact />} component_name={'EmergencyContact'} />
          <Route exact path='/otheragency' element={<Otheragency />} component_name={'OtherAgency'} />
          <Route exact path='/authorization' element={<Authorization />} component_name={'Authorization'} />
          <Route exact path='/hangout' element={<Hangout />} component_name={'HangoutDetails'} />
          <Route exact path='/cases' element={<Cases />} element_name={'ProbationParoleCasesDetails'} />
          <Route exact path='/Homelessness' element={<Homelessness />} component_name={'HomelessnessDetails'} />
          <Route exact path='/consent' element={<Consent />} component_name={'ConsentDetails'} />
          <Route exact path='/intakeassessment' element={<Lastnightstay />} component_name={'Assessment'} />
          <Route exact path="/health_screenings" element={<HealthScreening />} component_name={'CSSR'} />
          <Route exact path='/supplementaryinfo' element={<Supplementaryinfo />} component_name={'SupplementaryInformation'} />
          <Route exact path="/routing" element={<Routings />} component_name={'RoutingForm'} />
          <Route exact path='/cssr' element={<Cssr />} component_name={'CSSR'} />
          <Route exact path='/qlesqsf' element={<QLESQSF />} component_name={'QLESQSF'} />
          <Route exact path='/phq9' element={<PHQ9 />} component_name={'PHQ9'} />
          <Route exact path='/recoverygoals' element={<Recoverygoals />} component_name={'RecoveryGoals'} />
          <Route exact path='/gadscreening' element={<GadScreening />} component_name={'GADScreeningTool'} />
          <Route exact path='/followup' element={<Followups />} component_name={'Followups'} />
          <Route exact path='/referrals' element={<Referrals />} component_name={'Referrals'} />
          <Route exact path='/notes' element={<Notes />} component_name={'Notes'} />
          <Route exact path='/test' element={<Test />} component_name={'CSSR'} />
          <Route exact path="/state_assessment" element={<Assessments />} component_name={'FinalAssessments'} />
          <Route exact path='/change_participation' element={<AppwithChangeParticipation />} component_name={'ParticipationChangeInformation'} />
          <Route exact path='/consent-for-roi' element={<Consent_for_ROI />} component_name={'Authorization'} />
          <Route exact path='/verbal-consent' element={<VerbalConsent />} component_name={'HospitalROI'} />
          <Route exact path='/consent7' element={<Consentform7 />} component_name={'ConsentForm'} />
          <Route exact path='/requestsupport' element={<RequestSupport />} component_name={'RequestSupport'} />
          {/*CHIPSS REPORT  */}
          <Route exact path="/cover_report" element={<CoverReport />} component_name={'CoverReport'} />{" "}
          <Route exact path="/referral_report" element={<ReferralReport />} component_name={'ReferralReport'} />{" "}
          {/* <Route exact  path="/rrc_monthly_report"  component={ReferralMonthReport}  />{" "} */}
          <Route exact path="/org_monthly_report" element={<ReferralMonthReport />} />{" "}
          <Route exact path="/admh_report" element={<BjaReport />} />{" "}
          <Route exact path="/chart" element={<Charts />} component_name={'Charts'} />{" "}
          <Route exact path="/printAssesment" element={<printAssesment />} component_name={'printAssesment'} />{" "}
          <Route exact path="/copecraftscreening" element={<printScreening />} component_name={'printScreening'} />{" "}
          <Route exact path="/printasias" element={<Printasias />} component_name={'Printasias'} />{" "}
          <Route exact path="/Printdischarge" element={<Printdischarge />} component_name={'Printdischarge'} />{" "}

          </Route>
       
        </Routes>
      </Suspense>
      
    </>
    
  );
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA


export default Main;








// import React, { Component , Suspense } from 'react';
// import "react-datepicker/dist/react-datepicker.css";
// import Forgotpass from './Forgotpass';
// import Login, {AppLogin} from './Login';
// import Dasboard , {Appwithdashboard} from './Dasboard';
// import Clients , {AppwithRouter}from './Clients';
// import Logout , {AppLogout} from './Logout';
// import Intakebasicinfo from './Intakebasicinfo.js';
// import Emergencycontact from './Emergencycontact';
// import Otheragency from './Otheragency';
// import Hangout from './Hangout';
// import Cases from './Cases';
// import Homelessness from './Homelessness';
// import Consent from './Consent';
// import Intakeassessment from './Intakeassessment';
// import Lastnightstay from './Lastnightstay';
// import Supplementaryinfo from './Supplementaryinfo';
// import Cssr from './Cssr';
// import QLESQSF from './QLESQSF';
// import PHQ9 from './PHQ9';
// import Recoverygoals from './Recoverygoals';
// import GadScreening from './GadScreening';
// import Followups from './FollowUp';
// import Referrals from './Referrals';
// import Assessments from './Assessments';
// import Notes from './Notes';
// import Test from './Test';
// import HealthScreening from './HealthScreening';
// import ChangeParticipation from './ChangeParticipation';
// import Routings from './Routings';
// import SessionOut from './SessionOut';
// import Authorization from './Authorization';
// import Consent_for_ROI from './Consent_for_ROI';
// import CallbackToLogin from './CallbackToLogin';
// import ChangePassword , {AppChangePassword} from './ChangePassword';
// import Otp from './Otp';
// import { customURLGen, can_add } from '../ReusableComponents/urlGenerator'
// import { Route, NavLink, Switch, BrowserRouter as Router , Routes } from 'react-router-dom'
// import { browserHistory } from 'react-router';
// import '../global.js';
// import '../globalvars.js';
// import { has_access_to } from '../ReusableComponents/urlGenerator';
// import VerbalConsent from './VerbalConsent';
// import MyClients from "./MyClients/index";
// import Consentform7 from './consentform7';
// import axios from 'axios';
// import UserManagement from './UserManagement/index';
// import ClientManagement from './ClientManagement';
// import UncopeScrrening from './uncopeScrrening';
// import AdultPlacementAssesment from './adultPlacementAssesment';
// //import bioMedicalCondition from './bioMedicalCondition';
// //import behavioralComplications from './behavioralComplications';
// //import readinessChange from './readinessChange';
// //import problemPotential from './problemPotential';
// //import recovery from './recovery';

// // Report section
// import Charts from "./Charts";
// import CoverReport from "./Charts/CoverReport";
// import ReferralReport from "./Charts/ReferralReport";
// import ReferralMonthReport from "./Charts/ReferralMonthReport";
// import BjaReport from "./Charts/BjaReport";
// import Printasias from './Print/Printasias.js';
// import Printdischarge from './Print/Printdischarge.js';
// class ProtectedRoute extends Component {
//   render() {
//     const { component: Component, ...props } = this.props;
//     //console.log('ProtectedRoute', this.props);
// 	//console.log(this.props.path);
// 	  console.log('Peer ' + global.is_clinical_specialist);
//     return (
//       <Route
//         {...props}
//         render={props => {
//           if (sessionStorage.getItem('__TOKEN__') !== '' && sessionStorage.getItem('__TOKEN__') !== null && sessionStorage.getItem('__TOKEN__') !== undefined && sessionStorage.getItem('verified') === "true") {
// 	    if (this.props.path=='/cover_report' && (global.is_admin || global.is_peer_specialist  )) {
// 		return (<><NavLink to='/cover_report' /> <CoverReport {...props} /> </>);

//             } else if (this.props.path=='/referral_report' && (global.is_admin ||  global.is_peer_specialist  ) ) {
//            	 return (<><NavLink to='/referral_report' /> <ReferralReport {...props} /> </>);
//           }else if (this.props.path=='/rrc_monthly_report' && (global.is_admin || global.is_peer_specialist  ) ) {
//            	 return (<><NavLink to='/rrc_monthly_report' /> <ReferralMonthReport {...props} /> </>);
//           }else if (this.props.path=='/admh_report' && (global.is_admin || global.is_peer_specialist  ) ) {
//            	 return (<><NavLink to='/admh_report' /> <BjaReport {...props} /> </>);
//           }else if (this.props.path=='/chart' && (global.is_admin  || global.is_peer_specialist ) ) {
//            		 return (<><NavLink to='/chart' /> <Charts {...props} /> </>);
//           }else if ((sessionStorage.getItem('clientId') === '' || sessionStorage.getItem('clientId') === null || sessionStorage.getItem('clientId') === undefined) && ((global.is_staff && this.props.path !== '/myclients') || (!global.staff && ['/intake-basicinfo', '/clients'].filter(path => path === this.props.path).length === 0))) {
//               //with empty client id a staff can only access clients page and others can access clients page and add new client page
//               //console.log('empty', ['/intake-basicinfo',  '/clients'].filter(path => path === this.props.path).length)
//               return (<><NavLink to="/myclients" /> <MyClients {...props} /> </>);
//             } else if (global.is_client_inactive && !['/dashboard', '/myclients','/clients'].filter(path => path === this.props.path).length === 0) {
//               //anyone who is visiting inactive client can see only dashboard page of client
//               //console.log('inactive', !['/dashboard',  '/clients'].filter(path => path === this.props.path).length)
//               return (<><NavLink to='/dashboard' /> <Dasboard {...props} /> </>);


//             } else if (this.props.component_name && !has_access_to(this.props.component_name)) {
//               // except Admin, check all the users permission to access the page
//               //console.log('NavLinked, client active?: ', global.is_client_inactive, ['/dashboard',  '/clients'].filter(path => path === this.props.path), )
//               return (<><NavLink to='/intake-basicinfo' /> <Intakebasicinfo {...props} /> </>);


//             }  else if (sessionStorage.getItem('clientId') !== '' && sessionStorage.getItem('clientId') !== null && sessionStorage.getItem('clientId') !== undefined && this.props.path === '/' && sessionStorage.getItem('__TOKEN__') !== '' && sessionStorage.getItem('__TOKEN__') !== null && sessionStorage.getItem('__TOKEN__') !== undefined) {
//               return (<><NavLink to="/myclients" /> <Clients {...props} /> </>);
//             } else {
//               return (<Component {...props} />)
//             }

//           } else {
//             return (<><NavLink to='/' /> <Login {...props} /> </>);
//             // return (<><NavLink to='/cover_report' /> <CoverReport {...props} /> </>);
//           }
//         }}
//       />
//     )
//   }
// }
// class Main extends Component {
//   render() {
//     if (sessionStorage.getItem('__TOKEN__')) {
//       var jsonData = { 'apikey': sessionStorage.getItem('__TOKEN__') };
//       var datasend = JSON.stringify(jsonData);
//       axios.post(customURLGen("clientList"), datasend).then(res => {
//         console.log(res);
//       }).catch(err => {
//         console.log(err);
//         // sessionStorage.clear();
//         // window.location.href="/";
//       })
//     }
//     return (
//       <Suspense fallback="loading">
//       <Routes>
//         <Route exact path='/' element={<AppLogin />} />
//         <Route exact path='/forgotpass' element={<Forgotpass />} />
//         <Route exact path='/logout' element={<AppLogout />} />
//         <Route exact path="/myclients" element={<MyClients />} component_name={'index'} />
//         <Route path="/callback_to_login/:id" render={(props) => <CallbackToLogin {...props} />} />
//         <Route exact path='/session_timeout' element={<SessionOut />} />
//         <Route path="/changePassword" element={<AppChangePassword />} />
//         <Route path="/otp" element={<Otp />} />
//         <Route exact path='/userManagement' element={<UserManagement />} component_name={'UserManagement'} />
//         <Route exact path='/clientManagement' element={<ClientManagement />} component_name={'ClientManagement'} />
//         <Route exact path="/uncopeScreening" element={<UncopeScrrening />} />
//         <Route exact path="/adultPlacementAssesment" element={<AdultPlacementAssesment />} />
//         <Route exact path='/dashboard' element={<Appwithdashboard />} component_name={'Dasboard'} />
//         <Route exact path='/clients' element={<AppwithRouter />} />
//         <Route exact path='/intake-basicinfo' element={<Intakebasicinfo />} element_name={'ClientInformation'} />
//         <Route exact path='/emergencycontact' element={<Emergencycontact />} component_name={'EmergencyContact'} />
//         <Route exact path='/otheragency' element={<Otheragency />} component_name={'OtherAgency'} />
//         <Route exact path='/authorization' element={<Authorization />} component_name={'Authorization'} />
//         <Route exact path='/hangout' element={<Hangout />} component_name={'HangoutDetails'} />
//         <Route exact path='/cases' element={<Cases />} element_name={'ProbationParoleCasesDetails'} />
//         <Route exact path='/Homelessness' element={<Homelessness />} component_name={'HomelessnessDetails'} />
//         <Route exact path='/consent' element={<Consent />} component_name={'ConsentDetails'} />
//         <Route exact path='/intakeassessment' element={<Lastnightstay />} component_name={'Assessment'} />
//         <Route exact path="/health_screenings" element={<HealthScreening />} component_name={'CSSR'} />
//         <Route exact path='/supplementaryinfo' element={<Supplementaryinfo />} component_name={'SupplementaryInformation'} />
//         <Route exact path="/routing" element={<Routings />} component_name={'RoutingForm'} />
//         <Route exact path='/cssr' element={<Cssr />} component_name={'CSSR'} />
//         <Route exact path='/qlesqsf' element={<QLESQSF />} component_name={'QLESQSF'} />
//         <Route exact path='/phq9' element={<PHQ9 />} component_name={'PHQ9'} />
//         <Route exact path='/recoverygoals' element={<Recoverygoals />} component_name={'RecoveryGoals'} />
//         <Route exact path='/gadscreening' element={<GadScreening />} component_name={'GADScreeningTool'} />
//         <Route exact path='/followup' element={<Followups />} component_name={'Followups'} />
//         <Route exact path='/referrals' element={<Referrals />} component_name={'Referrals'} />
//         <Route exact path='/notes' element={<Notes />} component_name={'Notes'} />
//         <Route exact path='/test' element={<Test />} component_name={'CSSR'} />
//         <Route exact path="/state_assessment" element={<Assessments />} component_name={'FinalAssessments'} />
//         <Route exact path='/change_participation' element={<ChangeParticipation />} component_name={'ParticipationChangeInformation'} />
//         <Route exact path='/consent-for-roi' element={<Consent_for_ROI />} component_name={'Authorization'} />
//         <Route exact path='/verbal-consent' element={<VerbalConsent />} component_name={'HospitalROI'} />
//         <Route exact path='/consent7' element={<Consentform7 />} component_name={'ConsentForm'} />

//         {/*CHIPSS REPORT  */}

//         <Route exact path="/cover_report" element={<CoverReport />} component_name={'CoverReport'} />{" "}
//         <Route exact path="/referral_report" element={<ReferralReport />} component_name={'ReferralReport'} />{" "}
//         {/* <Route exact  path="/rrc_monthly_report"  component={ReferralMonthReport}  />{" "} */}
//         <Route exact path="/org_monthly_report" element={<ReferralMonthReport />} />{" "}
//         <Route exact path="/admh_report" element={<BjaReport />} />{" "}
//         <Route exact path="/chart" element={<Charts />} component_name={'Charts'} />{" "}



//         <Route exact path="/printAssesment" element={<printAssesment />} component_name={'printAssesment'} />{" "}
//         <Route exact path="/copecraftscreening" element={<printScreening />} component_name={'printScreening'} />{" "}
//         <Route exact path="/printasias" element={<Printasias />} component_name={'Printasias'} />{" "}
//         <Route exact path="/Printdischarge" element={<Printdischarge />} component_name={'Printdischarge'} />{" "}
//       </Routes>
      
//     </Suspense>
     
//     );
//   }
// }

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA


// export default Main;
