import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PrintReportIcon from "../../assests/images/icon-print.png";
import { AppTopNav } from "../Topnav";
// import { DateRangePicker } from "react-date-range";

import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import "../daterangecss/style.css";

import moment from "moment";

import "../../../src/App.css";
import ChevronDownIcon from "../../assests/images/chevronDown.svg";
import ChevronUpIcon from "../../assests/images/chevronUp.svg";

import { CSVLink } from "react-csv";

import axios from "axios";
import ReactModal from "react-modal";
import { API_URL } from "../../commonFunctions/ApiUrl";

import { Field, Form, Formik } from "formik";
const MainContainer = styled.section`
  width: 100%;
  margin: 0 auto;
  background-color: #f9f9f9;
  border: 1px solid #d5d5d5;
  color: #333;
`;

const TableContainer = styled.div`
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  background-color: #f9f9f9;
  padding:0px;

`;

const Table = styled.table`
  border: none;
  overflow: auto;
  th,
  td {
    white-space: nowrap;
  }
  th {
    background-color: #e9e9e9;
  }
  width: 100%;
`;

const TableHead = styled.thead`
 

`;

const TableHeadRow = styled.tr``;

const TableHeadRowColumnVal = styled.th`
  border: 1px solid #d5d5d5;
  backgroundColor:red;
  tr {
    padding: 0;
    min-width:150px;
  }
`;

const Input = styled.input`
  height: 30px;
  width: 80%;
  margin: 10px auto;
  border: 1px solid #d5d5d5;
  border-radius: 2px;
  padding-left: 8px;
  ::placeholder {
    opacity: 0.8;
  }
`;

const InputHolder = styled.div`
  display: flex;
  justify-content: center;
`;

const TableBody = styled.tbody`
  tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  tr:nth-child(even) {
    background-color: #fdfcf5;
  }
  td {
    border: 1px solid #d5d5d5;
    border-bottom: none;
    border-top: none;
    padding-left: 8px;
    padding-right: 20px;
    height: 40px;
  }
  // height:400px;
  // overflow: scroll;
 
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #7bb731;
  color: #fff;
  border-radius: 4px;
  width: 120px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterDate = styled.div`
  width: 180px;
  border: 1px solid #cbcbcb;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  justify-content: center;
  margin-right: 10px;
  span {
    margin-right: 5px;
  }
  img {
    cursor: pointer;
  }
`;

const Header = styled.div`
  width: 98%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
`;

const Title = styled.div`
  width: 50%;
  height: initial;
  display: flex;
  align-items: center;
`;

const ModelTitle = styled.div`
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  // background-color: #f9f9f9;
  background-color: #89bc3b;

  font-size: 20px;
  font-weight: bold;
  // position:fixed;
`;

// const DatePickerContainer = styled.div`
//   width: 50%;
//   display: flex;
//   justify-content: flex-end;
// `;

const DatePickerContainer = styled.div`
  margin-top: 10px;
  margin-left: 50%;
  justify-content: flex-end;
  display: flex;
`;

const PrintReport = styled.div`
width: 100%;
padding:0px 0px 0px 15px;
display: flex;
justify-content: end;
align-items: center;
height: 40px;

padding-right: 16px;
background-color: #efefef;
span {
  color: #7bb731;
  margin-left: 8px;
}
img {
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

// Code By ashraf Start

const PageHeading = styled.div`
  width: 100%;
  padding:10px 0px 0px 0px;
  display: flex;
  justify-content: Center;
  align-items: center;
  height: 40px;

  padding-right: 16px;
  background-color: #efefef;
  span {
    color: #7bb731;
    margin-left: 8px;
  }
  // img {
  //   width: 20px;
  //   height: 20px;
  //   cursor: pointer;
  // }
`;




// Code By ashraf End



const DateContainer = styled.div`
  display: ${(props) => (props.openDateModal ? "block" : "none")};
`;

const tableHeaderValues = [
  "GRPA Client ID",
  "Entry Date",
  "Full Name",
  "Date Of Birth",
  "Gender",
  "Primary Race",
  "Ethincity",
  "Total Monthly Income",
  "Any alcohol",
  "Cocaine/Crack",
  "Benzodia",
];

const PageHeadingStyle=styled.div`
margin:"0px auto";
display:"block";
width:100%;
background-color:#efefef;

`;


const printreportPageContainer=styled.div`
margin:"0px auto";
display:"block";
background-color:#efefef;
width:100% !important;


`;


function ReferralMonthReport() {
  const [date, setDate] = useState("");
  const [getRcrData, setRcrData] = useState([]);

  const [openDateModal, setOpenDateModal] = useState(false);
  const [openExpendcolModal, setopenExpendcolModal] = useState(false);
  const wrapperRef = React.useRef(null);


  const [startDate,setstartDate]=useState('2021-01-01');
const [endDate,setendDate]=useState('2021-12-31');

// const OrgId = sessionStorage.getItem("organization_id");
const OrgId = 5184;
const styles = { width: 260, display: 'block', marginBottom: 10,height:50,borderRadius:"2px" };
 
  useEffect(() => {
    fetchApi(startDate,endDate);
  }, [startDate,endDate]);


 
  const fetchApi = (startDate, endDate) => {
    axios
      .get(API_URL + `rrc_monthly_report?Token=${sessionStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&startDate=${startDate}&endDate=${endDate}`)
      // .get(API_URL + `rrc_monthly_report?startDate=${startDate}&endDate=${endDate}`)
      .then((response) => {
       
        setRcrData(response.data.data);
      })
      .catch((error) => {});
  }


  const headers_for_csvfile = [
    { label: "User", key: "User" },
    { label: "User ID", key: "User_ID" },
    { label: "Client ID", key: "Client_ID" },
    { label: "GPRA Client ID", key: "GRPA_Client_ID" },
    { label: "Entry Date", key: "Entry_Date" },
    { label: "Day of the week", key: "Day_of_the_week" },
    { label: "Name", key: "Name" },
    { label: "Date of birth", key: "Date_Of_Birth" },
    { label: "Gender", key: "Gender" },
    { label: "Primary Race", key: "Primary_Race" },
    { label: "Ethnicity", key: "Ethnicity" },
    { label: "Current ZIP", key: "Current_ZIP" },
    { label: "Total Monthly Income", key: "Total_Monthly_Income" },
    { label: "a. Any alcohol", key: "Any_alcohol" },
    { label: "b1. Alcohol to intoxication (5+)", key: "Alcohol_to_intoxication_5" },
    { label: "b2. Alcohol to intoxication", key: "Alcohol_to_intoxication" },
    { label: "a. Cocaine/Crack", key: "Cocaine_Crack" },
    { label: "Cocaine/Crack (route)", key: "Cocaine_Crack_route" },
    { label: "g. 1. Benzodiazepines:", key: "Benzodiazepines" },
    { label: "Benzodiazepines (route)", key: "Benzodiazepines_route" },
    { label: "2. Barbiturates", key: "Barbiturates" },
    { label: "Barbiturates (route)", key: "Barbiturates_route" },
    { label: "b. Marijuana/Hashish", key: "Marijuana_Hashish" },
    { label: "Marijuana/Hashish (route)", key: "Marijuana_Hashish_route" },
    { label: "e. Hallucinogens/psychedelics", key: "Hallucinogens_psychedelics" },
    { label: "Hallucinogens/psychedelics (route)", key: "Hallucinogens_psychedelics_route" },
    { label: "f. Methamphetamine or other amphetamines", key: "Methamphetamine_or_other_amphetamines" },
    { label: "Methamphetamine or other amphetamines (route)", key: "Methamphetamine_or_other_amphetamines_route" },
    { label: "h. Inhalants", key: "Inhalants" },
    { label: "Inhalants (route)", key: "Inhalants_route" },
    { label: "3. Non-prescription GHB", key: "Non_prescription_GHB" },
    { label: "Non-prescription GHB (route)", key: "Non_prescription_GHB_route" },
    { label: "4. Ketamine", key: "Ketamine" },
    { label: "Ketamine (route)", key: "Ketamine_route" },
    { label: "5. Other tranquilizers, downers, sedatives, or hypnotics", key: "Other_tranquilizers_downers_sedatives_or_hypnotics" },
    { label: "Other tranquilizers, downers, sedatives, or hypnotics (route)", key: "Other_tranquilizers_downers_sedatives_or_hypnotics_route" },
    { label: "g. Other", key: "Other" },
    { label: "i. Other illegal drugs (Specify)", key: "Other_illegal_drugs_Specify" },
    { label: "Other illegal drugs (route)", key: "Other_illegal_drugs_route" },
    { label: "1. Heroin", key: "Heroin" },
    { label: "Heroin (route)", key: "Heroin_route" },
    { label: "2. Morphine", key: "Morphine" },
    { label: "Morphine (route)", key: "Morphine_route" },
    { label: "3. Dilaudid", key: "Dilaudid" },
    { label: "Dilaudid (route)", key: "Dilaudid_route" },
    { label: "4. Demerol", key: "Demerol" },
    { label: "Demerol (route)", key: "Demerol_route" },
    { label: "5. Percocet", key: "Percocet" },
    { label: "Percocet (route)", key: "Percocet_route" },
    { label: "6. Darvon", key: "Darvon" },
    { label: "Darvon (route)", key: "Darvon_route" },
    { label: "7. Codeine", key: "Codeine" },
    { label: "Codeine (route)", key: "Codeine_route" },
    { label: "8. Tylenol 2, 3, 4", key: "Tylenol" },
    { label: "Tylenol 2, 3, 4 (route)", key: "Tylenol_2" },
    { label: "9. OxyContin/Oxycodone", key: "OxyContin_Oxycodone" },
    { label: "OxyContin/Oxycodone (route)", key: "OxyContin_Oxycodone_route" },
    { label: "d. Non-prescription methadone", key: "Non_prescription_methadone" },
    { label: "Non-prescription methadone (route)", key: "Non_prescription_methadone_route" },
    { label: "Opioid", key: "Opioid" },
    { label: "Referral Source", key: "Referral_Source" },
    { label: "Specific Referral Source", key: "Specific_Referral_Source" },
    { label: "UAB SAMHSA Client ID", key: "UAB_SAMHSA_Client_ID" },
    { label: "Ref. Target", key: "Ref_Target" },
    { label: "Agency Target", key: "Agency_Target" },
    { label: "If family/friends were involved, were they referred to any services?", key: "If_family_friends_were_involved_were_they_referred_to_any_services" },
    { label: "Was the ASAM completed?", key: "Was_the_ASAM_completed" },
    { label: "Assigned Peer Support Specialist:", key: "Assigned_Peer_Support_Specialist" },
    { label: "Current County", key: "Current_County" },
    { label: "Have you ever overdosed?", key: "Have_you_ever_overdosed" },
    { label: "New/Old", key: "New_Old" },
    { label: "Trauma?", key: "Trauma" },
    { label: "Suicide Attempt", key: "Suicide_Attempt" },
    { label: "Primary Phone Number", key: "Primary_Phone_Number" },
    { label: "Address", key: "Address" },
    { label: "Timestamp", key: "Timestamp" },

  ]; 


const downloadArr=[];

  // code by ashraf End


  // code by sandeep start

const tableHeaderValuesModal = {
  Name: "Name",
  User: "User",
  User_ID: "User ID",
  Client_ID: "Client ID",
  GRPA_Client_ID: "GPRA Client ID",
  Entry_Date: "Entry Date",
  Day_of_the_week: "Day of the week",
  Date_Of_Birth: "Date of birth",
  Gender: "Gender",
  Primary_Race: "Primary Race",
  Ethnicity: "Ethnicity",
  Current_ZIP: "Current ZIP",
  Total_Monthly_Income: "Total Monthly Income",
  Any_alcohol: "a. Any alcohol",
  Alcohol_to_intoxication_5: "b1. Alcohol to intoxication (5+)",
  Alcohol_to_intoxication: "b2. Alcohol to intoxication",
  Cocaine_Crack: "a. Cocaine/Crack",
  Cocaine_Crack_route: "Cocaine/Crack (route)",
  Benzodiazepines: "g. 1. Benzodiazepines:",
  Benzodiazepines_route: "Benzodiazepines (route)",
  Barbiturates: "2. Barbiturates",
  Barbiturates_route: "Barbiturates (route)",
  Marijuana_Hashish: "b. Marijuana/Hashish",
  Marijuana_Hashish_route: "Marijuana/Hashish (route)",
  Hallucinogens_psychedelics: "e. Hallucinogens/psychedelics",
  Hallucinogens_psychedelics_route: "Hallucinogens/psychedelics (route)",
  Methamphetamine_or_other_amphetamines:
    "f. Methamphetamine or other amphetamines",
  Methamphetamine_or_other_amphetamines_route:
    "Methamphetamine or other amphetamines (route)",
  Inhalants: "h. Inhalants",
  Inhalants_route: "Inhalants (route)",
  Non_prescription_GHB: "3. Non-prescription GHB",
  Non_prescription_GHB_route: "Non-prescription GHB (route)",
  Ketamine: "4. Ketamine",
  Ketamine_route: "Ketamine (route)",
  Other_tranquilizers_downers_sedatives_or_hypnotics:
    "5. Other tranquilizers, downers, sedatives, or hypnotics",
  Other_tranquilizers_downers_sedatives_or_hypnotics_route:
    "Other tranquilizers, downers, sedatives, or hypnotics (route)",
  Other: "g. Other",
  Other_illegal_drugs_Specify: "i. Other illegal drugs (Specify)",
  Other_illegal_drugs_route: "Other illegal drugs (route)",
  Heroin: "1. Heroin",
  Heroin_route: "Heroin (route)",
  Morphine: "2. Morphine",
  Morphine_route: "Morphine (route)",
  Dilaudid: "3. Dilaudid",
  Dilaudid_route: "Dilaudid (route)",
  Demerol: "4. Demerol",
  Demerol_route: "Demerol (route)",
  Percocet: "5. Percocet",
  Percocet_route: "Percocet (route)",
  Darvon: "6. Darvon",
  Darvon_route: "Darvon (route)",
  Codeine: "7. Codeine",
  Codeine_route: "Codeine (route)",
  Tylenol: "8. Tylenol 2, 3, 4",
  Tylenol_2: "Tylenol 2, 3, 4 (route)",
  OxyContin_Oxycodone: "9. OxyContin/Oxycodone",
  OxyContin_Oxycodone_route: "OxyContin/Oxycodone (route)",
  Non_prescription_methadone: "d. Non-prescription methadone",
  Non_prescription_methadone_route: "Non-prescription methadone (route)",
  Opioid: "Opioid",
  Referral_Source: "Referral Source",
  Specific_Referral_Source: "Specific Referral Source",
  UAB_SAMHSA_Client_ID: "UAB SAMHSA Client ID",
  Ref_Target: "Ref. Target",
  Agency_Target: "Agency Target",
  If_family_friends_were_involved_were_they_referred_to_any_services:
    "If family/friends were involved, were they referred to any services?",

  Was_the_ASAM_completed: "Was the ASAM completed?",
  Assigned_Peer_Support_Specialist: "Assigned Peer Support Specialist",
  Current_County: "Current County",
  Have_you_ever_overdosed: "Have you ever overdosed?",
  New_Old: "New/Old",
  Trauma: "Trauma?",
  Suicide_Attempt: "Suicide Attempt",
  Primary_Phone_Number: "Primary Phone Number",
  Address: "Address",
  Timestamp: "Timestamp",
};

// const inval = null ;

const formInitialschema = {
 
};

const formHandelingFun = (value) => {
  // alert("test"); return false;

  const params = value;
  console.log(`Hello Ashraf ${params}`);
  if(params !== ' ' || params !== null  ) {
  const insert_rrc_col = [];
  Object.entries(params).map( ([key,value])=> {
    {console.log(`Hello Ashraf 1: ${value}`)}
   if(value === true)
 {
  insert_rrc_col.push(key)
 }    
  })

    sessionStorage.setItem("rrc_coloumns", JSON.stringify(insert_rrc_col));
  }

  // console.log(params);
  setopenExpendcolModal(false)

};
var rrc_coloumns = sessionStorage.getItem("rrc_coloumns");


console.log(Array.isArray(rrc_coloumns));

if(rrc_coloumns === ' ' || rrc_coloumns === null || Array.isArray(rrc_coloumns) === true)
{
 const insert_rrc_col1 = ["Name", "User", "User_ID", "Client_ID", "GRPA_Client_ID", "Entry_Date", "Day_of_the_week"];
  sessionStorage.setItem("rrc_coloumns", JSON.stringify(insert_rrc_col1));

}
// console.log("this data :"+rrc_coloumns);
 

// console.log("local col :" + rrc_coloumns);
// const a = rrc_coloumns?.includes("User_IDp")


// code by sandeep end


  return (
    <MainContainer>
      <AppTopNav dropdown={true} />{" "}
      
<PageHeadingStyle>
   <h3 className="py-2" style={{color:"#89BC3B",textAlign:"center"}}>RRC Monthly Report</h3>
   </PageHeadingStyle>


   <printreportPageContainer style={{width:"100%"}}>
    <div className="py-2" style={{color:"#89BC3B",textAlign:"right",display:"inlineBlock"}} >
      <img className="mr-2" style={{cursor:"pointer"}} src={PrintReportIcon} alt="print" onClick={() => window.print()} />
        <span className="mr-3" style={{cursor:"pointer"}} onClick={() => window.print()}>Print Report</span>
   </div>
   </printreportPageContainer>
      
      <Header>
        <Title> RRC Monthly Report </Title>{" "}

        <Button
          style={{ textAlign: "center", width: "300px", height: "40px" }}
          onClick={() => setopenExpendcolModal(true)}
        >
          Expand
        </Button>
        <div>
          <ReactModal
            isOpen={openExpendcolModal}
            onRequestClose={() => setopenExpendcolModal(false)}
            className="modal-dialog"
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
                // backgroundColor: 'gray',
                zIndex: 1000,
              },
              content: {
                position: "absolute",
                top: "40px",
                left: "40px",
                right: "40px",
                bottom: "40px",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                outline: "none",
                padding: "20px",
              },
            }}
          >
            <div>
              {" "}
              <ModelTitle>Expand Data </ModelTitle>{" "}
            </div>

           


            <Formik
              enableReinitialize={true}
              initialValues={formInitialschema}
              onSubmit={(value) => {
                formHandelingFun(value);
               
              }}
            >
              <Form action="" method="post" className="">
                <div style={{ paddingLeft: "2px;" }}>
                  {Object.entries(tableHeaderValuesModal).map(
                    ([key, value]) => {
                      return (
                        <>
                          <Field
                            type="checkbox"
                            name={key}
                            class="form-check-input"
                            // value={rrc_coloumns?.includes(key) ? true : false}
                            // checked={rrc_coloumns?.includes(key) ? true : false}
                            // enableReinitialize={true}
                            // {(rrc_coloumns?.includes(key) ? checked: '')}
                          />
                          
                         {/* { React.createElement('input',{type: 'checkbox', defaultChecked: (rrc_coloumns?.includes(key) ? true : false)})} */}

                          <label>{value}</label>
                          <br />
                        </>
                      );
                    }
                  )}
                </div>

                <div className=" row col-md-12">
                  <Button
                    className="btn btn-secondary col-md-4"
                    style={{
                      textAlign: "left",
                      width: "100px",
                      height: "40px",
                    }}
                    onClick={() => setopenExpendcolModal(false)}
                  >
                    close
                  </Button>
                  <div className="col-md-4"></div>
                  <Button
                    type="submit"
                    className="btn btn-secondary col-md-4"
                    style={{
                      textAlign: "right",
                      width: "100px",
                      height: "40px",
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </Formik>
          </ReactModal>
        </div>
        {/* end code by sandeep */}
        <DatePickerContainer>
         
          {/* Code by Ashraf Start*/}
          <DateRangePicker 
                size="lg" placeholder="2021-01-01 ~ 2021-12-31" style={styles}
                onChange={(e)=>{
                  console.log(`rrc_monthly_report?startDate=${startDate}&endDate=${endDate}`);
                 return ( (e)?(setstartDate(moment(e[0]).format("YYYY-MM-DD")),setendDate(moment(e[1]).format("YYYY-MM-DD"))):(setstartDate('2021-01-01'),setendDate('2021-12-31')));
                }}
                  className="RRCDatepickerRange"
                  
                />
          {/* <Button> Download CSV </Button>{" "} */}
          <Button>
          {" "}
          <CSVLink
            filename="rcr_monthly_report.csv"
            data={getRcrData}
            // data={CSVJsonData}
            // headers={headers}
            headers={headers_for_csvfile}
            target="_blank"
            style={{
              color: "#ffffff",

              width: "120px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Download CSV{" "}
          </CSVLink>
        </Button>

        {/* Code By ashraf End */}


        </DatePickerContainer>{" "}
      </Header>{" "}




      <div className="row">
        <div className="col-2" style={{ width:"110%"}}>
        <TableContainer style={{}}>
        <Table>
          <TableHead>
            <TableHeadRow>
              {" "}
              <TableHeadRowColumnVal>
                    <tr
                     style={{
                       display: "flex",
                       justifyContent: "space-between",
                       alignItems: "center",
                     }}
                   >
                     <th style={{ paddingLeft: "8px" }} > Name </th>
                    
                     
                   </tr>
                   <InputHolder>
                   <Input placeholder="Search" />
                 </InputHolder>{" "}
                 </TableHeadRowColumnVal>
            </TableHeadRow>{" "}
          </TableHead>{" "}
          <TableBody>
            {" "}
            {/* {getRcrData.map((e) => { */}
            {getRcrData.map((e)=>{
              // console.log("table data : = "+CSVJsonData);
              return (
                <TableHeadRow style={{borderBottom:"1px solid #dbd4d4"}}>
                  {" "}
                  {/* {TableRowValues.map((values) => ( */}{" "}
                   <td> {e.Name} </td> 
                  
                  {/* ))} */}{" "}
                </TableHeadRow>
              );
            })}{" "}
          </TableBody>{" "}
        </Table>{" "}
      </TableContainer>{" "}

        </div>
        <div className="col-10" style={{}}>

        <TableContainer style={{marginLeft:"-10px"}}>
        <Table>
          <TableHead>
            <TableHeadRow>
              {" "}
              {Object.entries(tableHeaderValuesModal).map(([key, value]) => {
                return (
                  // (rrc_coloumns?.includes(key) ? true : false)
                  
                     (rrc_coloumns?.includes(key) ? 
                    <>
                    <TableHeadRowColumnVal>
                    <tr
                     style={{
                       display: "flex",
                       justifyContent: "space-between",
                       alignItems: "center",
                     }}
                   >
                     <th style={{ paddingLeft: "8px" }} > {value} </th>
                     <td style={{ display: "flex", flexDirection: "column" }}>
                       <img
                         src={ChevronUpIcon}
                         style={{ marginBottom: "5px" }}
                         alt="ChevronUpIcon"
                       />
                       <img src={ChevronDownIcon} alt="ChevronDownIcon" />
                     </td>{" "}
                     
                   </tr>
                   <InputHolder>
                   <Input placeholder="Search" />
                 </InputHolder>{" "}
                 </TableHeadRowColumnVal>
                 </>
                     : '') 
                    
                 
                );
              })}{" "}
            </TableHeadRow>{" "}
          </TableHead>{" "}
          <TableBody>
            {" "}
            {/* {getRcrData.map((e) => { */}
            {getRcrData.map((e)=>{
              // console.log("table data : = "+CSVJsonData);
              return (
                <TableHeadRow style={{borderBottom:"1px solid #dbd4d4"}}>
                  {" "}
                  {/* {TableRowValues.map((values) => ( */}{" "}
                  {/* { (rrc_coloumns?.includes('Name') ? <td> {e.Name} </td> : '') } */}
                  { (rrc_coloumns?.includes('User') ? <td> {e.User} </td> : '') }
                  { (rrc_coloumns?.includes('User_ID') ? <td> {e.User_ID} </td> : '') }
                  { (rrc_coloumns?.includes('Client_ID') ? <td> {e.Client_ID} </td> : '') }
                  { (rrc_coloumns?.includes('GRPA_Client_ID') ? <td> {e.GRPA_Client_ID} </td> : '') }
                  { (rrc_coloumns?.includes('Entry_Date') ? <td> {e.Entry_Date} </td> : '') }
                  { (rrc_coloumns?.includes('Day_of_the_week') ? <td> {e.Day_of_the_week} </td> : '') }
                  { (rrc_coloumns?.includes('Date_Of_Birth') ? <td> {e.Date_Of_Birth} </td> : '') }
                  { (rrc_coloumns?.includes('Gender') ? <td> {e.Gender} </td> : '') }
                  { (rrc_coloumns?.includes('Primary_Race') ? <td> {e.Primary_Race} </td> : '') }
                  { (rrc_coloumns?.includes('Ethnicity') ? <td> {e.Ethnicity} </td> : '') }
                  { (rrc_coloumns?.includes('Current_ZIP') ? <td> {e.Current_ZIP} </td> : '') }
                  { (rrc_coloumns?.includes('Total_Monthly_Income') ? <td> {e.Total_Monthly_Income} </td> : '') }
                  { (rrc_coloumns?.includes('Any_alcohol') ? <td> {e.Any_alcohol} </td> : '') }
                  { (rrc_coloumns?.includes('Alcohol_to_intoxication_5') ? <td> {e.Alcohol_to_intoxication_5} </td> : '') }
                  { (rrc_coloumns?.includes('Alcohol_to_intoxication') ? <td> {e.Alcohol_to_intoxication} </td> : '') }
                  { (rrc_coloumns?.includes('Cocaine_Crack') ? <td> {e.Cocaine_Crack} </td> : '') }
                  { (rrc_coloumns?.includes('Cocaine_Crack_route') ? <td> {e.Cocaine_Crack_route} </td> : '') }
                  { (rrc_coloumns?.includes('Benzodiazepines') ? <td> {e.Benzodiazepines} </td> : '') }
                  { (rrc_coloumns?.includes('Benzodiazepines_route') ? <td> {e.Benzodiazepines_route} </td> : '') }
                  { (rrc_coloumns?.includes('Barbiturates') ? <td> {e.Barbiturates} </td> : '') }
                  { (rrc_coloumns?.includes('Barbiturates_route') ? <td> {e.Barbiturates_route} </td> : '') }
                  { (rrc_coloumns?.includes('Marijuana_Hashish') ? <td> {e.Marijuana_Hashish} </td> : '') }
                  { (rrc_coloumns?.includes('Marijuana_Hashish_route') ? <td> {e.Marijuana_Hashish_route} </td> : '') }
                  { (rrc_coloumns?.includes('Hallucinogens_psychedelics') ? <td> {e.Hallucinogens_psychedelics} </td> : '') }
                  { (rrc_coloumns?.includes('Hallucinogens_psychedelics_route') ? <td> {e.Hallucinogens_psychedelics_route} </td> : '') }
                  { (rrc_coloumns?.includes('Methamphetamine_or_other_amphetamines') ? <td> {e.Methamphetamine_or_other_amphetamines} </td> : '') }
                  { (rrc_coloumns?.includes('Methamphetamine_or_other_amphetamines_route') ? <td> {e.Methamphetamine_or_other_amphetamines_route} </td> : '') }
                  { (rrc_coloumns?.includes('Inhalants') ? <td> {e.Inhalants} </td> : '') }
                  { (rrc_coloumns?.includes('Inhalants_route') ? <td> {e.Inhalants_route} </td> : '') }
                  { (rrc_coloumns?.includes('Non_prescription_GHB') ? <td> {e.Non_prescription_GHB} </td> : '') }
                  { (rrc_coloumns?.includes('Non_prescription_GHB_route') ? <td> {e.Non_prescription_GHB_route} </td> : '') }
                  { (rrc_coloumns?.includes('Ketamine') ? <td> {e.Ketamine} </td> : '') }
                  { (rrc_coloumns?.includes('Ketamine_route') ? <td> {e.Ketamine_route} </td> : '') }
                  { (rrc_coloumns?.includes('Other_tranquilizers_downers_sedatives_or_hypnotics') ? <td> {e.Other_tranquilizers_downers_sedatives_or_hypnotics} </td> : '') }
                  { (rrc_coloumns?.includes('Other_tranquilizers_downers_sedatives_or_hypnotics_route') ? <td> {e.Other_tranquilizers_downers_sedatives_or_hypnotics_route} </td> : '') }
                  { (rrc_coloumns?.includes('Other') ? <td> {e.Other} </td> : '') }
                  { (rrc_coloumns?.includes('Other_illegal_drugs_Specify') ? <td> {e.Other_illegal_drugs_Specify} </td> : '') }
                  { (rrc_coloumns?.includes('Other_illegal_drugs_route') ? <td> {e.Other_illegal_drugs_route} </td> : '') }
                  { (rrc_coloumns?.includes('Heroin') ? <td> {e.Heroin} </td> : '') }
                  { (rrc_coloumns?.includes('Heroin_route') ? <td> {e.Heroin_route} </td> : '') }
                  { (rrc_coloumns?.includes('Morphine') ? <td> {e.Morphine} </td> : '') }
                  { (rrc_coloumns?.includes('Morphine_route') ? <td> {e.Morphine_route} </td> : '') }
                  { (rrc_coloumns?.includes('Dilaudid') ? <td> {e.Dilaudid} </td> : '') }
                  { (rrc_coloumns?.includes('Dilaudid_route') ? <td> {e.Dilaudid_route} </td> : '') }
                  { (rrc_coloumns?.includes('Demerol') ? <td> {e.Demerol} </td> : '') }
                  { (rrc_coloumns?.includes('Demerol_route') ? <td> {e.Demerol_route} </td> : '') }
                  { (rrc_coloumns?.includes('Percocet') ? <td> {e.Percocet} </td> : '') }
                  { (rrc_coloumns?.includes('Percocet_route') ? <td> {e.Percocet_route} </td> : '') }
                  { (rrc_coloumns?.includes('Darvon') ? <td> {e.Darvon} </td> : '') }
                  { (rrc_coloumns?.includes('Darvon_route') ? <td> {e.Darvon_route} </td> : '') }
                  { (rrc_coloumns?.includes('Codeine') ? <td> {e.Codeine} </td> : '') }
                  { (rrc_coloumns?.includes('Codeine_route') ? <td> {e.Codeine_route} </td> : '') }
                  { (rrc_coloumns?.includes('Tylenol') ? <td> {e.Tylenol} </td> : '') }
                  { (rrc_coloumns?.includes('Tylenol_2') ? <td> {e.Tylenol_2} </td> : '') }
                  { (rrc_coloumns?.includes('OxyContin_Oxycodone') ? <td> {e.OxyContin_Oxycodone} </td> : '') }
                  { (rrc_coloumns?.includes('OxyContin_Oxycodone_route') ? <td> {e.OxyContin_Oxycodone_route} </td> : '') }
                  { (rrc_coloumns?.includes('Non_prescription_methadone') ? <td> {e.Non_prescription_methadone} </td> : '') }
                  { (rrc_coloumns?.includes('Non_prescription_methadone_route') ? <td> {e.Non_prescription_methadone_route} </td> : '') }
                  { (rrc_coloumns?.includes('Opioid') ? <td> {e.Opioid} </td> : '') }
                  { (rrc_coloumns?.includes('Referral_Source') ? <td> {e.Referral_Source} </td> : '') }
                  { (rrc_coloumns?.includes('Specific_Referral_Source') ? <td> {e.Specific_Referral_Source} </td> : '') }
                  { (rrc_coloumns?.includes('UAB_SAMHSA_Client_ID') ? <td> {e.UAB_SAMHSA_Client_ID} </td> : '') }
                  { (rrc_coloumns?.includes('Ref_Target') ? <td> {e.Ref_Target} </td> : '') }
                  { (rrc_coloumns?.includes('Agency_Target') ? <td> {e.Agency_Target} </td> : '') }
                  { (rrc_coloumns?.includes('If_family_friends_were_involved_were_they_referred_to_any_services') ? <td> {e.If_family_friends_were_involved_were_they_referred_to_any_services} </td> : '') }
                  { (rrc_coloumns?.includes('Was_the_ASAM_completed') ? <td> {e.Was_the_ASAM_completed} </td> : '') }
                  { (rrc_coloumns?.includes('Assigned_Peer_Support_Specialist') ? <td> {e.Assigned_Peer_Support_Specialist} </td> : '') }
                  { (rrc_coloumns?.includes('Current_County') ? <td> {e.Current_County} </td> : '') }
                  { (rrc_coloumns?.includes('Have_you_ever_overdosed') ? <td> {e.Have_you_ever_overdosed} </td> : '') }
                  { (rrc_coloumns?.includes('New_Old') ? <td> {e.New_Old} </td> : '') }
                  { (rrc_coloumns?.includes('Trauma') ? <td> {e.Trauma} </td> : '') }
                  { (rrc_coloumns?.includes('Suicide_Attempt') ? <td> {e.Suicide_Attempt} </td> : '') }
                  { (rrc_coloumns?.includes('Primary_Phone_Number') ? <td> {e.Primary_Phone_Number} </td> : '') }
                  { (rrc_coloumns?.includes('Address') ? <td> {e.Address} </td> : '') }
                  { (rrc_coloumns?.includes('Timestamp') ? <td> {e.Timestamp} </td> : '') }
                  
                  {/* ))} */}{" "}
                </TableHeadRow>
              );
            })}{" "}
          </TableBody>{" "}
        </Table>{" "}
      </TableContainer>{" "}


        </div>
      </div>
     
    </MainContainer>
  );
}

export default ReferralMonthReport;
