import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import "../App.css";
import ReactTooltip from "react-tooltip";
import services from "../services";
import { newMultiSelectOptionGenerator } from "../ReusableComponents/OptionsGenerator";
import { Alert } from "./toastify";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextareaAutosize from 'react-textarea-autosize';
import swal from "sweetalert"

export default function BehavioralComplications(props) {

  const [responseID, setResponseId] = useState(0);
  const [isSaved, setIsSaved] = useState(true);
  const [isSaveAndComplete, setisSaveAndComplete] = useState(false)
  const [isRiskRatingMandatory, setisRiskRatingMandatory] = useState(false);
  const [EmotionalBehaviour, setEmotionalBehaviour] = useState({
    bvc_trauma: "",
    bvc_trauma_explain: "",
    bvc_illness: "",
    bvc_issues: "",
    bvc_issues_explain: "",
    bvc_felt: "",
    bvc_felt_explain: "",
    emotional_bvc_hallucinations: "",
    emotional_bvc_hallucinations_explain: "",

    orientation_comments: "",
    general_comments: "",
    mood_comments: "",
    speech_comments: "",
    memory_comments: "",
    process_comments: "",
    thought_content_comments: "",
    insight_comments: "",
    other_comments: "",
    bvc_comments: "",
    emotional_comments: "",
    emotional_risk_rating: "",
    notes: "",
    emotional_risk_rating_information: ""
    // form_type:props.form_type,

  });

  const [EmotionalMedications, setEmotionalMedications] = useState([
    {
      emotional_medication: "",
      emotional_prescribedFor: "",
      emotional_dosage: "",
      emotional_frequency: "",
      emotional_taking_as_directed: "",
      emotional_taking_as_prescribed: ""
    }
  ])
  const [mental_health_disorder, setMental_health_disorder] = useState({
    mental_health_disorder: "",
    icd_10_code: "",
    comments: ""
  });
  const [immediate, setimmediate] = useState([]);
  const [recent, setrecent] = useState([]);
  const [remote, setremote] = useState([]);
  const [speech, setspeech] = useState([]);
  const [orientation, setorientation] = useState([]);
  const [dress, setdress] = useState([]);
  const [grooming, setgrooming] = useState([]);
  const [facial_exp, setfacial] = useState([]);
  const [mood, setmood] = useState([]);
  const [affect, setaffect] = useState([]);
  const [judgement, setjudgement] = useState([]);
  const [insight, setinsight] = useState([]);
  const [process, setprocess] = useState([]);
  const [content, setcontent] = useState([]);
  const [mentaldisorderOptions, setmentaldisorderOptions] = useState([])



  const mentaldis = async () => {
    try {
      const response = await axios.post(
        global.restApiURL + "customRest/getMentalDisorderDropDown",
        JSON.stringify({
          dataListBody: {
            apikey: sessionStorage.getItem("__TOKEN__"),
          },
        })
      );

      console.log("response", response);
      const data = response.data.data[0];
      const descriptions = JSON.parse(data.MentalHealthDisorder);
      setmentaldisorderOptions(descriptions)

    } catch (error) {
      console.error("Error fetching mental disorder options", error);
    }
  };


  useEffect(() => {
    mentaldis();
    loadData();
  }, [])

  useEffect(() => {
    props.childToParent(EmotionalBehaviour, mental_health_disorder, EmotionalMedications, mentaldisorderOptions, immediate, recent, remote, speech, orientation, dress, grooming, facial_exp, mood, affect, judgement, insight, process, content);
  }, [EmotionalBehaviour, mental_health_disorder, EmotionalMedications, mentaldisorderOptions, immediate, recent, remote, speech, orientation, dress, grooming, facial_exp, mood, affect, judgement, insight, process, content])

  useEffect(() => {
    // props.tabEditOrNot(true);
    // props.saveAndExit(handleSubmit);
  }, [EmotionalBehaviour]);

  // immediate,recent,remote,speech,orientation,dress,grooming,facial,mood,affect,judgement,insight,process,content
  const orientationOptions = [
    { value: "normal", label: "Normal" },
    { value: "deficits", label: "Deficits" },
    { value: "person", label: "Person" },
    { value: "place", label: "Place" },
    { value: "time", label: "Time" },
    { value: "situation", label: "Situation" },
  ];
  const speechOptions = [
    { value: "Normal", label: "Normal" },
    { value: "Pressured", label: "Pressured" },
    { value: "Stammering", label: "Stammering" },
    { value: "Mute", label: "Mute" },
    { value: "Loud", label: "Loud" },
    { value: "Soft", label: "Soft" },
    { value: "Rambling", label: "Rambling" },
    { value: "Slurred", label: "Slurred" },
    { value: "Echolalia", label: "Echolalia" },
  ];

  const facialOptions = [
    { value: "Appropriate", label: "Appropriate" },
    { value: "Flat", label: "Flat" },
    { value: "Sad", label: "Sad" },
    { value: "Angry", label: "Angry" },
    { value: "Fearful", label: "Fearful" },
    { value: "not_observed", label: "Not Observed" },
  ];
  const groomingOptions = [
    { value: "Appropriate", label: "Appropriate" },
    { value: "Meticulous", label: "Meticulous" },
    { value: "Dirty", label: "Dirty" },
    { value: "Poor", label: "Poor" },
    { value: "Bizarre", label: "Bizarre" },
    { value: "not_observed", label: "Not Observed" },
  ];
  // dressOptions,grooming,facial_exp
  const dressOptions = [
    { value: "Appropriate", label: "Appropriate" },
    { value: "Meticulous", label: "Meticulous" },
    { value: "Eccentric", label: "Eccentric" },
    { value: "Seductive", label: "Seductive" },
    { value: "Disheveled", label: "Disheveled" },
    { value: "not_observed", label: "Not Observed" },
  ];

  const remoteMemoryOptions = [
    { value: "intact", label: "Intact" },
    { value: "mildly_impaired", label: "Mildly Impaired" },
    { value: "moderately_impaired", label: "Moderately Impaired" },
    { value: "severely_impaired", label: "Severely Impaired" },
  ];
  const recentMemoryOptions = [
    { value: "intact", label: "Intact" },
    { value: "mildly_impaired", label: "Mildly Impaired" },
    { value: "moderately_impaired", label: "Moderately Impaired" },
    { value: "severely_impaired", label: "Severely Impaired" },
  ];

  const immidiateMemoryOptions = [
    { value: "intact", label: "Intact" },
    { value: "mildly_impaired", label: "Mildly Impaired" },
    { value: "moderately_impaired", label: "Moderately Impaired" },
    { value: "severely_impaired", label: "Severely Impaired" },
  ];

  const thoughtContentOptions = [
    { value: "normal", label: "Normal" },
    { value: "somatic_complaints", label: "Somatic Complaints" },
    { value: "obsessions_compulsions", label: "Obsessions/Compulsions" },
    { value: "poverty_of_content", label: "Poverty of Content" },
    { value: "guilt", label: " Guilt" },
    { value: "illogical_thinking", label: "Illogical Thinking" },
    { value: "hopelessness", label: "Hopelessness" },
    {
      value: "suicidal_or_homicidal_ideation",
      label: "Suicidal or Homicidal Ideation",
    },
    { value: "suspicious", label: "Suspicious" },
    { value: "depressive", label: "Depressive" },
    { value: "prejudices_biases", label: "Prejudices/Biases" },
    { value: "other", label: "Other" },


  ];

  const thoughtProcessOptions = [
    { value: "logical", label: "Logical" },
    { value: "relevant", label: "Relevant" },
    { value: "coherent", label: "Coherent" },
    { value: "illogical", label: "Illogical" },
    { value: "goal_directed", label: "Goal Directed" },
    { value: "incoherent", label: "Incoherent" },
    { value: "circumstantial", label: "Circumstantial" },
    { value: "rambling", label: "Rambling" },
    { value: "blocking", label: "Blocking" },
    { value: "flight_of_ideas", label: "Flight of Ideas" },
    { value: "neologisms", label: "Neologisms" },
    { value: "loose_associations", label: "Loose Associations" },
    { value: "tangential", label: "Tangential" },
    { value: "perplexed", label: "Perplexed" },
    { value: "confabulating", label: "Confabulating" },
    { value: "clanging", label: "Clanging" },
    { value: "confused", label: "Confused" },
    { value: "grossly_disorganized", label: "Grossly Disorganized" },
    { value: "other", label: "Other" },
  ];

  const judgementOptions = [
    { value: "good", label: "Good" },
    { value: "partial", label: "Partial" },
    { value: "limited", label: "Limited" },
    { value: "poor", label: "Poor" },
  ];

  const insightOptions = [
    { value: "good", label: "Good" },
    { value: "partial", label: "Partial" },
    { value: "limited", label: "Limited" },
    { value: "poor", label: "Poor" },
  ];
  const moodOptions = [
    { value: "appropriate", label: "Appropriate" },
    { value: "depressed", label: "Depressed" },
    { value: "euphoric", label: "Euphoric" },
    { value: "anxious", label: "Anxious" },
    { value: "irritable", label: "Irritable" },
    { value: "euthymic", label: "Euthymic" },
  ];

  const affectOptions = [
    { value: "appropriate", label: "Appropriate" },
    { value: "hostile", label: "Hostile" },
    { value: "blunted", label: "Blunted" },
    { value: "restricted", label: "Restricted" },
    { value: "labile", label: "Labile" },
    { value: "flat", label: "Flat" },
  ];
  const emotional_risk_rating0 = `Good impulse control & coping skills sub-domains (dangerousness/lethality, interference with recovery efforts, social functioning, self-care ability, course of illness).`
  const emotional_risk_rating1 = `There is a suspected or diagnosed EBC condition that requires intervention, but does not significantly interfere with TX. Relationships are being impaired but not endangered by substance use.`
  const emotional_risk_rating2 = `Persistent EBC condition, with symptoms that distract from recovery efforts, but are not an immediate threat to safety and do not prevent independent functioning.`
  const emotional_risk_rating3 = `Severe EBC symptomatology, but sufficient control  that does not require involuntary confinement, impulses to harm self or others, but not dangerous in a 24-hr setting.`
  const emotional_risk_rating4 = `Severe EBC symptomatology, requires involuntary  confinement. Exhibits severe and acute life-threatening symptoms (e.g., dangerous or impulsive behavior or cognitive functioning) posing  imminent danger to self and others.`

  const dataListBody = {
    apikey: sessionStorage.getItem("__TOKEN__"),
    client_id: sessionStorage.getItem("clientId"),
    form_type: props.form_type,
    uploaded_by: sessionStorage.getItem("username"),
  }

  const handleOnChange = (e, index) => {
    props.tabEditOrNot(true);
    props.saveAndExit("", "", "", handleSubmit, "", "", "", "", "", "", "", "", "");
    setEmotionalBehaviour({ ...EmotionalBehaviour, [e.target.name]: e.target.value, });
    const updatedMedications = [...EmotionalMedications];
    const updatedMedication = { ...updatedMedications[index], [e.target.name]: e.target.value };
    updatedMedications[index] = updatedMedication;
    setEmotionalMedications(updatedMedications);
    setIsSaved(false);
    if (e.target.name == "bvc_trauma" && e.target.value == "no") {
      setEmotionalBehaviour({ ...EmotionalBehaviour, ["bvc_trauma_explain"]: "", ["bvc_trauma"]: "no", });
    } else if (e.target.name == "bvc_issues" && e.target.value == "no") {
      setEmotionalBehaviour({ ...EmotionalBehaviour, ["bvc_issues_explain"]: "", ["bvc_issues"]: "no", });
    } else if (e.target.name == "bvc_illness" && e.target.value == "no") {
      setMental_health_disorder({ mental_health_disorder: "", icd_10_code: "", comments: "" });
    } else if (e.target.name == "bvc_felt" && e.target.value == "no") {
      setEmotionalBehaviour({ ...EmotionalBehaviour, ["bvc_felt_explain"]: "", ["bvc_felt"]: "no", });
    } else if (e.target.name == "emotional_bvc_hallucinations" && e.target.value == "no") {
      setEmotionalBehaviour({ ...EmotionalBehaviour, ["emotional_bvc_hallucinations"]: "", ["emotional_bvc_hallucinations"]: "no", });
    } else if (e.target.name == "emotional_risk_rating") {
      let val = e.target.value
      switch (val) {
        case "0": setEmotionalBehaviour({ ...EmotionalBehaviour, ["emotional_risk_rating_information"]: emotional_risk_rating0, ["emotional_risk_rating"]: 0 });
          break;
        case "1": setEmotionalBehaviour({ ...EmotionalBehaviour, ["emotional_risk_rating_information"]: emotional_risk_rating1, ["emotional_risk_rating"]: 1 });
          break;
        case "2": setEmotionalBehaviour({ ...EmotionalBehaviour, ["emotional_risk_rating_information"]: emotional_risk_rating2, ["emotional_risk_rating"]: 2 });
          break;
        case "3": setEmotionalBehaviour({ ...EmotionalBehaviour, ["emotional_risk_rating_information"]: emotional_risk_rating3, ["emotional_risk_rating"]: 3 });
          break;
        case "4": setEmotionalBehaviour({ ...EmotionalBehaviour, ["emotional_risk_rating_information"]: emotional_risk_rating4, ["emotional_risk_rating"]: 4 });
          break;
      }
      props.riskRatingDecisionWithValue(e.target.value);
      if (e.target.value >= 2 && EmotionalBehaviour.emotional_comments.length < 1) {
        setisRiskRatingMandatory(true);
      } else {
        setisRiskRatingMandatory(false);
      }
    } else if (e.target.name == "emotional_comments") {
      if (EmotionalBehaviour.emotional_risk_rating >= 2 && e.target.value.length < 1) {
        setisRiskRatingMandatory(true);
      } else {
        setisRiskRatingMandatory(false);
      }
      props.riskRatingDecisionWithComment(e.target.value);
      props.riskRatingDecisionWithValue(EmotionalBehaviour.emotional_risk_rating);
    } else if (e.target.name === "mental_health_disorder") {
      // Handle Mental Health Disorder dropdown change
      const selectedOption = mentaldisorderOptions.find((option) => option.description === e.target.value);
      setMental_health_disorder({
        mental_health_disorder: e.target.value,
        icd_10_code: selectedOption ? selectedOption.code : "",
      });
    }
  };

  const handleSubmit = async (e, shouldToasterShow) => {
    e.preventDefault();
    if (EmotionalBehaviour.emotional_comments.length < 1 && EmotionalBehaviour.emotional_risk_rating >= 2) {
      alert("Risk rating summary field is mandatory.");
    } else {
      const final_data = {
        dataListBody,
        EmotionalBehaviour,
        immediate,
        recent,
        remote,
        speech,
        orientation,
        dress,
        grooming,
        facial_exp,
        mood,
        affect,
        judgement,
        insight,
        process,
        content,
        EmotionalMedications,
        // icd_10_code
      };
      const mental_health_orders = props.datafrommentaldisorder.map((item, index) => {
        if (index === 0) {
          // Match values with the 0th index and update if changed
          if (
            item.mental_health_disorder !== mental_health_disorder.mental_health_disorder ||
            item.icd_10_code !== mental_health_disorder.icd_10_code
          ) {
            return {
              mental_health_disorder: mental_health_disorder.mental_health_disorder,
              icd_10_code: mental_health_disorder.icd_10_code,
              comments: item.comments,
            };
          }
        }

        // Append other indices
        return {
          mental_health_disorder: item.mental_health_disorder,
          icd_10_code: item.icd_10_code,
          comments: item.comments,
        };
      });

      // Include mental_health_orders in the final_data object
      final_data.mental_health_orders = mental_health_orders;
      try {
        let res = await axios.post(
          global.restApiURL + "customRest/saveEmotionalBehaviour",
          JSON.stringify(final_data)
        );
        if (res.data.success && shouldToasterShow === undefined) {
          loadData();
          setIsSaved(true);
          toast.success("Data added successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: "success_behaviourComplications",
            theme: "colored"
          });
          props.tabEditOrNot(true);
        } else if (res.data.success && shouldToasterShow === "saveNexit") {
          // Handle logic for 'saveNexit' case
        }
      } catch (error) {
        // Handle error if the API call fails
        console.error("Error submitting data:", error);
      }
    }
  };


  const loadData = async (e) => {
    setIsSaved(true)
    if (!props.isSaveAndComplete) {
      let res = await axios.post(
        global.restApiURL + "customRest/getEmotionalBehaviour",
        JSON.stringify(dataListBody)
      );
      if (res.data.success == true && res.status == 200) {
        setEmotionalBehaviour(res.data.data.EmotionalBehaviour);
        setIsSaved(true);
        if (res.data.data.recent.length !== 0) {
          let data = JSON.parse(res.data.data.recent);
          setrecent(data);
        }
        if (res.data.data.remote.length !== 0) {
          let data = JSON.parse(res.data.data.remote);
          setremote(data);
        }
        if (res.data.data.speech.length !== 0) {
          let data = JSON.parse(res.data.data.speech);
          setspeech(data);
        }
        if (res.data.data.orientation.length !== 0) {
          let data = JSON.parse(res.data.data.orientation);
          setorientation(data);
        }
        if (res.data.data.dress.length !== 0) {

          let data = JSON.parse(res.data.data.dress);
          setdress(data);
        }
        if (res.data.data.grooming.length !== 0) {
          let data = JSON.parse(res.data.data.grooming);
          setgrooming(data);
        }
        if (res.data.data.facial_exp.length !== 0) {
          let data = JSON.parse(res.data.data.facial_exp);
          setfacial(data);
        }
        if (res.data.data.mood.length !== 0) {
          let data = JSON.parse(res.data.data.mood);
          setmood(data);
        }
        if (res.data.data.affect.length !== 0) {
          let data = JSON.parse(res.data.data.affect);
          setaffect(data);
        }
        if (res.data.data.judgement.length !== 0) {
          let data = JSON.parse(res.data.data.judgement);
          setjudgement(data);
        }
        if (res.data.data.insight.length !== 0) {
          let data = JSON.parse(res.data.data.insight);
          setinsight(data);
        }
        if (res.data.data.process.length !== 0) {
          let data = JSON.parse(res.data.data.process);
          setprocess(data);
        }
        if (res.data.data.content.length !== 0) {
          let data = JSON.parse(res.data.data.content);
          setcontent(data);
        }
        if (res.data.data.immediate.length !== 0) {
          let data = JSON.parse(res.data.data.immediate);
          setimmediate(data);
        }
        if (res.data.data.emotionalMedications && res.data.data.emotionalMedications.length > 0) {
          setEmotionalMedications(res.data.data.emotionalMedications);
        }

        try {
          const mentalHealthDisorderRes = await axios.post(
            global.restApiURL + "customRest/getMentalHealthDisorderInfo",
            JSON.stringify(dataListBody)
          );

          if (mentalHealthDisorderRes.data.success && mentalHealthDisorderRes.status === 200) {
            const mentalHealthDisorderData = mentalHealthDisorderRes.data.data;
            setMental_health_disorder({
              mental_health_disorder: mentalHealthDisorderData.mental_health_orders[0].mental_health_disorder,
              icd_10_code: mentalHealthDisorderData.mental_health_orders[0].icd_10_code,
              comments: mentalHealthDisorderData.mental_health_orders[0].comments,
            });
          }
        } catch (error) {
          // Handle error if the API call for mental_health_disorder fails
          console.error("Error fetching mental_health_disorder data:", error);
        }

      }

    } else {
      let res = await axios.post(
        global.restApiURL + "customRest/getEmotionalBehaviourTemp",
        JSON.stringify(dataListBody)
      );
      if (res.data.success == true && res.status == 200) {
        setisSaveAndComplete(true)
        setEmotionalBehaviour(res.data.data.EmotionalBehaviour);
        setIsSaved(true);
        if (res.data.data.emotionalMedications && res.data.data.emotionalMedications.length > 0) {
          setEmotionalMedications(res.data.data.emotionalMedications);
        }
        if (res.data.data.recent.length !== 0) {
          let data = JSON.parse(res.data.data.recent);
          setrecent(data);
        }
        if (res.data.data.remote.length !== 0) {
          let data = JSON.parse(res.data.data.remote);
          setremote(data);
        }
        if (res.data.data.speech.length !== 0) {
          let data = JSON.parse(res.data.data.speech);
          setspeech(data);
        }
        if (res.data.data.orientation.length !== 0) {
          let data = JSON.parse(res.data.data.orientation);
          setorientation(data);
        }
        if (res.data.data.dress.length !== 0) {

          let data = JSON.parse(res.data.data.dress);
          setdress(data);
        }
        if (res.data.data.grooming.length !== 0) {
          let data = JSON.parse(res.data.data.grooming);
          setgrooming(data);
        }
        if (res.data.data.facial_exp.length !== 0) {
          let data = JSON.parse(res.data.data.facial_exp);
          setfacial(data);
        }
        if (res.data.data.mood.length !== 0) {
          let data = JSON.parse(res.data.data.mood);
          setmood(data);
        }
        if (res.data.data.affect.length !== 0) {
          let data = JSON.parse(res.data.data.affect);
          setaffect(data);
        }
        if (res.data.data.judgement.length !== 0) {
          let data = JSON.parse(res.data.data.judgement);
          setjudgement(data);
        }
        if (res.data.data.insight.length !== 0) {
          let data = JSON.parse(res.data.data.insight);
          setinsight(data);
        }
        if (res.data.data.process.length !== 0) {
          let data = JSON.parse(res.data.data.process);
          setprocess(data);
        }
        if (res.data.data.content.length !== 0) {
          let data = JSON.parse(res.data.data.content);
          setcontent(data);
        }
        if (res.data.data.immediate.length !== 0) {
          let data = JSON.parse(res.data.data.immediate);
          setimmediate(data);
        }

      }
      try {
        const mentalHealthDisorderTempRes = await axios.post(
          global.restApiURL + "customRest/getMentalHealthDisorderInfoTemp",
          JSON.stringify(dataListBody)
        );

        if (mentalHealthDisorderTempRes.data.success && mentalHealthDisorderTempRes.status === 200) {
          const mentalHealthDisorderTempData = mentalHealthDisorderTempRes.data.data;
          setMental_health_disorder({
            mental_health_disorder: mentalHealthDisorderTempData.mental_health_orders[0].mental_health_disorder,
            icd_10_code: mentalHealthDisorderTempData.mental_health_orders[0].icd_10_code,
            comments: mentalHealthDisorderTempData.mental_health_orders[0].comments
          });
        }
      } catch (error) {
        console.error("Error fetching mental_health_disorder data (isSaveAndComplete=true):", error);
      }
    }
  };

  const addRows = (e) => {
    e.preventDefault();
    const data = {
      emotional_medication: "",
      emotional_prescribedFor: "",
      emotional_dosage: "",
      emotional_frequency: "",
      emotional_taking_as_prescribed: "",
      emotional_taking_as_directed: "",

    };
    setEmotionalMedications(prevState => [...prevState, data]);
    setIsSaved(false);
  };

  const deleteRow = (index) => {
    const updatedMedications = [...EmotionalMedications];
    const rowToRemove = updatedMedications[index];
  
    // Check if the row is not blank
    const isRowNotBlank = Object.values(rowToRemove).some(value => value.trim() !== '');
  
    if (isRowNotBlank) {
      swal({
        title: "Are you sure?",
        text: "Are you sure you want to remove this row?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          updatedMedications.splice(index, 1);
          setEmotionalMedications(updatedMedications);
          setIsSaved(false);
        }
      });
    } else {
      // Remove the blank row without showing the confirmation modal
      updatedMedications.splice(index, 1);
      setEmotionalMedications(updatedMedications);
      setIsSaved(false);
    }
  };


  return (
    <div className="hold-transition sidebar-mini text-sm">
      <div className="wrapper">
        <div className="">
          <section className="content-body">
            <div className="row">
              <div className="col-md-12">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card shadow-none">
                        <div>
                          <p
                            className="lead float-left"
                            style={{ marginBottom: "1rem" }}
                          >
                            <b className="text-green">
                              Emotional, behavioral, cognitive conditions and
                              complications{" "}
                            </b>
                          </p>
                        </div>
                        <div>

                          <div className="row marginBottom1">
                            <div className="col-lg-4 col-md-8">
                              <label>
                                Have you experienced significant events that
                                have caused trauma?
                              </label>
                            </div>
                            <div className="col-lg-2 col-md-4">
                              <select
                                className="form-control"
                                name="bvc_trauma"
                                onChange={handleOnChange}
                                value={EmotionalBehaviour.bvc_trauma}
                                disabled={isSaveAndComplete}
                              >
                                <option value="">Select</option>
                                <option
                                  value="yes"
                                  selected={
                                    EmotionalBehaviour.bvc_trauma == "yes"
                                      ? true
                                      : false
                                  }
                                >
                                  Yes
                                </option>
                                <option
                                  value="no"
                                  selected={
                                    EmotionalBehaviour.bvc_trauma == "no"
                                      ? true
                                      : false
                                  }

                                >
                                  No
                                </option>
                              </select>
                            </div>

                            {EmotionalBehaviour.bvc_trauma == "yes" && (
                              <>
                                <div className="col-lg-6 col-md-12 mb-2">
                                  <TextareaAutosize
                                    className="form-control"
                                    style={{ overflow: "hidden" }}
                                    rows={1}
                                    minRows={(EmotionalBehaviour.bvc_trauma_explain?.length + (EmotionalBehaviour.bvc_trauma_explain?.split(/\r\n|\r|\n/).length) * 40) / 50}
                                    placeholder="Please explain"
                                    value={
                                      EmotionalBehaviour.bvc_trauma_explain
                                    }
                                    name="bvc_trauma_explain"
                                    onChange={handleOnChange}
                                    disabled={isSaveAndComplete}
                                  />

                                </div>
                              </>
                            )}
                          </div>
                          <div className="row marginBottom1">
                            <div className="col-lg-4 col-md-8">
                              <label>
                                Have you been diagnosed with or told you have a mental illness?
                              </label>
                            </div>
                            <div className="col-lg-2 col-md-4">
                              <select
                                className="form-control"
                                name="bvc_illness"
                                onChange={handleOnChange}
                                value={EmotionalBehaviour.bvc_illness}
                                disabled={isSaveAndComplete}
                              >
                                <option value="">Select</option>
                                <option
                                  value="yes"
                                  selected={EmotionalBehaviour.bvc_illness === "yes"}
                                >
                                  Yes
                                </option>
                                <option
                                  value="no"
                                  selected={EmotionalBehaviour.bvc_illness === "no"}
                                >
                                  No
                                </option>
                              </select>
                            </div>
                            {EmotionalBehaviour.bvc_illness === "yes" && (
                              <>
                                <div className="col-lg-2" style={{ marginTop: "0.5rem" }}>
                                  <label htmlFor="mental_health_disorder" className="form-label">
                                    Please Clarify
                                  </label>
                                </div>
                                <div className="col-lg-4">
                                  <select
                                    className="form-control"
                                    name="mental_health_disorder"
                                    onChange={handleOnChange}
                                    value={mental_health_disorder.mental_health_disorder}
                                    disabled={isSaveAndComplete}
                                  >
                                    <option value="">Select</option>
                                    {mentaldisorderOptions.map((option) => (
                                      <option
                                        key={option.code}
                                        value={option.description}
                                        data-icd-10-code={option.code}
                                      >
                                        {option.description}
                                      </option>
                                    ))}
                                  </select>
                                  {/* Hidden field to store the selected code */}
                                  <input
                                    type="hidden"
                                    name="icd_10_code"
                                    value={mental_health_disorder.icd_10_code}
                                  />
                                </div>

                              </>
                            )}
                          </div>
                          <div className="row marginBottom1">
                            <div className="col-lg-4 col-md-8">
                              <label>
                                {" "}
                                Do you see anyone for mental health issues?{" "}
                              </label>
                            </div>
                            <div className="col-lg-2 col-md-4">
                              <select
                                className="form-control"
                                name="bvc_issues"
                                onChange={handleOnChange}
                                value={EmotionalBehaviour.bvc_issues}
                                disabled={isSaveAndComplete}
                              >
                                <option value="">Select</option>
                                <option
                                  value="yes"
                                  selected={
                                    EmotionalBehaviour.bvc_issues == "yes"
                                      ? true
                                      : false
                                  }
                                >
                                  Yes
                                </option>
                                <option
                                  value="no"
                                  selected={
                                    EmotionalBehaviour.bvc_issues == "no"
                                      ? true
                                      : false
                                  }
                                >
                                  No
                                </option>
                              </select>
                            </div>
                            {EmotionalBehaviour.bvc_issues == "yes" && (
                              <>
                                <div className="col-lg-6 col-md-12 mb-2">
                                  <TextareaAutosize
                                    rows={1}
                                    minRows={(EmotionalBehaviour.bvc_issues_explain?.length + (EmotionalBehaviour.bvc_issues_explain?.split(/\r\n|\r|\n/).length) * 40) / 50}
                                    placeholder="Please explain"
                                    value={
                                      EmotionalBehaviour.bvc_issues_explain
                                    }
                                    onChange={handleOnChange}
                                    style={{ overflow: "hidden" }}
                                    className="form-control"
                                    name="bvc_issues_explain"
                                    disabled={isSaveAndComplete}
                                  />
                                </div>
                              </>
                            )}
                          </div>

                          <div className="row marginBottom1">
                            <div className="col-lg-4 col-md-8">
                              <label>
                                Have you ever felt like killing or hurting
                                yourself or someone else?
                              </label>
                            </div>
                            <div className="col-lg-2 col-md-4">
                              <select
                                className="form-control"
                                name="bvc_felt"
                                onChange={handleOnChange}
                                value={EmotionalBehaviour.bvc_felt}
                                disabled={isSaveAndComplete}
                              >
                                <option value="">Select</option>
                                <option
                                  value="yes"
                                  selected={
                                    EmotionalBehaviour.bvc_felt == "yes"
                                      ? true
                                      : false
                                  }
                                >
                                  Yes
                                </option>
                                <option
                                  value="no"
                                  selected={
                                    EmotionalBehaviour.bvc_felt == "no"
                                      ? true
                                      : false
                                  }
                                >
                                  No
                                </option>
                              </select>
                            </div>
                            {EmotionalBehaviour.bvc_felt == "yes" && (
                              <>
                                <div className="col-lg-6 col-md-12 mb-2">
                                  <TextareaAutosize
                                    rows={1}
                                    minRows={(EmotionalBehaviour.bvc_felt_explain?.length + (EmotionalBehaviour.bvc_felt_explain?.split(/\r\n|\r|\n/).length) * 40) / 50}
                                    placeholder="Please explain"
                                    style={{ overflow: 'hidden' }}
                                    value={EmotionalBehaviour.bvc_felt_explain}
                                    onChange={handleOnChange}
                                    className="form-control"
                                    name="bvc_felt_explain"
                                    disabled={isSaveAndComplete}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          <div className="row marginBottom1">
                            <div className="col-lg-4 col-md-8">
                              <label>
                                Have you ever experienced hallucinations or
                                difficulty telling what is real from that which
                                is not (auditory, visual, olfactory, tactile)?
                              </label>
                            </div>
                            <div className="col-lg-2 col-md-4">
                              <select
                                className="form-control"
                                name="emotional_bvc_hallucinations"
                                onChange={handleOnChange}
                                value={EmotionalBehaviour.emotional_bvc_hallucinations}
                                disabled={isSaveAndComplete}
                              >
                                <option value="">Select</option>
                                <option
                                  value="yes"
                                  selected={
                                    EmotionalBehaviour.emotional_bvc_hallucinations ==
                                      "yes"
                                      ? true
                                      : false
                                  }
                                >
                                  Yes
                                </option>
                                <option
                                  value="no"
                                  selected={
                                    EmotionalBehaviour.emotional_bvc_hallucinations ==
                                      "no"
                                      ? true
                                      : false
                                  }
                                >
                                  No
                                </option>
                              </select>
                            </div>
                            {EmotionalBehaviour.emotional_bvc_hallucinations == "yes" && (
                              <>
                                <div className="col-lg-6 col-md-12 mb-2">
                                  <TextareaAutosize
                                    rows={1}
                                    minRows={(EmotionalBehaviour.emotional_bvc_hallucinations_explain?.length + (EmotionalBehaviour.emotional_bvc_hallucinations_explain?.split(/\r\n|\r|\n/).length) * 40) / 50}
                                    style={{ overflow: 'hidden' }}
                                    placeholder="Please explain"
                                    value={
                                      EmotionalBehaviour.emotional_bvc_hallucinations_explain
                                    }
                                    onChange={handleOnChange}
                                    className="form-control"
                                    name="emotional_bvc_hallucinations_explain"
                                    disabled={isSaveAndComplete}
                                  />
                                </div>
                              </>
                            )}
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <button
                                  className="btn bt-sm btn-secondary  float-right"
                                  onClick={addRows}
                                  disabled={isSaveAndComplete}
                                >
                                  <i className="fa fa-plus mr-1"></i>Add
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="row marginBottom1">
                            <div className="col-md-12">
                              <div className="form-group">
                                {/* <div className="col-md-12">
                                  <button
                                    className="btn bt-sm btn-secondary mt-2 mr-3 float-right"
                                    onClick={addRows}
                                    disabled={isSaveAndComplete}
                                  >
                                    <i className="fa fa-plus mr-1"></i>Add
                                  </button>
                                </div> */}
                                <label>
                                  List any
                                  <strong> psychiatric medications</strong> you
                                  currently take, have taken, or should be
                                  taking, including over the counter medications
                                </label>
                                <div style={{ overflowX: "scroll" }}>
                                  <table className="table table-bordered table-striped " style={{ width: "max-content" }}>
                                    <thead>
                                      <th>Medication</th>
                                      <th>Prescribed For</th>
                                      <th>Dosage</th>
                                      <th>Frequency</th>
                                      <th>Taking as Prescribed?</th>
                                      <th>If OTC, Taking as Directed?</th>
                                    </thead>
                                    <tbody>
                                      {EmotionalMedications.map((medication, index) => (
                                        <tr key={index}>
                                          <td className="td1">
                                            <TextareaAutosize
                                              name="emotional_medication"
                                              value={medication.emotional_medication}
                                              onChange={(e) => handleOnChange(e, index)}
                                              className="form-control"
                                              disabled={isSaveAndComplete}
                                              minRows={(medication.emotional_medication?.length / 24) + medication.emotional_medication?.split(/\r\n|\r|\n/).length}
                                              style={{ overflow: 'hidden' }}
                                            />
                                          </td>
                                          <td className="td2">
                                            <TextareaAutosize
                                              name={"emotional_prescribedFor"}
                                              value={medication.emotional_prescribedFor}
                                              onChange={(e) => handleOnChange(e, index)}
                                              className="form-control"
                                              disabled={isSaveAndComplete}
                                              minRows={(medication.emotional_prescribedFor?.length / 24) + medication.emotional_prescribedFor?.split(/\r\n|\r|\n/).length}
                                              style={{ overflow: 'hidden' }}
                                            />
                                          </td>
                                          <td className="td2">
                                            <TextareaAutosize
                                              name={"emotional_dosage"}
                                              value={medication.emotional_dosage}
                                              onChange={(e) => handleOnChange(e, index)}
                                              className="form-control"
                                              disabled={isSaveAndComplete}
                                              minRows={(medication.emotional_dosage?.length / 24) + medication.emotional_dosage?.split(/\r\n|\r|\n/).length}
                                              style={{ overflow: 'hidden' }}
                                            />
                                          </td>
                                          <td className="td3">
                                            <TextareaAutosize
                                              name={"emotional_frequency"}
                                              value={medication.emotional_frequency}
                                              onChange={(e) => handleOnChange(e, index)}
                                              className="form-control"
                                              disabled={isSaveAndComplete}
                                              minRows={(medication.emotional_frequency?.length / 24) + medication.emotional_frequency?.split(/\r\n|\r|\n/).length}
                                              style={{ overflow: 'hidden' }}
                                            />
                                          </td>
                                          <td className="td4">
                                            <select
                                              className="form-control"
                                              value={medication.emotional_taking_as_prescribed}
                                              name={"emotional_taking_as_prescribed"}
                                              onChange={(e) => handleOnChange(e, index)}
                                              disabled={isSaveAndComplete}
                                            >
                                              <option value="">Select</option>
                                              <option value="yes">Yes</option>
                                              <option value="no">No</option>
                                            </select>
                                          </td>
                                          <td className="td5">
                                            <select
                                              className="form-control"
                                              value={medication.emotional_taking_as_directed}
                                              name={"emotional_taking_as_directed"}
                                              onChange={(e) => handleOnChange(e, index)}
                                              disabled={isSaveAndComplete}
                                            >
                                              <option value="">Select</option>
                                              <option value="yes">Yes</option>
                                              <option value="no">No</option>
                                            </select>
                                          </td>

                                          {index !== 0 && (
                                            <td>
                                              <button
                                                className="btn btn-sm btn-danger"
                                                type="button"
                                                onClick={() => deleteRow(index)}
                                                disabled={isSaveAndComplete}
                                              >
                                                Remove
                                              </button>
                                            </td>
                                          )}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label style={{ fontSize: "1rem" }}>
                                  Comments :{" "}
                                </label>
                                <TextareaAutosize
                                  style={{ overflow: 'hidden' }}
                                  minRows={(EmotionalBehaviour.bvc_comments?.length / 110) + EmotionalBehaviour.bvc_comments?.split(/\r\n|\r|\n/).length}
                                  className="form-control"
                                  value={EmotionalBehaviour.bvc_comments}
                                  onChange={handleOnChange}
                                  name="bvc_comments"
                                  disabled={isSaveAndComplete}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: "4%" }}>
                            <div className="col-md-12">
                              <strong style={{ fontSize: "1rem" }}>
                                Mental Status Examination:
                              </strong>
                            </div>
                          </div>
                          <hr />
                          <div class="row">
                            <div className="col-md-12 form-group">
                              <strong>ORIENTATION</strong>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-12 form-group">
                              Orientation
                              <Select
                                options={orientationOptions}
                                className="mt-2"
                                onChange={(e) => {
                                  setorientation(e);
                                  setIsSaved(false);
                                  props.childToParent(EmotionalBehaviour, immediate, recent, remote, speech, e, dress, grooming, facial_exp, mood, affect, judgement, insight, process, content);
                                }
                                }
                                value={orientation}
                                name="orientation"
                                isMulti
                                closeMenuOnSelect={false}
                                isDisabled={isSaveAndComplete}
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              Comments
                              <TextareaAutosize
                                className="form-control mt-2"
                                style={{ overflow: "hidden" }}
                                minRows={(EmotionalBehaviour.orientation_comments?.length / 110) + EmotionalBehaviour.orientation_comments?.split(/\r\n|\r|\n/).length}
                                name="orientation_comments"
                                value={EmotionalBehaviour.orientation_comments}
                                onChange={handleOnChange}
                                disabled={isSaveAndComplete}
                              />
                            </div>
                          </div>
                          <div class="row marginTop2">
                            <div className="col-md-12 form-group">
                              <strong>GENERAL APPEARANCE</strong>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-12 form-group">
                              Dress
                              <Select
                                className="mt-2"
                                onChange={(e) => {
                                  setdress(e);
                                  setIsSaved(false);
                                  props.childToParent(EmotionalBehaviour, immediate, recent, remote, speech, orientation, e, grooming, facial_exp, mood, affect, judgement, insight, process, content);
                                }}
                                name="dress"
                                options={dressOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                value={dress}
                                isDisabled={isSaveAndComplete}
                              />
                            </div>

                            <div className="col-lg-4 col-md-12 form-group">
                              Grooming
                              <Select
                                className="mt-2"
                                name="grooming"
                                value={grooming}
                                onChange={(e) => {
                                  setgrooming(e);
                                  setIsSaved(false);
                                  props.childToParent(EmotionalBehaviour, immediate, recent, remote, speech, orientation, dress, e, facial_exp, mood, affect, judgement, insight, process, content);
                                }}
                                options={groomingOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                isDisabled={isSaveAndComplete}
                              />

                            </div>
                            <div className="col-lg-4 col-md-12">
                              Facial Expression
                              <Select
                                className="mt-2"
                                name="facial"
                                value={facial_exp}
                                onChange={(e) => {
                                  setfacial(e);
                                  setIsSaved(false);
                                  props.childToParent(EmotionalBehaviour, immediate, recent, remote, speech, orientation, dress, grooming, e, mood, affect, judgement, insight, process, content);
                                }}
                                options={facialOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                isDisabled={isSaveAndComplete}
                              />

                            </div>
                            <div className="col-md-12 form-group">
                              Comments
                              <TextareaAutosize
                                style={{ overflow: 'hidden' }}
                                className="form-control mt-2"
                                minRows={(EmotionalBehaviour.general_comments?.length / 110) + EmotionalBehaviour.general_comments?.split(/\r\n|\r|\n/).length}
                                value={EmotionalBehaviour.general_comments}
                                name="general_comments"
                                onChange={handleOnChange}
                                disabled={isSaveAndComplete}
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div className="col-md-12 form-group">
                              <strong>MOOD/AFFECT</strong>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-12 form-group">
                              Mood
                              <Select
                                className="mt-2"
                                name="mood"
                                value={mood}
                                onChange={(e) => {
                                  setmood(e);
                                  setIsSaved(false);
                                  props.childToParent(EmotionalBehaviour, immediate, recent, remote, speech, orientation, dress, grooming, facial_exp, e, affect, judgement, insight, process, content);
                                }}
                                options={moodOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                isDisabled={isSaveAndComplete}
                              />
                            </div>
                            <div className="col-lg-4 col-md-12 form-group">
                              Affect
                              <Select
                                className="mt-2"
                                name="affect"
                                value={affect}
                                onChange={(e) => {
                                  setaffect(e);
                                  setIsSaved(false);
                                  props.childToParent(EmotionalBehaviour, immediate, recent, remote, speech, orientation, dress, grooming, facial_exp, mood, e, judgement, insight, process, content);
                                }}
                                options={affectOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                isDisabled={isSaveAndComplete}
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              Comments
                              <TextareaAutosize
                                style={{ overflow: 'hidden' }}
                                className="form-control mt-2"
                                value={EmotionalBehaviour.mood_comments}
                                onChange={handleOnChange}
                                minRows={(EmotionalBehaviour.mood_comments?.length / 110) + EmotionalBehaviour.mood_comments?.split(/\r\n|\r|\n/).length}
                                name="mood_comments"
                                disabled={isSaveAndComplete}
                              />
                              {/*<TextareaAutosize className="form-control mt-2" value="" onChange={(e) => setmood_comments(e.target.value)} rows={2} name="mood_comments" />*/}
                            </div>
                          </div>
                          <div class="row">
                            <div className="col-md-12 form-group">
                              <strong>SPEECH</strong>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-4 col-md-12 form-group">
                              Speech
                              <Select
                                className="mt-2"
                                name="speech"
                                value={speech}
                                onChange={(e) => {
                                  setspeech(e);
                                  setIsSaved(false);
                                  props.childToParent(EmotionalBehaviour, immediate, recent, remote, e, orientation, dress, grooming, facial_exp, mood, affect, judgement, insight, process, content);
                                }}
                                options={speechOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                isDisabled={isSaveAndComplete}
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              Comments
                              <TextareaAutosize
                                style={{ overflow: 'hidden' }}
                                className="form-control mt-2"
                                value={EmotionalBehaviour.speech_comments}
                                minRows={(EmotionalBehaviour.speech_comments?.length / 110) + EmotionalBehaviour.speech_comments?.split(/\r\n|\r|\n/).length}
                                name="speech_comments"
                                onChange={handleOnChange}
                                disabled={isSaveAndComplete}
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div className="col-md-12 form-group">
                              <strong>MEMORY</strong>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-12 form-group">
                              Immediate
                              <Select
                                className="mt-2"
                                name="immediate"
                                value={immediate}
                                onChange={(e) => {
                                  setimmediate(e);
                                  setIsSaved(false);
                                  props.childToParent(EmotionalBehaviour, e, recent, remote, speech, orientation, dress, grooming, facial_exp, mood, affect, judgement, insight, process, content);
                                }}
                                options={immidiateMemoryOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                isDisabled={isSaveAndComplete}
                              />
                            </div>

                            <div className="col-lg-4 col-md-12 form-group">
                              Recent
                              <Select
                                className="mt-2"
                                name="recent"
                                value={recent}
                                onChange={(e) => {
                                  setrecent(e);
                                  setIsSaved(false);
                                  props.childToParent(EmotionalBehaviour, immediate, e, remote, speech, orientation, dress, grooming, facial_exp, mood, affect, judgement, insight, process, content);
                                }}
                                options={recentMemoryOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                isDisabled={isSaveAndComplete}
                              />
                            </div>
                            <div className="col-lg-4 col-md-12 form-group">
                              Remote
                              <Select
                                className="mt-2"
                                name="remote"
                                value={remote}
                                onChange={(e) => {
                                  setremote(e);
                                  setIsSaved(false);
                                  props.childToParent(EmotionalBehaviour, immediate, recent, e, speech, orientation, dress, grooming, facial_exp, mood, affect, judgement, insight, process, content);
                                }}
                                options={remoteMemoryOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                isDisabled={isSaveAndComplete}
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              Comments
                              <TextareaAutosize
                                style={{ overflow: 'hidden' }}
                                className="form-control mt-2"
                                value={EmotionalBehaviour.memory_comments}
                                minRows={(EmotionalBehaviour.memory_comments?.length / 110) + EmotionalBehaviour.memory_comments?.split(/\r\n|\r|\n/).length}
                                name="memory_comments"
                                onChange={handleOnChange}
                                disabled={isSaveAndComplete}
                              />
                              {/*<TextareaAutosize className="form-control mt-2" value={memory_comments} rows={2} name="memory_comments" onChange={(e) => setmemory_comments(e.target.value)} />*/}
                            </div>
                          </div>
                          <div class="row">
                            <div className="col-md-12 form-group">
                              <strong>THOUGHT PROCESS</strong>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-4 col-md-12 form-group">
                              Thought Process
                              <Select
                                className="mt-2"
                                name="process"
                                value={process}
                                onChange={(e) => {
                                  setprocess(e);
                                  setIsSaved(false);
                                  props.childToParent(EmotionalBehaviour, immediate, recent, remote, speech, orientation, dress, grooming, facial_exp, mood, affect, judgement, insight, e, content);
                                }}
                                options={thoughtProcessOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                isDisabled={isSaveAndComplete}
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              Comments
                              <TextareaAutosize
                                className="form-control mt-2"
                                value={EmotionalBehaviour.process_comments}
                                minRows={(EmotionalBehaviour.process_comments?.length / 110) + EmotionalBehaviour.process_comments?.split(/\r\n|\r|\n/).length}
                                style={{ overflow: "hidden" }}
                                name="process_comments"
                                onChange={handleOnChange}
                                disabled={isSaveAndComplete}
                              />
                            </div>
                          </div>

                          <div class="row">
                            <div className="col-md-12 form-group">
                              <strong>THOUGHT CONTENT</strong>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-4 col-md-12 form-group">
                              Thought Content
                              <Select
                                className="mt-2"
                                name="content"
                                value={content}
                                onChange={(e) => {
                                  setcontent(e);
                                  setIsSaved(false);
                                  props.childToParent(EmotionalBehaviour, immediate, recent, remote, speech, orientation, dress, grooming, facial_exp, mood, affect, judgement, insight, process, e);
                                }}
                                options={thoughtContentOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                isDisabled={isSaveAndComplete}
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              Comments
                              <TextareaAutosize
                                className="form-control mt-2"
                                minRows={(EmotionalBehaviour.thought_content_comments?.length / 110) + EmotionalBehaviour.thought_content_comments?.split(/\r\n|\r|\n/).length}
                                style={{ overflow: 'hidden' }}
                                value={EmotionalBehaviour.thought_content_comments}
                                name="thought_content_comments"
                                onChange={handleOnChange}
                                disabled={isSaveAndComplete}
                              />
                              {/*<TextareaAutosize className="form-control mt-2" rows={2} value={content_comments} name="content_comments" onChange={(e) => setcontent_comments(e.target.value)} />*/}
                            </div>
                          </div>
                          <div class="row">
                            <div className="col-md-12 form-group">
                              <strong>JUDGEMENT AND INSIGHT</strong>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-12 form-group">
                              Judgement
                              <Select
                                className="mt-2"
                                name="judgement"
                                value={judgement}
                                onChange={(e) => {
                                  setjudgement(e);
                                  setIsSaved(false);
                                  props.childToParent(EmotionalBehaviour, immediate, recent, remote, speech, orientation, dress, grooming, facial_exp, mood, affect, e, insight, process, content);
                                }}
                                options={judgementOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                isDisabled={isSaveAndComplete}
                              />
                            </div>

                            <div className="col-lg-4 col-md-12 from-group">
                              Insight
                              <Select
                                className="mt-2"
                                name="insight"
                                value={insight}
                                onChange={(e) => {
                                  setinsight(e);
                                  setIsSaved(false);
                                  props.childToParent(EmotionalBehaviour, immediate, recent, remote, speech, orientation, dress, grooming, facial_exp, mood, affect, judgement, e, process, content);
                                }}
                                options={insightOptions}
                                isMulti
                                closeMenuOnSelect={false}
                                isDisabled={isSaveAndComplete}
                              />
                            </div>
                            <div className="col-md-12 form-group">
                              Comments
                              <TextareaAutosize
                                className="form-control mt-2"
                                style={{ overflow: 'hidden' }}
                                value={EmotionalBehaviour.insight_comments}
                                minRows={(EmotionalBehaviour.insight_comments?.length / 110) + EmotionalBehaviour.insight_comments?.split(/\r\n|\r|\n/).length}
                                name="insight_comments"
                                onChange={handleOnChange}
                                disabled={isSaveAndComplete}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 form-group">
                              <strong>Other Considerations</strong>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 form-group">
                              <label>Notes or comments</label>
                              <TextareaAutosize
                                className="form-control mt-2"
                                value={EmotionalBehaviour.other_comments}
                                minRows={(EmotionalBehaviour.other_comments?.length / 110) + EmotionalBehaviour.other_comments?.split(/\r\n|\r|\n/).length}
                                style={{ overflow: 'hidden' }}
                                name="other_comments"
                                onChange={handleOnChange}
                                disabled={isSaveAndComplete}
                              />
                              {/*<TextareaAutosize className="form-control mt-2" value={other_comments} rows={2} name="other_comments" onChange={(e) => setother_comments(e.target.value)} />*/}
                            </div>
                          </div>
                          <div className="row marginBottom1">
                            <div className="col-md-12">
                              <div className="green_band">
                                <div className="row">
                                  <div className="col-lg-4 col-md-8">
                                    <p className="lead sa_risk">
                                      <b>Substance Use Risk Rating </b>
                                      <span className="risk_rating"></span>
                                    </p>
                                  </div>
                                  <div className="col-lg-6 col-md-12 mb-2">
                                    <div className="form-group">
                                      <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label0" name="emotional_risk_rating" value="0" onChange={handleOnChange} checked={EmotionalBehaviour.emotional_risk_rating == "0" ? true : false} />&nbsp;
                                      <label className="labelMargin">0</label>
                                      <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label1" name="emotional_risk_rating" value="1" onChange={handleOnChange} checked={EmotionalBehaviour.emotional_risk_rating == 1 ? true : false} />&nbsp;
                                      <label className="labelMargin">1</label>
                                      <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label2" name="emotional_risk_rating" value="2" onChange={handleOnChange} checked={EmotionalBehaviour.emotional_risk_rating == 2 ? true : false} />{" "} &nbsp;
                                      <label className="labelMargin">2</label>
                                      <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label3" name="emotional_risk_rating" value="3" onChange={handleOnChange} checked={EmotionalBehaviour.emotional_risk_rating == 3 ? true : false} /> &nbsp;
                                      <label className="labelMargin">3</label>
                                      <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label4" name="emotional_risk_rating" value="4" onChange={handleOnChange} checked={EmotionalBehaviour.emotional_risk_rating == 4 ? true : false} /> &nbsp;
                                      <label className="labelMargin">4</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <ReactTooltip id="label0" place="bottom" effect="solid" className="toolTipLabel0" >{emotional_risk_rating0}</ReactTooltip>
                          <ReactTooltip id="label1" place="bottom" effect="solid" className="toolTipLabel1">{emotional_risk_rating1}</ReactTooltip>
                          <ReactTooltip id="label2" place="bottom" effect="solid" className="toolTipLabel2">{emotional_risk_rating2}</ReactTooltip>
                          <ReactTooltip id="label3" place="bottom" effect="solid" className="toolTipLabel3" >{emotional_risk_rating3}</ReactTooltip>
                          <ReactTooltip id="label4" place="bottom" effect="solid" className="toolTipLabel4" >{emotional_risk_rating4}</ReactTooltip>
                          <div className="row">
                            <div className="col-md-12">
                              Diagnostic summary for this dimension
                            </div>
                            <hr />
                            <div className="col-12 mb-2">
                              <div className="form-group">
                                <label>Analysis</label>
                                <TextareaAutosize
                                  className="form-control"
                                  minRows={EmotionalBehaviour.emotional_risk_rating == 4 ? 3 : 2}
                                  readOnly
                                  style={{ backgroundColor: "#FEE5A1", overflow: 'hidden' }}
                                  name="emotional_risk_rating_information"
                                  value={EmotionalBehaviour.emotional_risk_rating_information}
                                />
                              </div>
                            </div>
                            <div className="col-12 mb-2">
                              <div className="form-group">
                                <label>Risk Rating Summary </label>
                                <TextareaAutosize
                                  name="emotional_comments"
                                  style={{ overflow: 'hidden' }}
                                  minRows={(EmotionalBehaviour.emotional_comments?.length / 110) + EmotionalBehaviour.emotional_comments?.split(/\r\n|\r|\n/).length}
                                  disabled={isSaveAndComplete}
                                  value={EmotionalBehaviour.emotional_comments}
                                  onChange={handleOnChange}
                                  className="form-control"
                                />
                                {isRiskRatingMandatory ? <> <span style={{ color: "red" }}>This field is mandatory.*</span></> : ""}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <button
                                type="submit"
                                data-save="save"
                                value="save"
                                className="btn btn-success float-right btnmargin"
                                disabled={isSaveAndComplete | isSaved}
                              // disabled={true}
                              >
                                {" "}
                                <i className="fas fa-save"></i> Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}