
import React, { useEffect, useState, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import SearchIcon from "../../assests/images/search-icon.svg";
import TextField from "./TextField";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import { Typeahead } from 'react-bootstrap-typeahead';
import axios from "axios";
import { useNavigate } from "react-router-dom";


const Section = styled.section`
  width: 95.7%;
  margin: 0 auto;

  min-height: 233px;
  display: ${(props) => (props.showSearchClient ? "flex" : "none")};
  flex-direction: column;
  background: none;

  @media (max-width: 684px) {
    height: max-content;
  }
  @media (max-width: 580px) {
    /* width: 92.7%; */
  }
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.09px;

  color: #000000;
`;

const InputFieldContainerTitle = styled.div`
  margin-bottom: 16px;
  span {
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;

    letter-spacing: -0.09px;

    color: #000000;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const FormContainer = styled(Form)`
  /* height: 233px; */
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 19px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-bottom: 10px;
  }
`;

const Button = styled.button`
  border: none;
  outline: none;
  width: 185px;
  height: 50px;

  background: #009cf0;
  border-radius: 4px;
  font-weight: 600;
  font-size: 15px;
  line-height: 140%;
  margin-right: 20px;
  letter-spacing: -0.09px;

  color: #ffffff;
  span {
    margin-left: 13px;
  }
`;
const AddClientButton = styled(Button)`
  width: 144px;
  height: 50px;

  margin-right: 29px;
  background: #333333;
  border-radius: 4px;
  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;

const ResetButton = styled.input`
  width: 140px;
  height: 49px;

  background: #ffffff;
  border: 1px solid #a8a8a8;
  border-radius: 4px;
  color: #000;

  outline: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 140%;

  text-align: center;
  letter-spacing: -0.09px;

  color: #333333;
  @media (max-width: 378px) {
    width: 120px;
  }
`;

const SearchButton = styled.button`
  width: 140px;
  height: 49px;
  margin-right: 20px;

  background: #009cf0;
  font-weight: 600;
  font-size: 15px;
  line-height: 140%;

  text-align: center;
  letter-spacing: -0.09px;
  border-radius: 4px;
  border: none;
  outline: none;
  color: #fff;
  @media (max-width: 378px) {
    width: 120px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-shadow: none;
  background: none;
  position: static;
  @media (max-width: 643px) {
    justify-content: center;
    margin-left: 8px;
    margin-right: 8px;
  }
  @media (max-width: 580px) {
    /* justify-content: center; */
  }
`;

const Container = styled.div`
  display: flex;

  border-radius: 8px;

  background: #fff;
  justify-content: flex-start;
  @media (max-width: 643px) {
    justify-content: center;
  }
  @media (max-width: 580px) {
    /* justify-content: center; */
  }
`;

const FieldContainer = styled.div`
  @media (max-width: 1343px) {
    flex: ${(props) => (props.btn ? "0 0 30%" : "0 0 25%")};

    max-width: none;
  }
  @media (max-width: 1123px) {
    flex: ${(props) => (props.btn ? "0 0 40%" : "0 0 25%")};
  }
  @media (max-width: 849px) {
    flex: ${(props) => (props.btn ? "0 0 50%" : "0 0 25%")};
  }
  @media (max-width: 685px) {
    flex: ${(props) => (props.btn ? "0 0 100%" : "0 0 25%")};
  }
`;

const InputField = (props) => {

  const [organisation, setOrganisation] = useState("");
  const [showSearchClient, setShowSearchClient] = useState(false);
  const [data, setData] = useState([]);
  const [searchName, setsearchName] = useState("");
  const [DateRange, setDateRange] = useState(null);
  const [showTypeahead, setShowTypeahead] = useState(false);
  const typeaheadRef = useRef(null);
  const navigate = useNavigate();


  const showOrganizationsList = () => {
    let orgs_list = JSON.parse(sessionStorage.getItem('permissions')).orgs_list;
    if (orgs_list === undefined || orgs_list === null) {
      orgs_list = [];
    }
    if (orgs_list.length == 1) {
      setOrganisation(orgs_list[0].org_name);
      sessionStorage.setItem('selected_organization', orgs_list[0].org_name);
      sessionStorage.setItem('organization_id', orgs_list[0].org_id);
      sessionStorage.setItem('org_phone_number', orgs_list[0].phone_number);
      sessionStorage.setItem('org_emergency_phone_number', orgs_list[0].emergency_phone_number);
    } else {
      setOrganisation(orgs_list);
    }
  }

  function handleClientSearchContainer() {
    setShowSearchClient(!showSearchClient);
  }

  let today = new Date();
  let dd = today.getDate();

  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;

  const validate = Yup.object().shape({

    name: Yup.string(),
    // .max(15, "Must be 15 characters or less"),
    // .required("Client Name is required"),
    client_id: Yup.string(),
    // .required("Client Id is Required")
    // .matches(/^[0-9]+$/, "Must be only digits"),
    dob: Yup.date(),
    intake_date: Yup.date(),
    primary_phone: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Invalid phone number"
      ),
    primary_email: Yup.string().email("Email is invalid"),
    zipcode_mailing: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits"),
  });

  const sendFormData = async (values) => {
    let array = [{ name: 'name', value: searchName },
    { name: 'client_id', value: values.client_id },
    { name: 'dob', value: values.dob },
    { name: 'intake_date', value: DateRange },
    { name: 'primary_phone', value: values.primary_phone },
    { name: 'primary_email', value: values.primary_email },
    { name: 'zipcode_mailing', value: values.zipcode_mailing }]
    array = JSON.stringify(array)
    if (searchName == "" && values.client_id == "" && values.dob == "" && DateRange == null && values.primary_phone == "" && values.primary_email == "" && values.zipcode_mailing == "") {
      alert("You can't search with out any Value.");
    } else {
      await sessionStorage.setItem('searchValues', array);
      navigate("/clients");
      // window.location.href = "/clients";
    }
  }

  const setDate = (event, picker) => {
    var startDate = convert(String(picker.startDate));
    var endDate = convert(String(picker.endDate));
    var dateRange = startDate + " - " + endDate;
    setDateRange(dateRange);
  }

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const resetButton = () => {
    setDateRange(null);
  }

  const CryptoJSAesDecrypt = (passphrase, encrypted_json_string) => {
    const CryptoJS = require("crypto-js");
    var obj_json = JSON.parse(encrypted_json_string);
    var encrypted = obj_json.ciphertext;
    var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
    var iv = CryptoJS.enc.Hex.parse(obj_json.iv);
    var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999 });
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  const getClientsList = () => {
    var jsonData = {
      'apikey': sessionStorage.getItem('__TOKEN__'),
      "organization": sessionStorage.getItem('selected_organization')
    };
    axios.post(global.restApiURL + 'customRest/getClientAutoSearch', JSON.stringify(jsonData))
      .then(res => {

        // const returndata = res.data; // get the data array instead of object
        if (res.data.success && res.status == 200) {

          let myData = []
          JSON.parse(CryptoJSAesDecrypt("tactionData", res.data.data)).map((item) => {
            myData.push(item.client_name)
          })

          setData(myData);
        }
        else if ("error_code" in res.data) {
          console.log("Session Out");
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          sessionStorage.setItem('error_msg', res.data.msg);
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        // this.setState({ loading: false, error: true });
        console.error(err);
      }
      )
  }

  function onChangeName(getName) {
    setsearchName(getName[0]);
  }

  useEffect(() => {
    showOrganizationsList();
    getClientsList();
  }, []);

  useEffect(() => {
    if (showTypeahead) {
      // Set focus on the Typeahead component when it's shown
      if (typeaheadRef.current) {
        typeaheadRef.current.focus();
      }
    }
  }, [showTypeahead]);


  const onBlur = () => {
    setShowTypeahead(false);
    // setsearchName("");
  }



  return (
    <div>
      <div>
        <ButtonContainer>
          <Button onClick={handleClientSearchContainer}>
            <img src={SearchIcon} alt="search" />
            <span>Search for client</span>
          </Button>
        </ButtonContainer>
        <Section showSearchClient={showSearchClient}>
          <InputFieldContainerTitle>
            <span>Search Client</span>
          </InputFieldContainerTitle>
          <Formik
            initialValues={{
              name: searchName,
              client_id: "",
              dob: "",
              intake_date: "",
              primary_phone: "",
              primary_email: "",
              zipcode_mailing: "",
            }}
            validationSchema={validate}
            onSubmit={(values, actions) => {
              sendFormData(values);
            }}
          >
            {(formik) => (
              <FormContainer method="post" id="clientsearchform">
                <InputContainer className="card">
                  <Container id="searchdiv" className="card-body row">
                    <FieldContainer className="col-md-3">
                      <div className="form-group">
                        <Label htmlFor="clientName">Client Name</Label><br />
                        {!showTypeahead && (
                          <TextField
                            type="text"
                            className="date"
                            id="Client_name"
                            name="name"
                            value={searchName}
                            placeholder="Client Name"
                            onFocus={() => setShowTypeahead(true)} 

                          />
                        )}
                        {showTypeahead && (
                          <Typeahead
                            ref={typeaheadRef}
                            labelKey="Full_name"
                            options={data}
                            placeholder="Client Name"
                            className="search sc-fzXfNQ dAQahv input-group homeContainerSearch"
                            clearButton={true}
                            onChange={(searchName) => onChangeName(searchName)}
                            onInputChange={(searchName) => setsearchName(searchName)}
                            />
                        )}
                      </div>
                    </FieldContainer>
                    <FieldContainer className="col-md-3">
                      <div className="form-group">
                        <Label>
                          Client ID
                          <span style={{ color: "red", marginLeft: "2px" }}>
                            *
                          </span>
                        </Label>
                        <TextField
                          type="text"
                          id="client_id"
                          name="client_id"
                          placeholder="Client ID"
                        />
                      </div>
                    </FieldContainer>
                    <FieldContainer className="col-md-3">
                      <div className="form-group">
                        <Label>Date of Birth</Label>
                        <div className="input-group date">
                          <TextField
                            type="date"
                            name="dob"
                            placeholder="11 Aug 1998"
                            max={today}
                          />
                        </div>
                      </div>
                    </FieldContainer>
                    <FieldContainer className="col-md-3">
                      <div className="form-group">
                        <Label>Intake Date</Label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="far fa-calendar-alt"></i>
                            </span>
                          </div>
                          <DateRangePicker onApply={setDate} locale='MM/DD/YYYY' className=""
                            initialSettings={{ autoUpdateInput: false }}>
                            <input className="form-control pull-right" id="intake_date" name="intake_date" placeholder="Intake Date" defaultValue={DateRange} readOnly type="text" style={{ backgroundColor: "transparent" }} />
                          </DateRangePicker>
                        </div>
                      </div>
                    </FieldContainer>
                    <FieldContainer className="col-md-3">
                      <div className="form-group">
                        <Label>Phone Number</Label>
                        <TextField
                          type="tel"
                          id="primary_phone"
                          name="primary_phone"
                          placeholder="Phone"
                          maxLength={15}
                        />
                      </div>
                    </FieldContainer>
                    <FieldContainer className="col-md-3">
                      <div className="form-group">
                        <Label>Email Address</Label>
                        <TextField
                          type="email"
                          id="email"
                          name="primary_email"
                          placeholder="Email"
                        />
                      </div>
                    </FieldContainer>

                    <FieldContainer className="col-md-3">
                      <div className="form-group">
                        <Label>Zip Code</Label>
                        <TextField
                          type="text"
                          id="zipcode_mailing"
                          name="zipcode_mailing"
                          placeholder="ZIP Code"
                          maxLength={5}
                          minLength={5}
                        />
                      </div>
                    </FieldContainer>
                    <FieldContainer
                      className="col-md-3"
                      style={{ justifyContent: "center", display: "flex" }}
                      btn={true}
                    >
                      <div className="form-group ">
                        <Label>&nbsp;</Label>
                        <br />
                        <SearchButton type="submit">Search</SearchButton>
                        <ResetButton type="reset" value="Reset" onClick={resetButton} />
                      </div>
                    </FieldContainer>
                  </Container>
                </InputContainer>
              </FormContainer>
            )}
          </Formik>
        </Section>
      </div>
    </div>
  );
}

export default InputField;
