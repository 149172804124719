// toastService.js
import toastr from 'toastr';

toastr.options = {
    "debug": false,
    "positionClass": "toast-bottom-right", // Set the position to bottom right
    "onclick": null,
    "fadeIn": 3000,
    "fadeOut": null,
    "timeOut": 5000,
    "extendedTimeOut": 1000
  };

const showSuccessToast = (message) => {
  toastr.success(message);
};

const showErrorToast = (message) => {
  toastr.error(message);
};

export { showSuccessToast, showErrorToast };
