import { FormControl, MenuItem, Select, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MinusIcon from "../../assests/images/minus.svg";

import TrashIcon from "../../assests/images/trash-2.svg";

import Draggable from "react-draggable";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup } from "victory";
import LastYearIcon from "../../assests/images/icon-lastyear.png";
import MoveIcon from "../../assests/images/move.svg";
import PlusIcon from "../../assests/images/plus.svg";
import PolygonRedIcon from "../../assests/images/Polygon-red.svg";
import ReferralIcon from "../../assests/images/referral-icon.png";
import BlankState from "./BlankState";
import { sampleData } from "./ClientsStackBar";

import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../../commonFunctions/ApiUrl";

let temp = JSON.stringify([...sampleData]);

temp = JSON.parse(temp);

temp.forEach((val, index) => {
  val.data.forEach((value, index) => {
    if (index >= 8) value.val = 0;
    else value.val = Math.random(20) * 50;
  });
});
///console.log(temp);
const MainContainer = styled.section`
  width: 60%;
  border: 1px solid #f0f0f0;
  margin: 20px 0 0 0;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  /* -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3); */
  height: ${(props) =>
    props.expand ? "400px" : props.blankState ? "400px" : "70px"};

    fontFamily:Apple-System,Arial,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,STXihei,sans-serif ;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 17px;
`;

const HeadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => (props.expand ? "70px" : "70px")};

  padding-left: 16px;
`;

const ActionIconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`;

const ActionIcon = styled.img`
  margin-right: 5px;
  cursor: pointer;
`;

const MiddleContainer = styled.div`
  display: flex;
`;

const ClientsDataContainer = styled.div`
  display: flex;
  width: 35%;
  justify-content: space-evenly;
`;

const DetailsDesc = styled.div`
  display: flex;
  flex-direction: column;

  width: 65%;
  fontFamily:Apple-System,Arial,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,STXihei,sans-serif ;
`;

const YearTitle = styled.div`
  font-size: 11px;
`;

const DataDesc = styled.div`
  font-size: 32px;
  font-weight: bold;
`;

const ClientImg = styled.div`
  width: 35%;
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 100px;

  background: linear-gradient(
    rgba(254, 254, 254, 1) 15%,
    rgba(204, 204, 209, 1) 60%,
    rgba(254, 254, 254, 1) 85%
  );
`;

const ReferralResourceDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 38%;
  /* justify-content: space-between; */
  padding-left: 50px;
  /* align-items: center; */
`;

const Circle = styled.span`
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor && props.bgColor};
  margin-top: 5px;
  margin-right: 5px;
`;

const ReferralResourceData = styled.div`
  display: flex;

  padding-bottom: 20px;
`;

const ResourceTitle = styled.div`
  width: 50%;
  font-size: 14px;
  font-family: Inter;
 fontFamily:"Apple-System,Arial,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,STXihei,sans-serif ";
 font-size: 12px;
 text-transform:capitalize,
`;

const Data = styled.div`
  /* width: 50%; */
  font-size: 18px;
  font-family: Inter;
  font-weight: 400;
`;

const Desc = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
`;

const DataContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
`;

const ChartContainer = styled.div``;

const LastContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Decrease = styled.span`
  color: red;
  img {
    margin-right: 5px;
  }
`;

const Image = styled.img`
  padding-left: 30px;
`;

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    border: "none",
    fontSize: "14px",
    marginTop: "2px",
    "&::before": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
  },
});

const AddToDashBoard = styled.button`
  color: #7bb731;
  margin-right: 10px;
  border: none;
  background: none;
  outline: none;
  font-size: 17px;
`;
function TotalReferrals(props) {

  const { addToDashBoard } = props;
  const [final_AddToDashBoard, setfinal_AddToDashBoard] = useState(addToDashBoard);
  const [TotReferrals_in_Local, setTotReferralsInlocal] = useState(false);

  const [blankState, setBlankState] = React.useState(false);
  const [expand, setExpand] = React.useState(true);
  const [xpos, setXpos] = useState(0);
  const [year, setYear] = React.useState("2022");

  const [getDetailsDesc, setDetailsDesc] = useState([]);
  const [getReferralResourceData, setReferralResourceData] = useState([]);
  const [getlast_year_data, setlast_year_data] = useState([]);
  const [getgraph_data, setgraph_data] = useState([]);
  const OrgId = sessionStorage.getItem("organization_id");
  // const OrgId=5184;

  useEffect((year) => {
    fetchAllReport(year);
    if(sessionStorage.getItem("TotReferrals")!==null){
      let bool=sessionStorage.getItem("TotReferrals")
      if(bool=="true"){
        setTotReferralsInlocal(true)
      }else{
        setTotReferralsInlocal(false)
      }
    }
  }, []);

  const fetchAllReport = (year) => {

    const TotReferrals_Local = sessionStorage.getItem("TotReferrals");
    setTotReferralsInlocal(TotReferrals_Local);


    if (year == undefined) {
      year = "2022";
    } else {
      year = year;
    }

    axios
      .get(API_URL + `referral_source_list_client?Token=${sessionStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${year}`)
      .then((response) => {
        // API Response

        setDetailsDesc(response.data.data[0].DetailsDesc);
        setReferralResourceData(response.data.data[0].ReferralResourceData);
        setlast_year_data(response.data.data[0].last_year_data);
        setgraph_data(response.data.data[0].graph_data);
      })
      .catch((error) => {});
  };

  // const [final_AddToDashBoard,setfinal_AddToDashBoard]=useState(addToDashBoard);

  const classes = useStyles(props);
  const handleChange = (event) => {
    setYear(event.target.value);
    const option = event.target.value;
    axios
      .get(API_URL + `referral_source_list_client?Token=${sessionStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${option}`)
      .then((response) => {
        setDetailsDesc(response.data.data[0].DetailsDesc);
        setReferralResourceData(response.data.data[0].ReferralResourceData);
        setlast_year_data(response.data.data[0].last_year_data);
        setgraph_data(response.data.data[0].graph_data);
       
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleDrag(e, data) {
    if (data.x < -100) {
      props.setDirection(true);
    } else if (data.x > 100) {
      props.setDirection(false);
    } else {
      setXpos(0);
    }
  }
  const handleBlankState = (e) => {
    setBlankState(true);
    sessionStorage.removeItem("TotReferrals");
    setfinal_AddToDashBoard(true);


  };
  const handleExpand = (e) => setExpand(!expand);

  function TotReferrals() {
    sessionStorage.setItem("TotReferrals", true);
    // window.location.href = "/chart";
    toast.success("Added to dashboard successfully");
    setfinal_AddToDashBoard(false);

    setTimeout(() => {
      if(sessionStorage.getItem("TotReferrals")!==null){
        let bool=sessionStorage.getItem("TotReferrals")
        if(bool=="true"){
          setTotReferralsInlocal(true);
          window.location.reload()
        }else{
          setTotReferralsInlocal(false)
        }
      }
    }, 1000);
  }


  

  return (
    <Draggable
      handle="#drag"
      axis="x"
      bounds="parent"
      onDrag={handleDrag}
      allowAnyClick={false}
      position={{ x: xpos, y: 0 }}
    >
      <MainContainer blankState={blankState} expand={expand}>
        {blankState ? (
          <BlankState />
        ) : (
          <>
            <HeadContainer expand={expand}>
              <Title>
                Referral Source - Year
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  className={classes.root}
                  style={{ marginLeft: "5px" }}
                >
                  <Select
                    value={year}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className={classes.root}
                    style={{ width: "65px", fontSize: "17px",color:" #675757" }}
                  >
                    <MenuItem value="2022">2022</MenuItem>
                    <MenuItem value="2021">2021</MenuItem>
                    <MenuItem value="2020">2020</MenuItem>
                    <MenuItem value="2019">2019</MenuItem>
                    <MenuItem value="2018">2018</MenuItem>
                  </Select>
                </FormControl>
              </Title>
              {final_AddToDashBoard ? (
                // <AddToDashBoard onClick={TotReferrals}>
                //   + Add to Dashboard
                // </AddToDashBoard>
                TotReferrals_in_Local == true ? (
                  <>
                     {" "}
                    <AddToDashBoard > + Added</AddToDashBoard>
                  </>
                ) : (
                  <>
                  {" "}
                    <AddToDashBoard onClick={TotReferrals}>
                      + Add to Dashboard
                    </AddToDashBoard>
                  
                  </>
                )
              ) : (
                <ActionIconContainer>
                  <ActionIcon
                    src={expand ? MinusIcon : PlusIcon}
                    onClick={handleExpand}
                  />
                  <ActionIcon src={MoveIcon} id="drag" />
                  <ActionIcon src={TrashIcon} onClick={handleBlankState} />
                </ActionIconContainer>
              )}
            </HeadContainer>
            {expand && (
              <>
                <MiddleContainer>
                  <ClientsDataContainer>
                    <ClientImg>
                      <Image src={ReferralIcon} />
                    </ClientImg>
                    {getDetailsDesc.map((e) => (
                      <DetailsDesc>
                        <YearTitle style={{fontSize: "12px",textTransform:"capitalize"}}>{e.year_title}</YearTitle>
                        <DataDesc>{e.val}</DataDesc>
                        <Decrease>
                          <img src={PolygonRedIcon} alt="polygonIcon" />

                          {/* spelling problem */}
                          <span>{e.DataDesc}</span>
                        </Decrease>
                      </DetailsDesc>
                    ))}
                  </ClientsDataContainer>
                  <VerticalLine></VerticalLine>
                  <ReferralResourceDataContainer>
                    {getReferralResourceData.map((e) => (
                      <ReferralResourceData>
                        <Circle bgColor={e.color}></Circle>

                        {/* spelling problem */}
                        <ResourceTitle >{e.resource_title}</ResourceTitle>
                        <Data>{e.val}</Data>
                      </ReferralResourceData>
                    ))}
                  </ReferralResourceDataContainer>
                  <Desc>
                    {getlast_year_data.map((e, i) => (
                      <DataContainer>
                        <ClientImg>
                          <img src={LastYearIcon} alt="last year" />
                        </ClientImg>

                        {/* <ClientImg>
                        <img src={NoreferralIcon} alt="noreferral" />
                      </ClientImg> */}

                        <DetailsDesc>
                          <YearTitle>{e.title}</YearTitle>
                          <DataDesc>{e.vale}</DataDesc>
                        </DetailsDesc>
                      </DataContainer>
                    ))}

                    {/* <DataContainer style={{ margin: 0 }}>
                      <ClientImg>
                        <img src={NoreferralIcon} alt="noreferral" />
                      </ClientImg>
                      <DetailsDesc>
                        <YearTitle>CLIENTS W/NO REFERRAL</YearTitle>
                        <DataDesc>1,353</DataDesc>
                      </DetailsDesc>
                    </DataContainer> */}
                  </Desc>
                </MiddleContainer>
                <ChartContainer>
                  <VictoryChart
                    height={130}
                    width={800}
                    style={{
                      parent: {
                        height: "140px",
                        width: "100%",
                        fontFamily:"Apple-System,Arial,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,STXihei,sans-serif ",
                      },
                    }}

                   
                  >
                    <VictoryGroup
                      offset={10}
                      colorScale={["#7199a9", "#B3DAD1","#59bee9"]}
                      padding={{ top: "0px" }}
                      // width={700}
                      height={130}

                      style={{  fontFamily:"Apple-System,Arial,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,STXihei,sans-serif ",}}
                    >
                      {getgraph_data.map((data, index) => {
                        return (
                          index < 4 && (
                            <VictoryBar
                              key={Math.random() * 100}
                              data={data.data}
                              // y="val"
                            />
                          )
                        );
                      })}
                      {/* <VictoryBar
                        data={[
                          { x: 1, y: 100 },
                          { x: 2, y: 20 },
                          { x: 3, y: 50 },
                        ]}
                      />
                      <VictoryBar
                        data={[
                          { x: 1, y: 70 },
                          { x: 2, y: 50 },
                          { x: 3, y: 30 },
                        ]}
                      />
                      <VictoryBar
                        data={[
                          { x: 1, y: 30 },
                          { x: 2, y: 40 },
                          { x: 3, y: 90 },
                        ]}
                      /> */}
                    </VictoryGroup>
                    <VictoryAxis style={{ tickLabels: { fontSize: "12px",
                    fontFamily:"Apple-System,Arial,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,STXihei,sans-serif ",
                  
                  
                  } }} />
                  </VictoryChart>
                </ChartContainer>
                <LastContainer style={{ fontSize: "15px" }}>
                  Referral By Month
                </LastContainer>
              </>
            )}
          </>
        )}
      </MainContainer>
    </Draggable>
  );
}

export default TotalReferrals;
