import React, { useEffect, useState } from "react";
import styled from "styled-components";
import UserIcon from "../../assests/images/users.svg";
import Modal from '.././Modals/Modal/index';
import DetailCard from "./DetailCard";
import InputFieldContainer from "./InputFieldContainer";

import axios from "axios";
import PolygonGreenIcon from "../../assests/images/polygon-green.svg";
import PolygonRedIcon from "../../assests/images/Polygon-red.svg";
import DocumentViewIcon from "../../assests/images/carbon_document-view.svg";
import ChatFollowIcon from "../../assests/images/chat-follow-up.svg";
import ClarityRepeatLineIcon from "../../assests/images/clarity_repeat-line.svg";
import services from "../../services";
import Footer from "../Footer";
import Topnav, { AppTopNav } from "../Topnav";
import ClientTable from "./ClientTable";



const sampleData = [
  {
    month: "jan",
    data: [
      { gender: "male", val: 2 },
      { gender: "female", val: 2 },

      { gender: "trans", val: 2 },

      { gender: "blank", val: 2 },
    ],
  },
  {
    month: "feb",
    data: [
      { gender: "male", val: 2 },
      { gender: "female", val: 2 },

      { gender: "trans", val: 2 },

      { gender: "blank", val: 2 },
    ],
  },
  {
    month: "mar",
    data: [
      { gender: "male", val: 2 },
      { gender: "female", val: 2 },

      { gender: "trans", val: 2 },

      { gender: "blank", val: 2 },
    ],
  },
  {
    month: "apr",
    data: [
      { gender: "male", val: 2 },
      { gender: "female", val: 2 },

      { gender: "trans", val: 2 },

      { gender: "blank", val: 2 },
    ],
  },
  {
    month: "may",
    data: [
      { gender: "male", val: 2 },
      { gender: "female", val: 2 },

      { gender: "trans", val: 2 },

      { gender: "blank", val: 2 },
    ],
  },
  {
    month: "jun",
    data: [
      { gender: "male", val: 2 },
      { gender: "female", val: 2 },

      { gender: "trans", val: 2 },

      { gender: "blank", val: 2 },
    ],
  },
];


const MainContainer = styled.main`
  background-color: #e5e5e5;
  display: flex;
  flex-direction: column;
`;
const ClientsDetails = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 33px;
  @media (max-width: 1313px) {
    flex-wrap: wrap;
  }
  @media (max-width: 1280px) {
    flex-wrap: wrap;

    margin-left: 10px;
    margin-right: 10px;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    margin-left: 8px;
    margin-right: 8px;
    justify-content: space-between;
  }
`;

const ClientTablesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 74px;
  justify-content: center;
  @media (max-width: 1419px) {
    justify-content: center;
    align-items: center;
    align-content: center;
  }
`;
const myClients = [
  {
    name: "Wisdom Heather",
    intakeDate: "4th Sep 2021",
    lastUpdated: "8th Sep 2021",
  },
  {
    name: "Rob Christian",
    intakeDate: "4th Sep 2021",
    lastUpdated: "8th Sep 2021",
  },
  {
    name: "Rob Christian",
    intakeDate: "4th Sep 2021",
    lastUpdated: "8th Sep 2021",
  },

  {
    name: "Rob Christian",
    intakeDate: "4th Sep 2021",
    lastUpdated: "8th Sep 2021",
  },
  {
    name: "Rob Christian",
    intakeDate: "4th Sep 2021",
    lastUpdated: "8th Sep 2021",
  },
];

const followUp = [
  {
    date: "9th Sep 2021",
    peerSpecialist: "Wisdom Heather",
    modeOfContact: "Email",
  },
  {
    date: "9th Sep 2021",
    peerSpecialist: "Wisdom Heather",
    modeOfContact: "Email",
  },
];

const myOpenForms = [
  {
    name: "Rob Christian",
    form: "Client Information",
    dateStarted: "8th Sep 2021",
  },
  {
    name: "Rob Christian",
    form: "Client Information",
    dateStarted: "8th Sep 2021",
  },
  {
    name: "Rob Christian",
    form: "Client Information",
    dateStarted: "8th Sep 2021",
  },
  {
    name: "Rob Christian",
    form: "Client Information",
    dateStarted: "8th Sep 2021",
  },
  {
    name: "Rob Christian",
    form: "Client Information",
    dateStarted: "8th Sep 2021",
  },
];

const referrals = [
  { date: "Rob Christian", type: "4th Sep 2021", agency: "8th Sep 2021" },
  { date: "Rob Christian", type: "4th Sep 2021", agency: "8th Sep 2021" },
  { date: "Rob Christian", type: "4th Sep 2021", agency: "8th Sep 2021" },
  { date: "Rob Christian", type: "4th Sep 2021", agency: "8th Sep 2021" },
  { date: "Rob Christian", type: "4th Sep 2021", agency: "8th Sep 2021" },
];

const myNotes = [
  {
    name: "Rob Christian",
    date: "8th Sep 2021",
  },
  {
    name: "Rob Christian",
    date: "8th Sep 2021",
  },
  {
    name: "Rob Christian",
    date: "8th Sep 2021",
  },
  {
    name: "Rob Christian",
    date: "8th Sep 2021",
  },
  {
    name: "Rob Christian",
    date: "8th Sep 2021",
  },
];



const MyClients = (props) => {
  const [clientList, setClientList] = useState(null);
  const [clientListLastweek, setClientListLastweek] = useState(null);
  const [ClientListSecondLastweek, setClientListSecondLastweek] = useState(null);
  const [referralList, setReferralList] = useState(null);
  const [referralListLastWeek, setReferralListLastWeek] = useState(null);
  const [referralListSecondLastWeek, setReferralSecondListLastWeek] = useState(null);
  const [notesList, setNotesList] = useState(null);
  const [pastFollowupList, setpastFollowupList] = useState(null);
  const [upcomingFollowupList, setupcomingFollowupList] = useState(null);
  const [staleRecordList, setStaleRecordList] = useState(null);
  const [openFormList, setOpenFormList] = useState(null);
  const [organisation, setOrganisation] = useState('');
  const [showOrganizationModal, setshowOrganizationModal] = useState(false);
  const [org_select_error, setOrg_select_error] = useState(null);
  const [ClientListWeekData, setClientListWeekData] = useState(0);
  const [ReferalListLastWeekData, setReferalListLastWeekData] = useState(0);

  // code Added by ashraf
  const [incompleteAssesmentList, setIncompleteAssesmentList] = useState([]);
  const [completeAssesmentList, setCompleteAssesmentList] = useState([]);
  const [totalClient, setTotalClient] = useState("");
  

  const parseResponse = (promise) => {
    if (promise != true) {
      return promise.then(data => {
        return data;
      }).catch(err => {
        console.log(err);
      });
    }
  };

  const getAllClientList = async () => {
    let requestData = { 
      'apikey': sessionStorage.getItem('__TOKEN__'),
      'user_id': sessionStorage.getItem('loggedinusername'),
      'filters' : {
        'organization' : sessionStorage.getItem('selected_organization')
      } 
    }
    try {
      let totalClient = await parseResponse(services.getClientsList(requestData));
      if (totalClient.pageInfo.records != undefined && totalClient.pageInfo.records != null) {
        setTotalClient(totalClient.pageInfo.records);
      }
    }catch{
      console.log(totalClient);
    }
    
    let requestData2 = {
      'apikey': sessionStorage.getItem('__TOKEN__'),
      'organization': sessionStorage.getItem('selected_organization'),
      'user_name': sessionStorage.getItem('loggedinusername')
    }
    let clientListApi = await axios.post(global.restApiURL+'customRest/getClientForDashboard', JSON.stringify(requestData2));
    setClientList(clientListApi.data.data);
  }

  const getAllClientListWeek = async () => {
    let requestData = {
      'apikey': sessionStorage.getItem('__TOKEN__'),
      'lastweek': true,
      'user_id': sessionStorage.getItem('loggedinusername'),
    }
    let clientListWeekReq = await parseResponse(services.getClientsList(requestData));
    setClientListLastweek(clientListWeekReq);
    await getAllClientSecondListWeek(clientListWeekReq);
  }

  const getAllClientSecondListWeek = async (clientListWeekReq) => {
    let requestData = {
      'apikey': sessionStorage.getItem('__TOKEN__'),
      'secondLastweek': true,
      'user_id': sessionStorage.getItem('loggedinusername'),
    }
    let clientListSecondWeekReq = await parseResponse(services.getClientsList(requestData));
    setClientListSecondLastweek(clientListSecondWeekReq);
    let ClientWeekData = clientListWeekReq?.data?.length - clientListSecondWeekReq?.data?.length;
    setClientListWeekData(ClientWeekData)
  }

  const getReferralList = async () => {
    if (sessionStorage.getItem('loggedinUserID') != '') {
      let getReferralListBody = {
        apikey: sessionStorage.getItem('__TOKEN__'),
        user_id: sessionStorage.getItem('loggedinUserID'),
      };
      let referralList = await parseResponse(services.getReferralListing(getReferralListBody));
      setReferralList(referralList);
    }
  }

  const getReferralListLastWeek = async () => {
    if (sessionStorage.getItem('loggedinUserID') != '') {
      let getReferralListBody = {
        apikey: sessionStorage.getItem('__TOKEN__'),
        user_id: sessionStorage.getItem('loggedinUserID'),
        lastWeek: true
      };
      let referralListLastWeek = await parseResponse(services.getReferralListing(getReferralListBody));
      getReferralSecondListLastWeek(referralListLastWeek);
    }
  }

  const getReferralSecondListLastWeek = async (referralListLastWeek) => {
    if (sessionStorage.getItem('loggedinUserID') != '') {
      let getReferralListBody = {
        apikey: sessionStorage.getItem('__TOKEN__'),
        user_id: sessionStorage.getItem('loggedinUserID'),
        secondLastweek: true
      };
      let referralSecondListLastWeek = await parseResponse(services.getReferralListing(getReferralListBody));
      setReferralSecondListLastWeek(referralSecondListLastWeek);
      let referalLastWeek = referralListLastWeek?.length - referralSecondListLastWeek?.length;
      setReferalListLastWeekData(referalLastWeek);
    }
  }

  const getNotesList = async () => {
    if (sessionStorage.getItem('loggedinUserID') != '') {
      let getNotesListBody = {
        apikey: sessionStorage.getItem('__TOKEN__'),
        user_id: sessionStorage.getItem('loggedinUserID'),
      };
      let NotesList = await parseResponse(services.getNotesListing(getNotesListBody));
      setNotesList(NotesList);
    }
  }

  const getPastFollowupList = async () => {
    if (sessionStorage.getItem('loggedinUserID') != '') {
      let getNotesListBody = {
        apikey: sessionStorage.getItem('__TOKEN__'),
        user_id: sessionStorage.getItem('loggedinUserID'),
        type: 'previous'
      };
      let PastFollowupList = await parseResponse(services.getFollowupListing(getNotesListBody));
      setpastFollowupList(PastFollowupList);
    }
  }

  const getUpcomingFollowupList = async () => {
    if (sessionStorage.getItem('loggedinUserID') != '') {
      let getNotesListBody = {
        apikey: sessionStorage.getItem('__TOKEN__'),
        user_id: sessionStorage.getItem('loggedinUserID'),
        type: 'upcoming'
      };
      let upcomingFollowupList = await parseResponse(services.getFollowupListing(getNotesListBody));
      setupcomingFollowupList(upcomingFollowupList);
    }
  }

  const getStaleRecordList = async () => {
    if (sessionStorage.getItem('loggedinUserID') != '') {
      let getStaleRecordListBody = {
        apikey: sessionStorage.getItem('__TOKEN__'),
        user_id: sessionStorage.getItem('loggedinUserID'),
      };
      let staleRecordList = await parseResponse(services.getStaleRecordsListing(getStaleRecordListBody));
      setStaleRecordList(staleRecordList);
    }
  }

  const getOpenFormList = async () => {
    if (sessionStorage.getItem('loggedinUserID') != '') {
      let getStaleRecordListBody = {
        apikey: sessionStorage.getItem('__TOKEN__'),
        user_id: sessionStorage.getItem('loggedinusername'),
        org_name: sessionStorage.getItem('selected_organization')
      };
      let getOpenFormList = await parseResponse(services.getopenFormListing(getStaleRecordListBody));
      setOpenFormList(getOpenFormList);
    }
  }
  // Code Added By ashraf Start
  const datalistBody = {
    dataListBody: {
      apikey: sessionStorage.getItem('__TOKEN__'),
      created_by: sessionStorage.getItem('loggedinusername'),
      organization: sessionStorage.getItem('selected_organization'),
    }
  };
  const getIncompleteAssesmentList = async () => {
    if (sessionStorage.getItem('loggedinUserID') != '') {
      let res = await axios.post(global.restApiURL + 'customRest/getAssessmentInProgress', JSON.stringify(datalistBody))
      // console.log("Response is:",res.data.success)
      res.data.success && setIncompleteAssesmentList(res.data.data);
    }
  }
  const getcompletedAssesmentList = async () => {
    if (sessionStorage.getItem('loggedinUserID') != '') {
      let res = await axios.post(global.restApiURL + 'customRest/getAssessmentCompleted', JSON.stringify(datalistBody))
      res.data.success && setCompleteAssesmentList(res.data.data)
      // console.log("Response is:",res.data.data)
    }
  }
  // Code Added  By Ashraf End


  const showOrganizationsList = async () => {
    let orgs_list = JSON.parse(sessionStorage.getItem('permissions')).orgs_list;
    //console.log('orgs',orgs_list);
    if (orgs_list === undefined || orgs_list === null) {
      orgs_list = [];
    }
    if (orgs_list.length == 1) {
      setOrganisation(orgs_list[0].org_name);
      await sessionStorage.setItem('selected_organization', orgs_list[0].org_name);
      sessionStorage.setItem('organization_id', orgs_list[0].org_id);
      callApi();
    } else {
      setOrganisation(orgs_list);
      setshowOrganizationModal(true);
    }
  }

  const handleOrgChange = async (event) => {
    await sessionStorage.setItem('selected_organization', event.target.value);
    await sessionStorage.setItem('organization_id', event.target.name);
    await callApi();
  }

  const saveOrgChange = (event) => {
    if (sessionStorage.getItem('selected_organization') === null || sessionStorage.getItem('selected_organization') === undefined || sessionStorage.getItem('selected_organization') === '') {
      setOrg_select_error('Select Organization');
    } else {
      setshowOrganizationModal(false);
      setOrg_select_error('');
    }
  }

  const callApi = async () => {
    // debugger
    getAllClientList();
    getReferralList();
    getNotesList();
    getPastFollowupList();
    getUpcomingFollowupList();
    getAllClientListWeek();
    getReferralListLastWeek();
    getStaleRecordList();
    getOpenFormList();
    // Code Added By ashraf
    getIncompleteAssesmentList();
    getcompletedAssesmentList();
  }



  useEffect( () => {
    if (sessionStorage.getItem('selected_organization') === null || sessionStorage.getItem('selected_organization') === undefined || sessionStorage.getItem('selected_organization') === '' || organisation == null) {
       showOrganizationsList();
    } else {
      callApi();
    }
  }, []);

  let orgs_list;
  if (organisation != null && organisation != undefined && organisation != '' && typeof (organisation) != "string") {
    orgs_list = organisation?.map(org => (
      <option value={org.org_name} name={org.org_id} selected={sessionStorage.getItem('selected_organization') === org.org_name}>{org.org_name}</option>
    ));
  }

  return (
    <MainContainer>
      <AppTopNav organisation={organisation} dropdown={true} />
      {/* <Topnav  organisation={organisation} dropdown={true}></Topnav> */}

      <InputFieldContainer props={props} organisation={organisation} />
      <ClientsDetails>
        <DetailCard
          bgColor="#FFE9E9"
          name="Total Clients"
          icon={UserIcon}
          subTitle="+ Add a Client"
          url="/intake-basicinfo"
          number="658"
          polygonText="+3 last week"
          polygonIcon={ClientListWeekData >= 0 ? PolygonGreenIcon : PolygonRedIcon}
          totalClient={totalClient}
          lastWeek={ClientListWeekData}
        />
        <DetailCard
          bgColor="#E5FFEA"
          name="Referrals"
          icon={ClarityRepeatLineIcon}
          subTitle="+ Add a Referral"
          url=""
          number="45"
          polygonText="-17 last week"
          polygonIcon={ReferalListLastWeekData >= 0 ? PolygonGreenIcon : PolygonRedIcon}
          clientList={referralList}
          lastWeek={ReferalListLastWeekData}
        />
        <DetailCard
          bgColor="#FFFBD8"
          name="Past Follow-ups"
          icon={ChatFollowIcon}
          subTitle="+ Add Follow-up"
          url=""
          number="12"
          clientList={pastFollowupList}
        />
        <DetailCard
          bgColor="#FFE8D8"
          name="Open Forms"
          icon={DocumentViewIcon}
          subTitle="+ Add Form"
          url=""
          number="140"
          polygonText="+1 last week"
          polygonIcon={PolygonGreenIcon}
          clientList={openFormList}
        />
      </ClientsDetails>
      <ClientTablesContainer>
        <ClientTable
          title="My Clients"
          headTitle={[
            { name: "LN, FN", title: "lastName_FirstName", condition:"client_name"},
            { name: 'Date of Birth', title: "Dob", condition:"client_dob"},
            { name: "Intake Date", title: `Intake_date`, condition:"intake_date" },
            { name: "Last Updated", title: `Last_updated_on`, condition:"last_updated" },
          ]}
          records={myClients}
          clientList={clientList}
        />
        <ClientTable
          title="My Upcoming Follow-ups"
          headTitle={[
            { name: "LN, FN", title: "client_record_name" },
            { name: "Date of Birth", title: "client_record_dob" },
            { name: "Follow up Date", title: "followUpDate" },
            { name: "Last Updated", title: "last_updated" },
          ]}
          records={followUp}
          clientList={upcomingFollowupList}
        />
        <ClientTable
          title="My Open Forms"
          headTitle={[
            { name: "LN, FN", title: `Last_First_Name` },
            { name: "Form", title: `form_name` },
            { name: "Date Started", title: `date_open_form` },
          ]}
          records={myOpenForms}
          clientList={openFormList}
        />
        <ClientTable
          title="Referrals"
          headTitle={[
            { name: "LN, FN", title: `client_name` },
            { name: "Type", title: `referral_type` },
            { name: "Agency", title: `client_referred_agency` },
            { name: "Date", title: `date` },
          ]}
          records={referralList}
          clientList={referralList}
        />
        <ClientTable
          title="Stale Records"
          headTitle={[
            { name: "LN, FN", title: "client_name" },
            { name: "Date of Birth", title: "client_dob" },
            { name: "Follow up Date", title: "followup_date" },
            { name: "Last Updated", title: "last_updated" },
          ]}
          clientList={staleRecordList}
        />
        <ClientTable
          title="My Notes"
          headTitle={[
            { name: "LN, FN", title: `client_name` },
            { name: "Note", title: 'note' },
            { name: "Date", title: `updated_on` },
          ]}
          records={myNotes}
          clientList={notesList}
        />
        {/* Code Added by ashraf start */}

        {!global.is_peer_specialist && <>
          <ClientTable
            title="Incomplete Assessments"
            headTitle={[
              { name: "LN, FN", title: `client_name` },
              { name: "Date of Birth", title: `dob` },
              { name: "Form Type", title: `form_type` },
              { name: "Last Updated", title: `updated_at` },
            ]}
            records={myNotes}
            clientList={incompleteAssesmentList}
          />

          <ClientTable
            title="Completed Assessments"
            headTitle={[
              { name: "LN, FN", title: `client_name` },
              { name: "Date of Birth", title: `dob` },
              { name: "Form Type", title: `form_type` },
              { name: "Completion Date", title: `completion_date` },
            ]}
            records={myNotes}
            clientList={completeAssesmentList}
          />

        </>}
        {/* Code Added by ashraf End */}
      </ClientTablesContainer>
      <Modal
        showOverlay={true}
        show={showOrganizationModal}
        disableOverlayClick={true}
        showCloseButton={false}
        size="sm"
        className="modal-lg modal-dialog"
        style={{ lineHeight: "1.5", letterSpacing: "inherit" }}
      >
        <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
          <Modal.Title>
            <div className="row" >
              <div className="col-sm-12">
                <p className="lead float-left"><b className="text-green">Select Organization</b></p>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <fieldset>
            {organisation?.length > 1 ? (
              <div>
                {org_select_error !== '' ? (<div className="text-danger">{org_select_error}</div>) : ''}
                <label id="select_organiztion">Select Organization</label>
                <select className="form-control" id="Organization" onChange={handleOrgChange}>
                  <option value="">Select</option>
                  {orgs_list}
                </select>
              </div>) : (
              <p className="text-danger">No Organization is assigned to you. Contact Administrator.</p>)}

          </fieldset>


        </Modal.Body>
        <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
          <div className="row">
            <div className="col-md-12">
              <div className="justify-content-between">

                <button className="btn btn-success float-right btnmargin" type="button" onClick={saveOrgChange}  >Select</button>

              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Footer sidebar={false} />
    </MainContainer>
  );
}

export default MyClients;
