import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
export default class Co_occuring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrest_last_30_days: "",
      client_attended_self_help_groups_number: "",
      participated_in_self_care_groups: "",
      co_occuring_disorders_identified: "",
      prior_treatment_episodes: "",
      co_occuring_disorder_screen: "",
      co_occuring_disorder_assesment: "",
      saved: true,
      isSaveAndComplete: false,
    };
  }
  dataListBody = {
    apikey: sessionStorage.getItem("__TOKEN__"),
    client_id: sessionStorage.getItem("clientId"),
    form_type: this.props.form_type,
    uploaded_by: sessionStorage.getItem("username"),
  };
  co_occuring_disorder_option = [
    { value: "not_performed", label: "Not performed" },
    { value: "administered_results(-)", label: " Administered/Results (-)" },
    { value: "administered_results(+)", label: "Administered/Results (+)" },
  ];
  handleChange = async (e) => {
    this.props.tabEditOrNot(true);
    this.props.saveAndExit("", "", this.handleOnSubmit, "", "", "", "", "", "");
    this.setState({ [e.target.name]: e.target.value, ["saved"]: false });
    setTimeout(() => {
      this.props.childToParent(this.state);
    }, 500);
  };
  handleOnSubmit = async (e) => {
    e.preventDefault();

    let final_data = {
      CoOccurringData: this.state,
      dataListBody: this.dataListBody,
    };
    let res = await axios.post(
      global.restApiURL + "customRest/updateCooccuring",
      JSON.stringify(final_data)
    );
    if (res.data.success == true) {
      this.loadData();
      toast.success("Data added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: "success_CoOccouring",
        theme: "colored",
      });
    }
    this.props.tabEditOrNot(false);
  };
  loadData = async () => {
    let res = await axios.post(
      global.restApiURL + "customRest/getCooccuring",
      JSON.stringify(this.dataListBody)
    );
    if (res.data.success == true) {
      this.setState({
        co_occuring_disorder_screen:
          res.data.data.CoOccurringData.co_occuring_disorder_screen,
        co_occuring_disorder_assesment:
          res.data.data.CoOccurringData.co_occuring_disorder_assesment,
        co_occuring_disorders_identified:
          res.data.data.CoOccurringData.co_occuring_disorders_identified,
        prior_treatment_episodes:
          res.data.data.CoOccurringData.prior_treatment_episodes,
        arrest_last_30_days: res.data.data.CoOccurringData.arrest_last_30_days,
        participated_in_self_care_groups:
          res.data.data.CoOccurringData.participated_in_self_care_groups,
        client_attended_self_help_groups_number:
          res.data.data.CoOccurringData.client_attended_self_help_groups_number,
        saved: true,
      });
    }
    setTimeout(() => {
      this.props.childToParent(this.state);
    }, 500);
  };

  componentDidMount() {
    this.loadData();
  }
  render() {
    return (
      <React.Fragment>
        {/* <ToastContainer style={{ zIndex: 900 }} /> */}
        <form onSubmit={this.handleOnSubmit}>
          <div className="row">
            <div className="col-md-12">
              <p
                className="lead float-left"
                style={{
                  fontSize: "1.05rem",
                  lineHeight: "20px",
                  marginBottom: "1rem",
                }}
              >
                <b className="text-green">Co-Occurring</b>
              </p>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Co-Occurring Disorders Screen Results</label>
                {/* <input type="text" className='form-control' value={this.state.co_occuring_disorder_screen} name="co_occuring_disorder_screen" onChange={this.handleChange} /> */}
                <select
                  name="co_occuring_disorder_screen"
                  disabled={this.state.isSaveAndComplete}
                  value={this.state.co_occuring_disorder_screen}
                  className="form-control"
                  onChange={this.handleChange}
                >
                  <option>Select</option>
                  {this.co_occuring_disorder_option.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Co-Occurring Disorders Assessment performed?</label>
                {/* <input type="text" className='form-control' value={this.state.co_occuring_disorder_assesment} name="co_occuring_disorder_assesment" onChange={this.handleChange} /> */}
                <select
                  name="co_occuring_disorder_assesment"
                  disabled={this.state.isSaveAndComplete}
                  value={this.state.co_occuring_disorder_assesment}
                  className="form-control"
                  onChange={this.handleChange}
                >
                  <option>Select</option>
                  {this.co_occuring_disorder_option.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Co-Occurring Disorder Identified</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={this.state.isSaveAndComplete}
                  value={this.state.co_occuring_disorders_identified}
                  name="co_occuring_disorders_identified"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Number of Prior Treatment Episodes</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={this.state.isSaveAndComplete}
                  value={this.state.prior_treatment_episodes}
                  name="prior_treatment_episodes"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Number of arrests 30 days prior to interview date</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={this.state.isSaveAndComplete}
                  value={this.state.arrest_last_30_days}
                  name="arrest_last_30_days"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Has the client participated in self-help groups or support
                  groups in the last 30 days?
                </label>
                <select
                  className="form-control"
                  disabled={this.state.isSaveAndComplete}
                  value={this.state.participated_in_self_care_groups}
                  name="participated_in_self_care_groups"
                  onChange={this.handleChange}
                >
                  <option value="">Select</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>
            {this.state.participated_in_self_care_groups == "yes" && (
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    How often has the client attended self-help groups or
                    support groups in the last 30 days?
                  </label>
                  <select
                    className="form-control"
                    disabled={this.state.isSaveAndComplete}
                    value={this.state.client_attended_self_help_groups_number}
                    name="client_attended_self_help_groups_number"
                    onChange={this.handleChange}
                  >
                    <option value="">Select</option>
                    <option value="1_3_times_per_month">
                      1-3 times per month
                    </option>
                    <option value="1_2_times_per_week">
                      1-2 times per week
                    </option>
                    <option value="3_6_times_per_week">
                      3-6 times per week
                    </option>
                  </select>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-12">
              {/* <button className='btn btn-success float-right'><i className="fas fa-save"></i> Save</button> */}
              <button
                type="submit"
                data-save="save"
                disabled={this.state.saved}
                value="save"
                className="btn btn-success float-right btnmargin"
              >
                {" "}
                <i className="fas fa-save"></i> Save
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
