import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../global.js";
import $ from "jquery";
import { CButton, CCollapse, CCard, CCardBody } from "@coreui/react";
import Modal from "./Modals/Modal/index.js";

const Sidenav = (props) => {
  const [clientId, setClientId] = useState("");
  var uri_arr = document.location.pathname.split("/");
  var php_file_name = "/" + uri_arr[uri_arr.length - 1];
  const [visible, setVisible] = useState(false);
  const [navigation, setNavigation] = useState(false);
  const [intakeVisible, setIntakeVisible] = useState(true);
  const [basicInfoVisible, setBasicInfoVisible] = useState(false);
  const [roiVisible, setRoiVisible] = useState(false);
  const [otherInfoVisible, setOtherInfoVisible] = useState(false);
  const [intakeAssessmentVisible, setIntakeAssessmentVisible] = useState(false);

  const [activeLink, setActiveLink] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [pendingNavLink, setPendingNavLink] = useState(null);


  const navigate = useNavigate();

  const handleNavClicked = (e, to) => {
    e.preventDefault();
    // setActiveLink(to);
    if (props.unSaved) {
      setPendingNavLink(to);
      setModalVisible(true);
    }
    if (props.unSaved === undefined || !props.unSaved) {
      navigate(to);
    }
  };

  const handleConfirmNavigationClick = () => {
    // debugger
    // let navigateForward = props.shouldNavigate(props);
    let navigateForward = props.shouldNavigate();
    setModalVisible(false);
    if (pendingNavLink != null && navigateForward) {
      props.onSaveRequest();
      navigate(pendingNavLink);
    }
  };

  const handleNotConfirmNavigationClick = () => {
    // debugger
    // && props.shouldNavigate() it should not check when click on no button.
    setModalVisible(false);
    if (pendingNavLink != null) {
      navigate(pendingNavLink);
    }
  };

  useEffect(() => {
    setActiveLink(window.location.pathname);

    // const menuItems = document.querySelectorAll('li');

    // menuItems.forEach(item => {
    //   item.addEventListener('click', function () {
    //     debugger
    //     // Example condition: only add 'menu-open' if the item is not already active
    //     if (!this.classList.contains('active')) {
    //       this.classList.add('menu-open');
    //     }else {
    //       this.classList.remove('menu-open');
    //     }

    //     // Set this item as active (optional logic)
    //     // this.classList.toggle('active');
    //   });
    // });

    // const handleClick = (e) => {
    //   const thisHref = e.target.getAttribute('href');
    //   if (sessionStorage.getItem('clientId') === '') {
    //     e.preventDefault();
    //   }else {
    //     if (!sessionStorage.getItem('changes_unsaved')) {
    //       navigate(thisHref);
    //     }
    //   }
    // };
    // const uriArr = document.location.pathname.split("/");
    // const phpFileName = "/" + uriArr[uriArr.length - 1];
    // document.querySelectorAll('.sidenav a.nav-link a.sideNavCustomCss').forEach(link => {
    //   if (link.getAttribute("href") === phpFileName) {
    //     link.parentElement.classList.add("active");  
    //     link.parentElement.parentElement.parentElement.classList.add("collapse", "show", "enter-done");
    //     link.classList.add("active");
    //   }
    // });

    // document.querySelectorAll('.sidenav a.nav-link').forEach(link => {
    //   link.addEventListener('click', handleClick);
    // });

    // return () => {
    //   document.querySelectorAll('.sidenav a.nav-link div.sideNavCustomCss').forEach(link => {
    //     link.removeEventListener('click', handleClick);
    //   });
    // };

  }, [navigate]);

  return (
    <div className="col-md-3 col-lg-2 sidenav">
      <ul className="nav nav-pills nav-sidebar flex-column nav-compact text-sm" data-widget="treeview" role="menu" data-accordion="false">
        <li className="nav-item" id="gpra">
          <div className="card">
            <NavLink to="/dashboard" className="nav-link" id="gpra_a" onClick={(e) => handleNavClicked(e, '/dashboard')}>
              <div className="card-body p-0">
                <i className="fas fa-arrow-left"></i> Client Dashboard
              </div>
            </NavLink>
          </div>
        </li>
        {/* <div className="card"> */}
        <li id="intake" className="nav-item has-treeview">
          <div className="row" style={{ paddingLeft: "4px" }}>
            <div className="col-md-12 col-lg-12">
              <div className="text-green">
                <span>Client Forms</span>
              </div>
            </div>
          </div>
          <div className="card">
            <nav className="nav-link" id="intake_a" onClick={() => setIntakeVisible(!intakeVisible)}>
              <div className="card-body p-0">
                <i className="fas fa-hospital"></i> Intake
                <i className="float-right fas fa-angle-left"></i>
              </div>
            </nav>
            <CCollapse visible={intakeVisible}>
              <ul className="navbar-nav" id="basic_info">
                <div className="card">
                  <li className="nav-item">
                    <a className="nav-link" id="basic_info_a" onClick={() => setBasicInfoVisible(!basicInfoVisible)} >
                      <div className="card-body p-0">
                        Basic Info
                        <i className="float-right fas fa-angle-left"></i>
                      </div>
                    </a>
                    <CCollapse visible={basicInfoVisible}>
                      <ul className="navbar-nav">
                        {/* <div className="card"> */}
                        <li className="nav-item">
                          <div className={`nav-link sideNavCustomCss link-creation ${activeLink === '/intake-basicinfo' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/intake-basicinfo')}>
                            <div className="card-body p-0">Basic Info</div>
                          </div>
                        </li>
                        {/* </div> */}
                        {/* <div className="card"> */}
                        <li className="nav-item">
                          <div className={`nav-link sideNavCustomCss link-creation ${activeLink === '/emergencycontact' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/emergencycontact')}>
                            <div className="card-body p-0">Emergency Contact</div>
                          </div>
                        </li>
                        {/* </div> */}
                      </ul>
                    </CCollapse>
                  </li>
                </div>
                <div className="card">
                  <li className="nav-item">
                    <div className={`nav-link link-creation ${activeLink === '/consent' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/consent')} >
                      <div className="card-body p-0">Consent to Services</div>
                    </div>
                  </li>
                </div>
                <div className="card">
                  <li className="nav-item">
                    <a className="nav-link" id="roi_a" onClick={() => setRoiVisible(!roiVisible)} >
                      <div className="card-body p-0">
                        Release of Information
                        <i className="float-right fas fa-angle-left"></i>
                      </div>
                    </a>
                    <CCollapse visible={roiVisible}>
                      <ul className="navbar-nav">
                        {/* <div className="card"> */}
                        <li className="nav-item">
                          <div className={`nav-link sideNavCustomCss link-creation ${activeLink === '/consent-for-roi' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/consent-for-roi')}>
                            <div className="card-body p-0">Authorization for ROI</div>
                          </div>
                        </li>
                        {/* </div> */}
                        {/* <div className="card"> */}
                        <li className="nav-item">
                          <div className={`nav-link sideNavCustomCss link-creation ${activeLink === '/verbal-consent' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/verbal-consent')}>
                            <div className="card-body p-0">Criminal Justice ROI</div>
                          </div>
                        </li>
                        {/* </div> */}
                        {/* <div className="card"> */}
                        <li className="nav-item">
                          <div className={`nav-link sideNavCustomCss link-creation ${activeLink === '/consent7' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/consent7')}>
                            <div className="card-body p-0">Hospital ROI</div>
                          </div>
                        </li>
                        {/* </div> */}
                      </ul>
                    </CCollapse>
                  </li>
                </div>
                <div className="card">
                  <li className="nav-item">
                    <a className="nav-link" id="other_info_a" onClick={() => setOtherInfoVisible(!otherInfoVisible)} >
                      <div className="card-body p-0">
                        Other Info
                        <i className="float-right fas fa-angle-left"></i>
                      </div>
                    </a>
                    <CCollapse visible={otherInfoVisible}>
                      <ul className="navbar-nav">
                        {/* <div className="card"> */}
                        <li className="nav-item">
                          <div className={`nav-link sideNavCustomCss link-creation ${activeLink === '/otheragency' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/otheragency')}>
                            <div className="card-body p-0">Other Agencies</div>
                          </div>
                        </li>
                        {/* </div> */}
                        {/* <div className="card"> */}
                        <li className="nav-item">
                          <div className={`nav-link sideNavCustomCss link-creation ${activeLink === '/hangout' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/hangout')}>
                            <div className="card-body p-0">Hangout</div>
                          </div>
                        </li>
                        {/* </div> */}
                        {/* <div className="card"> */}
                        <li className="nav-item">
                          <div className={`nav-link sideNavCustomCss link-creation ${activeLink === '/cases' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/cases')}>
                            <div className="card-body p-0">Probation/Parole/Cases</div>
                          </div>
                        </li>
                        {/* </div> */}
                        {/* <div className="card"> */}
                        <li className="nav-item">
                          <div className={`nav-link sideNavCustomCss link-creation ${activeLink === '/homelessness' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/homelessness')}>
                            <div className="card-body p-0">Homelessness</div>
                          </div>
                        </li>
                        {/* </div> */}
                        {/* <li className="nav-item">
                        <NavLink to="/childandfamilyservices" className="nav-link" onClick={(e) => handleNavClicked(e, '/childandfamilyservices')}>
                          <div className="card-body p-0">Child and Family Services</div>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/otherinfo" className="nav-link" onClick={(e) => handleNavClicked(e, '/otherinfo')}>
                          <div className="card-body p-0">Other Information</div>
                        </NavLink>
                      </li> */}
                      </ul>
                    </CCollapse>
                  </li>
                </div>
                {/* <div className="card"> */}
                  <li className="nav-item" id="intake_assessment">
                    <a className="nav-link" onClick={() => setIntakeAssessmentVisible(!intakeAssessmentVisible)} >
                      <div className="card-body p-0">
                        Intake Assessment
                        <i className="float-right fas fa-angle-left"></i>
                      </div>
                    </a>
                    <CCollapse visible={intakeAssessmentVisible}>
                      <ul className="navbar-nav">
                        {/* <div className="card"> */}
                        <li className="nav-item">
                          <div className={`nav-link sideNavCustomCss link-creation ${activeLink === '/intakeassessment' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/intakeassessment')}>
                            <div className="card-body p-0">Assessment</div>
                          </div>
                        </li>
                        {/* </div> */}
                        {/* <div className="card"> */}
                        <li className="nav-item">
                          <div className={`nav-link sideNavCustomCss link-creation ${activeLink === '/supplementaryinfo' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/supplementaryinfo')}>
                            <div className="card-body p-0">Supplementary Information</div>
                          </div>
                        </li>
                        {/* </div> */}
                      </ul>
                    </CCollapse>
                  </li>
                {/* </div> */}
              </ul>
            </CCollapse>
          </div>
        </li>
        {/* </div> */}

        <li className="nav-item">
          <div className="card">
            <div className={`nav-link link-creation ${activeLink === '/recoverygoals' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/recoverygoals')} >
              <div className="card-body p-0">
                <i className="dcarissidenav fas fa-heart"></i> Recovery Goals</div>
            </div>
          </div>
        </li>
        <li className="nav-item has-treeview" id="health_screenings">
          <div className="card">
            <div className={`nav-link link-creation ${activeLink === '/health_screenings' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/health_screenings')} >
              <div className="card-body p-0"><i className="fas fa-stethoscope"></i> Health Screenings</div>
            </div>
          </div>
        </li>
        <div className="row sidenav" style={{ paddingLeft: "6px" }}>
          <div className="col-md-12 col-lg-12">
            <div className="text-green">
              <span>Other Forms</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <li className="nav-item" id="followup">
              <div className="card">
                <div className={`nav-link link-creation ${activeLink === '/followup' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/followup')} >
                  <div className="card-body p-0">
                    <i className="nav-icon fas fa-calendar-check"></i>{" "}Follow-Ups</div>
                </div>
              </div>
            </li>
          </div>
          <div className="col-md-12 col-lg-6">
            <li className="nav-item">
              <div className="card">
                <div className={`nav-link link-creation ${activeLink === '/notes' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/notes')} >
                  <div className="card-body p-0">
                    <i className="nav-icon far fa-clipboard"></i> Notes</div>
                </div>
              </div>
            </li>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <li className="nav-item">
              <div className="card">
                <div className={`nav-link link-creation ${activeLink === '/referrals' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/referrals')} >
                  {/*   */}
                  <div className="card-body p-0"><i className="nav-icon fas fa-calendar-check"></i> Referrals</div>
                </div>
                {/* <NavLink to="/referrals" className="nav-link" onClick={(e) => handleNavClicked(e, '/referrals')}>
                  <div className="card-body p-0">
                    <i className="nav-icon fas fa-calendar-check"></i> Referrals
                  </div>
                </NavLink> */}
              </div>
            </li>
          </div>
          <div className="col-md-12 col-lg-6">
            <li className="nav-item">
              <div className="card">
                <div className={`nav-link link-creation ${activeLink === '/routing' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/routing')} >
                  <div className="card-body p-0">
                    <i className="nav-icon fas fa-medkit"></i> Routings
                  </div>
                </div>
              </div>
            </li>
          </div>
        </div>
        <li className="nav-item">
          <div className="card">
            <div className={`nav-link link-creation ${activeLink === '/change_participation' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/change_participation')} >
              <div className="card-body p-0">
                <i className="fas fa-user-md"></i> Change Participation</div>
            </div>
          </div>
        </li>
        <div className="card">
          <li className="nav-item">
            <div className={`nav-link link-creation ${activeLink === '/state_assessment' ? 'active' : ''}`} onClick={(e) => handleNavClicked(e, '/state_assessment')} >
              <div className="card-body p-0">
                <i className="fas fa-clipboard-list"></i> State Assessment</div>
            </div>
          </li>
          <Modal
            showOverlay={true}
            show={modalVisible}
            onClose={() => setModalVisible(false)}
            disableOverlayClick={true}
            showCloseButton={false}
            size="sm"
            className="modal-md modal-dialog"
          >
            <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
              <Modal.Title>
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="modal-title float-left text-green">Leave this page</h4>
                  </div>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ minHeight: 'auto', textAlign: 'center' }}>
              <h4>Do you want to save the changes?</h4>
            </Modal.Body>
            <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
              <div className="row">
                <div className="col-md-6">
                  <button className="btn btn-default float-left" onClick={handleNotConfirmNavigationClick} type="button">No</button>
                </div>
                <div className="col-md-6">
                  <button id="Open" className="btn btn-success float-right btnmargin" onClick={handleConfirmNavigationClick}>
                    <i className="fas fa-save"></i> Yes
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </ul>
    </div>
  );
};

export default Sidenav;
