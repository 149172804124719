import React, { Component } from 'react';
import $ from 'jquery';
import Topnav, { AppTopNav } from './Topnav';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import { validationParser1 } from '../ReusableComponents/validationParser';
import { disable_button } from '../ReusableComponents/validationParser2';
import InputMask from 'react-input-mask';
import { can_edit } from '../ReusableComponents/urlGenerator';
import { selectOptionsGenerator } from '../ReusableComponents/OptionsGenerator';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import LocationSearchInput from '../Components/AddressAutocomplete';


import { InputTextField, LabelField } from '../ReusableComponents/LabelnInput'
import {ConfirmBeforeLeave} from './ConfirmBeforeLeave';
import { Alert } from "./toastify";
import ReactTooltip from 'react-tooltip';
import { encryptStorage } from './LocalStorageInterceptor';
import HOC from '../HOC';
import { showSuccessToast } from './toastService';

const axios = require('axios').default;
var context;
let rawValidationData = "";

class Emergencycontact extends Component {
  constructor() {
    super();
    context = this;
    this.handleId = this.handleId.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    // this.validationParser = this.validationParser.bind(this);
    this.validationmapper = this.validationmapper.bind(this);
    this.state = {

      // emergencyContactFormData: {
      // 		name_primary:'',
      // 		relationship_primary:'',
      // 		phone_primary:'',
      // 		address_pri : '',
      // 		city_primary : '',
      // 		county_primary : '',
      // 		zip_code_pri : '',
      // 		name_secondary : '',
      // 		relationship_secondary : '',
      // 		phone_secondary : '',
      // 		address_sec : '',
      // 		city_secondary : '',
      // 		county_secondary : '',
      // 		zip_code_sec : '',
      // },
      emergencyContactFormData: this.getModal(),
      required: this.getModal(),
      labelText: this.getModal(),
      tooltips: this.getModal(),
      maxLength: this.getModal(),
      tooltip: this.getModal(),
      tooltipVisibility: this.getModal(),

      unSaved: false,

      leave_msg_pri_options: [],
      leave_msg_sec_options: [],
      same_as_client_addr_pri_options: [],
      same_as_client_addr_sec_options: [],
      state_primary_options: [],
      state_secondary_options: [],
      address: '',
    };
  }

  getModal() {
    var modal = {
      errorMessage: '',
      errorMessageSecondary: "",
      name_primary: '',
      relationship_primary: '',
      phone_primary: '',
      address_pri: '',
      city_primary: '',
      county_primary: '',
      zip_code_pri: '',
      name_secondary: '',
      relationship_secondary: '',
      phone_secondary: '',
      address_sec: '',
      city_secondary: '',
      county_secondary: '',
      zip_code_sec: '',
      leave_msg_pri: '',
      leave_msg_sec: '',
      same_as_client_addr_pri: '',
      same_as_client_addr_sec: '',
      // Code Added By Ashraf Start
      email_primary: '',
      email_secondary: '',
      // Code Added by Ashraf End
      state_primary: "",//encryptStorage.getItem('state_lns') && encryptStorage.getItem('state_lns') !== '' ? encryptStorage.getItem('state_lns') : '',
      state_secondary: ""//encryptStorage.getItem('state_lns') && encryptStorage.getItem('state_lns') !== '' ? encryptStorage.getItem('state_lns') : '',
    }

    return modal;
  }

  handleChange = date => {
    this.setState({
      startDateintakedate: date
    });
  };

  handleDataChange = (event) => {
    event.persist();
    console.log('data change', event)
    var stateCopy = Object.assign({}, this.state);
    stateCopy.emergencyContactFormData[event.target.name] = event.target.value;
    stateCopy['unSaved'] = true;
    this.setState(stateCopy);
  }

  handlemonth1followupdate = date => {
    console.log("Selected date is : " + date);
    this.setState({
      month1followupdate: date
    });
  };

  handleChangesixmonthfollow = date => {

    console.log("Selected date is : " + date);

    this.setState({
      sixmonthfollow: date
    });
  };
  handleChangedob = date => {

    console.log("Selected date is : " + date);

    this.setState({
      dob: date
    });
  };
  componentWillMount() {
    //for validation

    axios.get(global.restApiURL + 'webservice/rest/class/id/2?apikey=' + sessionStorage.getItem('__CL_TOKEN__'))
      .then(res => {
        var responce = res.data;

        var validatorPareserobj = validationParser1(responce.data.layoutDefinitions.children[0].children);

        rawValidationData = validatorPareserobj;

        this.validationmapper(validatorPareserobj);

        this.createDropdowns(validatorPareserobj);

      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      })
  }

  handlePhoneChange = (event) => {
    if (event.target !== null) {
      let test = event.target.value;
      if (event.target.name === "phone_secondary") {
        if (this.state.emergencyContactFormData.phone_secondary !== "") {
          this.setState({
            errorMessageSecondary: ''
          });
        }
        this.setState(prevState => ({
          ...prevState,
          emergencyContactFormData: {
            ...prevState.emergencyContactFormData,
            phone_secondary: test
          },
          unSaved: true
        }));
      }
      if (event.target.name === "phone_primary") {
        if (this.state.emergencyContactFormData.phone_primary !== "") {
          this.setState({
            errorMessage: ''
          });
        }
        this.setState(prevState => ({
          ...prevState,
          emergencyContactFormData: {
            ...prevState.emergencyContactFormData,
            phone_primary: test
          },
          unSaved: true
        }));
      }
    }
  }

  beforeMaskedValueChange2 = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
    if (value.endsWith('-') && userInput !== '-' && !this.state.emergencyContactFormData.phone_secondary.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    return {
      value,
      selection
    };
  }

  beforeMaskedValueChange1 = (newState, oldState, userInput) => {

    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !this.state.emergencyContactFormData.phone_primary.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection
    };
  }


  componentDidMount() {
    const { navigate } = this.props
    disable_button('emagencyinfoform');
    sessionStorage.setItem('responceId', '');
    var prevExist = false;
    // for clientbasic info
    if (sessionStorage.getItem('clientId') !== '') {
      axios.get(global.restApiURL + 'webservice/rest/object-list?apikey=' + sessionStorage.getItem('__TOKEN__') + '&limit=1&objectClass=EmergencyContact&q={"client_id":"' + sessionStorage.getItem('clientId') + '"}')
        .then(res => {
          const responce = res.data.data; // get the data array instead of object
          if (res.data.total != 0) {
            prevExist = true;

            sessionStorage.setItem('responceId', responce.id);
            axios.get(global.restApiURL + 'webservice/rest/object/id/' + responce.id + '?apikey=' + sessionStorage.getItem('__TOKEN__'))
              .then(res => {
                const responce = res.data.data.elements;
                this.datamapper(responce)

                //if client address is same then, update with the latest address
                if (this.state.emergencyContactFormData.same_as_client_addr_pri === true &&
                  (encryptStorage.getItem('address') || encryptStorage.getItem('city_lns') || encryptStorage.getItem('state_lns')
                    || encryptStorage.getItem('county_lns') || encryptStorage.getItem('zipcode_lns'))) {

                  $("#address_pri").val(encryptStorage.getItem('address') ? encryptStorage.getItem('address') : '');
                  $("#city_primary").val(encryptStorage.getItem('city_lns') ? encryptStorage.getItem('city_lns') : '');
                  $("#state_primary").val(encryptStorage.getItem('state_lns') ? encryptStorage.getItem('state_lns') : '');
                  $("#county_primary").val(encryptStorage.getItem('county_lns') ? encryptStorage.getItem('county_lns') : '');
                  $("#zip_code_pri").val(encryptStorage.getItem('zipcode_lns') ? encryptStorage.getItem('zipcode_lns') : '');
                  this.setState({ sameAddr: true });
                }

              })
              .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err);
              })
          } else {
            prevExist = false;
          }

        })
        .catch(err => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        })
    }

    $.validator.setDefaults({
      submitHandler: function () {
        var jsonData = '';
        if (prevExist === true) {
          jsonData = { 'className': 'EmergencyContact', 'key': sessionStorage.getItem('clientId'), 'id': sessionStorage.getItem('responceId'), 'published': true };
        } else {
          jsonData = { 'className': 'EmergencyContact', 'key': sessionStorage.getItem('clientId'), 'published': true };
        }
        jsonData.elements = [];
        var formData = $("#emagencyinfoform").serializeArray();
        $.each(formData, function () {
          jsonData.elements.push({ name: this.name, value: this.value });
        });
        var sameAddr = context.state.sameAddr;
        jsonData.elements.push({ name: 'same_as_client_addr_pri', value: sameAddr });
        var json = JSON.stringify(jsonData);
        axios.post(sessionStorage.getItem('emergency_contact_save_url'), json)
          .then((res) => {
            var responce = res.data;
            if ($('#clicked').val() === 'proceed') {
              sessionStorage.setItem('success_msg', "Additional Contacts saved successfully")
              const route = `${global.traversArray["emergencycontact"]}`;
              navigate(route);
              showSuccessToast("Additional Contacts saved successfully")
            } else {
              context.setState({ unSaved: false });
              if (!sessionStorage.getItem('responceId')) {
                sessionStorage.setItem('responceId', responce.id)
              }
              prevExist = true;
              Alert("success", "Additional Contacts saved successfully.");
            }
          })
          .catch(function (err) {
            console.log(err.message);
          }
          );
      }
    });
  }

  datamapper(arr) {
    let obj = {
      name_primary: '',
      relationship_primary: '',
      phone_primary: '',
      address_pri: '',
      city_primary: '',
      county_primary: '',
      zip_code_pri: '',
      name_secondary: '',
      relationship_secondary: '',
      phone_secondary: '',
      address_sec: '',
      city_secondary: '',
      county_secondary: '',
      zip_code_sec: '',
      leave_msg_pri: '',
      leave_msg_sec: '',
      same_as_client_addr_pri: '',
      same_as_client_addr_sec: '',
      state_primary: '',
      state_secondary: '',
      // Code Added By Ashraf Start
      email_primary: '',
      email_secondary: '',
      // Code Added by Ashraf End
    }
    $.each(arr, function (index, value) {
      //console.log(value.name+"==="+value.value)
      if (value.name === 'name_primary') {
        obj.name_primary = value.value;
      }
      if (value.name === 'leave_msg_pri') {
        obj.leave_msg_pri = value.value;
      }
      if (value.name === 'same_as_client_addr_pri') {
        obj.same_as_client_addr_pri = value.value;
      }
      if (value.name === 'state_primary') {
        // obj.state_primary = value.value ? value.value : encryptStorage.getItem('state_lns') && encryptStorage.getItem('state_lns') !== '' ? encryptStorage.getItem('state_lns') : '';//value.value;
        obj.state_primary = value.value
      }
      if (value.name === 'state_secondary') {
        // obj.state_secondary = value.value ? value.value : encryptStorage.getItem('state_lns') && encryptStorage.getItem('state_lns') !== '' ? encryptStorage.getItem('state_lns') : ''; //value.value;
        obj.state_secondary = value.value
      }
      if (value.name === 'leave_msg_sec') {
        obj.leave_msg_sec = value.value;
      }
      if (value.name === 'same_as_client_addr_sec') {
        obj.same_as_client_addr_sec = value.value;
      }
      if (value.name === 'relationship_primary') {
        obj.relationship_primary = value.value;
      }
      if (value.name === 'phone_primary') {
        obj.phone_primary = value.value;
      }
      if (value.name === 'address_pri') {
        obj.address_pri = value.value;
      }

      if (value.name === 'city_primary') {
        obj.city_primary = value.value;
      }
      if (value.name === 'county_primary') {
        obj.county_primary = value.value;
      }
      if (value.name === 'zip_code_pri') {
        obj.zip_code_pri = value.value;
      }
      if (value.name === 'name_secondary') {
        obj.name_secondary = value.value;
      }
      if (value.name === 'relationship_secondary') {
        obj.relationship_secondary = value.value;
      }
      if (value.name === 'phone_secondary') {
        obj.phone_secondary = value.value;
      }
      if (value.name === 'address_sec') {
        obj.address_sec = value.value;
      }
      if (value.name === 'city_secondary') {
        obj.city_secondary = value.value;
      }
      if (value.name === 'county_secondary') {
        obj.county_secondary = value.value;
      }
      if (value.name === 'zip_code_sec') {
        obj.zip_code_sec = value.value;
      }
      // Code Added By Ashraf Start

      if (value.name === 'email_primary') {
        obj.email_primary = value.value;
      }
      if (value.name === 'email_secondary') {
        obj.email_secondary = value.value;
      }
      // Code Added by Ashraf End


    });

    if (obj.same_as_client_addr_pri) {
      this.setState({ sameAddr: true });
    } else {
      this.setState({ sameAddr: false });
    }
    this.setState({
      emergencyContactFormData: obj
    });

    if (rawValidationData !== "") {
      this.createDropdowns(rawValidationData);
    }
  }

  createDropdowns(validationObj) {
    this.setState({
      leave_msg_pri_options: selectOptionsGenerator(validationObj.leave_msg_pri.options, context.state.emergencyContactFormData.leave_msg_pri),
      leave_msg_sec_options: selectOptionsGenerator(validationObj.leave_msg_sec.options, context.state.emergencyContactFormData.leave_msg_sec),
      same_as_client_addr_sec_options: selectOptionsGenerator(validationObj.same_as_client_addr_sec.options, context.state.emergencyContactFormData.same_as_client_addr_sec),
      state_primary_options: selectOptionsGenerator(validationObj.state_primary.options, context.state.emergencyContactFormData.state_primary),
      state_secondary_options: selectOptionsGenerator(validationObj.state_secondary.options, context.state.emergencyContactFormData.state_secondary),
    });

    console.log(this.state.emergencyContactFormData.leave_msg_sec);

  }

  createDropdowns1(rawOptions) {
    let leave_msg_pri_list = rawOptions["leave_msg_pri"].options.length > 0 && rawOptions["leave_msg_pri"].options.map((item, i) => {
      if (item.key === this.state.emergencyContactFormData.leave_msg_pri) {
        return (
          <option key={i} value={item.key} selected={true} >{item.value}</option>
        )
      }
      else {
        return (
          <option key={i} value={item.key} selected={false} >{item.value}</option>
        )
      }

    }, this);

    let leave_msg_sec_list = rawOptions["leave_msg_sec"].options.length > 0 && rawOptions["leave_msg_sec"].options.map((item, i) => {
      if (item.key === this.state.emergencyContactFormData.leave_msg_sec) {
        return (
          <option key={i} value={item.key} selected={true} >{item.value}</option>
        )
      }
      else {
        return (
          <option key={i} value={item.key} selected={false} >{item.value}</option>
        )
      }

    }, this);

    let same_as_client_addr_sec_list = rawOptions["same_as_client_addr_sec"].options.length > 0 && rawOptions["same_as_client_addr_sec"].options.map((item, i) => {
      if (item.key === this.state.emergencyContactFormData.same_as_client_addr_sec) {
        return (
          <option key={i} value={item.key} selected={true} >{item.value}</option>
        )
      }
      else {
        return (
          <option key={i} value={item.key} selected={false} >{item.value}</option>
        )
      }

    }, this);

    let state_primary_list = rawOptions["state_primary"].options.length > 0 && rawOptions["state_primary"].options.map((item, i) => {
      if (item.key === this.state.emergencyContactFormData.state_primary) {
        if (i === 0) {
          return (
            <option key={i} value="" selected={true} ></option>
          )
        } else {
          return (
            <option key={i} value={item.key} selected={true} >{item.value}</option>
          )
        }

      }
      else {
        if (i === 0) {
          return (
            <option key={i} value="" selected={false} ></option>
          )
        } else {
          return (
            <option key={i} value={item.key} selected={false} >{item.value}</option>
          )
        }
      }

    }, this);

    let state_secondary_list = rawOptions["state_secondary"].options.length > 0 && rawOptions["state_secondary"].options.map((item, i) => {
      if (item.key === this.state.emergencyContactFormData.state_secondary) {
        if (i === 0) {
          return (
            <option key={i} value="" selected={true} ></option>
          )
        } else {
          return (
            <option key={i} value={item.key} selected={true} >{item.value}</option>
          )

        }

      }
      else {
        if (i === 0) {
          return (
            <option key={i} value="" selected={false} ></option>
          )
        } else {
          return (
            <option key={i} value={item.key} selected={false} >{item.value}</option>
          )

        }
      }

    }, this);

    this.setState({
      leave_msg_pri_options: leave_msg_pri_list,
      leave_msg_sec_options: leave_msg_sec_list,
      same_as_client_addr_sec_options: same_as_client_addr_sec_list,
      state_primary_options: state_primary_list,
      state_secondary_options: state_secondary_list
    });

    console.log(this.state.emergencyContactFormData.leave_msg_sec);
  }

  validationmapper(arr) {

    let obj = this.getModal();

    obj.name_primary = arr['name_primary'].mandatory;
    obj.relationship_primary = arr['relationship_primary'].mandatory;
    obj.phone_primary = arr['phone_primary'].mandatory;
    obj.address_pri = arr['address_pri'].mandatory;
    obj.city_primary = arr['city_primary'].mandatory;
    obj.county_primary = arr['county_primary'].mandatory;
    obj.zip_code_pri = arr['zip_code_pri'].mandatory;
    obj.name_secondary = arr['name_secondary'].mandatory;
    obj.relationship_secondary = arr['relationship_secondary'].mandatory;
    obj.phone_secondary = arr['phone_secondary'].mandatory;
    obj.address_sec = arr['address_sec'].mandatory;
    obj.city_secondary = arr['city_secondary'].mandatory;
    obj.county_secondary = arr['county_secondary'].mandatory;
    obj.zip_code_sec = arr['zip_code_sec'].mandatory;
    obj.leave_msg_pri = arr['leave_msg_pri'].mandatory;
    obj.leave_msg_sec = arr['leave_msg_sec'].mandatory;
    obj.same_as_client_addr_pri = arr['same_as_client_addr_pri'].mandatory;
    obj.same_as_client_addr_sec = arr['same_as_client_addr_sec'].mandatory;
    obj.state_primary = arr['state_primary'].mandatory;
    obj.state_secondary = arr['state_secondary'].mandatory;

    // Code Added by ashraf start
    obj.email_primary = arr['email_primary'].mandatory;
    obj.email_secondary = arr['email_secondary'].mandatory;
    // Code Added by ashraf En

    let required = obj;

    obj = this.getModal();

    obj.name_primary = arr['name_primary'].columnLength;
    obj.relationship_primary = arr['relationship_primary'].columnLength;
    obj.phone_primary = arr['phone_primary'].columnLength;
    obj.address_pri = arr['address_pri'].columnLength;
    obj.city_primary = arr['city_primary'].columnLength;
    obj.county_primary = arr['county_primary'].columnLength;
    obj.zip_code_pri = arr['zip_code_pri'].columnLength;
    obj.name_secondary = arr['name_secondary'].columnLength;
    obj.relationship_secondary = arr['relationship_secondary'].columnLength;
    obj.phone_secondary = arr['phone_secondary'].columnLength;
    obj.address_sec = arr['address_sec'].columnLength;
    obj.city_secondary = arr['city_secondary'].columnLength;
    obj.county_secondary = arr['county_secondary'].columnLength;
    obj.zip_code_sec = arr['zip_code_sec'].columnLength;
    obj.leave_msg_pri = arr['leave_msg_pri'].columnLength;
    obj.leave_msg_sec = arr['leave_msg_sec'].columnLength;
    obj.same_as_client_addr_pri = arr['same_as_client_addr_pri'].columnLength;
    obj.same_as_client_addr_sec = arr['same_as_client_addr_sec'].columnLength;
    obj.state_primary = arr['state_primary'].columnLength;
    obj.state_secondary = arr['state_secondary'].columnLength;
    // Code Added by ashraf start
    obj.email_primary = arr['email_primary'].columnLength;
    obj.email_secondary = arr['email_secondary'].columnLength;
    // Code Added by ashraf End
    let maxLength = obj;

    obj = this.getModal();

    obj.name_primary = arr['name_primary'].title;
    obj.relationship_primary = arr['relationship_primary'].title;
    obj.phone_primary = arr['phone_primary'].title;
    obj.address_pri = arr['address_pri'].title;
    obj.city_primary = arr['city_primary'].title;
    obj.county_primary = arr['county_primary'].title;
    obj.zip_code_pri = arr['zip_code_pri'].title;
    obj.name_secondary = arr['name_secondary'].title;
    obj.relationship_secondary = arr['relationship_secondary'].title;
    obj.phone_secondary = arr['phone_secondary'].title;
    obj.address_sec = arr['address_sec'].title;
    obj.city_secondary = arr['city_secondary'].title;
    obj.county_secondary = arr['county_secondary'].title;
    obj.zip_code_sec = arr['zip_code_sec'].title;
    obj.leave_msg_pri = arr['leave_msg_pri'].title;
    obj.leave_msg_sec = arr['leave_msg_sec'].title;
    obj.same_as_client_addr_pri = arr['same_as_client_addr_pri'].title;
    obj.same_as_client_addr_sec = arr['same_as_client_addr_sec'].title;
    obj.state_primary = arr['state_primary'].title;
    obj.state_secondary = arr['state_secondary'].title;
    // Code Added by ashraf start
    obj.email_primary = arr['email_primary'].title;
    obj.email_secondary = arr['email_secondary'].title;
    // Code Added by ashraf End

    let titles = obj;

    obj = this.getModal();

    obj.name_primary = arr['name_primary'].tooltip;
    obj.relationship_primary = arr['relationship_primary'].tooltip;
    obj.phone_primary = arr['phone_primary'].tooltip;
    obj.address_pri = arr['address_pri'].tooltip;
    obj.city_primary = arr['city_primary'].tooltip;
    obj.county_primary = arr['county_primary'].tooltip;
    obj.zip_code_pri = arr['zip_code_pri'].tooltip;
    obj.name_secondary = arr['name_secondary'].tooltip;
    obj.relationship_secondary = arr['relationship_secondary'].tooltip;
    obj.phone_secondary = arr['phone_secondary'].tooltip;
    obj.address_sec = arr['address_sec'].tooltip;
    obj.city_secondary = arr['city_secondary'].tooltip;
    obj.county_secondary = arr['county_secondary'].tooltip;
    obj.zip_code_sec = arr['zip_code_sec'].tooltip;
    obj.leave_msg_pri = arr['leave_msg_pri'].tooltip;
    obj.leave_msg_sec = arr['leave_msg_sec'].tooltip;
    obj.same_as_client_addr_pri = arr['same_as_client_addr_pri'].tooltip;
    obj.same_as_client_addr_sec = arr['same_as_client_addr_sec'].tooltip;
    obj.state_primary = arr['state_primary'].tooltip;
    obj.state_secondary = arr['state_secondary'].tooltip;
    // Code Added by ashraf start
    obj.email_primary = arr['email_primary'].tooltip;
    obj.email_secondary = arr['email_secondary'].tooltip;
    // Code Added by ashraf End

    let tooltips = obj;

    this.setState({
      required: required,
      labelText: titles,
      tooltips: tooltips,
      maxLength: maxLength
    });

  }

  // handleId = (e) => {
  //   $('#clicked').val(e.target.value);
  //   $('#clicked').val(e.currentTarget.value);
  // }

  handleId = (e) => {
    const { phone_primary, phone_secondary } = this.state.emergencyContactFormData;
    let formattedPrimaryPhone = phone_primary !== null ? phone_primary : '';
    let formattedSecondaryPhone = phone_secondary !== null ? phone_secondary : '';

    // Remove all non-numeric characters from the phone number
    const numericPrimaryPhone = phone_primary.replace(/\D/g, '');
    const numericSecondaryPhone = phone_secondary.replace(/\D/g, '');

    // Format the phone number as (999) 999-9999
    if (numericPrimaryPhone.length === 10) {
      formattedPrimaryPhone = `(${numericPrimaryPhone.substring(0, 3)}) ${numericPrimaryPhone.substring(3, 6)}-${numericPrimaryPhone.substring(6)}`;
    }

    if (numericSecondaryPhone.length === 10) {
      formattedSecondaryPhone = `(${numericSecondaryPhone.substring(0, 3)}) ${numericSecondaryPhone.substring(3, 6)}-${numericSecondaryPhone.substring(6)}`;
    }
    // Check if the formatted phone number is less than 14 characters (formatted length)
    if ((formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) || (formattedSecondaryPhone.length < 14 && formattedSecondaryPhone.length > 0)) {
      e.preventDefault();
      if (formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) {
        e.preventDefault();
        this.setState({ errorMessage: "Please enter at least 10 digits." });
      }
      if (formattedSecondaryPhone.length < 14 && formattedSecondaryPhone.length > 0) {
        e.preventDefault();
        this.setState({ errorMessageSecondary: "Please enter at least 10 digits." });
      }
    } else {
      $('#clicked').val(e.target.value);
      $('#clicked').val(e.currentTarget.value);
    }
  };

  // saveChanges() {
  //   //isko b dekhna hai
  //   $('#clicked').val('save');
  //   $('#emagencyinfoform').submit();
  // }

  saveChanges() {
    //isko b dekhna hai
    const { phone_primary, phone_secondary } = this.state.emergencyContactFormData;
    let formattedPrimaryPhone = phone_primary !== null && phone_primary !== undefined ? phone_primary : '';
    let formattedSecondaryPhone = phone_secondary !== null && phone_secondary !== undefined ? phone_secondary : '';
    // Remove all non-numeric characters from the phone number
    const numericPrimaryPhone = phone_primary==null?"":phone_primary.replace(/\D/g, '');
    const numericSecondaryPhone = phone_secondary==null?"":phone_secondary.replace(/\D/g, '');
    // Format the phone number as (999) 999-9999
    if (numericPrimaryPhone.length === 10) {
      formattedPrimaryPhone = `(${numericPrimaryPhone.substring(0, 3)}) ${numericPrimaryPhone.substring(3, 6)}-${numericPrimaryPhone.substring(6)}`;
    }
    if (numericSecondaryPhone.length === 10) {
      formattedSecondaryPhone = `(${numericSecondaryPhone.substring(0, 3)}) ${numericSecondaryPhone.substring(3, 6)}-${numericSecondaryPhone.substring(6)}`;
    }
    // Check if the formatted phone number is less than 14 characters (formatted length)
    if ((formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) || (formattedSecondaryPhone.length < 14 && formattedSecondaryPhone.length > 0)) {
      if (formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) {
        this.setState({ errorMessage: "Please enter at least 10 digits." });
      }
      if (formattedSecondaryPhone.length < 14 && formattedSecondaryPhone.length > 0) {
        this.setState({ errorMessageSecondary: "Please enter at least 10 digits." });
      }
      return false
    }
    else {
      $('#clicked').val('save');
      $('#emagencyinfoform').submit();
    }
  }

  handleUpdateState = (event) => {
    var emergencyContactFormData = this.state.emergencyContactFormData;
    event.persist();
    emergencyContactFormData["address_pri"] = "";
    emergencyContactFormData["city_primary"] = "";
    emergencyContactFormData["state_primary"] = "";
    emergencyContactFormData["county_primary"] = "";
    emergencyContactFormData["zip_code_pri"] = "";
    if (event.target.checked) {
      emergencyContactFormData['same_as_client_addr_pri'] = true;
      if (encryptStorage.getItem('address') || encryptStorage.getItem('city_lns') || encryptStorage.getItem('state_lns') || encryptStorage.getItem('county_lns') || encryptStorage.getItem('zipcode_lns')) {
        let tempEmergencyData = emergencyContactFormData;
        tempEmergencyData['address_pri'] = encryptStorage.getItem('address');
        this.setState({ emergencyContactFormData: tempEmergencyData });
        $("#address_pri").val(encryptStorage.getItem('address') ? encryptStorage.getItem('address') : '');
        $("#city_primary").val(encryptStorage.getItem('city_lns') ? encryptStorage.getItem('city_lns') : '');
        $("#state_primary").val(encryptStorage.getItem('state_lns') ? encryptStorage.getItem('state_lns') : '');
        $("#county_primary").val(encryptStorage.getItem('county_lns') ? encryptStorage.getItem('county_lns') : '');
        $("#zip_code_pri").val(encryptStorage.getItem('zipcode_lns') ? encryptStorage.getItem('zipcode_lns') : '');
        this.setState({ sameAddr: true, emergencyContactFormData, unSaved: true });
      } else if (sessionStorage.getItem('clientId')) {

        axios.get(global.restApiURL + 'webservice/rest/object-list?apikey=' + sessionStorage.getItem('__TOKEN__') + '&limit=1&objectClass=ClientInformation&q={"client_id":"' + sessionStorage.getItem('clientId') + '"}')
          .then(res => {

            const responce = res.data.data; // get the data array instead of object
            if (responce[0] !== undefined || responce.id !== '') {
              axios.get(global.restApiURL + 'webservice/rest/object/id/' + responce.id + '?apikey=' + sessionStorage.getItem('__TOKEN__'))
                .then(res => {

                  const responce = res.data.data.elements;

                  console.log("Obj list : ", responce);
                  $.each(responce, function (index, value) {
                    //console.log(value.name+"==="+value.value)
                    if (value.name === 'address_lns') {
                      $("#address_pri").val(value.value);
                      encryptStorage.setItem('address', value.value);
                    } else if (value.name === 'city_lns') {
                      $("#city_primary").val(value.value);
                      encryptStorage.setItem('city_lns', value.value);
                    } else if (value.name === 'state_lns') {
                      $("#state_primary").val(value.value);
                      encryptStorage.setItem('state_lns', value.value);
                    } else if (value.name === 'county_lns') {
                      $("#county_primary").val(value.value);
                      encryptStorage.setItem('county_lns', value.value);
                    } else if (value.name === 'zipcode_lns') {
                      $("#zip_code_pri").val(value.value);
                      encryptStorage.setItem('zipcode_lns', value.value);
                    }
                  });
                  this.setState({ sameAddr: true, emergencyContactFormData, unSaved: true });
                })
                .catch(err => { // log request error and prevent access to undefined state
                  this.setState({ loading: false, error: true });
                  console.error(err);
                })
            }
          })
          .catch(err => { // log request error and prevent access to undefined state
            this.setState({ loading: false, error: true });
            console.error(err);
          }
          )
      }
    } else {
      emergencyContactFormData['same_as_client_addr_pri'] = false;
      emergencyContactFormData['address_pri'] = '';
      emergencyContactFormData['state_primary'] = '';
      $("#address_pri").val('');
      $("#city_primary").val('');
      $("#state_primary").val('');
      $("#county_primary").val('');
      $("#zip_code_pri").val('');
      this.setState({ sameAddr: false, emergencyContactFormData, unSaved: true });
    }
  }

  shouldNavigateFunction = (e) => {
    // debugger
    var navigation = false;
    let { phone_primary, phone_secondary } = this.state.emergencyContactFormData;
    let formattedPrimaryPhone = phone_primary !== null && phone_primary !== undefined ? phone_primary : '';
    let formattedSecondaryPhone = phone_secondary !== null && phone_secondary !== undefined ? phone_secondary : '';
    // Remove all non-numeric characters from the phone number
    const numericPrimaryPhone = phone_primary==null?"":phone_primary.replace(/\D/g, '');
    const numericSecondaryPhone = phone_secondary==null?"":phone_secondary.replace(/\D/g, '');
    // Format the phone number as (999) 999-9999
    if (numericPrimaryPhone.length === 10) {
      formattedPrimaryPhone = `(${numericPrimaryPhone.substring(0, 3)}) ${numericPrimaryPhone.substring(3, 6)}-${numericPrimaryPhone.substring(6)}`;
    }
    if (numericSecondaryPhone.length === 10) {
      formattedSecondaryPhone = `(${numericSecondaryPhone.substring(0, 3)}) ${numericSecondaryPhone.substring(3, 6)}-${numericSecondaryPhone.substring(6)}`;
    }
    // Check if the formatted phone number is less than 14 characters (formatted length)
    if ((formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) || (formattedSecondaryPhone.length < 14 && formattedSecondaryPhone.length > 0)) {
      // e.preventDefaCult();
      if (formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) {
        this.setState({ errorMessage: "Please enter at least 10 digits." });
      }
      if (formattedSecondaryPhone.length < 14 && formattedSecondaryPhone.length > 0) {
        this.setState({ errorMessageSecondary: "Please enter at least 10 digits." });
      }
      // navigation = false;
      return navigation;
    }else {
      navigation = true;
    }
    return navigation;
  }


  handleSecAddrChange = (event) => {
    event.persist();
    let emergencyContactFormData = this.state.emergencyContactFormData;
    emergencyContactFormData['same_as_client_addr_sec'] = event.target.value
    this.setState({
      emergencyContactFormData, unSaved: true
    });
  }

  handleChange_gpi = address => {
    var stateCopy = Object.assign({}, this.state);
    stateCopy.emergencyContactFormData['address_pri'] = address;
    stateCopy.emergencyContactFormData["city_primary"] = "";
    stateCopy.emergencyContactFormData["state_primary"] = "";
    stateCopy.emergencyContactFormData["county_primary"] = "";
    stateCopy.emergencyContactFormData["zip_code_pri"] = "";
    stateCopy['unSaved'] = true;
    this.setState(stateCopy);
  };

  handleSelect = address => {
    this.handleChange_gpi(address)
    geocodeByAddress(address)
      .then((results) => {
        var stateCopy = Object.assign({}, this.state);
        for (var i = 0; i < results[0].address_components.length; i++) {
          // stateCopy.emergencyContactFormData['address_pri'] = results[0].formatted_address;
          // console.log(results[0].address_components[i].types.filter(data=>data==="sublocality"),"results[0].address_components[i].types.filter");

          if (results[0].address_components[i].types[0] === "locality") {
            stateCopy.emergencyContactFormData['city_primary'] = results[0].address_components[i].long_name;
            stateCopy.emergencyContactFormData['address_pri'] = stateCopy.emergencyContactFormData['address_pri'].replace(results[0].address_components[i].long_name + ', ', '');
          }
          if (results[0].address_components[i].types[0] === "administrative_area_level_1") {
            stateCopy.emergencyContactFormData['state_primary'] = results[0].address_components[i].short_name;
            stateCopy.emergencyContactFormData['address_pri'] = stateCopy.emergencyContactFormData['address_pri'].replace(results[0].address_components[i].short_name + ', ', '');
          }
          if (results[0].address_components[i].types[0] === "administrative_area_level_2") {
            stateCopy.emergencyContactFormData['county_primary'] = results[0].address_components[i].long_name;
            stateCopy.emergencyContactFormData['address_pri'] = stateCopy.emergencyContactFormData['address_pri'].replace(results[0].address_components[i].long_name + ', ', '');
          }
          if (results[0].address_components[i].types[0] === "postal_code") {
            stateCopy.emergencyContactFormData['zip_code_pri'] = results[0].address_components[i].long_name;
            stateCopy.emergencyContactFormData['address_pri'] = stateCopy.emergencyContactFormData['address_pri'].replace(results[0].address_components[i].long_name + ', ', '');
          }
        }
        stateCopy['unSaved'] = true;
        this.setState(stateCopy);
      })
      .catch(error => console.error('Error', error)
      );
  };

  // handleChange_gpi_sec_address = address => {
  //   var stateCopy = Object.assign({}, this.state);
  //   stateCopy.emergencyContactFormData['address_sec'] = address
  //   stateCopy['unSaved'] = true;
  //   this.setState(stateCopy);
  // };

  handleChange_gpi_sec_address = address => {
    var stateCopy = Object.assign({}, this.state);
    stateCopy.emergencyContactFormData['address_sec'] = address;
    stateCopy.emergencyContactFormData['city_secondary'] = "";
    stateCopy.emergencyContactFormData['state_secondary'] = "";
    stateCopy.emergencyContactFormData['county_secondary'] = "";
    stateCopy.emergencyContactFormData['zip_code_sec'] = "";
    stateCopy['unSaved'] = true;
    this.setState(stateCopy);
  };

  handleSelect_sec_address = address => {
    this.handleChange_gpi_sec_address(address)
    geocodeByAddress(address)
      // .then(results => getLatLng(results[0]))
      // .then(latLng => console.log('Success', latLng))
      .then((results) => {
        var stateCopy = Object.assign({}, this.state);
        for (var i = 0; i < results[0].address_components.length; i++) {
          // stateCopy.emergencyContactFormData['address_pri'] = results[0].formatted_address;
          // console.log(results[0].address_components[i].types.filter(data=>data==="sublocality"),"results[0].address_components[i].types.filter");

          if (results[0].address_components[i].types[0] === "locality") {
            stateCopy.emergencyContactFormData['city_secondary'] = results[0].address_components[i].long_name;
            stateCopy.emergencyContactFormData['address_sec'] = stateCopy.emergencyContactFormData['address_sec'].replace(results[0].address_components[i].long_name + ', ', '');
          }
          if (results[0].address_components[i].types[0] === "administrative_area_level_1") {
            stateCopy.emergencyContactFormData['state_secondary'] = results[0].address_components[i].short_name;
            stateCopy.emergencyContactFormData['address_sec'] = stateCopy.emergencyContactFormData['address_sec'].replace(results[0].address_components[i].short_name + ', ', '');
          }
          if (results[0].address_components[i].types[0] === "administrative_area_level_2") {
            stateCopy.emergencyContactFormData['county_secondary'] = results[0].address_components[i].long_name;
            stateCopy.emergencyContactFormData['address_sec'] = stateCopy.emergencyContactFormData['address_sec'].replace(results[0].address_components[i].long_name + ', ', '');
          }
          if (results[0].address_components[i].types[0] === "postal_code") {
            stateCopy.emergencyContactFormData['zip_code_sec'] = results[0].address_components[i].long_name;
            stateCopy.emergencyContactFormData['address_sec'] = stateCopy.emergencyContactFormData['address_sec'].replace(results[0].address_components[i].long_name + ', ', '');
          }
        }
        stateCopy['unSaved'] = true;
        this.setState(stateCopy);
      })
      .catch(error => console.error('Error', error)
      );
  };

  render() {
    sessionStorage.setItem('formname', 'Additional Contacts')
    $(document).ready(function () {
      var NumRegex = /^[0-9]+$|^$|^\s$/;
      var cityRegEx = /^[a-zA-Z ]*$/;

      $.validator.addMethod('NumRegex', function (value, element, parameter) {
        return value.match(NumRegex);
      }, '');
      $.validator.addMethod('cityRegEx', function (value, element, parameter) {
        return value.match(cityRegEx);
      }, 'Please enter a valid city name');

      $('#emagencyinfoform').validate({
        rules: {
          zip_code_pri: {
            NumRegex: true
          },
          zip_code_sec: {
            NumRegex: true
          },
          city_primary: {
            cityRegEx: true
          },
          city_secondary: {
            cityRegEx: true
          }
        },
        errorElement: 'span',
        errorPlacement: function (error, element) {
          error.addClass('invalid-feedback');
          element.closest('.form-group').append(error);
        },
        highlight: function (element, errorClass, validClass) {
          $(element).addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).removeClass('is-invalid');
        },
        onkeyup: function (element) {
          $('#zip_code_pri').valid();
          $('#zip_code_sec').valid();
        }
      });
    });

    return (
      <div className="hold-transition sidebar-mini text-sm">
        { /*     Start Kuldeep Singh     This was using in dev server when Routing was reloading but now instead of <ConfirmBeforeLeave /> we are using <sidenav /> we are using sidenav By Kuldeep Singh            */ }
        {/* <ConfirmBeforeLeave onRef={ref => (this.childForm = ref)} unSaved={context.state.unSaved} onSaveRequest={() => context.saveChanges()} /> */}
        { /*          This was using in dev server when Routing was reloading but now instead of <ConfirmBeforeLeave /> we are using <sidenav /> By Kuldeep Singh     End Kuldeep Singh       */ }
        <ReactTooltip place="right" type="dark" effect="solid" />
        <div className="wrapper">
          <AppTopNav />
          <div className="content-wrapper">
            <Patientinfoheader clientId={sessionStorage.getItem('clientId')} dob={encryptStorage.getItem('dob')} email={encryptStorage.getItem('email')} phone={encryptStorage.getItem('phone')} peer_support_specialist={encryptStorage.getItem('peer_support_specialist')} next_followup_date={encryptStorage.getItem('month_1_followup_date')} intake_date={encryptStorage.getItem('intake_date')} initiated_by={encryptStorage.getItem('last_updated_by')} formname={sessionStorage.getItem('formname')} client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')} />
            <section className="content">
              <div className="row">
                <Sidenav onSaveRequest={() => context.saveChanges()} unSaved={context.state.unSaved} shouldNavigate={this.shouldNavigateFunction} />
                <fieldset className="container-fluid col-md-9 col-lg-10" disabled={!can_edit('EmergencyContact')}>
                  <form method="post" id="emagencyinfoform">
                    <div className="card card-default">
                      <div className="card-header">
                        <p className="lead float-left"><b className="text-green">Emergency Contacts</b> <small>People around the client who can be contacted if required </small></p>
                        <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={this.handleId}>
                          <img src="/dist/img/save-proceed_icon.png" alt="" /> Save &amp; Proceed </button>
                        <button type="submit" value='save' className="btn btn-success float-right btnmargin" onClick={this.handleId}> <i className="fas fa-save"></i> Save</button>
                        <input type="hidden" id="clicked" value="" readOnly />
                      </div>
                      <div className="card-body">
                        <h6>Emergency </h6>
                        <hr />
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <div className="row">
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <InputTextField labelText={this.state.labelText.name_primary} placeholder={this.state.labelText.name_primary} type="text" maxLength={this.state.maxLength.name_primary} className="form-control" id="name_primary" name="name_primary" defaultValue={this.state.emergencyContactFormData.name_primary} required={this.state.required.name_primary} tooltipText={this.state.tooltips.name_primary} tooltipVisibility={this.state.tooltipVisibility.name_primary} onChange={this.handleDataChange} />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <InputTextField labelText={this.state.labelText.relationship_primary} placeholder={this.state.labelText.relationship_primary} type="text" maxLength={this.state.maxLength.relationship_primary} className="form-control" id="relationship_primary" name="relationship_primary" defaultValue={this.state.emergencyContactFormData.relationship_primary} required={this.state.required.relationship_primary} tooltipText={this.state.tooltips.relationship_primary} tooltipVisibility={this.state.tooltipVisibility.relationship_primary} onChange={this.handleDataChange} />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <div className="card card-default">
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                          <LabelField title={this.state.labelText.phone_primary} mandatory={this.state.required.phone_primary} name="phone_primary" tooltip={this.state.tooltips.phone_primary} id="phone_primary" />
                                          <InputMask id="phone_primary" name="phone_primary" placeholder={this.state.labelText.phone_primary} className="form-control" mask="(999) 999-9999" maskChar={null} value={this.state.emergencyContactFormData.phone_primary} beforeMaskedValueChange={this.beforeMaskedValueChange1} onChange={this.handlePhoneChange} />
                                          <span style={{ color: 'red', fontSize: "75%" }}>{this.state.emergencyContactFormData.phone_primary === "" ? "" : this.state.errorMessage}</span>
                                        </div>
                                      </div>
                                      {/*Code added by ashraf start  */}
                                      <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                          <LabelField title={this.state.labelText.email_primary} name="email_primary" tooltip={this.state.tooltips.email_primary} id="email_primary" />
                                          <InputMask id="email_primary" type="email" name="email_primary" placeholder={this.state.labelText.email_primary} className="form-control" value={this.state.emergencyContactFormData.email_primary} onChange={this.handleDataChange} />
                                        </div>
                                      </div>
                                      {/*Code added by ashraf end  */}
                                      <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                          <LabelField title={this.state.labelText.leave_msg_pri} mandatory={this.state.required.leave_msg_pri} name="leave_msg_pri" tooltip={this.state.tooltips.leave_msg_pri} id="leave_msg_pri" />

                                          <select className="custom-select" id="leave_msg_pri" name="leave_msg_pri" onChange={this.handleDataChange} value={this.state.emergencyContactFormData.leave_msg_pri} required={this.state.required.leave_msg_pri} disabled={this.state.emergencyContactFormData.phone_primary?.length !== 14} >

                                            {this.state.leave_msg_pri_options}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12" >
                            <div className="card">
                              <div className="card-header">
                                <div className="row">
                                  <div className="col-lg-12 col-md-12">
                                    <span className="card-title"><input className="" type="checkbox" checked={this.state.emergencyContactFormData.same_as_client_addr_pri === true} name="same_as_client_addr_pri" onClick={this.handleUpdateState} required={this.state.required.same_as_client_addr_pri} />
                                      <LabelField title={this.state.labelText.same_as_client_addr_pri} mandatory={this.state.required.same_as_client_addr_pri} name="same_as_client_addr_pri" tooltip={this.state.tooltips.same_as_client_addr_pri} id="same_as_client_addr_pri" /> </span>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="card-body">
                                <div className="row">
                                  <LocationSearchInput
                                    value={this.state.emergencyContactFormData.address_pri}
                                    onChange={this.handleChange_gpi}
                                    onSelect={this.handleSelect}
                                    placeholder={this.state.labelText.address_pri}
                                    id="address_pri"
                                    name="address_pri"
                                    defaultValue={this.state.emergencyContactFormData.address_pri}
                                    required={this.state.required.address_pri}
                                    tooltipText={this.state.tooltips.address_pri}
                                    tooltipVisibility={this.state.tooltipVisibility.address_pri}
                                  />
                                  <div className="col-md-8 form-group">
                                    <InputTextField placeholder={this.state.labelText.city_primary} type="text" maxLength={this.state.maxLength.city_primary} className="form-control" id="city_primary" name="city_primary" defaultValue={this.state.emergencyContactFormData.city_primary} required={this.state.required.city_primary} tooltipText={this.state.tooltips.city_primary} tooltipVisibility={this.state.tooltipVisibility.city_primary} onChange={this.handleDataChange} readOnly={this.state.sameAddr} />
                                  </div>
                                  <div className="col-md-4 form-group">
                                    {this.state.emergencyContactFormData.same_as_client_addr_pri ? (
                                      // jab checked kiya to yehe dikhega
                                      <select className="custom-select" id="state_primary" name="state_primary" onChange={this.handleDataChange} required={this.state.required.state_primary} defaultValue={this.state.emergencyContactFormData.state_primary} disabled={this.state.sameAddr ? true : false}>
                                        {this.state.state_primary_options}
                                      </select>
                                    ) : (
                                      // jab checked nahi karonga mai tab yehe field dikhegi
                                      <select className="custom-select" id="state_primary" name="state_primary" onChange={this.handleDataChange} required={this.state.required.state_primary} value={this.state.emergencyContactFormData.state_primary} disabled={this.state.sameAddr ? true : false}>
                                        {this.state.state_primary_options}
                                      </select>
                                    )}
                                    {this.state.sameAddr ? (<input type="hidden" readOnly={this.state.sameAddr} name="state_primary" defaultValue={encryptStorage.getItem('state_lns')} />) : ''}
                                  </div>
                                  <div className="col-md-8 form-group">
                                    <InputTextField placeholder={this.state.labelText.county_primary} type="text" maxLength={this.state.maxLength.county_primary} className="form-control" id="county_primary" name="county_primary" defaultValue={this.state.emergencyContactFormData.county_primary} required={this.state.required.county_primary} tooltipText={this.state.tooltips.county_primary} tooltipVisibility={this.state.tooltipVisibility.county_primary} onChange={this.handleDataChange} readOnly={this.state.sameAddr} />
                                  </div>
                                  <div className="col-md-4 form-group">
                                    <input type="text" className="form-control" id="zip_code_pri" name="zip_code_pri" placeholder={this.state.labelText.zip_code_pri} defaultValue={this.state.emergencyContactFormData.zip_code_pri} required={this.state.required.zip_code_pri} readOnly={this.state.sameAddr} maxLength={this.state.maxLength.zip_code_pri} minLength={this.state.maxLength.zip_code_pri} onChange={this.handleDataChange} />
                                  </div>
                                </div>
                              </div> */}
                              <div className="card-body">
                                <div className="row">
                                  <LocationSearchInput
                                    value={this.state.emergencyContactFormData.address_pri}
                                    onChange={this.handleChange_gpi}
                                    onSelect={this.handleSelect}
                                    placeholder={this.state.labelText.address_pri}
                                    id="address_pri"
                                    name="address_pri"
                                    readOnly={this.state.sameAddr}
                                    defaultValue={this.state.emergencyContactFormData.address_pri}
                                    required={this.state.required.address_pri}
                                    tooltipText={this.state.tooltips.address_pri}
                                    tooltipVisibility={this.state.tooltipVisibility.address_pri}
                                  />
                                  <div className="col-md-8 form-group">
                                    {this.state.emergencyContactFormData.same_as_client_addr_pri ?
                                      <input placeholder={this.state.labelText.city_primary} type="text" maxLength={this.state.maxLength.city_primary} className="form-control" id="city_primary" name="city_primary" defaultValue={this.state.emergencyContactFormData.city_primary} required={this.state.required.city_primary} tooltipText={this.state.tooltips.city_primary} tooltipVisibility={this.state.tooltipVisibility.city_primary} onChange={this.handleDataChange} readOnly={this.state.sameAddr} />
                                      : <input placeholder={this.state.labelText.city_primary} type="text" maxLength={this.state.maxLength.city_primary} className="form-control" id="city_primary" name="city_primary" value={this.state.emergencyContactFormData.city_primary} required={this.state.required.city_primary} tooltipText={this.state.tooltips.city_primary} tooltipVisibility={this.state.tooltipVisibility.city_primary} onChange={this.handleDataChange} readOnly={this.state.sameAddr} />
                                    }
                                    {/* <InputTextField placeholder={this.state.labelText.city_primary} type="text" maxLength={this.state.maxLength.city_primary} className="form-control" id="city_primary" name="city_primary" defaultValue={this.state.emergencyContactFormData.city_primary} required={this.state.required.city_primary} tooltipText={this.state.tooltips.city_primary} tooltipVisibility={this.state.tooltipVisibility.city_primary} onChange={this.handleDataChange} readOnly={this.state.sameAddr} /> */}
                                  </div>
                                  <div className="col-md-4 form-group">
                                    {this.state.emergencyContactFormData.same_as_client_addr_pri ? (
                                      // jab checked kiya to yehe dikhega
                                      <select className="custom-select" id="state_primary" name="state_primary" onChange={this.handleDataChange} required={this.state.required.state_primary} value={encryptStorage.getItem('state_lns')} disabled={this.state.sameAddr ? true : false}>
                                        {this.state.state_primary_options}
                                      </select>
                                    ) : (
                                      // jab checked nahi karonga mai tab yehe field dikhegi
                                      <select className="custom-select" id="state_primary" name="state_primary" onChange={this.handleDataChange} required={this.state.required.state_primary} value={this.state.emergencyContactFormData.state_primary} disabled={this.state.sameAddr ? true : false}>
                                        {this.state.state_primary_options}
                                      </select>
                                    )}
                                    {this.state.sameAddr ? (<input type="hidden" readOnly={this.state.sameAddr} name="state_primary" defaultValue={encryptStorage.getItem('state_lns')} />) : ''}
                                  </div>
                                  <div className="col-md-8 form-group">
                                    {this.state.emergencyContactFormData.same_as_client_addr_pri ?
                                      <input placeholder={this.state.labelText.county_primary} type="text" maxLength={this.state.maxLength.county_primary} className="form-control" id="county_primary" name="county_primary" defaultValue={this.state.emergencyContactFormData.county_primary} required={this.state.required.county_primary} tooltipText={this.state.tooltips.county_primary} tooltipVisibility={this.state.tooltipVisibility.county_primary} onChange={this.handleDataChange} readOnly={this.state.sameAddr} />
                                      : <input placeholder={this.state.labelText.county_primary} type="text" maxLength={this.state.maxLength.county_primary} className="form-control" id="county_primary" name="county_primary" value={this.state.emergencyContactFormData.county_primary} required={this.state.required.county_primary} tooltipText={this.state.tooltips.county_primary} tooltipVisibility={this.state.tooltipVisibility.county_primary} onChange={this.handleDataChange} readOnly={this.state.sameAddr} />

                                    }
                                    {/* <InputTextField placeholder={this.state.labelText.county_primary} type="text" maxLength={this.state.maxLength.county_primary} className="form-control" id="county_primary" name="county_primary" defaultValue={this.state.emergencyContactFormData.county_primary} required={this.state.required.county_primary}  tooltipText={this.state.tooltips.county_primary} tooltipVisibility={this.state.tooltipVisibility.county_primary} onChange={this.handleDataChange} readOnly={this.state.sameAddr} /> */}
                                  </div>
                                  <div className="col-md-4 form-group">
                                    {this.state.emergencyContactFormData.same_as_client_addr_pri ?
                                      <input type="text" className="form-control" id="zip_code_pri" name="zip_code_pri" placeholder={this.state.labelText.zip_code_pri} defaultValue={this.state.emergencyContactFormData.zip_code_pri} required={this.state.required.zip_code_pri} readOnly={this.state.sameAddr} maxLength={this.state.maxLength.zip_code_pri}  onChange={this.handleDataChange} />
                                      : <input type="text" className="form-control" id="zip_code_pri" name="zip_code_pri" placeholder={this.state.labelText.zip_code_pri} value={this.state.emergencyContactFormData.zip_code_pri} required={this.state.required.zip_code_pri} readOnly={this.state.sameAddr} maxLength={this.state.maxLength.zip_code_pri}  onChange={this.handleDataChange} />
                                    }
                                    {/* <input type="text" className="form-control" id="zip_code_pri" name="zip_code_pri" placeholder={this.state.labelText.zip_code_pri} defaultValue={this.state.emergencyContactFormData.zip_code_pri} required={this.state.required.zip_code_pri} readOnly={this.state.sameAddr} maxLength={this.state.maxLength.zip_code_pri} minLength={this.state.maxLength.zip_code_pri} onChange={this.handleDataChange} /> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h6>Secondary </h6>
                        <hr />
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <div className="row">
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <InputTextField labelText={this.state.labelText.name_secondary} placeholder={this.state.labelText.name_secondary} type="text" maxLength={this.state.maxLength.name_secondary} className="form-control" id="name_secondary" name="name_secondary" defaultValue={this.state.emergencyContactFormData.name_secondary} required={this.state.required.name_secondary} tooltipText={this.state.tooltips.name_secondary} tooltipVisibility={this.state.tooltipVisibility.name_secondary} onChange={this.handleDataChange} />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <InputTextField labelText={this.state.labelText.relationship_secondary} placeholder={this.state.labelText.relationship_secondary} type="text" maxLength={this.state.maxLength.relationship_secondary} className="form-control" id="relationship_secondary" name="relationship_secondary" defaultValue={this.state.emergencyContactFormData.relationship_secondary} required={this.state.required.relationship_secondary} tooltipText={this.state.tooltips.relationship_secondary} tooltipVisibility={this.state.tooltipVisibility.relationship_secondary} onChange={this.handleDataChange} />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <div className="card">
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                          <LabelField title={this.state.labelText.phone_secondary} mandatory={this.state.required.phone_secondary} name="phone_secondary" tooltip={this.state.tooltips.phone_secondary} id="phone_secondary" />
                                          <InputMask id="phone_secondary" name="phone_secondary" placeholder={this.state.labelText.phone_secondary} className="form-control" mask="(999) 999-9999" maskChar={null} value={this.state.emergencyContactFormData.phone_secondary} beforeMaskedValueChange={this.beforeMaskedValueChange2} onChange={this.handlePhoneChange} required={this.state.required.phone_secondary} />
                                          <span style={{ color: 'red', fontSize: "75%" }}>{this.state.emergencyContactFormData.phone_secondary === "" ? "" : this.state.errorMessageSecondary}</span>
                                        </div>
                                      </div>
                                      {/*Code added by ashraf start  */}
                                      <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                          <LabelField title={this.state.labelText.email_secondary} name="email_secondary" tooltip={this.state.tooltips.email_secondary} id="email_secondary" />
                                          <InputMask id="email_secondary" type="email" name="email_secondary" placeholder={this.state.labelText.email_secondary} className="form-control" value={this.state.emergencyContactFormData.email_secondary} onChange={this.handleDataChange} />
                                        </div>
                                      </div>
                                      {/*Code added by ashraf end  */}
                                      <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                          <LabelField title={this.state.labelText.leave_msg_sec} mandatory={this.state.required.leave_msg_sec} name="leave_msg_sec" tooltip={this.state.tooltips.leave_msg_sec} id="leave_msg_sec" />
                                          <select className="custom-select" id="leave_msg_sec" value={this.state.emergencyContactFormData.leave_msg_sec} name="leave_msg_sec" required={this.state.required.leave_msg_sec} disabled={this.state.emergencyContactFormData.phone_secondary?.length !== 14} onChange={this.handleDataChange}>
                                            {this.state.leave_msg_sec_options}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12" >
                            <div className="card">
                              <div className="card-header">
                                <div className="row">
                                  <div className="col-lg-8 col-md-8">
                                    <LabelField title={this.state.labelText.same_as_client_addr_sec} mandatory={this.state.required.same_as_client_addr_sec} name="same_as_client_addr_sec" tooltip={this.state.tooltips.same_as_client_addr_sec} id="same_as_client_addr_sec" />
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                    <select className="custom-select float-right" id="same_as_client_addr_sec" name="same_as_client_addr_sec" value={this.state.emergencyContactFormData.same_as_client_addr_sec} onChange={this.handleSecAddrChange} required={this.state.required.same_as_client_addr_sec}>
                                      {this.state.same_as_client_addr_sec_options}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <LocationSearchInput
                                    value={this.state.emergencyContactFormData.address_sec}
                                    onChange={this.handleChange_gpi_sec_address}
                                    onSelect={this.handleSelect_sec_address}
                                    placeholder={this.state.labelText.address_sec}
                                    id="address_sec"
                                    name="address_sec"
                                    defaultValue={this.state.emergencyContactFormData.address_sec}
                                    required={this.state.required.address_sec}
                                    tooltipText={this.state.tooltips.address_sec}
                                    tooltipVisibility={this.state.tooltipVisibility.address_sec}
                                    readOnly={this.state.emergencyContactFormData.same_as_client_addr_sec !== 'Yes'}
                                  />
                                  {/* <div className="col-md-12 form-group">
                                    <InputTextField placeholder={this.state.labelText.address_sec} type="text" maxLength={this.state.maxLength.address_sec} className="form-control" id="address_sec" name="address_sec" defaultValue={this.state.emergencyContactFormData.address_sec} required={this.state.required.address_sec} tooltipText={this.state.tooltips.address_sec} tooltipVisibility={this.state.tooltipVisibility.address_sec} onChange={this.handleDataChange} readOnly={this.state.emergencyContactFormData.same_as_client_addr_sec !== 'Yes'} />
                                  </div> */}
                                  <div className="col-md-8 form-group">
                                    <InputTextField placeholder={this.state.labelText.city_secondary} type="text" maxLength={this.state.maxLength.city_secondary} className="form-control" id="city_secondary" name="city_secondary" defaultValue={this.state.emergencyContactFormData.city_secondary} required={this.state.required.city_secondary} tooltipText={this.state.tooltips.city_secondary} tooltipVisibility={this.state.tooltipVisibility.city_secondary} onChange={this.handleDataChange} readOnly={this.state.emergencyContactFormData.same_as_client_addr_sec !== 'Yes'} />
                                  </div>
                                  <div className="col-md-4 form-group">
                                    <select className="custom-select" id="state_secondary" name="state_secondary" disabled={this.state.emergencyContactFormData.same_as_client_addr_sec !== 'Yes' ? true : false} onChange={this.handleDataChange} required={this.state.required.state_secondary} value={this.state.emergencyContactFormData.state_secondary}>
                                      {this.state.state_secondary_options}
                                    </select>
                                  </div>
                                  <div className="col-md-8 form-group">
                                    <InputTextField placeholder={this.state.labelText.county_secondary} type="text" maxLength={this.state.maxLength.county_secondary} className="form-control" id="county_secondary" name="county_secondary" defaultValue={this.state.emergencyContactFormData.county_secondary} required={this.state.required.county_secondary} tooltipText={this.state.tooltips.county_secondary} tooltipVisibility={this.state.tooltipVisibility.county_secondary} onChange={this.handleDataChange} readOnly={this.state.emergencyContactFormData.same_as_client_addr_sec !== 'Yes'} />
                                  </div>
                                  <div className="col-md-4 form-group">
                                    {/*<InputTextField placeholder={this.state.labelText.zip_code_sec} type="text"  maxLength={this.state.maxLength.zip_code_sec} className="form-control" id="zip_code_sec" name="zip_code_sec" defaultValue={this.state.emergencyContactFormData.zip_code_sec} required={this.state.required.zip_code_sec} tooltipText={this.state.tooltips.zip_code_sec} tooltipVisibility={this.state.tooltipVisibility.zip_code_sec} onChange={this.handleDataChange} readOnly={this.state.emergencyContactFormData.same_as_client_addr_sec !== 'Yes'}/>*/}
                                    <input type="text" className="form-control" id="zip_code_sec" name="zip_code_sec" placeholder={this.state.labelText.zip_code_sec} defaultValue={this.state.emergencyContactFormData.zip_code_sec} required={this.state.required.zip_code_sec} readOnly={this.state.emergencyContactFormData.same_as_client_addr_sec !== 'Yes'} maxLength={this.state.maxLength.zip_code_sec} minLength={this.state.maxLength.zip_code_sec} onChange={this.handleDataChange} />
                                    <input type="hidden" id="client_id" name="client_id" defaultValue={sessionStorage.getItem('clientId')} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer float-right" >
                        <div className="float-right">
                          <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={this.handleId}>
                            <img src="dist/img/save-proceed_icon.png" alt="" /> Save &amp; Proceed </button>
                          <button type="submit" value='save' id="saveButton" className="btn btn-success float-right btnmargin" onClick={this.handleId}> <i className="fas fa-save"></i> Save</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </fieldset>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default HOC(Emergencycontact);
