import React, { Component } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom'
import '../global.js';
import { customURLGen, can_add } from '../ReusableComponents/urlGenerator'
import ReactTooltip from 'react-tooltip';
import { date_validate } from '../ReusableComponents/globalFunctions';
import moment from 'moment';
import { Nav, NavDropdown } from "react-bootstrap";
import { encryptStorage } from './LocalStorageInterceptor';
import axios from 'axios';
import HOC from '../HOC.js';

const reports = [
  // "ADMH-Intake",
  // "ADMH-Residential Waiting List",
  // "ADMH-Entered Tx",dashboard
  // "ADMH-Follow up",
  // "ADMH-Discharge Unsuccessful",
  // "ADMH-Discharge Successful",
  // "Field Locations - ADMH Report",
  // "State Required Report",
  "Dashboard",
  "Cover Report",
  "Referral Report",
  /*"RRC Monthly Report",*/
  // "ADMH Report"
];

class Topnav extends Component {

  constructor(props) {
    super(props);
    this.state = {
      top10List: [],
      requests: 0,
      debug: false,
    }
  }

  CryptoJSAesDecrypt = (passphrase, encrypted_json_string) => {
    const CryptoJS = require("crypto-js");
    var obj_json = JSON.parse(encrypted_json_string);
    var encrypted = obj_json.ciphertext;
    var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
    var iv = CryptoJS.enc.Hex.parse(obj_json.iv);
    var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999 });
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  componentDidMount() {
    const {navigate } = this.props
    var apiKey = { apikey: sessionStorage.getItem('__TOKEN__') };
    if (sessionStorage.getItem('selected_organization') === null || sessionStorage.getItem('selected_organization') === undefined || sessionStorage.getItem('selected_organization') === '') {
      if (this.props.organisation === null || this.props.organisation === undefined || this.props.organisation === '') {
        if (sessionStorage.getItem('permissions')) {
          let getPermission = JSON.parse(sessionStorage.getItem('permissions')).orgs_list;
          apiKey['organization'] = getPermission[0].org_name;
        }
      } else {
        apiKey['organization'] = this.props.organisation;
      }
    } else {
      apiKey['organization'] = sessionStorage.getItem('selected_organization');
    }

    axios.post(customURLGen("recent10Clients"), JSON.stringify(apiKey))
      .then(async res => {
        var response = res.data;
        if (response.success && res.status == 200) {
          await this.setState({
            top10List: JSON.parse(this.CryptoJSAesDecrypt("tactionData", response.data.clients)),//response.data.clients,
            requests: response.data.requests
          })
        }
        else if ("error_code" in response) {
          console.log("Session Out");
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(response.msg);
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      })
  }

  filtered_clients = async () => {
    await sessionStorage.setItem('client_search_filter', 'requested_status');
    if (sessionStorage.getItem('changes_unsaved')) {
    } else {
      // navigate("/clients")
      // window.location.href = '/clients';
    }
  }

  clientsPage = () => {
    sessionStorage.removeItem('client_search_filter');
    if (sessionStorage.getItem('changes_unsaved')) {
    } else {
      // navigate("/clients")
      // window.location.href = '/clients';
    }
  }

  handleButtonView = (event, rowData) => {
    sessionStorage.setItem('clientId', event.target.id);
    encryptStorage.setItem('client_last_name', rowData.last_name);
    encryptStorage.setItem('ssn', rowData.ssn_no);
    encryptStorage.setItem('gpra_no', rowData.gpra_no);
    encryptStorage.setItem('client_middle_initial', rowData.middle_name);
    encryptStorage.setItem('client_first_name', rowData.first_name);
    encryptStorage.setItem('email', rowData.Primary_email)
    encryptStorage.setItem('phone', rowData.Primary_phone)
    encryptStorage.setItem('intake_date', moment(rowData.Intake_date).format('MM/DD/YYYY'));
    encryptStorage.setItem('dob', moment(rowData.Dob).format('MM/DD/YYYY'))
    encryptStorage.setItem('month_1_followup_date', date_validate(moment(rowData.Intake_date).format('MM/DD/YYYY'), 90));
    encryptStorage.setItem('last_updated_by', rowData.Last_updated_by == undefined ? rowData.PeerSupportSpecialist : rowData.Last_updated_by);
    encryptStorage.setItem('Secondary_phone', rowData.Secondary_phone);
    sessionStorage.setItem('Leave_msg_pri', rowData.Leave_msg_pri);
    sessionStorage.setItem('Leave_msg_sec', rowData.Leave_msg_sec);
    sessionStorage.setItem('Type_lns', rowData.Type_lns);
    encryptStorage.setItem('Address_lns', rowData.Address_lns);
    sessionStorage.setItem('viewMode', 'false');
    encryptStorage.setItem('peer_support_specialist', rowData.PeerSupportSpecialist);
    sessionStorage.setItem('client_status', rowData.Client_status);
    /*sessionStorage.setItem('last_updated_by', rowData.Last_updated_by);
    sessionStorage.setItem('Secondary_phone', rowData.Secondary_phone);
    sessionStorage.setItem('Leave_msg_pri', rowData.Leave_msg_pri);
    sessionStorage.setItem('Leave_msg_sec', rowData.Leave_msg_sec);
    sessionStorage.setItem('Type_lns', rowData.Type_lns);
    sessionStorage.setItem('Address_lns', rowData.Address_lns);
*/
    sessionStorage.setItem('viewMode', 'false');
    if (sessionStorage.getItem('changes_unsaved')) {
      //dont move
    } else {
      // this.props.navigate("/dashboard");

      window.location.href = '/dashboard';
    }

  };

  generateDropdownOptions() {
    var listOptions = '';
    if (this.state.top10List.length !== 0) {
      listOptions = this.state.top10List.map((item) => {
        return (
          <div>
            <p style={{ cursor: "pointer" }} className="dropdown-item" id={item.Client_id} onClick={(e) => { this.handleButtonView(e, item) }}> {item.Full_name} </p>
            {/* <NavLink to={`/basicinfo/id=${item.Client_id}`} className="dropdown-item" id={item.Client_id} onClick={(e) => { this.handleButtonView(e, item) }}> {item.Full_name} </NavLink> */}
            <div className="dropdown-divider"></div>
          </div>
        );
      });
    }
    else {
      listOptions = <div>
        <NavLink to="#" className="dropdown-item">Your client list is currently empty. <br />Please {can_add('ClientInformation') ? <Link to="intake-basicinfo" onClick={this.clearclientid}>click here</Link> : ''} to add a client </NavLink>
        <div className="dropdown-divider"></div>
      </div>;
    }
    return (listOptions);
  }

  render() {
    return (
      <div>
        <ReactTooltip place="right" type="dark" effect="solid" />
        <nav className="main-header navbar navbar-expand navbar-green navbar-dark" >
          <NavLink to="/myclients" >
            <img src="/dist/img/dcaris-logo-white.png" alt="DCARIS" style={{ marginTop: "4px", marginBottom: "5px" }} />
          </NavLink> &nbsp; &nbsp;
          <ul className="navbar-nav">
            <li className="nav-item">
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <NavLink to="/myclients" className="nav-link"><h5>Home</h5></NavLink>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <NavLink to="/clients" className="nav-link" onClick={this.clientsPage}><h5>Clients</h5></NavLink>
            </li>
            <li className="nav-item d-none d-sm-inline-block dropdown">
              <a className="nav-link" data-toggle="dropdown">
                <h5>Recent Clients</h5>
                <span className="badge badge-danger navbar-badge">{this.state.top10List.length}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                {this.generateDropdownOptions()}
                <div><NavLink className="dropdown-item" to="/clients" style={{ textAlign: 'left', color: '#007bff', background: 'transparent' }} onClick={this.clientsPage}> See All Clients </NavLink></div>
              </div>
            </li>
            {true? (
          
              <li className="nav-item d-none d-sm-inline-block">
                <NavLink to="/adminrequest" className="nav-link" onClick={this.filtered_clients}><h5>Status Requests</h5>
                  <span className="badge badge-danger navbar-badge">{this.state.requests}</span></NavLink>
              </li>) : ''}

            {global.is_admin ? (
           
              <>
                <li className="nav-item d-none d-sm-inline-block">
                  <NavLink className="nav-link" target='_blank' to="https://login.microsoftonline.com">
                    <h5>Reports</h5>
                  </NavLink>
                </li>
              </>
            ) : ""}
           




          </ul>
          {/*<form className="form-inline ml-3">
            <div className="input-group input-group-sm">
            <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
              <div className="input-group-append">
                <button className="btn btn-navbar" type="submit">
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
          </form>
          */}
          {/* Report Code */}



          {/* {global.is_admin ? (
            <>
              <Nav
                style={{ fontSize: "18px" }}
                className="nav-item d-none d-sm-inline-block"
              >
                <NavDropdown
                  id="nav-dropdown-dark-example"
                  title="Reports"
                  menuVariant="dark"
                >
                  {reports.map((e) => (
                    <>
                      <NavDropdown.Item
                        key={e}
                        style={{
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          const lowerCase = e.toLowerCase();
                          if (e == "Dashboard") {
                            const path = "chart";
                            //history.push(`/${path}`);
                            navigate(`/${path}`)
                            // window.location.href = `/${path}`;
                          } else {
                            const path = lowerCase.replaceAll(" ", "_");
                            //history.push(`/${path}`);
                            navigate(`/${path}`)
                            // window.location.href = `/${path}`;
                          }
                        }}
                      >
                        {e}
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                    </>
                  ))}
                </NavDropdown>
              </Nav>
            </>
          ) : ""} */}

          {/* {console.log("globalAdmin" , global.is_admin)} */}



          <ul className="navbar-nav ml-auto">
            <li className="nav-item d-none d-sm-inline-block">
              <NavLink to="/requestsupport" className="nav-link"><span>Get Support</span></NavLink>
              {/* onClick={this.clientsPage} */}
            </li>
            {this.state.debug ? (
              <li>
                {global.is_staff
                  ? "Staff"
                  : global.is_admin
                    ? "Admin"
                    : global.is_peer_specialist
                      ? "Peer"
                      : "Clinical"}
              </li>
            ) : (
              ""
            )}

            {true ? (
            // global.is_admin
              <li className="nav-item d-none d-sm-inline-block">
                <NavLink to="/userManagement" className="nav-link">
                  <span>Admin |</span>
                </NavLink>
                {/* <NavLink to="/userManagement" className="nav-link"><h5>Admin</h5></NavLink> */}
              </li>
            ) : (
              ""
            )}
            <li className="nav-item">
              {/* <Link className="nav-link" to="/changePassword">
                Change Password
              </Link> */}
              <NavLink className="nav-link" to="/changePassword">
                Change Password
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/logout">
                <i className="fas fa-sign-out-alt"></i>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    );
  }

}



export function AppTopNav(props) {
  const navigate = useNavigate();
  return (<Topnav {...props} navigate={navigate}></Topnav>
  )
}
export default HOC(Topnav)
