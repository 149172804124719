import React, { useEffect, useState } from "react";
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from "react-tooltip";
import { getIndexOfMaxValueOfAnArray, getReadinessScore } from './CommanFunction/commanFunction';
import axios from 'axios';

const ReadinessChange = (props) => {
  const [questions, setquestions] = useState([
    { question: "My substance use is okay as it is.", answer: '', name: "okay_as_it" },
    { question: "I am trying to use substances less than I used to.", answer: '', name: "less_than_I_used_to" },
    { question: "I enjoy my substance use but sometimes I use too much.", answer: '', name: "I_use_too_much" },
    { question: "I should cut down on my substance use.", answer: '', name: "my_substance_use" },
    { question: "It’s a waste of time thinking about my substance use.", answer: '', name: "thinking_about_my" },
    { question: "I have just recently changed my substance use habits.", answer: '', name: "substance_use_habits" },
    { question: "Anyone can talk about wanting to do something substance use, but I am actually doing something about it.", answer: '', name: "something_about_it" },
    { question: "I am at the stage where I should think about using less substances.", answer: '', name: "less_substances" },
    { question: "My substance use is a problem.", answer: '', name: "use_is_a_problem" },
    { question: "It’s alright for me to keep using substances as I do now.", answer: '', name: "as_I_do_now" },
    { question: "I am actually changing my substance use habits right now.", answer: '', name: "right_now" },
    { question: "My life would still be the same, even if I use substances less.", answer: '', name: "use_substances_less" },
  ]);

  const [isRiskRatingMandatory, setisRiskRatingMandatory] = useState(false);
  const [isRiskRatingMandatory2, setisRiskRatingMandatory2] = useState(false);

  const [isSaveAndComplete, setisSaveAndComplete] = useState(props.isSaveAndComplete)
  const readiness_risk_rating0 = `Willing, engaged in treatment.`
  const readiness_risk_rating1 = `Willing to enter treatment, but is ambivalent about the need to change. Or willing to change substance use, but believes it will not be difficult to do so.`
  const readiness_risk_rating2 = `Reluctant to agree to treatment. Able to articulate negative consequences of usage but has low commitment to change use. Only passively involved in treatment.`
  const readiness_risk_rating3 = `Unaware of need for change, minimal awareness of the need for treatment, and unwilling or only partially able to follow through with recommendations.`
  const readiness_risk_rating4 = `Not willing to explore change, knows very little about addiction, and is in denial of the illness and its implications. Unable to follow-through with recommendations.`
  
  const readiness_mental_risk_rating0 = `Willingly engaged in TX as proactive, responsible participant; willing to change mental functioning and behavior.`
  const readiness_mental_risk_rating1 = `Willing to enter TX and explore strategies for changing mental functioning but is ambivalent about the need to change. Willing to explore the need for strategies to deal with mental disorders. Participation in MH TX is sufficient to avert mental decompensating.`
  const readiness_mental_risk_rating2 = `Reluctant to agree to TX for mental disorders. Is able to articulate the negative consequences of mental health problems but has low commitment to therapy. Has low readiness to change and passively involved in TX.`
  const readiness_mental_risk_rating3 = `Exhibits inconsistent follow through and shows minimal awareness of mental disorder or need for TX. Unaware of the need for change and is unwilling or partial able to follow through with recommendations.`
  const readiness_mental_risk_rating4 = `A. No immediate action required. Unable to follow through/little to no awareness of a MH disorder or neg. consequences. Sees no connection between suffering and m-disorder. B. Immediate Action Required; behavior represents imminent danger.`
  
  
  
  const [saved, setsaved] = useState(true);
  const [otherData, setotherData] = useState({
    why_are_you_here: '',
    help_with_any_other_problem: '',
    help_with_any_other_problem_explain: '',
    readiness_mental_risk_rating: '',
    readiness_comments: '',
    readiness_risk_rating: '',
    readiness_risk_rating_information: "",
    readiness_notes_comment:"",
    // New fields
    readiness_mental_risk_rating_information:"",
    readiness_mental_comments:"",
    form_type: props.form_type,
  })
  const [precontemplationScore, setprecontemplationScore] = useState("")
  const [contemplationScore, setcontemplationScore] = useState("")
  const [actionScore, setactionScore] = useState("")
  const [stage_of_change_designation, setstageOfChangeDesignation] = useState("")
  const scoringFieldName = [
    "okay_as_it", "less_than_I_used_to", "I_use_too_much", "my_substance_use",
    "thinking_about_my", "substance_use_habits", "something_about_it", "less_substances",
    "use_is_a_problem", "as_I_do_now", "right_now", "use_substances_less",
  ]

  // const [preContemplationQuestionArry, setpreContemplationQuestionArry] = useState([
  //   okay_as_it => "",
  //   question_5 => "",
  //   question_10 => "",
  //   question_12 => "",
  // ])
  // const [contemplationQuestionArry, setContemplationQuestionArry] = useState([
  //   question_3 => "",
  //   question_4 => "",
  //   question_8 => "",
  //   question_9 => "",
  // ])
  // const [actionQuestionArry, setActionQuestionArry] = useState([
  //   question_2 => "",
  //   question_6 => "",
  //   question_7 => "",
  //   question_11 => "",
  // ])

  const [scoreCalculationArray, setscoreCalculationArray] = useState([
    "", "", "", "", "", "", "", "", "", "", "", ""
  ])

  const dataListBody = {
    apikey: sessionStorage.getItem("__TOKEN__"),
    client_id: sessionStorage.getItem("clientId"),
    form_type: props.form_type,
    uploaded_by: sessionStorage.getItem("username"),
  }
  useEffect(() => {
    loadData();
  }, [])
  useEffect(() => {
    props.childToParent(otherData, questions, precontemplationScore, contemplationScore, actionScore, stage_of_change_designation);
  }, [otherData, questions])

  const handleChange = (e, index) => {
    setsaved(false);
    props.tabEditOrNot(true);
    props.saveAndExit("", "", "", "", handleSubmit, "", "", "", "", "", "", "", "");
    let tempName = e.target.name;
    if (tempName == "help_with_any_other_problem" && e.target.value == "no") {
      setotherData({ ...otherData, ["help_with_any_other_problem_explain"]: "", ["help_with_any_other_problem"]: "no" });
    // if (tempName == "problem_with_substances" && e.target.value == "no") {
    //     setotherData({ ...otherData, ["problem_with_substances_explain"]: "", ["problem_with_substances"]: "no" });
    // } 
    } else if (tempName == "readiness_risk_rating") {
      switch (e.target.value) {
        case "0": setotherData({ ...otherData, ["readiness_risk_rating_information"]: readiness_risk_rating0, ["readiness_risk_rating"]: 0 });
          break;
        case "1": setotherData({ ...otherData, ["readiness_risk_rating_information"]: readiness_risk_rating1, ["readiness_risk_rating"]: 1 });
          break;
        case "2": setotherData({ ...otherData, ["readiness_risk_rating_information"]: readiness_risk_rating2, ["readiness_risk_rating"]: 2 });
          break;
        case "3": setotherData({ ...otherData, ["readiness_risk_rating_information"]: readiness_risk_rating3, ["readiness_risk_rating"]: 3 });
          break;
        case "4": setotherData({ ...otherData, ["readiness_risk_rating_information"]: readiness_risk_rating4, ["readiness_risk_rating"]: 4 });
          break;
      }
      props.riskRatingDecisionWithValue(e.target.value);
      if(e.target.value >= 2 && otherData.readiness_comments.length < 1){
        setisRiskRatingMandatory(true);
      }else{
        setisRiskRatingMandatory(false);
      }
    }else if(tempName == 'readiness_comments'){
      if(e.target.value.length < 1 && otherData.readiness_risk_rating >= 2){
        setisRiskRatingMandatory(true);
      }else{
        setisRiskRatingMandatory(false);
      }
      props.riskRatingDecisionWithComment(e.target.value);
      props.riskRatingDecisionWithValue(otherData.readiness_risk_rating);
      setotherData({ ...otherData, ["readiness_comments"]: e.target.value });
    }else if (tempName == "readiness_mental_risk_rating") {
      switch (e.target.value) {
        case "0": setotherData({ ...otherData, ["readiness_mental_risk_rating_information"]: readiness_mental_risk_rating0, ["readiness_mental_risk_rating"]: 0 });
          break;
        case "1": setotherData({ ...otherData, ["readiness_mental_risk_rating_information"]: readiness_mental_risk_rating1, ["readiness_mental_risk_rating"]: 1 });
          break;
        case "2": setotherData({ ...otherData, ["readiness_mental_risk_rating_information"]: readiness_mental_risk_rating2, ["readiness_mental_risk_rating"]: 2 });
          break;
        case "3": setotherData({ ...otherData, ["readiness_mental_risk_rating_information"]: readiness_mental_risk_rating3, ["readiness_mental_risk_rating"]: 3 });
          break;
        case "4": setotherData({ ...otherData, ["readiness_mental_risk_rating_information"]: readiness_mental_risk_rating4, ["readiness_mental_risk_rating"]: 4 });
          break;
      }
      props.riskRatingDecisionWithValue2(e.target.value);
      if(e.target.value >= 2 && otherData.readiness_mental_comments.length < 1){
        setisRiskRatingMandatory2(true);
      }else{
        setisRiskRatingMandatory2(false);
      }
    }else if(tempName == "readiness_mental_comments"){
      if(e.target.value.length < 1 && otherData.readiness_mental_risk_rating >= 2){
        setisRiskRatingMandatory2(true);
      }else{
        setisRiskRatingMandatory2(false);
      }
      props.riskRatingDecisionWithComment2(e.target.value);
      props.riskRatingDecisionWithValue2(otherData.readiness_mental_risk_rating);
      setotherData({ ...otherData, ["readiness_mental_comments"]: e.target.value });
    }else if (scoringFieldName.includes(tempName)) {
      scoreCalculationArray[index] = e.target.value != "" ? parseInt(e.target.value) : "";
      switch (tempName) {
        case "okay_as_it": questions[index].answer = e.target.value != "" ? parseInt(e.target.value) : ""; setquestions(questions);
          break;
        case "less_than_I_used_to": questions[index].answer = e.target.value != "" ? parseInt(e.target.value) : ""; setquestions(questions);
          break;
        case "I_use_too_much": questions[index].answer = e.target.value != "" ? parseInt(e.target.value) : ""; setquestions(questions);
          break;
        case "my_substance_use": questions[index].answer = e.target.value != "" ? parseInt(e.target.value) : ""; setquestions(questions);
          break;
        case "thinking_about_my": questions[index].answer = e.target.value != "" ? parseInt(e.target.value) : ""; setquestions(questions);
          break;
        case "substance_use_habits": questions[index].answer = e.target.value != "" ? parseInt(e.target.value) : ""; setquestions(questions);
          break;
        case "something_about_it": questions[index].answer = e.target.value != "" ? parseInt(e.target.value) : ""; setquestions(questions);
          break;
        case "less_substances": questions[index].answer = e.target.value != "" ? parseInt(e.target.value) : ""; setquestions(questions);
          break;
        case "use_is_a_problem": questions[index].answer = e.target.value != "" ? parseInt(e.target.value) : ""; setquestions(questions);
          break;
        case "as_I_do_now": questions[index].answer = e.target.value != "" ? parseInt(e.target.value) : ""; setquestions(questions);
          break;
        case "right_now": questions[index].answer = e.target.value != "" ? parseInt(e.target.value) : ""; setquestions(questions);
          break;
        case "use_substances_less": questions[index].answer = e.target.value != "" ? parseInt(e.target.value) : ""; setquestions(questions);
          break;
      }
    } else {
      setotherData({ ...otherData, [e.target.name]: e.target.value });
    }
    if (scoringFieldName.includes(tempName)) {
      let tempObj_PreConScore = getReadinessScore([scoreCalculationArray[0], scoreCalculationArray[4], scoreCalculationArray[9], scoreCalculationArray[11]]);
      let tempObj_ConScore = getReadinessScore([scoreCalculationArray[2], scoreCalculationArray[3], scoreCalculationArray[7], scoreCalculationArray[8]]);
      let tempObj_actionScore = getReadinessScore([scoreCalculationArray[1], scoreCalculationArray[5], scoreCalculationArray[6], scoreCalculationArray[10]]);
      // Calculate PreContemption score
      // Set PreContemption score
      tempObj_PreConScore.balnkCount == 4 ? setprecontemplationScore("") : tempObj_PreConScore.balnkCount == 1 ? setprecontemplationScore(tempObj_PreConScore.totalSum * 1.33 * -1) : tempObj_PreConScore.balnkCount >= 2 ? setprecontemplationScore("Invalid") : setprecontemplationScore(tempObj_PreConScore.totalSum * -1)
      // Set Contemption score
      tempObj_ConScore.balnkCount == 4 ? setcontemplationScore("") : tempObj_ConScore.balnkCount == 1 ? setcontemplationScore(tempObj_ConScore.totalSum * 1.33) : tempObj_ConScore.balnkCount >= 2 ? setcontemplationScore("Invalid") : setcontemplationScore(tempObj_ConScore.totalSum)
      // Set Action score
      tempObj_actionScore.balnkCount == 4 ? setactionScore("") : tempObj_actionScore.balnkCount == 1 ? setactionScore(tempObj_actionScore.totalSum * 1.33) : tempObj_actionScore.balnkCount >= 2 ? setactionScore("Invalid") : setactionScore(tempObj_actionScore.totalSum)

      setTimeout(() => {
        let indexOfMaxValue = getIndexOfMaxValueOfAnArray([
          tempObj_PreConScore.balnkCount == 4 ? "" : tempObj_PreConScore.balnkCount == 1 ? (tempObj_PreConScore.totalSum * 1.33 * -1) : tempObj_PreConScore.balnkCount >= 2 ? ("Invalid") : (tempObj_PreConScore.totalSum * -1),
          tempObj_ConScore.balnkCount == 4 ? ("") : tempObj_ConScore.balnkCount == 1 ? (tempObj_ConScore.totalSum * 1.33) : tempObj_ConScore.balnkCount >= 2 ? ("Invalid") : (tempObj_ConScore.totalSum),
          tempObj_actionScore.balnkCount == 4 ? ("") : tempObj_actionScore.balnkCount == 1 ? (tempObj_actionScore.totalSum * 1.33) : tempObj_actionScore.balnkCount >= 2 ? ("Invalid") : (tempObj_actionScore.totalSum),

          // precontemplationScore,contemplationScore,actionScore
        ])
        let designationVal = indexOfMaxValue.index == 0 ? "Precontemplation" : indexOfMaxValue.index == 1 ? "Contemplation" : indexOfMaxValue.index == 2 ? "Action" : ""
        setstageOfChangeDesignation(designationVal)
       
      }, 500)
      //calculation for stage of change designation 
      // let maxScore = Math.max(PreConScoreTemp, ConScoreTemp, actionScoreTemp)
      // if (maxScore == actionScoreTemp) {
      //   setstageOfChangeDesignation("Action")
      // } else if (maxScore == ConScoreTemp) {
      //   setstageOfChangeDesignation("Contemplation")
      // } else if (maxScore == PreConScoreTemp) {
      //   setstageOfChangeDesignation("Precontemplation")
      // }
      // }, 100)

      // Data sent to ADMH Component
      props.childToParent(
        otherData,
        questions,
        questions[0].answer + questions[4].answer + questions[9].answer + questions[11].answer,
        questions[2].answer + questions[3].answer + questions[7].answer + questions[8].answer,
        questions[1].answer + questions[5].answer + questions[6].answer + questions[10].answer,
        stage_of_change_designation,
      );
    }
  }

  const loadData = async () => {
    if (!props.isSaveAndComplete) {
      setsaved(true);
      let res = await axios.post(global.restApiURL + "customRest/getReadinessForChange", JSON.stringify(dataListBody));
      if (res.data.success == true && res.status == 200) {
        setotherData(res.data.data.otherData);
        res.data.data.precontemplation_score != "" && setprecontemplationScore(parseInt(res.data.data.precontemplation_score))
        res.data.data.contemplation_score != "" && setcontemplationScore(parseInt(res.data.data.contemplation_score))
        res.data.data.action_score != "" && setactionScore(parseInt(res.data.data.action_score))
        setstageOfChangeDesignation(res.data.data.stage_of_change_designation)
        //  setprecontemplationScore,setcontemplationScore,setactionScore
        if (res.data.data.questions.length > 0 && res.data.data.questions != "null") {
          setquestions(JSON.parse(res.data.data.questions));
        }
      }
      let uncopeScreeningRes = await axios.post(global.restApiURL + "customRest/getUncopeScreeningInfo", JSON.stringify(dataListBody) )
      if (uncopeScreeningRes.data.success === true && uncopeScreeningRes.status === 200) {
        setotherData(prevData => ({
          ...prevData,
          why_are_you_here: uncopeScreeningRes.data.data.Uncopescreening.important_thing
        }));
      }
    } else {
      let res = await axios.post(global.restApiURL + "customRest/getReadinessForChangeTemp",
        JSON.stringify(dataListBody));
      if (res.data.success == true && res.status == 200) {
        setotherData(res.data.data.otherData);
        res.data.data.precontemplation_score != "" && setprecontemplationScore(parseInt(res.data.data.precontemplation_score))
        res.data.data.contemplation_score != "" && setcontemplationScore(parseInt(res.data.data.contemplation_score))
        res.data.data.action_score != "" && setactionScore(parseInt(res.data.data.action_score))
        setstageOfChangeDesignation(res.data.data.stage_of_change_designation)
        //  setprecontemplationScore,setcontemplationScore,setactionScore
        if (res.data.data.questions.length > 0 && res.data.data.questions != "null") {
          setquestions(JSON.parse(res.data.data.questions));
        }
      }
    }
  }



  const handleSubmit = async (e) => {
    e.preventDefault();
    if((otherData.readiness_comments.length < 1 && otherData.readiness_risk_rating >= 2) || (otherData.readiness_mental_comments.length < 1 && otherData.readiness_mental_risk_rating >= 2)){
      alert("Risk rating summary field is mandatory.");
    }else{
      const final_data = {
        dataListBody,
        otherData,
        actionScore,
        contemplationScore,
        precontemplationScore,
        questions,
        stage_of_change_designation
      }
      let res = await axios.post(global.restApiURL + "customRest/saveReadinessForChange",
        JSON.stringify(final_data))
        if (res.data.success == true) {
        loadData();
        toast.success('Data added successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: 'success_readinessChange',
          theme:'colored'
        });
      }
      props.tabEditOrNot(false)
    }
  };



  return (
    <>
      <div className="hold-transition sidebar-mini text-sm">
        <div className="wrapper">
          <div className="">
            <section className="content-body">
              <div className="row">
                <div className="col-md-12">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card shadow-none">
                          <div>
                            <p className="lead float-left" style={{ marginBottom: '1rem' }}>
                              <b className="text-green">
                                Readiness to change
                              </b>
                            </p>
                          </div>
                          <div>
                            <div className="row marginBottom1">
                              <div className="col-md-4">
                                <label>Why are you here today?</label>
                              </div>
                              <div className="col-md-8">
                                <TextareaAutosize 
                                  style={{overflow:'hidden'}}
                                  minRows={(otherData.why_are_you_here?.length/71) + otherData.why_are_you_here?.split(/\r\n|\r|\n/).length} 
                                  disabled={isSaveAndComplete} 
                                  name="why_are_you_here" 
                                  className="form-control" 
                                  onChange={handleChange} 
                                  value={otherData.why_are_you_here} 
                                />
                              </div>
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-12">
                                <strong>Readiness for Change Questionnaire</strong><br />
                                {/* <span>For each sentence, please select the answer that best describes how the client feels. <a href="/readiness_to_change_questionnaire_-_drinking.pdf" target="_blank">You can see guidelines here.</a></span> */}
                                <span>For each sentence, please select the answer that best describes the <b>client’s perspective of themselves.</b> <a href="/readiness_for_change_questionnaire_-_drinking.pdf" target="_blank">You can see guidelines here.</a></span>
                                <div className="mt-2">
                                  {questions.map((val, index) => {
                                    return (<>
                                      <div className="row marginBottom1">
                                        <div className="col-md-4">
                                          <label> {val.question}</label>
                                        </div>
                                        <div className="col-md-4">
                                          <select className="form-control" disabled={isSaveAndComplete} index={index} value={val.answer} name={val.name} onChange={(e) => { handleChange(e, index) }} >
                                            <option value="">Select</option>
                                            <option value="-2">Strongly disagree</option>
                                            <option value="-1">Disagree</option>
                                            <option value="0">Unsure</option>
                                            <option value="1">Agree</option>
                                            <option value="2">Strongly agree</option>
                                          </select>
                                        </div>
                                      </div>
                                    </>)
                                  })}
                                </div>

                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label>Precontemplation score</label>
                                      {/* <input type="text" className="form-control" disabled={isSaveAndComplete} name="precontemplation_score" onChange={handleChange} value={otherData.precontemplation_score}/> */}
                                      <input type="text" className="form-control" disabled={isSaveAndComplete} name="precontemplation_score" onChange={handleChange} value={precontemplationScore} />

                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label>Contemplation score</label>
                                      <input type="text" className="form-control" disabled={isSaveAndComplete} name="contemplation_score" onChange={handleChange} value={contemplationScore} />


                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label>Action score</label>
                                      <input type="text" className="form-control" disabled={isSaveAndComplete} name="action_score" onChange={handleChange} value={actionScore} />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label>Stage of Change designation</label>
                                      <input type="text" className="form-control" disabled={true} name="stage_of_change_designation" onChange={handleChange} value={stage_of_change_designation} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-4">
                                <label>Do you need help with any other problems?</label>
                              </div>
                              <div className="col-md-2">
                                <select className="form-control" disabled={isSaveAndComplete} name="help_with_any_other_problem" onChange={handleChange} value={otherData.help_with_any_other_problem}>
                                  <option value="">Select</option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                              {otherData.help_with_any_other_problem == "yes" && <>
                                <div className="col-md-6">
                                  <TextareaAutosize 
                                    style={{overflow:'hidden'}} 
                                    minRows={(otherData.help_with_any_other_problem_explain?.length/40) + otherData.help_with_any_other_problem_explain?.split(/\r\n|\r|\n/).length}
                                    disabled={isSaveAndComplete} 
                                    placeholder="Please explain" 
                                    name="help_with_any_other_problem_explain" 
                                    className="form-control" 
                                    onChange={handleChange} 
                                    value={otherData.help_with_any_other_problem_explain} 
                                  />
                                </div>
                                </>
                              }
                            </div>

                            <div className="row marginBottom1">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Notes or comments</label>
                                  <TextareaAutosize 
                                    className="form-control" 
                                    minRows={(otherData.readiness_notes_comment?.length/110) + otherData.readiness_notes_comment?.split(/\r\n|\r|\n/).length}
                                    value={otherData.readiness_notes_comment} 
                                    style={{overflow:'hidden'}}
                                    name="readiness_notes_comment"
                                    onChange={handleChange}
                                    disabled={isSaveAndComplete}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-12">
                                <div className="green_band">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <p className="lead sa_risk">
                                        <b>Substance Use Risk Rating </b>
                                        <span className="readiness_risk_rating"></span>
                                      </p>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="form-group ml-0 pl-0">
                                        <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label0" name="readiness_risk_rating" value="0" onChange={handleChange} checked={otherData.readiness_risk_rating == "0" ? true : false} />&nbsp;
                                        <label className="labelMargin">0</label>
                                        <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label1" name="readiness_risk_rating" value="1" onChange={handleChange} checked={otherData.readiness_risk_rating == 1 ? true : false} />&nbsp;
                                        <label className="labelMargin">1</label>
                                        <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label2" name="readiness_risk_rating" value="2" onChange={handleChange} checked={otherData.readiness_risk_rating == 2 ? true : false} />&nbsp;
                                        <label className="labelMargin">2</label>
                                        <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label3" name="readiness_risk_rating" value="3" onChange={handleChange} checked={otherData.readiness_risk_rating == 3 ? true : false} />&nbsp;
                                        <label className="labelMargin">3</label>
                                        <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label4" name="readiness_risk_rating" value="4" onChange={handleChange} checked={otherData.readiness_risk_rating == 4 ? true : false} />&nbsp;
                                        <label className="labelMargin">4</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-md-6">
                                <div className="green_band">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <p className="lead sa_risk">
                                        <b>Mental Health Risk Rating </b>
                                        <span className="sa_risk"></span>
                                      </p>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="form-group">
                                        <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label5" name="readiness_mental_risk_rating" value="0" onChange={handleChange} checked={otherData.readiness_mental_risk_rating == "0" ? true : false} />&nbsp;
                                        <label className="labelMargin">0</label>
                                        <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label6" name="readiness_mental_risk_rating" value="1" onChange={handleChange} checked={otherData.readiness_mental_risk_rating == 1 ? true : false} />&nbsp;
                                        <label className="labelMargin">1</label>
                                        <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label7" name="readiness_mental_risk_rating" value="2" onChange={handleChange} checked={otherData.readiness_mental_risk_rating == 2 ? true : false} />{" "}&nbsp;
                                        <label className="labelMargin">2</label>
                                        <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label8" name="readiness_mental_risk_rating" value="3" onChange={handleChange} checked={otherData.readiness_mental_risk_rating == 3 ? true : false} />&nbsp;
                                        <label className="labelMargin">3</label>
                                        <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label9" name="readiness_mental_risk_rating" value="4" onChange={handleChange} checked={otherData.readiness_mental_risk_rating == 4 ? true : false} />&nbsp;
                                        <label className="labelMargin">4</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                            <ReactTooltip id="label0" place="bottom" effect="solid" className="toolTipLabel0">{readiness_risk_rating0}</ReactTooltip>
                            <ReactTooltip id="label1" place="bottom" effect="solid" className="toolTipLabel1">{readiness_risk_rating1}</ReactTooltip>
                            <ReactTooltip id="label2" place="bottom" effect="solid" className="toolTipLabel2">{readiness_risk_rating2}</ReactTooltip>
                            <ReactTooltip id="label3" place="bottom" effect="solid" className="toolTipLabel3">{readiness_risk_rating3}</ReactTooltip>
                            <ReactTooltip id="label4" place="bottom" effect="solid" className="toolTipLabel4" >{readiness_risk_rating4}</ReactTooltip>

                            <ReactTooltip id="label5" place="bottom" effect="solid" className="toolTipLabel0">{readiness_mental_risk_rating0}</ReactTooltip>
                            <ReactTooltip id="label6" place="bottom" effect="solid" className="toolTipLabel1">{readiness_mental_risk_rating1}</ReactTooltip>
                            <ReactTooltip id="label7" place="bottom" effect="solid" className="toolTipLabel2">{readiness_mental_risk_rating2}</ReactTooltip>
                            <ReactTooltip id="label8" place="bottom" effect="solid" className="toolTipLabel3">{readiness_mental_risk_rating3}</ReactTooltip>
                            <ReactTooltip id="label9" place="bottom" effect="solid" className="toolTipLabel4" >{readiness_mental_risk_rating4}</ReactTooltip>
                            <div className="row">
                              <div className='col-md-12'>
                                Diagnostic summary for this dimension
                              </div>
                              <hr />
                              <div className="col-12">
                                <div className='form-group'>
                                  <label>Analysis</label>
                                  <TextareaAutosize 
                                    className="form-control" 
                                    value={otherData.readiness_risk_rating_information} 
                                    name="readiness_risk_rating_information" readOnly 
                                    minRows={(otherData.readiness_risk_rating == "" || otherData.readiness_risk_rating == 0) ? 1 : 2}
                                    style={{ backgroundColor: '#FEE5A1', overflow:'hidden'}} 
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className='form-group'>
                                  <label>Risk Rating Summary</label>
                                  <TextareaAutosize 
                                    minRows={(otherData.readiness_comments?.length/110) + otherData.readiness_comments?.split(/\r\n|\r|\n/).length}
                                    className="form-control" 
                                    style={{overflow:'hidden'}} 
                                    disabled={isSaveAndComplete} 
                                    name="readiness_comments" 
                                    onChange={handleChange} 
                                    value={otherData.readiness_comments} 
                                  />
                                  {isRiskRatingMandatory && <> <span style={{color:"red"}}>This field is mandatory.*</span></>}
                                </div>
                              </div>
                              {/*  */}
                              <div className="col-12">
                                <div className="green_band">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <p className="lead sa_risk">
                                        <b>Mental Health Risk Rating </b>
                                        <span className="sa_risk"></span>
                                      </p>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="form-group">
                                        <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label5" name="readiness_mental_risk_rating" value="0" onChange={handleChange} checked={otherData.readiness_mental_risk_rating == "0" ? true : false} />&nbsp;
                                        <label className="labelMargin">0</label>
                                        <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label6" name="readiness_mental_risk_rating" value="1" onChange={handleChange} checked={otherData.readiness_mental_risk_rating == 1 ? true : false} />&nbsp;
                                        <label className="labelMargin">1</label>
                                        <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label7" name="readiness_mental_risk_rating" value="2" onChange={handleChange} checked={otherData.readiness_mental_risk_rating == 2 ? true : false} />{" "}&nbsp;
                                        <label className="labelMargin">2</label>
                                        <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label8" name="readiness_mental_risk_rating" value="3" onChange={handleChange} checked={otherData.readiness_mental_risk_rating == 3 ? true : false} />&nbsp;
                                        <label className="labelMargin">3</label>
                                        <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="label9" name="readiness_mental_risk_rating" value="4" onChange={handleChange} checked={otherData.readiness_mental_risk_rating == 4 ? true : false} />&nbsp;
                                        <label className="labelMargin">4</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className='form-group mt-2'>
                                  <label>Analysis</label>
                                  <TextareaAutosize 
                                    className="form-control" 
                                    minRows={otherData.readiness_mental_risk_rating == 0 ? 1 : (otherData.readiness_mental_risk_rating == 1  || otherData.readiness_mental_risk_rating == 4) ? 3 : 2}
                                    value={otherData.readiness_mental_risk_rating_information} 
                                    name="readiness_mental_risk_rating_information" readOnly 
                                    style={{ backgroundColor: '#FEE5A1', overflow:'hidden'}}
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className='form-group'>
                                  <label>Risk Rating Summary</label>
                                  <TextareaAutosize 
                                    className="form-control" 
                                    minRows={(otherData.readiness_mental_comments?.length/110) + otherData.readiness_mental_comments?.split(/\r\n|\r|\n/).length}
                                    style={{overflow:'hidden'}} 
                                    disabled={isSaveAndComplete} 
                                    name="readiness_mental_comments" 
                                    onChange={handleChange} 
                                    value={otherData.readiness_mental_comments} 
                                  />
                                  {isRiskRatingMandatory2 && <> <span style={{color:"red"}}>This field is mandatory.*</span></>}
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-12'>
                                <button type="submit" data-save="save" disabled={saved} value='save' className="btn btn-success float-right btnmargin"> <i className="fas fa-save"></i> Save</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReadinessChange
