

import SubstanceReducer from "./SubstanceReducer";
// import {combineReducers} from "redux";
import { combineReducers } from 'redux'

const rootreducer=combineReducers({
    substanceUseData:SubstanceReducer,
})


export default rootreducer;