// import React, { Component } from 'react';
// import services from '../../services';
// import Footer from '../Footer';
// import Topnav, { AppTopNav } from '../Topnav';
// import AdminSideNav from './AdminSideNav';
// import UserListing from './UserListing';
// const axios = require('axios').default;


// export default class UserManagement extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       search: false,
//       userList: {},
//       rolesList: {},
//       username: '',
//       email: '',
//       listNext : 1,
//       pageInfo: {
//         pageString : "",
//         pages : 0,
//         records : 0
//       },
//     }
//   }

//   updateListNext = (pageNum) => {
//     this.setState({
//       listNext : pageNum
//     })
//   }

//   componentDidMount() {
//     this.getUserList();
//     this.getRolesList();
//   }

//   parseResponse = (promise) => {
//     if (promise != true) {
//       return promise.then(data => {
//         return data;
//       }).catch(err => {
//         console.log(err);
//       });
//     }
//   };
//   getUserList = async () => {
//     let requestData = {
//       'apikey': sessionStorage.getItem('__TOKEN__'),
//       'organization': sessionStorage.getItem('selected_organization'),
//       "page": 1
//     }
//     let userListApi = await axios.post(global.restApiURL + 'customRest/getUserList', JSON.stringify(requestData));
//     this.setState({ 
//       userList: userListApi.data.data,
//       pageInfo: userListApi.data.pageInfo,
//     });
//   }

//   stateSattlement = (a = {}, b = "") => {
//     this.setState({ 
//       userList: a,
//       pageInfo: b,
//     });
//   }

//   getRolesList = async () => {
//     let requestData = {
//       'apikey': sessionStorage.getItem('__TOKEN__')
//     }
//     let rolesListApi = await this.parseResponse(services.getRolesList(requestData));
//     rolesListApi.map((role, index) => {
//       if (role.name == "ClinicalSpecialist") {
//         role.name = "Clinical Specialist";
//       }else if (role.name == "PeerSupportSpecialist") {
//         role.name = "Peer Support Specialist"
//       }else if(role.name == "organizational_admin"){
//         role.name = "Organizational Administrator";
//       }else {
//         delete rolesListApi[index]
//       }
//     })
//     this.setState({ rolesList: rolesListApi });
//   }

//   searchResult = async (evnt) => {
//     let requestData = {
//       'apikey': sessionStorage.getItem('__TOKEN__'),
//       'organization': sessionStorage.getItem('selected_organization'),
//       filters: {

//       }
//     }
//     if (isNaN(evnt)) {
//       evnt.preventDefault();
//     } else {
//       if (evnt <= this.state.pageInfo.pages) {
//         requestData.page = evnt;
//       }
//     }
//     if (this.state.username != '') {
//       requestData.filters.name = this.state.username
//     }
//     if (this.state.email != '') {
//       requestData.filters.email = this.state.email
//     }
//     if (this.state.username == '' && this.state.email == '' && this.state.pageInfo.pages <= 1) {
//       this.getUserList();
//     } else {
//       let userListApi = await axios.post(global.restApiURL + 'customRest/getSearchResult', JSON.stringify(requestData));
//       this.setState({
//         userList: userListApi.data.data,
//         pageInfo: userListApi.data.pageInfo,
//         search: true
//       });
//     }
//   }

//   handleChange = (e) => {
//     if(e.target.value === ""){
//       this.setState({ listNext : 1 });
//     }
//     let name = e.target.name;
//     let value = e.target.value;
//     this.setState({ [name]: value });
//   }

//   resetButton = () => {
//     this.setState({ email: '', username: '', search : false, listNext : 1 });
//     this.getUserList();
//   }

//   render() {
//     return (
//       <div className="hold-transition sidebar-mini text-sm">
//         <div className="wrapper">
//           <AppTopNav />
//           <div className="content-wrapper">
//             <section class="content-header"></section>
//             <div className='row'>
//               <div className='col-md-2'>
//                 <AdminSideNav />
//               </div>
//               <div className='col-md-10'>
//               <UserListing changePagination={this.updateListNext} userList={this.state.userList} rolesList={this.state.rolesList} resetButton={this.resetButton} stateSattlement={this.stateSattlement} getUserList={this.getUserList} handleChange={this.handleChange} searchResult={this.searchResult} username={this.state.username} email={this.state.email} wholeState={this.state}/>
//               </div>
//             </div>
//           </div>
//           <Footer />
//         </div>
//       </div>
//     )
//   }
// }
















import React, { Component } from 'react'
import services from '../../services';
import Topnav from '../Topnav';
import UserListing from './UserListing';
import AdminSideNav from './AdminSideNav';
import Modal from '../Modals/Modal';
import Footer from '../Footer';
const axios = require('axios').default;


export default class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: false,
      userList: {},
      rolesList: {},
      username: '',
      email: '',
      listNext : 1,
      pageInfo: {
        pageString : "",
        pages : 0,
        records : 0
      },
    }
  }

  updateListNext = (pageNum) => {
    this.setState({
      listNext : pageNum
    })
  }

  componentDidMount() {
    this.getUserList();
    this.getRolesList();
  }

  parseResponse = (promise) => {
    if (promise != true) {
      return promise.then(data => {
        return data;
      }).catch(err => {
        console.log(err);
      });
    }
  };

  getUserList = async (param) => {
    let requestData = {
      'apikey': sessionStorage.getItem('__TOKEN__'),
      'organization': sessionStorage.getItem('selected_organization'),
      "page": 1
    }
    if(param != undefined && param != "" && param != ""){
      requestData.limit = param;
    }
    let userListApi = await axios.post(global.restApiURL + 'customRest/getUserList', JSON.stringify(requestData));
    this.setState({ 
      userList: userListApi.data.data,
      pageInfo: userListApi.data.pageInfo,
    });
  }

  stateSattlement = (a = {}, b = "") => {
    this.setState({ 
      userList: a,
      pageInfo: b,
    });
  }

  getRolesList = async () => {
    let requestData = {
      'apikey': sessionStorage.getItem('__TOKEN__')
    }
    let rolesListApi = await this.parseResponse(services.getRolesList(requestData));
    rolesListApi.map((role, index) => {
     if (role.name == "ClinicalSpecialist") {
        role.name = "Clinical Specialist";
      }else if (role.name == "PeerSupportSpecialist") {
        role.name = "Peer Support Specialist"
      }else if(role.name == "organizational_admin"){
        role.name = "Organizational Administrator";
      }else {
        delete rolesListApi[index]
      }
    })
    this.setState({ rolesList: rolesListApi });
  }

  searchResult = async (evnt) => {
    let requestData = {
      'apikey': sessionStorage.getItem('__TOKEN__'),
      'organization': sessionStorage.getItem('selected_organization'),
      filters: {

      }
    }
    if (isNaN(evnt)) {
      evnt.preventDefault();
    }else {
      if (evnt <= this.state.pageInfo.pages) {
        requestData.page = evnt;
      }
    }
    if (this.state.username != '') {
      requestData.filters.name = this.state.username
    }
    if (this.state.email != '') {
      requestData.filters.email = this.state.email
    }
    if (this.state.username == '' && this.state.email == '' && this.state.pageInfo.pages <= 1) {
      this.getUserList();
    } else {
      let userListApi = await axios.post(global.restApiURL + 'customRest/getSearchResult', JSON.stringify(requestData));
      this.setState({
        userList: userListApi.data.data,
        pageInfo: userListApi.data.pageInfo,
        search: true
      });
    }
  }

  handleChange = (e) => {
    if(e.target.value === ""){
      this.setState({ listNext : 1 });
    }
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  }

  resetButton = () => {
    this.setState({ email: '', username: '', search : false, listNext : 1 });
    this.getUserList();
  }

  render() {
    return (
      <div className="hold-transition sidebar-mini text-sm">
        <div className="wrapper">
          <Topnav />
          <div className="content-wrapper">
            <section class="content-header"></section>
            <div className='row'>
              <div className='col-md-2'>
                <AdminSideNav />
              </div>
              <div className='col-md-10'>
                <UserListing changePagination={this.updateListNext} userList={this.state.userList} rolesList={this.state.rolesList} resetButton={this.resetButton} stateSattlement={this.stateSattlement} getUserList={this.getUserList} handleChange={this.handleChange} searchResult={this.searchResult} username={this.state.username} email={this.state.email} wholeState={this.state}/>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}
