
// export function login ()
// {
//     let url = global.restApiURL+'auth' ;
//     return url ;
// }

// export function classDefinitionURLGen ( classID )
// {
//     let classDefinitionURL = global.restApiURL + 'webservice/rest/class/id/' + classID + '?apikey=' + sessionStorage.getItem('__CL_TOKEN__') ;
//     return classDefinitionURL ;


// }

// export function searchObjectURLGen ( className )
// {
//     let searchObjectURL = global.restApiURL + 'webservice/rest/object-list?apikey=' + sessionStorage.getItem('__TOKEN__') + '&limit=1&objectClass=' + className +'&q={"client_id":"' + sessionStorage.getItem('clientId')+'"}' ;
//     return searchObjectURL ;
// }

// export function objectDataFetcherURLGen ( objID )
// {
//     let objectDataFetcherURL = global.restApiURL + 'webservice/rest/object/id/' + objID + '?apikey=' + sessionStorage.getItem('__TOKEN__') ;
//     return objectDataFetcherURL ;
// }

// export function saveClassObjectURLGen ()
// {
//     let saveClassObjectURL = global.restApiURL+'webservice/rest/object?apikey='+ sessionStorage.getItem('__TOKEN__') ;
//     return saveClassObjectURL ;
// }

// export function recentTop10ClientsURLGen ()
// {
//     let recentTop10ClientsURL = global.restApiURL + "customRest/getTop10ClientsListing" ;
//     return recentTop10ClientsURL ;
// }

// export function customURLGen ( urlFor )
// {
//     let customURL = '' ;

//     switch(urlFor) 
//     {
//         case 'recoverPassword' :
//             customURL = "https://api.chipss.org/customRest/recoverPassword" ;
//         break;
//         case 'recent10Clients' :
//             customURL = global.restApiURL + "customRest/getTop10ClientsListing" ;
//         break;
//         case 'clientList' :
//             customURL = global.restApiURL+'customRest/getClientsListing' ;
//         break;
        
//         default :
//             customURL = "" ;
//         break;
//     }

//     return customURL ;
// }


// export function has_access_to(component_name){
//     if(Array.isArray(component_name)){
//         let at_least_one_exist = [];
//         component_name.map( component_name => {
//             at_least_one_exist.push(global.is_admin || (!global.is_admin && component_name !== '' && component_name !== undefined && component_name !== null && global.permissions[global['class_to_url'][component_name]['pimcore_path']] && global.permissions[global['class_to_url'][component_name]['pimcore_path']]['view'] === "1"));
//         })
//         return at_least_one_exist.includes(true);
//     }else{
//         return global.is_admin || (!global.is_admin && component_name !== '' && component_name !== undefined && component_name !== null && global.permissions[global['class_to_url'][component_name]['pimcore_path']] && global.permissions[global['class_to_url'][component_name]['pimcore_path']]['view'] === "1")
//     }
    
// }

// export function can_add(component_name){
//     return global.is_admin || (!global.is_admin && component_name !== '' && component_name !== undefined && component_name !== null && global.permissions[global['class_to_url'][component_name]['pimcore_path']] && global.permissions[global['class_to_url'][component_name]['pimcore_path']]['create'] === "1")
// }

// export function can_edit(component_name){
//     //console.log('can_Edit ', component_name, global['class_to_url'][component_name]['pimcore_path'], (global.is_admin || (!global.is_admin && component_name !== '' && component_name !== undefined && component_name !== null && global.permissions[global['class_to_url'][component_name]['pimcore_path']]['save'] === "1")))
//     return global.is_admin || (!global.is_admin && component_name !== '' && component_name !== undefined && component_name !== null && global.permissions[global['class_to_url'][component_name]['pimcore_path']] && global.permissions[global['class_to_url'][component_name]['pimcore_path']]['save'] === "1")
// }


// export function can_view_version(component_name){
//     //console.log('can_Edit ', component_name, global['class_to_url'][component_name]['pimcore_path'], (global.is_admin || (!global.is_admin && component_name !== '' && component_name !== undefined && component_name !== null && global.permissions[global['class_to_url'][component_name]['pimcore_path']]['save'] === "1")))
//     return global.is_admin || (!global.is_admin && component_name !== '' && component_name !== undefined && component_name !== null && global.permissions[global['class_to_url'][component_name]['pimcore_path']] && global.permissions[global['class_to_url'][component_name]['pimcore_path']]['versions'] === "1")
// }



export function login ()
{
    let url = global.restApiURL+'auth' ;
    return url ;
}

export function classDefinitionURLGen ( classID )
{
    let classDefinitionURL = global.restApiURL + 'webservice/rest/class/id/' + classID + '?apikey=' + sessionStorage.getItem('__CL_TOKEN__') ;
    return classDefinitionURL ;
}

export function searchObjectURLGen ( className )
{
    let searchObjectURL = global.restApiURL + 'webservice/rest/object-list?apikey=' + sessionStorage.getItem('__TOKEN__') + '&limit=1&objectClass=' + className +'&q={"client_id":"' + sessionStorage.getItem('clientId')+'"}' ;
    return searchObjectURL ;
}

export function objectDataFetcherURLGen ( objID )
{
    let objectDataFetcherURL = global.restApiURL + 'webservice/rest/object/id/' + objID + '?apikey=' + sessionStorage.getItem('__TOKEN__') ;
    return objectDataFetcherURL ;
}

export function saveClassObjectURLGen ()
{
    let saveClassObjectURL = global.restApiURL+'webservice/rest/object?apikey='+ sessionStorage.getItem('__TOKEN__') ;
    return saveClassObjectURL ;
}

export function recentTop10ClientsURLGen ()
{
    let recentTop10ClientsURL = global.restApiURL + "customRest/getTop10ClientsListing" ;
    return recentTop10ClientsURL ;
}

export  function customURLGen ( urlFor )
{
    let customURL = '' ;
    
    switch(urlFor) 
    {
        case 'recoverPassword':
            customURL = "https://api.chipss.org/customRest/recoverPassword" ;
        break;
        case 'recent10Clients' :
            customURL = global.restApiURL + "customRest/getTop10ClientsListing" ;
        break;
        case 'clientList':
            customURL = global.restApiURL+'customRest/getClientsListing';
        break;
        default :
            customURL = "" ;
        break;
    }
    return customURL ;
}


export function has_access_to(component_name){
    if(Array.isArray(component_name)){
        let at_least_one_exist = [];
        component_name.map( component_name => {
            at_least_one_exist.push(global.is_admin || (!global.is_admin && component_name !== '' && component_name !== undefined && component_name !== null && global.permissions[global['class_to_url'][component_name]['pimcore_path']] && global.permissions[global['class_to_url'][component_name]['pimcore_path']]['view'] === "1"));
        })
        return at_least_one_exist.includes(true);
    }else{
        return global.is_admin || (!global.is_admin && component_name !== '' && component_name !== undefined && component_name !== null && global.permissions[global['class_to_url'][component_name]['pimcore_path']] && global.permissions[global['class_to_url'][component_name]['pimcore_path']]['view'] === "1")
    }
    
}

export function can_add(component_name){
    // return global.is_admin || (!global.is_admin && component_name !== '' && component_name !== undefined && component_name !== null && global.permissions[global['class_to_url'][component_name]['pimcore_path']] && global.permissions[global['class_to_url'][component_name]['pimcore_path']]['create'] === "1")
    return 1;
}

export function can_edit(component_name){
    //console.log('can_Edit ', component_name, global['class_to_url'][component_name]['pimcore_path'], (global.is_admin || (!global.is_admin && component_name !== '' && component_name !== undefined && component_name !== null && global.permissions[global['class_to_url'][component_name]['pimcore_path']]['save'] === "1")))
    // return global.is_admin || (!global.is_admin && component_name !== '' && component_name !== undefined && component_name !== null && global.permissions[global['class_to_url'][component_name]['pimcore_path']] && global.permissions[global['class_to_url'][component_name]['pimcore_path']]['save'] === "1")
    return 1;
}


export function can_view_version(component_name){
    //console.log('can_Edit ', component_name, global['class_to_url'][component_name]['pimcore_path'], (global.is_admin || (!global.is_admin && component_name !== '' && component_name !== undefined && component_name !== null && global.permissions[global['class_to_url'][component_name]['pimcore_path']]['save'] === "1")))
    // return global.is_admin || (!global.is_admin && component_name !== '' && component_name !== undefined && component_name !== null && global.permissions[global['class_to_url'][component_name]['pimcore_path']] && global.permissions[global['class_to_url'][component_name]['pimcore_path']]['versions'] === "1")
return 1;
}