import $ from 'jquery';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import { AppTopNav } from './Topnav';
//Tooltip imports
import DataTable, { defaultThemes } from 'react-data-table-component';
import { Link, NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import '../global.js';
import Cssr from './Cssr';
import GadScreening from './GadScreening';
import Modal from './Modals/Modal/index';
import PHQ9 from './PHQ9';
import QLESQSF from './QLESQSF';

import { can_add, can_edit } from '../ReusableComponents/urlGenerator';
import { encryptStorage } from './LocalStorageInterceptor';
import axios from 'axios';
import HOC from '../HOC.js';

var validationObj = {};

var context;


const customStyles = {
  table: {
    style: {
      color: defaultThemes.default.text.primary,
      backgroundColor: defaultThemes.default.background.default,
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#F9F9F9"
    },
  },
  header: {
    style: {
      minHeight: '1px',
    },
  },
  headRow: {
    style: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: defaultThemes.default.divider.default,
      backgroundColor: "#F9F9F9",
      minHeight: "40px"
    },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        fontWeight: "bold",
        fontSize: "0.91rem"
      },
    },
  },
  row: {
    style: {
      minHeight: '20px',
      borderStyle: 'solid',
      borderWidth: '10px',
      borderColor: defaultThemes.default.divider.default,
    }
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        // borderStyle: 'solid',
        // borderWidth: '1px',
        // borderColor: defaultThemes.default.divider.default,
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        fontSize: '0.9rem'
      },
    },
  },
};


class HealthScreening extends Component {
  constructor() {
    super();

    context = this;

    this.state = {
      isSaved : false,
      showFormModal: false,
      currentForm: '',
      healthScreeningHistoryData: [],
      pending: true,
      healthScreeningColumns: [
        {
          name: 'Screening Type',
          selector: (row) => row.type,
          sortable: true,

        },
        {
          name: 'Interviewer',
          selector: (row) => row.interviewer,
          sortable: true,
        },
        {
          name: 'Done on',
          selector: (row) => row.updated_on,
          sortable: true,
          cell: (row) => {
            return moment.unix(row.updated_on).format("MM/DD/YYYY hh:mm a");
          },
        },
        {
          name: 'Score',
          selector: (row) => row.score,
          sortable: false,
        },
        {
          name: 'Details',
          sortable: false,
          cell: (row) => (
            <div>
              <Link to="#"><i id={row.id} lang="none" className="fas fa-eye" aria-hidden="true" data-toggle="tooltip" title="view" onClick={this.showFormModal({ name: row.type, obj_id: row.id })}></i></Link>
            </div>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ]
    };
  }


  getModal(form = '') {
    var DataModal = {};
    if (this.state.currentForm !== '') {
      form = this.state.currentForm;
    }
    switch (form) {
      case 'C-SSRS':
        break;

      case 'PHQ-9':
        break;

      case 'Q-LES-Q-SF':
        break;

      case 'GAD-Screening':
        return DataModal = {

          experience_worry: '',
          wry_excess_in_freq: '',
          wry_difficult_to_ctrl: '',
          wry_excess_minor_things: '',
          wry_excess_freq_topics: '',
          in_6m_worry_more: '',
          restless_or_keyed_up: '',
          irritability: '',
          difficult_concentrating: '',
          easily_fatigued: '',
          con_mind_blank: '',
          muscle_tension: '',
          wry_symptoms_interfere: '',
          wry_symptoms_bother: '',
          client_id: '',
          version: '',
        };
        break;
      default: return DataModal;
    }
  }



  showFormModal = (form) => (event) => {
    this.setState({ isSaved : false});
    let form_class = '';
    let heading = '';
    let sub_heading = '';
    let pimClass = ';'
    let obj_id = 0;
    if (form.obj_id) {
      obj_id = form.obj_id;
    }
    switch (form.name) {
      case 'C-SSRS': form_class = <Cssr obj_id={obj_id} onRef={ref => (this.childForm = ref)} onModalClose = {this.onModalClose} getHealthScreeningsListing = {this.getHealthScreeningsListing} />;
        heading = 'C-SSRS';
        sub_heading = 'Columbia - Suicide Severity Rating Scale';

        break;

      case 'PHQ-9': form_class = <PHQ9 obj_id={obj_id} onRef={ref => (this.childForm = ref)} onModalClose = {this.onModalClose}
      getHealthScreeningsListing = {this.getHealthScreeningsListing}/>;
        heading = 'PHQ9 Information';
        sub_heading = 'Over the last 2 weeks, how often have you been bothered by any of the following problems?';
        break;

      case 'Q-LES-Q-SF': form_class = <QLESQSF obj_id={obj_id} onRef={ref => (this.childForm = ref)} onModalClose = {this.onModalClose} 
      getHealthScreeningsListing = {this.getHealthScreeningsListing}/>;
        heading = 'Q-LES-Q-SF';
        sub_heading = 'Quality of Life Enjoyment and Satisfaction Questionnaire – Short Form';
        break;

      case 'GAD Screening': form_class = <GadScreening obj_id={obj_id} onRef={ref => (this.childForm = ref)} onModalClose = {this.onModalClose} getHealthScreeningsListing = {this.getHealthScreeningsListing}  />;
        heading = 'GAD Screening Tool';
        sub_heading = 'General Anxiety Disorder';
        pimClass = 'GADScreeningTool';
        break;

      default: form_class = <Cssr />;
    }
    context.setState({
      currentForm: form.name,
      currentFormClass: form_class,
      currentHeading: heading,
      pimClass: pimClass,
      currentSubHeading: sub_heading,
      showFormModal: true
    })
  }

  onModalClose() {
    sessionStorage.removeItem('Obectkey');
    sessionStorage.removeItem('ObectID');
    context.setState({
      showFormModal: false
    })
  }

  handleFormDetails = (id) => {

  }

  onFormClose = () => {
    context.getHealthScreeningsListing();
    context.setState({ showFormModal: false })
  }

  componentDidMount() {
    var prevExist = false;
    sessionStorage.setItem('ObectID', '');
    this.getHealthScreeningsListing();
  }

  getHealthScreeningsListing = () => {
    if (sessionStorage.getItem('clientId') !== '') {
      let getHealthScreeningListBody = {
        apikey: sessionStorage.getItem('__TOKEN__'),
        client_id: sessionStorage.getItem('clientId'),
      };
      axios.post(global.restApiURL + 'customRest/getHealthScreeningList', JSON.stringify(getHealthScreeningListBody))
        .then(res => {
          if (res.data.success && res.status==200) {
            // const response = res.data.data.elements;
            setTimeout( ()=> {
              context.setState({
                healthScreeningHistoryData: context.parseHealthScreeningHistory(res.data.data),
                pending: false
              });
            }, 0)
            // this.overallResponseCalculator();   
          }
          else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray['out'];
          }else {
            alert(res.msg);
          }
        })
        .catch(err => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        }
      )
    }
  }

  parseHealthScreeningHistory(historyDataArray) {
    let parsedData = [];
    parsedData = historyDataArray.length > 0 && historyDataArray.map((item, i) => {
      var op = {};
      item.map((val) => {
        op[val.name] = val.value;
      })
      return op;
    }, this);
    return parsedData;
  }

  save = (event) => {
    event.preventDefault();
    this.setState({ isSaved : true});
    $('form').submit();
    setTimeout(() => {
      context.setState({
        showFormModal: false
      });
      setTimeout(() => {
        this.getHealthScreeningsListing();
      }, 500); 
    }, 1000); 
  };
  
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  //Handles save and save proceed buttons
  handleId = (e) => {
    $('#clicked').val(e.target.value);
    $('#clicked').val(e.currentTarget.value);
  }

  render() {
    // let form = '';
    // sessionStorage.setItem('formname', 'Health Screenings');
    // $(document).ready(function () {
    //   $('form').validate({
    //     submitHandler: function (form) {
          // console.log(1);
          if(context.childForm != undefined){
            context.childForm.save();
            // window.location.href = ""; //`${global.traversArray["health_screenings"]}`;
          }
          // else{
            
          // }
    //     },
    //     errorElement: 'p',
    //     errorPlacement: function (error, element) {
    //       error.addClass('invalid-feedback');
    //       element.closest('.form-group').append(error);
    //       element.parents('tr').find('td:first-child').append(error);
    //       element.parents('tr').addClass('health_screening_errors');
    //     },
    //     highlight: function (element, errorClass, validClass) {
    //       $(element).addClass('is-invalid');
    //     },
    //     unhighlight: function (element, errorClass, validClass) {
    //       $(element).removeClass('is-invalid');
    //       $(element).parents('tr').removeClass('health_screening_errors');
    //     }
    //   });
    // });

    return (
      <div className="hold-transition sidebar-mini text-sm">
        <ReactTooltip place="right" type="dark" effect="solid" />
        <div className="wrapper">
          <AppTopNav />
          <div className="content-wrapper">
            <Patientinfoheader clientId={sessionStorage.getItem('clientId')} dob={encryptStorage.getItem('dob')} email={encryptStorage.getItem('email')} phone={encryptStorage.getItem('phone')} peer_support_specialist={encryptStorage.getItem('peer_support_specialist')} next_followup_date={encryptStorage.getItem('month_1_followup_date')} intake_date={encryptStorage.getItem('intake_date')} initiated_by={encryptStorage.getItem('last_updated_by')} formname={sessionStorage.getItem('formname')} client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')} />
            <section className="content">
              <div className="row">
                <Sidenav />
                <div className="container-fluid col-md-9 col-lg-10">
                  <div className="card card-default">
                    <div className="card-header">
                      <p className="lead float-left"><b className="text-green">Health Screenings </b><small></small></p>
                      <div className="dropdown float-sm-right">
                        {can_add('CSSR') ? <button type="button" className="btn btn-success dropdown-toggle btnmargin" data-toggle="dropdown" aria-expanded="false">
                          Add New
                        </button> : ''}
                        <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() =>
                          this.props.navigate(`${global.traversArray['health_screenings']}`)
                          }
                           >
                          <img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                        <div className="dropdown-menu " x-placement="bottom-start" >
                          <a className="dropdown-item" data-toggle="modal" data-target="#cssr" href="#cssr" onClick={
                            this.showFormModal({ name: 'C-SSRS' })}>C-SSRS</a>
                          <a className="dropdown-item" data-toggle="modal" data-target="#gadscreeningtool" href="#gadscreeningtool" onClick={this.showFormModal({ name: 'GAD Screening' })}>GAD Screening</a>
                          <a className="dropdown-item" data-toggle="modal" data-target="#phq9" href="#phq9" onClick={this.showFormModal({ name: 'PHQ-9' })}>PHQ-9</a>
                          <a className="dropdown-item" data-toggle="modal" data-target="#qlesqsf" href="#qlesqsf" onClick={this.showFormModal({ name: 'Q-LES-Q-SF' })}>Q-LES-Q-SF</a>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                    <DataTable
                      className='table_Data'
                        columns={context.state.healthScreeningColumns}
                        data={context.state.healthScreeningHistoryData}
                        customStyles={global.customTableStyles}
                        striped={true}
                        progressPending={this.state.pending}
                        pagination ={ context.state.healthScreeningHistoryData ? true : false}
                        paginationComponentOptions={global.paginationComponentOptions}
                        highlightOnHover
                        pointerOnHover
                        noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                      />
                    </div>
                    <div className="card-footer float-sm-right">
                      <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() => 
                        this.props.navigate(`${global.traversArray['health_screenings']}`)
                        }><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Modal
          showOverlay={true}
          show={this.state.showFormModal}
          onClose={this.onFormClose}
          disableOverlayClick={true}
          showCloseButton={true}
          size="lg"
          className="modal-lg modal-dialog"
          style={{ lineHeight: "1.5", letterSpacing: "inherit" }}
        >
          <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
            <Modal.Title>
              <div className="row" >
                <div className="col-sm-7">
                  <p className="lead float-left"><b className="text-green">{this.state.currentHeading} </b> <small>{this.state.currentSubHeading}</small></p>
                </div>
                <div className="col-md-5 ">
                  {this.state.currentForm === "PHQ-9" ? (
                    <div className="col-md-8" style={{ float: "left", textAlign: "right" }}>
                      <NavLink className="float-right linkpadding" to="/dist/assets/phq9.pdf" target="_blank">View Instructions</NavLink>
                    </div>) : ''}
                  {this.state.currentForm === "GAD Screening" ? (
                    <div className="col-md-8" style={{ float: "left", textAlign: "right" }}>
                      <NavLink className="float-right linkpadding" to="/dist/assets/GAD.pdf" target="_blank">View Instructions</NavLink>
                    </div>) : ''}
                  {this.state.currentForm === "Q-LES-Q-SF" ? (
                    <div className="col-md-8" style={{ float: "left", textAlign: "right" }}>
                      <NavLink className="float-right linkpadding" to="/dist/assets/qlesqsf.pdf" target="_blank">View Instructions</NavLink>
                    </div>) : ''}
                  {can_edit('CSSR') ? <button className="btn btn-success float-right" id="saveButton" type="button" onClick={this.save} disabled={this.state.isSaved} ><i className="fas fa-save"></i>&nbsp;Save</button> : ''}
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <fieldset disabled={!can_edit('CSSR')} >
             {this.state.currentFormClass}
            </fieldset>
          </Modal.Body>
          <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
            <div className="row">
              <div className="col-md-12">
                <div className="justify-content-between">
                  <button className="btn btn-default float-left" id="closeButton" onClick={this.onModalClose} type="button">Close</button>
                  {can_edit('CSSR') ? <button className="btn btn-success float-right btnmargin" type="button" onClick={this.save}  disabled={this.state.isSaved}><i className="fas fa-save"></i>&nbsp;Save</button> : ""}
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        <Footer />
      </div>
    );
  }
}

export default  HOC(HealthScreening);