import React, { Component } from "react";
// import "./dashboard.css"
import axios from "axios";
import { Link, NavLink } from "react-router-dom";
import "../global.js";
import Footer from "./Footer";
import Patientinfoheader from "./Patientinfoheader";
import Topnav, { AppTopNav } from "./Topnav";

import DataTable, { defaultThemes } from "react-data-table-component";

import moment from "moment-timezone";
import { has_access_to } from "../ReusableComponents/urlGenerator";
import { Alert } from "./toastify";

import { date_validate } from "../ReusableComponents/globalFunctions";

import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { encryptStorage } from "./LocalStorageInterceptor.js";

let context;
const customStyles = {
  table: {
    style: {
      color: defaultThemes.default.text.primary,
      backgroundColor: defaultThemes.default.background.default,
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#F9F9F9",
    },
  },
  header: {
    style: {
      minHeight: "1px",
    },
  },
  headRow: {
    style: {
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: defaultThemes.default.divider.default,
      backgroundColor: "#F9F9F9",
      minHeight: "40px",
    },
  },
  headCells: {
    style: {
      borderRightStyle: "solid",
      borderRightWidth: "1px",
      borderRightColor: defaultThemes.default.divider.default,
      fontWeight: "bold",
      fontSize: "0.91rem",
    },
  },
  row: {
    style: {
      minHeight: "20px",
      borderRightStyle: "solid",
      borderRightWidth: "10px",
      borderRightColor: defaultThemes.default.divider.default,
    },
  },
  cells: {
    style: {
      borderLeftStyle: "solid",
      borderLeftWidth: "1px",
      borderLeftColor: defaultThemes.default.divider.default,
      fontSize: "0.9rem",
    },
  },
};

class Dasboard extends Component {
  constructor() {
    super();
    context = this;
    this.state = {
      upcomingFollowupsData: [],
      previousFollowupsData: [],
      notesData: [],
      referralsList: [],
      assessmentsList: [],
      formStatusData: [],
      isLoading: true,
      selectedForms: [],
      followup_columns: [
        {
          name: "Date",
          selector: (row) => row.date,
          sortable: true,

          cell: (row) => {
            return moment
              .unix(row.date)
              .tz("America/New_York")
              .format("MM/DD/YYYY");
          },
        },
        { 
          name: "Peer Specialist",
          selector: (row) => row.assigned_to,
          sortable: true,
        },
        {
          name: "Mode of contact",
          selector: (row) => row.mode_of_contact,
          sortable: true,
        },
      ],
      referrals_columns: [
        {
          name: "Date",
          selector: (row) => row.date,
          sortable: true,
          cell: (row) => {
            return moment
              .unix(row.date)
              .tz("America/New_York")
              .format("MM/DD/YYYY");
          },
          wrap: true,
        },
        {
          name: "Type",
          selector: (row) => row.referral_type,
          sortable: true,
          wrap: true,
        },
        {
          name: "Agency",
          selector: (row) => row.client_referred_agency,
          sortable: false,
          wrap: true,
        },
      ],

      assessments_columns: [
        {
          name: "Date",
          selector: (row) => row.assessment_date,
          sortable: true,
          cell: (row) => {
            return moment
              .unix(row.assessment_date)
              .tz("America/New_York")
              .format("MM/DD/YYYY");
          },
          wrap: true,
        },
        {
          name: "Assessed Level of Care",
          selector: (row) => row.assessed_level,
          sortable: true,
          wrap: true,
        },
        {
          name: "Placed Level of Care",
          selector: (row) => row.placed_level,
          sortable: true,
          wrap: true,
        },
      ],
      formStatusColmns: [

        {
          name: 'Date',
          selector: 'last_updated_time',
          sortable: true,
          width: '25%',
          className: "check22",
          cell: (row) => {
            return moment.unix(row.last_updated_time).tz("America/New_York").format("MM/DD/YYYY");
          },
          wrap: true

        },
        {
          name: 'Form',
          selector: 'class_name',
          sortable: true,
          width: '25%',
          wrap: true,
          cell: (row) => {
            let det = global.class_to_url[row.class_name];
            return det.name
          },
        },
        {
          name: 'Peer Specialist',
          width: '26%',
          selector: 'last_updated_by',
          sortable: true,
          wrap: true
        },


      ],

    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoading: false }); // After the delay, set isLoading to false to hide the loader
    }, 2000);

    console.log('globals before', global, sessionStorage.getItem('__Res__'));
    this.getPreviousFollowups();
    this.getUpcomingFollowups();
    this.getNotes();
    this.getReferrals();
    this.getAssessmentsList();
    this.getFormStatusList();

    if (!global.is_staff && !global.is_client_inactive) {
      this.setState({
        formStatusColmns: [...this.state.formStatusColmns, {
          name: 'Action',
          sortable: false,
          width: '13%',
          cell: (row) => (
            <div>
              <a href={global.class_to_url[row.class_name]['url']}><i id={row.class_name} lang="none" className="far fa-arrow-alt-circle-right" aria-hidden="true" data-toggle="tooltip" title="View Details" style={{ fontWeight: "400", width: "10%" }}></i></a>
            </div>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        }]
      })
    }



  }

  getPreviousFollowups() {
    if (sessionStorage.getItem("clientId") !== "") {
      var dateTime = new Date();
      var date = moment(dateTime).format("YYYY-MM-DD HH:mm");
      let getFollowupListBody = {
        apikey: sessionStorage.getItem("__TOKEN__"),
        client_id: sessionStorage.getItem("clientId"),
        type: "previous",
        limit: 5,
        date: date,
      };

      axios
        .post(
          global.restApiURL + "customRest/getFollowupsForDashboard",
          JSON.stringify(getFollowupListBody)
        )
        .then((res) => {
          if (res.data.success && res.status == 200) {
            // const response = res.data.data.elements;

            setTimeout(function () {
              context.setState({
                previousFollowupsData: context.parseData(res.data.data),
              });
              console.log(
                "obj fet data : ",
                context.state.previousFollowupsData
              );
            }, 50);

            // this.overallResponseCalculator();
          } else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray["out"];
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        });
    }
  }

  getUpcomingFollowups() {
    if (sessionStorage.getItem("clientId") !== "") {
      var dateTime = new Date();
      var date = moment(dateTime).format("YYYY-MM-DD HH:mm");
      let getFollowupListBody = {
        apikey: sessionStorage.getItem("__TOKEN__"),
        client_id: sessionStorage.getItem("clientId"),
        type: "upcoming",
        limit: 5,
        date: date,
      };

      axios
        .post(
          global.restApiURL + "customRest/getFollowupsForDashboard",
          JSON.stringify(getFollowupListBody)
        )
        .then((res) => {
          if (res.data.success && res.status == 200) {
            // const response = res.data.data.elements;

            setTimeout(function () {
              context.setState({
                upcomingFollowupsData: context.parseData(res.data.data),
              });
              console.log(
                "obj fet data : ",
                context.state.upcomingFollowupsData
              );
            }, 50);

            // this.overallResponseCalculator();
          } else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray["out"];
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        });
    }
  }

  getNotes() {
    if (sessionStorage.getItem("clientId") !== "") {
      let getFollowupListBody = {
        apikey: sessionStorage.getItem("__TOKEN__"),
        client_id: sessionStorage.getItem("clientId"),
        limit: 4,
      };

      axios
        .post(
          global.restApiURL + "customRest/getNotesForDashboard",
          JSON.stringify(getFollowupListBody)
        )
        .then((res) => {
          if (res.data.success && res.status == 200) {
            // const response = res.data.data.elements;

            setTimeout(function () {
              context.setState({
                notesData: context.parseData(res.data.data),
              });
              console.log("obj fet data : ", context.state.notesData);
            }, 50);

            // this.overallResponseCalculator();
          } else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray["out"];
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        });
    }
  }

  handleFormsSelected = (event) => {
    this.setState({ selectedForms: event.selectedRows });
  };

  downloadFormsToPdf = () => {
    if (
      sessionStorage.getItem("clientId") !== "" &&
      this.state.selectedForms.length > 0
    ) {
      let getFollowupListBody = {
        apikey: sessionStorage.getItem("__TOKEN__"),
        client_id: sessionStorage.getItem("clientId"),
        forms: this.state.selectedForms,
      };

      axios
        .post(
          global.restApiURL + "customRest/downloadFormsToPdf",
          JSON.stringify(getFollowupListBody)
        )
        .then((res) => {
          if (res.data.success && res.status == 200) {
            // download files;

            window.location = res.data.file;
            if (res.data.name !== "") {
              delete getFollowupListBody.forms;

              setTimeout(
                () =>
                  axios.post(
                    global.restApiURL + "customRest/deleteTempFile",
                    JSON.stringify(getFollowupListBody)
                  ),
                2000
              );
            }

            // this.overallResponseCalculator();
          } else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray["out"];
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        });
    } else {
      Alert("error", "Please select at least one form to download.");
    }
  };

  getReferrals() {
    if (sessionStorage.getItem("clientId") !== "") {
      let getFollowupListBody = {
        apikey: sessionStorage.getItem("__TOKEN__"),
        client_id: sessionStorage.getItem("clientId"),
        limit: 5,
      };

      axios
        .post(
          global.restApiURL + "customRest/getReferralsList",
          JSON.stringify(getFollowupListBody)
        )
        .then((res) => {
          console.log(res, "getReferralsList--ress");

          if (res.data.success && res.status == 200) {
            // const response = res.data.data.elements;

            setTimeout(function () {
              context.setState({
                referralsList: context.parseData(res.data.data),
              });
            }, 50);

            // res.data.data.slice(0,1).map((obj=>{obj.date}))
            if (res.data.data.length > 0) {
              res.data.data[0].map((obj) => {
                if (obj.name == "date") {
                  console.log(
                    "dtc",
                    date_validate(
                      moment
                        .unix(obj.value)
                        .tz("America/New_York")
                        .format("MM/DD/YYYY"),
                      1
                    ),
                    moment
                      .unix(obj.value)
                      .tz("America/New_York")
                      .format("MM/DD/YYYY")
                  );
                  sessionStorage.setItem(
                    "last_referal_date",
                    moment
                      .unix(obj.value)
                      .tz("America/New_York")
                      .format("MM/DD/YYYY")
                  );
                  encryptStorage.setItem(
                    "month_1_followup_date",
                    date_validate(
                      moment
                        .unix(obj.value)
                        .tz("America/New_York")
                        .format("MM/DD/YYYY"),
                      1
                    )
                  );
                }
              });
            }

            // this.overallResponseCalculator();
          } else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray["out"];
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        });
    }
  }

  handleFollowupClicked = (rowData) => {
    sessionStorage.setItem("viewDetail", rowData.id);
    if (!global.is_client_inactive && has_access_to("Followups")) {
      this.props.navigate("/followup")
      // window.location.href = "/followup";
    }
  };

  handleAssessmentClicked = (rowData) => {
    sessionStorage.setItem("viewDetail", rowData.id);
    if (!global.is_client_inactive && has_access_to("FinalAssessments")) {
      this.props.navigate("/state_assessment")
        }
  };

  handleReferralClicked = (rowData) => {
    sessionStorage.setItem("viewDetail", rowData.id);
    if (!global.is_client_inactive && has_access_to("Referrals")) {
      this.props.navigate("/referrals")
      // window.location.href = "/referrals";
    }
  };

  getFormStatusList() {
    if (sessionStorage.getItem("clientId") !== "") {
      let getFollowupListBody = {
        apikey: sessionStorage.getItem("__TOKEN__"),
        client_id: sessionStorage.getItem("clientId"),
      };

      axios
        .post(
          global.restApiURL + "customRest/getFormsStatusDashboard",
          JSON.stringify(getFollowupListBody)
        )
        .then((res) => {
          if (res.data.success && res.status == 200) {
            // const response = res.data.data.elements;
            setTimeout(function () {
              let forms_updated = [];
              res.data.data.map((forms) =>
                forms_updated.push(forms.class_details)
              );

              context.setState({
                formStatusData: forms_updated,
              });
            }, 50);

            // this.overallResponseCalculator();
          } else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray["out"];
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        });
    }
  }

  getAssessmentsList() {
    if (sessionStorage.getItem("clientId") !== "") {
      let getFollowupListBody = {
        apikey: sessionStorage.getItem("__TOKEN__"),
        client_id: sessionStorage.getItem("clientId"),
        limit: 5,
      };

      axios
        .post(
          global.restApiURL + "customRest/getAssessmentsList",
          JSON.stringify(getFollowupListBody)
        )
        .then((res) => {
          if (res.data.success && res.status == 200) {
            // const response = res.data.data.elements;

            setTimeout(function () {
              context.setState({
                assessmentsList: context.parseData(res.data.data),
              });
            }, 50);

            // this.overallResponseCalculator();
          } else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray["out"];
          } else {
            alert(res.msg);
          }
        })
        .catch((err) => {
          // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        });
    }
  }

  onclickStatusButton = () => {
    this.setState({ showStatusModal: true });
  };

  parseData(historyDataArray) {
    let parsedData = [];

    parsedData =
      historyDataArray.length > 0 &&
      historyDataArray.map((item, i) => {
        var op = {};

        item.map((val) => {
          op[val.name] = val.value;
        });

        // console.log("op : " , op );

        return op;
      }, this);

    // console.log("parsed Data : " , parsedData);

    return parsedData;
  }

  redirectToForm = (href) => {
    sessionStorage.setItem("modalOpen", true);
    console.log("salmanDash" , href )
    this.props.navigate(`/${href}`)
    // window.location.href = global.domain + href;
  };

  goToForms = () => {
    this.props.navigate("/intake-basicinfo");
    // console.log("salman" , this.props.navigate)
  };

  render() {
    const { isLoading } = this.state;
    const conditionalRowStyles = [
      {
        when: (row) => row.width < 770,
        style: {
          width: "90%",
        },
      },
    ];
    console.log("globals", global);
    let referral_list = this.state.referralsList;

    for (let i = 0; i < referral_list.length; i++) {
      let referral = referral_list[i];
      let selected_agencies = [];

      if (
        referral.client_referred_agency &&
        referral.client_referred_agency !== null &&
        referral.client_referred_agency !== undefined &&
        referral.client_referred_agency.length > 0
      ) {
        console.log("agency checking", referral.client_referred_agency);
        selected_agencies = referral.client_referred_agency.map((agency) =>
          agency === "Other (Please Specify Below)"
            ? (agency =
                "Other - " +
                (referral.othr_agency !== "" ? referral.othr_agency : "N/A"))
            : agency
        );
      }

      if (selected_agencies && selected_agencies.length > 0) {
        referral_list[i]["client_referred_agency"] = selected_agencies.map(
          (agency) => (
            <li>
              {agency.length > 20 ? agency.substring(0, 20) + ".." : agency}
            </li>
          )
        );
      }

      if (
        referral_list[i]["client_referred_agency"] &&
        referral_list[i]["client_referred_agency"].length > 2
      ) {
        referral_list[i]["client_referred_agency"] = referral_list[i][
          "client_referred_agency"
        ].slice(0, 2);
        referral_list[i]["client_referred_agency"].push(<li>...</li>);
      }
    }
    console.log("referrals", referral_list);
    return (
      <>
        {isLoading && <Loader />}
        <div className="hold-transition sidebar-mini text-sm">
          <div className="wrapper">
            <AppTopNav />
            <div className="content-wrapper dashboard">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1>Dashboard</h1>
                    </div>

                    <div className="col-sm-6">
                      {sessionStorage.getItem("client_status") === "Active" ? (
                        <Link to="/intake-basicinfo" onClick={this.goToForms} className="btn btn-primary btn-sm float-right">Go to Forms&nbsp;<i className="fas fa-chevron-right"></i></Link>
                        // <p
                        //   onClick={this.goToForms}
                        //   className="btn btn-primary btn-sm float-right"
                        // >
                        //   Go to Forms&nbsp;
                        //   <i className="fas fa-chevron-right"></i>
                        // </p>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div className="col-sm-6">
                      {(global.is_client_inactive) ? '' : (<Link to="/intake-basicinfo" onClick={this.goToForms} className="btn btn-primary btn-sm float-right">Go to Forms&nbsp;<i className="fas fa-chevron-right"></i></Link>)}
                    </div> */}
                  </div>
                </div>
              </section>
              <div style={{ marginTop: "-20px" }}>
                <Patientinfoheader
                  dashboard={true}
                  clientId={sessionStorage.getItem("clientId")}
                  onRef={(ref) => (this.childForm = ref)}
                  dob={encryptStorage.getItem("dob")}
                  email={encryptStorage.getItem("email")}
                  phone={encryptStorage.getItem("phone")}
                  peer_support_specialist={encryptStorage.getItem(
                    "peer_support_specialist"
                  )}
                  next_followup_date={encryptStorage.getItem(
                    "month_1_followup_date"
                  )}
                  intake_date={encryptStorage.getItem("intake_date")}
                  initiated_by={encryptStorage.getItem("last_updated_by")}
                  formname={sessionStorage.getItem("formname")}
                  client_name={
                    encryptStorage.getItem("client_first_name") +
                    " " +
                    encryptStorage.getItem("client_middle_initial") +
                    " " +
                    encryptStorage.getItem("client_last_name")
                  }
                />
              </div>
              <section className="content">
                <div className="row">
                  <section className="col-lg-4 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="card-header  border-0">
                        <h3 className="card-title">
                          <span className="text-green">
                            <i className="fas fa-calendar-check"></i>
                            Upcoming Follow-Ups
                          </span>
                        </h3>
                        <div className="card-tools">
                          {/* {console.log("final test " , global.is_staff + " " + global.is_client_inactive)} */}
                          {/* //  OLD CODE */}
                          {/* {global.is_staff || global.is_client_inactive ? ( */}         
                          {global.is_staff || global.is_client_inactive ? (
                            ""
                          ) : (
                            <div className="card-tools">
                              <i
                                className="fas fa-plus pointer"
                                data-toggle="tooltip"
                                title="Add"
                                onClick={() => this.redirectToForm("followup")}
                              ></i>
                              <Link
                                to="/followup"
                                className="btn btn-tool btn-sm"
                              >
                                <i
                                  className="fas fa-angle-double-right"
                                  data-toggle="tooltip"
                                  title="View All"
                                ></i>
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <DataTable
                          columns={this.state.followup_columns}
                          data={this.state.upcomingFollowupsData}
                          onRowClicked={this.handleFollowupClicked}
                          customStyles={global.customTableStyles}
                          striped={true}
                          className='table_Data'
                                                    highlightOnHover
                          pointerOnHover
                          noDataComponent={
                            <div className="no_record_found">
                              {global.no_record_found_text}
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </section>
                  <section className="col-lg-4 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="card-header  border-0">
                        <h3 className="card-title">
                          <span className="text-green">
                            <i className="fas fa-calendar-check"></i>
                            Past Follow-Ups
                          </span>
                        </h3>
                        <div className="card-tools">
                          {global.is_staff || global.is_client_inactive ? (
                            ""
                          ) : (
                            <Link
                              to="/followup"
                              className="btn btn-tool btn-sm"
                            >
                              <i
                                className="fas fa-angle-double-right"
                                data-toggle="tooltip"
                                title="View All"
                              ></i>
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <div className="table-responsive">
                          <DataTable
                            columns={this.state.followup_columns}
                            data={this.state.previousFollowupsData}
                            onRowClicked={this.handleFollowupClicked}
                            customStyles={global.customTableStyles}
                            striped={true}
                            className='table_Data'
                            highlightOnHover
                            pointerOnHover
                            noDataComponent={
                              <div className="no_record_found">
                                {global.no_record_found_text}
                              </div>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="col-lg-4 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="card-header border-0">
                        <h3 className="card-title">
                          <span className="text-green">
                            <i className="fas fa-list"></i>
                            Forms
                          </span>
                        </h3>
                        <div className="card-tools">
                          {!global.is_staff ? (
                            <i
                              className="fas fa-download pointer"
                              data-toggle="tooltip"
                              title="Download"
                              onClick={this.downloadFormsToPdf}
                            ></i>
                          ) : (
                            ""
                          )}
                          {global.is_staff || global.is_client_inactive ? (
                            ""
                          ) : (
                            <a href="" className="btn btn-tool btn-sm">
                              {/* <i className="fas fa-angle-double-right" data-toggle="tooltip" title="View All"></i> */}
                            </a>
                          )}
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <div
                          style={{
                            marginBottom: "10px",
                            overflowY: "scroll",
                            maxHeight: "280px",
                          }}
                        >
                          <DataTable
                            columns={this.state.formStatusColmns}
                            data={this.state.formStatusData}
                            customStyles={global.customTableStyles}
                            striped={true}
                            overflowY={true}
                            overflowX={true}
                            className='table_Data'
                            selectableRows={!global.is_staff}
                            onSelectedRowsChange={this.handleFormsSelected}
                            highlightOnHover
                            pointerOnHover
                            responsive={true}
                            conditionalRowStyles={conditionalRowStyles}
                            noDataComponent={
                              <div className="no_record_found">
                                {global.no_record_found_text}
                              </div>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="col-lg-4 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="card-header border-0">
                        <h3 className="card-title">
                          <span className="text-green">
                            <i className="fas fa-prescription"></i>
                            Referrals
                          </span>
                        </h3>
                        {global.is_staff || global.is_client_inactive ? (
                          ""
                        ) : (
                          <div className="card-tools">
                            <i
                              className="fas fa-plus pointer"
                              data-toggle="tooltip"
                              title="Add"
                              onClick={() => this.redirectToForm("referrals")}
                            ></i>
                            <Link
                              to="/referrals"
                              className="btn btn-tool btn-sm"
                            >
                              <i
                                className="fas fa-angle-double-right"
                                data-toggle="tooltip"
                                title="View All"
                              ></i>
                            </Link>
                          </div>
                        )}
                      </div>
                      <div className="card-body p-0">
                        <DataTable
                          columns={this.state.referrals_columns}
                          data={referral_list}
                          customStyles={global.customTableStyles}
                          striped={true}
                          onRowClicked={this.handleReferralClicked}
                          className='table_Data'
                          highlightOnHover
                          pointerOnHover
                          noDataComponent={
                            <div className="no_record_found">
                              {global.no_record_found_text}
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </section>
                  <section className="col-lg-4 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="card-header border-0">
                        <h3 className="card-title">
                          <span className="text-green">
                            <i className="fas fa-file-pdf"></i>State Assessment
                            Forms
                          </span>
                        </h3>
                        {global.is_staff || global.is_client_inactive ? (
                          ""
                        ) : (
                          <div className="card-tools">
                            <i
                              className="fas fa-plus pointer"
                              data-toggle="tooltip"
                              title="Add"
                              onClick={() =>
                                this.redirectToForm("state_assessment")
                              }
                            ></i>
                            < NavLink
                              to="/state_assessment"
                              className="btn btn-tool btn-sm"
                            >
                                                            <i
                                className="fas fa-angle-double-right"
                                data-toggle="tooltip"
                                title="View All"
                              ></i>
                            </NavLink>
                          </div>
                        )}
                      </div>
                      <div className="card-body p-0">
                        <DataTable
                          columns={this.state.assessments_columns}
                          data={this.state.assessmentsList}
                          customStyles={global.customTableStyles}
                          striped={true}
                          onRowClicked={this.handleAssessmentClicked}
                          className='table_Data'
                          highlightOnHover
                          pointerOnHover
                          noDataComponent={
                            <div className="no_record_found">
                              {global.no_record_found_text}
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </section>
                  <section className="col-lg-4 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">
                          <span className="text-green">
                            <i className="far fa-clipboard"></i>
                            Notes
                          </span>
                        </h3>
                        {global.is_staff || global.is_client_inactive ? (
                          ""
                        ) : (
                          <div className="card-tools">
                            <i
                              className="fas fa-plus pointer"
                              data-toggle="tooltip"
                              title="Add"
                              onClick={() => this.redirectToForm("notes")}
                            ></i>
                            <Link to="/notes" className="btn btn-tool btn-sm">
                              <i
                                className="fas fa-angle-double-right"
                                data-toggle="tooltip"
                                title="View All"
                              ></i>
                            </Link>
                          </div>
                        )}
                      </div>
                      <div className="card-body" style={{ minHeight: "291px" }}>
                        {this.state.notesData.length > 0 ? (
                          this.state.notesData.map((noteData) => (
                            <div id="accordion">
                              <div className="card">
                                <div className="card-header">
                                  <div
                                    className="row "
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    data-target={`#key` + noteData.id}
                                  >
                                    <div className="col-md-6">
                                      <span className="float-left text-accordain">
                                        {noteData.updated_by}
                                      </span>
                                    </div>
                                    <div className="col-md-6">
                                      <span className="float-right text-gray">
                                        {noteData.updated_on}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  id={`key` + noteData.id}
                                  className="panel-collapse collapse"
                                >
                                  <div className="card-body">
                                    {noteData.note}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="sc-fzXfMC  dashboard-table notes_dashboard_section">
                            <div class="no_record_found">No record found</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <Footer sidebar={false} />
          </div>
        </div>
      </>
    );
  }
}
export function Appwithdashboard(props) {
  const navigate = useNavigate();
  return <Dasboard navigate={navigate}></Dasboard>;
}
export default Dasboard;