
import React from 'react';
import { Outlet, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { updateGlobalState } from '../global.js';



const useUpdateGlobalStateOnNavigation = () => {
    const location = useLocation();

    React.useEffect(() => {
        updateGlobalState();
    }, [location]);
};


const ProtectedRoutes = () => {
    useUpdateGlobalStateOnNavigation()
    const navigate = useNavigate();
    const token = sessionStorage.getItem('__TOKEN__');
    const apiKey = sessionStorage.getItem('apikey');
    const isAuthenticated = token; 
    const client_id_check = sessionStorage.getItem('clientId') !== '' && sessionStorage.getItem('clientId') === null && sessionStorage.getItem('clientId') !== undefined && isAuthenticated;

  
//   React.useEffect(() => {
    //     if (client_id_check) {
    //         setTimeout(() => {
    //             navigate("/myclients");
    //         }, 0);
    //     }
    // }, []);
    return (
        isAuthenticated ? <Outlet /> : <Navigate to="/" />
    );
};

export default ProtectedRoutes;

