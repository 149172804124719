import React, { Component } from 'react';
import $ from 'jquery';
import Topnav from './Topnav';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';
import moment from 'moment';
import InputMask from 'react-input-mask';
import { validationParser, disable_button } from '../ReusableComponents/validationParser2';
import { selectOptionsGenerator } from '../ReusableComponents/OptionsGenerator';
import { can_edit } from '../ReusableComponents/urlGenerator';
import { InputTextField, LabelField } from '../ReusableComponents/LabelnInput'
import ConfirmBeforeLeave from './ConfirmBeforeLeave';
import { Alert } from "./toastify";
import ReactTooltip from 'react-tooltip';
import LocationSearchInput from '../Components/AddressAutocomplete';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { encryptStorage } from './LocalStorageInterceptor';
import HOC from '../HOC';
import { showSuccessToast } from './toastService';

const axios = require('axios').default;

var validationObj = "";
var context = "";

class Hangout extends Component {
  constructor() {
    super();
    context = this;
    this.handleId = this.handleId.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.getTimeStamp = this.getTimeStamp.bind(this);
    var now = new Date();
    var start_date = moment().subtract(29, 'days');
    var end_date = moment();
    this.state = {
      errorMessage: '',
      locationTypeErrorMsg: "",
      startDateintakedate: new Date,
      month1followupdate: new Date(now.getFullYear(), now.getMonth() + 1, now.getDate()),
      sixmonthfollow: new Date(now.getFullYear(), now.getMonth() + 6, now.getDate()),
      dob: '',
      colSize: "col-md-6",
      startDate: [start_date],
      endDate: [end_date],
      hangoutdata: this.getModal(),
      required: this.getModal(),
      labelText: this.getModal(),
      tooltips: this.getModal(),
      tooltipVisibility: this.getModal(),
      stateOptions: [],
      locationTypeOptions: [],
      leaveMessageOptions: [],
      unSaved: false,
      maxLength: this.getModal(),
    };
  }

  getModal() {
    var hangoutDataModal = {
      location_type: '',
      name: '',
      at_this_location_since_0: '',
      at_this_location_till_0: '',
      at_this_location_since_1: '',
      at_this_location_till_1: '',
      at_this_location_since_2: '',
      at_this_location_till_2: '',
      at_this_location_since_3: '',
      at_this_location_till_3: '',
      at_this_location_since_4: '',
      at_this_location_till_4: '',
      friends_name: '',
      phone: '',
      leave_msg: '',
      address: '',
      city: '',
      state: '', //encryptStorage.getItem('state_lns') && encryptStorage.getItem('state_lns') !== '' ? encryptStorage.getItem('state_lns') : '',
      county: '',
      zip_code: '',
    };
    return hangoutDataModal;
  }

  handleChange = date => {
    this.setState({ startDateintakedate: date });
  };

  handleDataChange = (event) => {
    event.persist();
    var stateCopy = Object.assign({}, this.state);
    stateCopy.hangoutdata[event.target.name] = event.target.value;
    stateCopy.locationTypeErrorMsg = "";
    stateCopy['unSaved'] = true;
    this.setState(stateCopy);
  }

  handleChange_gpi = address => {
    var stateCopy = Object.assign({}, this.state);
    stateCopy.hangoutdata['address'] = address;
    stateCopy.hangoutdata.city = '';
    stateCopy.hangoutdata.state = '';
    stateCopy.hangoutdata.zip_code = '';
    stateCopy.hangoutdata.county = '';
    stateCopy['unSaved'] = true;
    this.setState(stateCopy);
  };

  handleSelect = address => {
    this.handleChange_gpi(address)
    geocodeByAddress(address)
      .then((results) => {
        console.log(results, "result_from_getlant")
        var stateCopy = Object.assign({}, this.state);
        for (var i = 0; i < results[0].address_components.length; i++) {
          if (results[0].address_components[i].types[0] === "locality") {
            stateCopy.hangoutdata['city'] = results[0].address_components[i].long_name;
            stateCopy.hangoutdata['address'] = stateCopy.hangoutdata['address'].replace(results[0].address_components[i].long_name + ',', '');
          }
          if (results[0].address_components[i].types[0] === "administrative_area_level_1") {
            stateCopy.hangoutdata['state'] = results[0].address_components[i].short_name;
            stateCopy.hangoutdata['address'] = stateCopy.hangoutdata['address'].replace(results[0].address_components[i].short_name + ',', '');
          }
          if (results[0].address_components[i].types[0] === "administrative_area_level_2") {
            stateCopy.hangoutdata['county'] = results[0].address_components[i].long_name;
            stateCopy.hangoutdata['address'] = stateCopy.hangoutdata['address'].replace(results[0].address_components[i].long_name + ',', '');
          }
          if (results[0].address_components[i].types[0] === "postal_code") {
            stateCopy.hangoutdata['zip_code'] = results[0].address_components[i].long_name;
            stateCopy.hangoutdata['address'] = stateCopy.hangoutdata['address'].replace(results[0].address_components[i].long_name + ',', '');
          }
        }
        stateCopy['unSaved'] = true;
        this.setState(stateCopy);
      })
      .catch(
        error => console.error('Error', error)
      );
  };

  handlemonth1followupdate = date => {
    this.setState({ month1followupdate: date, unSaved: true });
  };

  handleChangesixmonthfollow = date => {
    this.setState({ sixmonthfollow: date, unSaved: true });
  };

  handleChangedob = date => {
    this.setState({ dob: date, unSaved: true, });
  };

  componentWillMount() {
    //for validation
    axios.get(global.restApiURL + 'webservice/rest/class/id/4?apikey=' + sessionStorage.getItem('__CL_TOKEN__'))
      .then(res => {
        var responce = res.data;
        validationObj = validationParser(responce.data.layoutDefinitions.children[0].children);
        this.createSelectOptions();
        this.setState({
          required: validationObj.mandatory,
          labelText: validationObj.title,
          tooltips: validationObj.tooltip,
          maxLength: validationObj.columnLength,
          tooltipVisibility: validationObj.tooltipVisibility,
        });
      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      }
      )
  }

  componentDidMount() {
    const { navigate } = this.props;
    disable_button('hangoutinfoform');
    var prevExist = false;
    sessionStorage.setItem('responceId', '');
    // for client info
    if (sessionStorage.getItem('clientId') !== '') {
      axios.get(global.restApiURL + 'webservice/rest/object-list?apikey=' + sessionStorage.getItem('__TOKEN__') + '&limit=1&objectClass=HangoutDetails&q={"client_id":"' + sessionStorage.getItem('clientId') + '"}')
        .then(res => {
          const responce = res.data.data; // get the data array instead of object
          if (res.data.total != 0) {
            prevExist = true;
            axios.get(global.restApiURL + 'webservice/rest/object/id/' + responce.id + '?apikey=' + sessionStorage.getItem('__TOKEN__'))
              .then(res => {
                sessionStorage.setItem('responceId', res.data.data.id);
                const responce = res.data.data.elements;
                this.datamapper(responce);
              })
              .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err);
              })
          } else {
            prevExist = false;
          }
        })
        .catch(err => { // log request error and prevent access to undefined state
          // this.setState({ loading: false, error: true });
          console.error(err);
        }
        )
    }
    $.validator.setDefaults({
      submitHandler: function () {
        var jsonData = '';
        if (prevExist === true) {
          jsonData = { 'className': 'HangoutDetails', 'key': sessionStorage.getItem('clientId'), 'id': sessionStorage.getItem('responceId'), 'published': true };
        } else {
          jsonData = { 'className': 'HangoutDetails', 'key': sessionStorage.getItem('clientId'), 'published': true };
        }
        jsonData.elements = [];
        var formData = $("#hangoutinfoform").serializeArray();
        $.each(formData, function () {
          jsonData.elements.push({ name: this.name, value: this.value });
        });
        for (let t = 0; t < context.state.startDate.length; t++) {
          jsonData.elements.push({ name: 'at_this_location_since_' + t, value: context.state.startDate[t] ? moment(context.state.startDate[t]).format("MM/DD/YYYY hh:mm:ss a") : '' });
          jsonData.elements.push({ name: 'at_this_location_till_' + t, value: context.state.endDate[t] ? moment(context.state.endDate[t]).format("MM/DD/YYYY hh:mm:ss a") : '' });
        }
        var json = JSON.stringify(jsonData);
        axios.post(sessionStorage.getItem('hangout_save_url'), json)
          .then((res) => {
            var responce = res.data;
            sessionStorage.setItem('success_msg', "Hangout Details saved successfully")

            if ($('#clicked').val() === 'proceed') {
              const route = `${global.traversArray["hangout"]}`;
              navigate(route);
              showSuccessToast("Hangout Details saved successfully")
              // window.location.href = global.domain + global.traversArray['hangout'];
            } else {
              context.setState({ unSaved: false });
              if (!sessionStorage.getItem('responceId')) {
                sessionStorage.setItem('responceId', responce.id)
              }
              prevExist = true;
              Alert('success', "Hangout Details saved successfully")
            }
          })
          .catch(function (err) {
            console.log(err.message);
          }
          );
      }
    });
  }

  datamapper(arr) {
    let obj = this.getModal();
    $.each(arr, function (index, value) {
      if (value.name === 'address') {
        obj.address = value.value;
      }
      if (value.name === 'location_type') {
        obj.location_type = value.value;
      }
      if (value.name === 'city') {
        obj.city = value.value;
      }
      if (value.name === 'name') {
        obj.name = value.value;
      }
      if (value.name === 'at_this_location_since_0') {
        //obj.at_this_location_since_0= value.value;
        obj.at_this_location_since_0 = moment(value.value).format("MM-DD-YYYY hh:mm:ss a");
      }
      if (value.name === 'at_this_location_till_0') {
        //obj.at_this_location_till= value.value;
        obj.at_this_location_till_0 = moment(value.value).format("MM-DD-YYYY hh:mm:ss a");
      }
      if (value.name === 'at_this_location_since_1') {
        obj.at_this_location_since_1 = value.value;
        obj.at_this_location_since_1 = moment(value.value).format("MM-DD-YYYY hh:mm:ss a");
      }
      if (value.name === 'at_this_location_till_1') {
        //obj.at_this_location_till= value.value;
        obj.at_this_location_till_1 = moment(value.value).format("MM-DD-YYYY hh:mm:ss a");
      }
      if (value.name === 'at_this_location_since_2') {
        obj.at_this_location_since_2 = value.value;
        obj.at_this_location_since_2 = moment(value.value).format("MM-DD-YYYY hh:mm:ss a");
      }
      if (value.name === 'at_this_location_till_2') {
        //obj.at_this_location_till= value.value;
        obj.at_this_location_till_2 = moment(value.value).format("MM-DD-YYYY hh:mm:ss a");
      }
      if (value.name === 'at_this_location_since_3') {
        obj.at_this_location_since_3 = value.value;
        obj.at_this_location_since_3 = moment(value.value).format("MM-DD-YYYY hh:mm:ss a");
      }
      if (value.name === 'at_this_location_till_3') {
        //obj.at_this_location_till= value.value;
        obj.at_this_location_till_3 = moment(value.value).format("MM-DD-YYYY hh:mm:ss a");
      }
      if (value.name === 'at_this_location_since_4') {
        obj.at_this_location_since_4 = value.value;
        obj.at_this_location_since_4 = moment(value.value).format("MM-DD-YYYY hh:mm:ss a");
      }
      if (value.name === 'at_this_location_till_4') {
        //obj.at_this_location_till= value.value;
        obj.at_this_location_till_4 = moment(value.value).format("MM-DD-YYYY hh:mm:ss a");
      }
      if (value.name === 'county') {
        obj.county = value.value;
      }
      if (value.name === 'leave_msg') {
        obj.leave_msg = value.value;
      }
      if (value.name === 'phone') {
        obj.phone = value.value;
      }
      if (value.name === 'state') {
        obj.state = value.value ? value.value : sessionStorage.getItem('state_lns') && sessionStorage.getItem('state_lns') !== '' ? sessionStorage.getItem('state_lns') : '';//value.value;
      }
      if (value.name === 'zip_code') {
        obj.zip_code = value.value;
      }
      if (value.name === 'friends_name') {
        obj.friends_name = value.value;
      }
    });
    let startDates = this.state.startDate;
    let endDates = this.state.endDate;
    for (let t = 0; t < 5; t++) {
      let st = obj['at_this_location_since_' + t] !== 'Invalid date' ? moment(obj['at_this_location_since_' + t], 'MM/DD/YYYY hh:mm:ss a') : undefined;
      let et = obj['at_this_location_till_' + t] !== 'Invalid date' ? moment(obj['at_this_location_till_' + t], 'MM/DD/YYYY hh:mm:ss a') : undefined;
      if (st !== undefined) {
        startDates[t] = st;
        endDates[t] = et;
      }
    }
    this.setState({ hangoutdata: obj, startDate: startDates, endDate: endDates, });
    this.createSelectOptions(obj.leave_msg);
  }

  getTimeStamp(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  // handleId = (e) => {
  //   $('#clicked').val(e.target.value);
  //   $('#clicked').val(e.currentTarget.value);
  // }

  handleId = (e) => {
    let navigateForward = this.shouldNavigateFunction();
    if (!navigateForward) {
      e.preventDefault();
      // this.setState({ errorMessage: 'Please enter at least 10 digits.' });
      return;
    } else {
      $('#clicked').val(e.target.value);
      $('#clicked').val(e.currentTarget.value);
    }
  }

  shouldNavigateFunction = () => {
    let navigateForward = false;
    if(this.state.hangoutdata.phone?.length < 14 && this.state.hangoutdata.phone?.length > 0){
      //this.state.hangoutdata.location_type === "" || 
      // if (this.state.hangoutdata.location_type === "") {
      //   this.setState({ locationTypeErrorMsg: 'This field is required.' });
      // }
      if (this.state.hangoutdata.phone?.length < 14 && this.state.hangoutdata.phone?.length > 0) {
        this.setState({ errorMessage: 'Please enter at least 10 digits.' });
      }
    } else {
      navigateForward = true;
    }
    return navigateForward;
  }

  saveChanges(e) {
    //isko b dekhna hai
    let navigateForward = this.shouldNavigateFunction();
    if (!navigateForward) {
      e.preventDefault();
      // this.setState({ errorMessage: 'Please enter at least 10 digits.' });
      // return;
    } else {
      $('#clicked').val('save');
      $('#hangoutinfoform').submit();
    }
  }

  handleEvent(event, picker, index) {
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    startDate[index] = picker.startDate;
    endDate[index] = picker.endDate;
    $('#hangoutinfoform :button').each(function () {
      console.log('clickerd')
      $(this).prop('disabled', false);
    });
    this.setState({ startDate, endDate, unSaved: true });
  }

  createSelectOptions(leavemsg) {
    if (validationObj !== "") {
      this.setState({
        stateOptions: selectOptionsGenerator(validationObj.options.state, this.state.hangoutdata.state),
        locationTypeOptions: selectOptionsGenerator(validationObj.options.location_type, this.state.hangoutdata.location_type),
        leaveMessageOptions: selectOptionsGenerator(validationObj.options.leave_msg, leavemsg),
      });
    }
  }

  handlePhoneChange = (event) => {
    this.setState({ errorMessage: '' });
    if (event.target !== null) {
      let test = event.target.value;
      if (event.target.name === "phone") {
        if (this.state.hangoutdata.phone !== "") {
          this.setState({
            errorMessage: ''
          });
        }
        this.setState(prevState => ({ ...prevState, hangoutdata: { ...prevState.hangoutdata, phone: test }, unSaved: true }));
      }
    }
  }

  AddTime = (id) => {
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if (startDate.length < 5) {
      startDate[id] = undefined;
      endDate[id] = undefined;
      this.setState({ startDate, endDate })
    }
  }

  removeTime = (id) => {
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if (startDate.length >= 2) {
      startDate.splice(id, 1);
      endDate.splice(id, 1);
      this.setState({ startDate, endDate })
    }
  }

  beforeMaskedValueChange2 = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !this.state.hangoutdata.phone.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    return { value, selection };
  }

  render() {
    sessionStorage.setItem('formname', 'Hangout Details');
    let label = [];
    let start = [];
    let end = [];
    for (let e = 0; e < this.state.startDate.length; e++) {
      start[e] = this.state.startDate[e] && this.state.startDate[e].format('MM/DD/YYYY hh:mm A') || '';
      end[e] = this.state.endDate[e] && this.state.endDate[e].format('MM/DD/YYYY hh:mm A') || '';
      label[e] = start[e] + ' - ' + end[e];
      if (start[e] === end[e]) {
        label[e] = start[e];
      }
    }
    let locale = {
      format: 'YYYY-MM-DD',
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Clear',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      firstDay: moment.localeData().firstDayOfWeek(),
    };
    $(document).ready(function () {
      var NumRegex = /^[0-9]+$|^$|^\s$/;
      var cityRegEx = /^[a-zA-Z\s\-']+$/
      $.validator.addMethod('NumRegex', function (value, element, parameter) {
        return value.match(NumRegex);
      }, '');
      $.validator.addMethod('cityRegEx', function (value, element, parameter) {
        return value.match(cityRegEx);
      }, 'Please enter a valid city name');
      $('#hangoutinfoform').validate({
        rules: {
          zip_code: {
            NumRegex: true
          },
          city: {
            // cityRegEx: true
          }
        },
        errorElement: 'span',
        errorPlacement: function (error, element) {
          error.addClass('invalid-feedback');
          element.closest('.form-group').append(error);
        },
        highlight: function (element, errorClass, validClass) {
          $(element).addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).removeClass('is-invalid');
        },
        onkeyup: function (element) {
          $('#zip_code').valid();
        }
      });
    });

    return (
      <div className="hold-transition sidebar-mini text-sm">
        { /*     Start Kuldeep Singh     This was using in dev server when Routing was reloading but now instead of <ConfirmBeforeLeave /> we are using <sidenav /> we are using sidenav By Kuldeep Singh            */}
        {/* <ConfirmBeforeLeave onRef={ref => (this.childForm = ref)} unSaved={context.state.unSaved} onSaveRequest={() => context.saveChanges()} /> */}
        { /*          This was using in dev server when Routing was reloading but now instead of <ConfirmBeforeLeave /> we are using <sidenav /> By Kuldeep Singh     End Kuldeep Singh       */}
        <ReactTooltip place="right" type="dark" effect="solid" />
        <div className="wrapper">
          <Topnav />
          <div className="content-wrapper">
            <Patientinfoheader clientId={sessionStorage.getItem('clientId')} dob={encryptStorage.getItem('dob')} email={encryptStorage.getItem('email')} phone={encryptStorage.getItem('phone')} peer_support_specialist={encryptStorage.getItem('peer_support_specialist')} next_followup_date={encryptStorage.getItem('month_1_followup_date')} intake_date={encryptStorage.getItem('intake_date')} initiated_by={encryptStorage.getItem('last_updated_by')} formname={sessionStorage.getItem('formname')} client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')} />
            <section className="content">
              <div className="row">
                <Sidenav onSaveRequest={() => context.saveChanges()} unSaved={context.state.unSaved} shouldNavigate={this.shouldNavigateFunction} />
                <fieldset disabled={!can_edit('HangoutDetails')} className="container-fluid col-md-9 col-lg-10">
                  <form method="post" id="hangoutinfoform">
                    <div className="card card-default">
                      <div className="card-header">
                        <p className="lead float-left"><b className="text-green">Hangout Details</b> <small>Do you hangout anywhere on a regular basis? </small></p>
                        <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={this.handleId}>
                          <img src="dist/img/save-proceed_icon.png" alt="" /> Save &amp; Proceed </button>
                        <button type="submit" value='save' id="saveButton" className="btn btn-success float-right btnmargin" onClick={this.handleId}> <i className="fas fa-save"></i> Save</button>
                        <input type="hidden" id="clicked" value="" readOnly />
                        <input type="hidden" id="client_id" name="client_id" defaultValue={sessionStorage.getItem('clientId')} />
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-6 col-md-12 float-right">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <LabelField title={this.state.labelText.location_type} mandatory={this.state.required.location_type} name="location_type" tooltip={this.state.tooltips.location_type} id="location_type" />
                                  <select className="custom-select" id="location_type" name="location_type" onChange={this.handleDataChange} value={this.state.hangoutdata.location_type}>
                                    {this.state.locationTypeOptions}
                                  </select>
                                  {/* <span style={{ color: 'red', fontSize: "80%" }}>{this.state.locationTypeErrorMsg !== "" ? this.state.locationTypeErrorMsg : ""}</span>   */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <InputTextField labelText={this.state.labelText.name} placeholder={this.state.labelText.name} type="text" maxLength={this.state.maxLength.name} className="form-control" id="name" name="name" defaultValue={this.state.hangoutdata.name} required={this.state.required.name} tooltipText={this.state.tooltips.name} tooltipVisibility={this.state.tooltipVisibility.name} onChange={this.handleDataChange} />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <LabelField title={this.state.labelText.at_this_location_since_0} mandatory={this.state.required.at_this_location_since_0} name="at_this_location_since_0" tooltip={this.state.tooltips.at_this_location_since_0} id="at_this_location_since_0" />
                                  {this.state.startDate.map((st, index) => (
                                    <>
                                      <div className="row">
                                        <div className="col-md-11 form-group">
                                          <CustomDatePicker
                                            locale={locale}
                                            startDate={this.state.startDate[index]}
                                            endDate={this.state.endDate[index]}
                                            onEvent={(picker, event) => this.handleEvent(picker, event, index)}
                                            label={label[index]}
                                            required={this.state.required.at_this_location_since_0}
                                            input={true}
                                          />
                                        </div>
                                        <div className="col-md-1" style={{ textAlign: 'left' }}>
                                          {index < 4 ? (<i className="fas fa-plus-circle  text-green" onClick={() => this.AddTime(index + 1)}></i>) : ''}
                                          {index > 0 ? (<i className="fas fa-minus-circle ml-1 text-red" onClick={() => this.removeTime(index)}></i>) : ''}
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <InputTextField labelText={this.state.labelText.friends_name} placeholder={this.state.labelText.friends_name} type="text" maxLength={this.state.maxLength.friends_name} className="form-control" id="friends_name" name="friends_name" defaultValue={this.state.hangoutdata.friends_name} required={this.state.required.friends_name} tooltipText={this.state.tooltips.friends_name} tooltipVisibility={this.state.tooltipVisibility.friends_name} onChange={this.handleDataChange} />
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="">
                                  <div className="">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <LabelField title={this.state.labelText.phone} mandatory={this.state.required.phone} name="phone" tooltip={this.state.tooltips.phone} id="phone" />
                                          <InputMask id="phone" name="phone" placeholder={this.state.labelText.phone} className="form-control" mask="(999) 999-9999" maskChar={null} value={this.state.hangoutdata.phone} beforeMaskedValueChange={this.beforeMaskedValueChange2} onChange={this.handlePhoneChange} required={this.state.required.phone} maxLength={this.state.maxLength.phone} />
                                          <span style={{ color: 'red', fontSize: "75%" }}>{this.state.hangoutdata.phone === "" ? "" : this.state.errorMessage}</span>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <LabelField title={this.state.labelText.leave_msg} mandatory={this.state.required.leave_msg} name="leave_msg" tooltip={this.state.tooltips.leave_msg} id="leave_msg" />
                                          <select className="custom-select" id="leave_msg" name="leave_msg" disabled={this.state.hangoutdata.phone?.length !== 14} onChange={this.handleDataChange} maxLength={this.state.maxLength?.leave_msg}>
                                            {this.state.leaveMessageOptions}
                                          </select>
                                          {this.state.hangoutdata.phone === '' ? (<input type="hidden" name="leave_msg" value="" />) : ''}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 float-right">
                            <div className="card">
                              <div className="card-header">
                                <div className="row">
                                  <div className="col-md-8">
                                    <span className="card-title">{this.state.labelText.address}</span>
                                    {/* <h3 className="card-title">{this.state.labelText.address}</h3> */}
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <LocationSearchInput
                                    value={this.state.hangoutdata.address}
                                    onChange={this.handleChange_gpi}
                                    onSelect={this.handleSelect}
                                    placeholder={this.state.labelText.address}
                                    id="address"
                                    name="address"
                                    defaultValue={this.state.hangoutdata.address}
                                    required={this.state.required.address}
                                    tooltipText={this.state.tooltips.address}
                                    tooltipVisibility={this.state.tooltipVisibility.address}
                                  />
                                  {/* <div className="col-md-12 form-group">
                                    <InputTextField placeholder={this.state.labelText.address} type="text" maxLength={this.state.maxLength.address} className="form-control" id="address" name="address" defaultValue={this.state.hangoutdata.address} required={this.state.required.address} tooltipText={this.state.tooltips.address} tooltipVisibility={this.state.tooltipVisibility.address} onChange={this.handleDataChange} />
                                  </div> */}
                                  <div className="col-md-8 form-group">
                                    <InputTextField placeholder={this.state.labelText.city} type="text" maxLength={this.state.maxLength.city} className="form-control" id="city" name="city" defaultValue={this.state.hangoutdata.city} required={this.state.required.city} tooltipText={this.state.tooltips.city} tooltipVisibility={this.state.tooltipVisibility.city} onChange={this.handleDataChange} />
                                  </div>
                                  <div className="col-md-4 form-group">
                                    <select className="custom-select" id="state" name="state" onChange={this.handleDataChange} required={this.state.required.state} value={this.state.hangoutdata.state}>
                                      {this.state.stateOptions}
                                    </select>
                                  </div>
                                  <div className="col-md-8 form-group">
                                    <InputTextField placeholder={this.state.labelText.county} type="text" maxLength={this.state.maxLength.county} className="form-control" id="county" name="county" defaultValue={this.state.hangoutdata.county} required={this.state.required.county} tooltipText={this.state.tooltips.county} tooltipVisibility={this.state.tooltipVisibility.county} onChange={this.handleDataChange} />
                                  </div>
                                  <div className="col-md-4 form-group">
                                    <input type="text" className="form-control" id="zip_code" maxlength="5" name="zip_code" placeholder={this.state.labelText.zip_code} required={this.state.required.zip_code} defaultValue={this.state.hangoutdata.zip_code} onChange={this.handleDataChange} />
                                    {/*<InputTextField placeholder={this.state.labelText.zip_code} type="text"  maxLength={this.state.maxLength.zip_code} className="form-control" id="zip_code" name="zip_code" defaultValue={this.state.hangoutdata.zip_code} required={this.state.required.zip_code} tooltipText={this.state.tooltips.zip_code} tooltipVisibility={this.state.tooltipVisibility.zip_code} onChange={this.handleDataChange}/>*/}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer float-right" >
                        <div className="float-right">
                          <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={this.handleId}>
                            <img src="dist/img/save-proceed_icon.png" alt="" /> Save &amp; Proceed </button>
                          <button type="submit" value='save' className="btn btn-success float-right btnmargin" onClick={this.handleId}> <i className="fas fa-save"></i> Save</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </fieldset>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

class CustomDatePicker extends Component {
  render() {
    return (
      <>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text"><i className="far fa-clock"></i></span>
          </div>

          <div className="datetimepicker_custom">
            <DatetimeRangePicker
              autoUpdateInput={false}
              timePicker
              timePicker12Hour
              showDropdowns
              timePickerSeconds
              locale={this.props.locale}
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              onEvent={this.props.onEvent}
              input={this.props.true}
            >
              <div className="input-group">
                <input type="text" className="form-control" value={this.props.label} id="datetime_picker" required={this.props.required} />
              </div>
            </DatetimeRangePicker>
          </div>
        </div>


      </>
    )
  }
}

export default HOC(Hangout) 
