// import React, { Component } from 'react'
// import { Alert } from '../toastify';
// import DataTable from 'react-data-table-component'
// import { Link } from 'react-router-dom';
// import services from '../../services';
// import ClientListing from '../ClientManagement/clientListing';
// import Modal from '../Modals/Modal';
// const axios = require('axios').default;
// // let userNameForClientList = "";

// export default class UserListing extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             emailregex : /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
//             emailErr : "",
//             editModal: false,
//             checkbox: [],
//             organizationList: [],
//             selectedOrganization: '',
//             selectedOrganizationName:'',
//             submitDisabled:false,
//             columns: [
//                 {
//                     name: 'First Name',
//                     selector: 'firstname',
//                     style: {
//                     },
//                 },
//                 {
//                     name: 'Last Name',
//                     selector: 'lastname',
//                     style: {
//                     },
//                 },
//                 {
//                     name: 'Email',
//                     selector: 'email',
//                     style: {
//                     },
//                 },
//                 {
//                     name: 'Role',
//                     selector: 'role_name',
//                     style: {
//                     },
//                 },
//                 {
//                     name: 'Username',
//                     selector: 'name',
//                     style: {
//                     },
//                 },
//                 {
//                     name: 'Active',
//                     selector: 'active',
//                 },
//                 {
//                     name: 'Action',
//                     cell: (row) => (
//                         <div>
//                             <Link to="#"><i className="fas fa-edit" id={JSON.stringify(row)} aria-hidden="true" data-toggle="tooltip" title="Edit" onClick={(e, row) => this.handleButtonEdit(e, row, 'edit')}></i></Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                             {row.active == "Active" ?
//                                 <Link to="#"><i className="fas fa-user-times" id={row.firstname + ' ' + row.lastname} data-param={JSON.stringify(row)} aria-hidden="true" data-toggle="tooltip" title="Deactivate" onClick={(e) => this.openClientModal(e)}></i></Link> :
//                                 <Link to="#"><i className="fas fa-user-times text-gray" id={row.id} aria-hidden="true" data-toggle="tooltip" title="Activate" onClick={(e, row) => this.activateUser(e, row, 'edit')}></i></Link>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                         </div>
//                     ),
//                     ignoreRowClick: true,
//                     allowOverflow: true,
//                     button: true,
//                 }
//             ],
//             columnsForClientList: [
//                 {
//                     name: 'Client Name',
//                     selector: 'Full_name',
//                     style: {
//                     },
//                 },
//                 {
//                     name: 'Client ID',
//                     selector: 'Client_id',
//                     style: {
//                     },
//                 },
//                 {
//                     name: 'Date of Birth',
//                     selector: 'Dob',
//                     style: {
//                     },
//                 },
//                 {
//                     name: 'Intake Date',
//                     selector: 'Intake_date',
//                     style: {
//                     },
//                 },
//                 {
//                     name: 'Phone',
//                     selector: 'Primary_phone',
//                     style: {
//                     },
//                 },
//                 {
//                     name: 'Email',
//                     selector: 'Primary_email',
//                 },
//                 {
//                     name: 'Last Updated By',
//                     selector: 'Last_updated_by',
//                 },
//                 {
//                     name: 'Peer Support Specialist',
//                     selector: 'PeerSupportSpecialist',
//                     style: {
//                     },
//                 },
//                 // {
//                 //     name: 'Action',
//                 //     cell: (row) => (
//                 //         <div>
//                 //             {/* <Link to="#"><i className="fas fa-edit" id={JSON.stringify(row)} aria-hidden="true" data-toggle="tooltip" title="Edit" onClick={(e, row) => this.handleButtonEdit(e, row, 'edit')}></i></Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
//                 //             {row.published == false ?
//                 //                 <Link to="#"><i className="fas fa-user-times text-gray" id={row.Client_id} aria-hidden="true" data-toggle="tooltip" title="Publish" onClick={(e) => this.publishClient(e)}></i></Link> :
//                 //                 <Link to="#"><i className="fas fa-user-times" id={row.Client_id} aria-hidden="true" data-toggle="tooltip" title="Unpublish" onClick={(e) => this.unPublishClient(e)}></i></Link>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                 //         </div>
//                 //     ),
//                 //     ignoreRowClick: true,
//                 //     allowOverflow: true,
//                 //     button: true,
//                 // }
//             ],
//             reAssignUser: "",
//             user_id: '',
//             first_name: '',
//             last_name: '',
//             email: '',
//             user_name: '',
//             role: '',
//             username: '',
//             clientsModal: false,
//             clientListModal: false,
//             clientList: {},
//             selectedUser: '',
//             selectedUserName: '',
//             selected_user: {},
//             listNext: 1,


//             clientList: [],
//             // search: "",
//             userList: {},
//             // startDateintakedate: '',
//             // dateRange: '',
//             listNextForClientsListing: 1,
//             pageInfo: {
//                 pageString: "",
//                 pages: 0,
//                 records: 0
//             },
//             pageInfoForClients: {
//                 pageString: "",
//                 pages: 0,
//                 records: 0
//             }
//         }
//         this.handleButtonEdit = this.handleButtonEdit.bind(this);
//         this.closeModal = this.closeModal.bind(this);
//     }



//     updateListNext = (pageNum) => {
//         console.log(pageNum);
//         this.setState({
//             listNext: pageNum
//         })
//     }

//     stateSattlement = (a = [], b = "") => {
//         this.setState({
//             clientList: a,
//             pageInfo: b,
//         });
//     }

//     CryptoJSAesDecrypt(passphrase, encrypted_json_string) {
//         const CryptoJS = require("crypto-js");
//         var obj_json = JSON.parse(encrypted_json_string);
//         var encrypted = obj_json.ciphertext;
//         var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
//         var iv = CryptoJS.enc.Hex.parse(obj_json.iv);
//         var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999 });
//         var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
//         return decrypted.toString(CryptoJS.enc.Utf8);
//     }

//     parseResponse = (promise) => {
//         if (promise != true) {
//             return promise.then(data => {
//                 return data;
//             }).catch(err => {
//                 console.log(err);
//             });
//         }
//     };

//     selectReAssignUser = (e) => {
//         this.setState({ reAssignUser: e.target.value });
//     }


//     getOrganizationsList = async () => {
//         axios.post(global.restApiURL + 'customRest/getOrganizationsAllList', JSON.stringify({
//             'apikey': sessionStorage.getItem('__TOKEN__'),
//         }))
//         .then(async (data) => {
//             this.setState({ organizationList: data.data.data });
//         })
//         .catch((error) => {
//             console.error('Error fetching organization list:', error);
//         });
//     }

//     handleButtonEdit(e, row, type = 'add') {
//         this.getOrganizationsList();
//     if (type == 'edit') {
//         let data = JSON.parse(e.target.id);
//         this.setState({ 'user_id': data.id, 'first_name': data.firstname, 'last_name': data.lastname, 'email': data.email, 'user_name': data.name, 'role': data.role_id });
//     }
//     this.setState({ editModal: true });
// }

// closeModal() {
//     this.setState({ 'user_id': '', 'first_name': '', 'last_name': '', 'email': '', 'user_name': '', 'role': '' ,'selectedOrganization':''});
//     this.setState({ editModal: false, clientsModal: false });
// }



    


//     handleChange = (e) => {
//         const { name, value } = e.target;
    
//         if (name === "selectedOrganization") {
//             const selectedOrg = this.state.organizationList.find(function (element) {
//                 return element.id == value;
//               })
//             const selectedOrgName = selectedOrg ? selectedOrg.name : '';
//             this.setState({
//                 selectedOrganization: value,
//                 selectedOrganizationName: selectedOrgName
//             });
//         } else {
//             this.setState({
//                 [name]: value
//             });
//         }
//     }

//     getPeerSupportSpecialistList = async () => {
//         let requestData = {
//             'apikey': sessionStorage.getItem('__TOKEN__'),
//             'organization': sessionStorage.getItem('selected_organization')
//         }
//         let userListApi = await this.parseResponse(services.getUserListing(requestData));
//         userListApi.map((user, index) => {
//             if (user.active == "Inactive") {
//                 userListApi.splice(index, 1);
//             }
//         })
//         this.setState({ userList: userListApi });
//     }

//     addUser = async () => {
//         // Validate required fields
//         if (this.state.first_name === "" || this.state.last_name === "" || this.state.email === "" || this.state.user_name === "" || (this.state.role === "" || this.state.role === "select") || (!global.is_admin && (this.state.selectedOrganization === '' || this.state.selectedOrganization === undefined))) {
//             Alert('error', 'All fields are mandatory')
//             return;
//         }
    
//         this.setState({ submitDisabled: true });
    
//         try {
//             let requestData = {
//                 'apikey': sessionStorage.getItem('__TOKEN__'),
//                 'type': 'user',
//                 'name': this.state.user_name,
//                 'firstName': this.state.first_name,
//                 'Lastname': this.state.last_name,
//                 'Email': this.state.email,
//                 'active': 1,
//                 'rid': this.state.role,
//                 'parentId': '0'
//             };
    
//             // Set organization data based on admin status
//             if (global.is_admin) {
//                 requestData['organization'] = `/Organization/${sessionStorage.getItem('selected_organization')}`;
//                 requestData['organization_id'] = sessionStorage.getItem('organization_id');
//             } else {
//                 requestData['organization'] = `/Organization/${this.state.selectedOrganizationName}`;
//                 requestData['organization_id'] = this.state.selectedOrganization;
//             }
    
//             let response;
//             if (this.state.user_id !== "") {
//                 if ((this.state.role === '' || this.state.role === 'Select') || (!global.is_admin && (this.state.selectedOrganization === '' || this.state.selectedOrganization === undefined))) {
//                     Alert('warning', 'Please select a role and organization');
//                     return;
//                 }
//                 // Editing an existing user
//                 requestData['userId'] = this.state.user_id;
    
//                 response = await this.parseResponse(services.editUser(requestData));
//                 let message = response.message ? 'User not updated' : 'User updated successfully';
//                 let type = response.message ? 'error' : 'success';
//                 Alert(type, message);
//             } else {
//                 // Creating a new user
//                 response = await this.parseResponse(services.addUser(requestData));
    
//                 let message = response.message ? response.response.data.message : 'User created successfully';
//                 let type = response.message ? 'error' : 'success';
//                 Alert(type, message);
//             }
    
//             if (!response.message) {
//                 this.closeModal();
//             }
    
//             this.setState({ 'user_id': '' });
//             this.props.getUserList();
    
//         } catch (error) {
//             console.error('Error:', error);
//             Alert('error', 'An error occurred. Please try again.');
//         } finally {
//             // Re-enable the submit button
//             this.setState({ submitDisabled: false });
//         }
//     };

//     onCheckboxCheck = (e) => {
//         let temparr = [];
//         let checkbox1 = this.state.checkbox;
//         let check = [];
//         let newarr = []

//         e.selectedRows.map(row => {
//             check.push(row.Client_id)
//             if (checkbox1.includes(row.Client_id)) {

//             } else {
//                 checkbox1.push(row.Client_id);
//             }

//         })

//         this.state.clientList.map((e) => {
//             if (check.includes(e.Client_id)) {

//             } else {
//                 temparr.push(e.Client_id)
//             }

//         })

//         checkbox1.map((val) => {
//             if (!temparr.includes(val)) {
//                 newarr.push(val)
//             }
//         })
//         this.setState({ checkbox: newarr });
//     }

//     reAssignClients = async () => {
//         if (this.state.reAssignUser == "" || this.state.reAssignUser == undefined || this.state.reAssignUser == "Select") {
//             // Alert('warning', 'Please select a User for Re-assign To');
//             Alert('warning', 'Please select a User you want to Re-assign To');
//         } else if (!this.state.checkbox.length > 0) {
//             Alert('warning', 'Please select a client');
//         } else if (this.state.checkbox.length > 0 && this.state.reAssignUser != "" && this.state.reAssignUser != undefined) {
//             let requestData = {
//                 'apikey': sessionStorage.getItem('__TOKEN__'),
//                 'client_id': this.state.checkbox,
//                 'username': this.state.reAssignUser
//             }
//             let reAssignClient = axios.post(global.restApiURL + 'customRest/reAssignClient', JSON.stringify(requestData))//await this.props.parseResponse(services.reAssignClients(requestData));
//                 .then(
//                     res => {
//                         this.setState({ clearRows: !this.state.clearRows, clientListModal: false, checkbox: [], reAssignUser: "" });
//                         this.props.changePagination(1);
//                         Alert("success", "Re Assigned Successfully and User deactivated");
//                         if (this.state.selected_user && this.state.selectedUserName) {
//                             this.deActivateUser(this.state.selected_user.id)
//                             this.getClientList(this.state.selectedUserName);
//                         } else {
//                             // alert("AAA");
//                             // this.getClientList(); 
//                             // this.changePagination(1);
//                             // this.paginationDataTable(1);
//                         }
//                     }
//                 )
//                 .catch(
//                     err => {
//                         Alert('warning', 'Something went wrong');
//                         console.log(err);
//                     }
//                 )
//         }
//         // else {
//         //     Alert('warning', 'Please select a client');
//         // }
//     }

//     getClientList = async (e) => {
//         let requestData = {
//             'apikey': sessionStorage.getItem('__TOKEN__'),
//             "is_published": true,
//             'filters': {
//                 "organization": sessionStorage.getItem('selected_organization')
//             }
//         }
//         if (typeof (e) == 'string') {
//             requestData["user_id"] = e
//         } else {
//             requestData["user_id"] = e.target.id
//         }
//         let clientListApi = await this.parseResponse(services.getClientsList(requestData));
//         console.log(requestData);
//         this.setState({ clientList: JSON.parse(this.CryptoJSAesDecrypt("tactionData", clientListApi.data)), pageInfoForClients: clientListApi.pageInfo });

//     }

//     openClientModal = async (e) => {
//         let user = JSON.parse(e.target.dataset.param);
//         this.setState({ clientsModal: true, selectedUser: user.name, selectedUserName: e.target.id, selected_user: user });
//         this.getPeerSupportSpecialistList();
//         this.getClientList(e);
//     }

//     deActivateUser = async (id) => {
//         let requestData = {
//             'apikey': sessionStorage.getItem('__TOKEN__'),
//             'userId': id,
//             'active': false
//         }
//         let editUserApi = await this.parseResponse(services.editUser(requestData));
//         Alert('success', 'User deactivated successfully');
//         this.props.getUserList();
//         this.closeModal();
//     }

//     activateUser = async (e) => {
//         let id = e.target.id;
//         this.props.changePagination(1);
//         let requestData = {
//             'apikey': sessionStorage.getItem('__TOKEN__'),
//             'userId': id,
//             'active': true
//         }
//         let editUserApi = await this.parseResponse(services.editUser(requestData));
//         this.props.getUserList();
//         Alert('success', 'User activated successfully');
//     }

//     showClientList = () => {
//         this.setState({ clientListModal: true });
//     }

//     closeClientListModal = () => {
//         this.setState({ clientListModal: false, reAssignUser: "Select", checkbox: [] });
//         this.closeModal();
//     }

//     paginationDataTable = async (pageNum) => {
//         this.props.changePagination(pageNum);
//         var jsonData = {
//             'apikey': sessionStorage.getItem('__TOKEN__'),
//             "organization": sessionStorage.getItem('selected_organization'),
//             "page": pageNum
//         };
//         if (this.props.wholeState.search == true) {
//             this.props.searchResult(pageNum);
//         } else {
//             let userData = await axios.post(global.restApiURL + 'customRest/getUserList', JSON.stringify(jsonData))
//             this.props.stateSattlement(userData.data.data, userData.data.pageInfo);
//         }
//     }

//     paginationDataTableForClients = async (pageNum) => {
//         this.setState({ listNextForClientsListing: pageNum });
//         let payLoad = {
//             "apikey": sessionStorage.getItem('__TOKEN__'),
//             is_published: true,
//             filters: { organization: sessionStorage.getItem('selected_organization') },
//             "user_id": this.state.selectedUserName,
//             "page": pageNum
//         }
//         let clientListApi = await this.parseResponse(services.getClientsList(payLoad));
//         this.setState({ clientList: JSON.parse(this.CryptoJSAesDecrypt("tactionData", clientListApi.data)), pageInfoForClients: clientListApi.pageInfo });
//     }

//     selectedRowDecision = (row) => {
//         if (this.state.checkbox.length > 0 && this.state.clientList.length > 0) {
//             return this.state.checkbox.includes(row.Client_id);
//         }
//     }


//     generatePagination = () => {
//         const paginationItems = [];
//         paginationItems.push(
//             <div className="card-body">
//                 <div className="sc-fzXfMB tnaXA">
//                     <nav className="sc-fzXfNc reOZF rdt_Pagination row">
//                         <span class="sc-fzXfNf sc-fzXfNg fsxVqc mt-1">{this.props.wholeState.pageInfo.pageString} of {this.props.wholeState.pageInfo.records}</span>
//                         <div className="sc-fzXfNe cAfIxA ml-5">
//                             <button onClick={() => { this.paginationDataTable(1) }} id="pagination-first-page" aria-label="First Page" aria-disabled="true" disabled="" className="sc-fzXfNd fZHyDG" style={{ backgroundColor: 'white', fill: '#007bff' }}>
//                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
//                                     <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
//                                     <path fill="none" d="M24 24H0V0h24v24z"></path>
//                                 </svg>
//                             </button>
//                             <button onClick={() => { if (this.props.wholeState.listNext <= 1) { } else { this.paginationDataTable((this.props.wholeState.listNext - 1)) } }} id="pagination-previous-page" aria-label="Previous Page" aria-disabled="true" disabled="" className="sc-fzXfNd fZHyDG" style={{ backgroundColor: 'white', fill: '#007bff' }}>
//                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
//                                     <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
//                                     <path d="M0 0h24v24H0z" fill="none"></path>
//                                 </svg>
//                             </button>
//                             <button onClick={() => { if (this.props.wholeState.listNext >= this.props.wholeState.pageInfo.pages) { } else { this.paginationDataTable((this.props.wholeState.listNext + 1)) } }} id="pagination-next-page" aria-label="Next Page" aria-disabled="false" className="sc-fzXfNd fZHyDG" fdprocessedid="qnf8l" style={{ backgroundColor: 'white', fill: '#007bff' }}>
//                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
//                                     <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
//                                     <path d="M0 0h24v24H0z" fill="none"></path>
//                                 </svg>
//                             </button>
//                             <button onClick={() => { this.paginationDataTable(this.props.wholeState.pageInfo.pages) }} id="pagination-last-page" aria-label="Last Page" aria-disabled="false" className="sc-fzXfNd fZHyDG" fdprocessedid="bxu9i" style={{ backgroundColor: 'white', fill: '#007bff' }}>
//                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
//                                     <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
//                                     <path fill="none" d="M0 0h24v24H0V0z"></path>
//                                 </svg>
//                             </button>
//                         </div>
//                     </nav>
//                 </div>
//             </div>
//         );
//         return paginationItems;
//     };

//     generatePaginationForClients = () => {
//         const paginationItems = [];
//         paginationItems.push(
//             <div className="card-body">
//                 <div className="sc-fzXfMB tnaXA">
//                     <nav className="sc-fzXfNc reOZF rdt_Pagination row">
//                         <span class="sc-fzXfNf sc-fzXfNg fsxVqc mt-1">{this.state.pageInfoForClients.pageString} of {this.state.pageInfoForClients.records}</span>
//                         <div className="sc-fzXfNe cAfIxA ml-5">
//                             <button onClick={() => { this.paginationDataTableForClients(1) }} id="pagination-first-page" aria-label="First Page" aria-disabled="true" disabled="" className="sc-fzXfNd fZHyDG" style={{ backgroundColor: 'white', fill: '#007bff' }}>
//                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
//                                     <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
//                                     <path fill="none" d="M24 24H0V0h24v24z"></path>
//                                 </svg>
//                             </button>
//                             <button onClick={() => { if (this.state.listNextForClientsListing <= 1) { } else { this.paginationDataTableForClients((this.state.listNextForClientsListing - 1)) } }} id="pagination-previous-page" aria-label="Previous Page" aria-disabled="true" disabled="" className="sc-fzXfNd fZHyDG" style={{ backgroundColor: 'white', fill: '#007bff' }}>
//                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
//                                     <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
//                                     <path d="M0 0h24v24H0z" fill="none"></path>
//                                 </svg>
//                             </button>
//                             <button onClick={() => { if (this.state.listNextForClientsListing >= this.state.pageInfoForClients.pages) { } else { this.paginationDataTableForClients((this.state.listNextForClientsListing + 1)) } }} id="pagination-next-page" aria-label="Next Page" aria-disabled="false" className="sc-fzXfNd fZHyDG" fdprocessedid="qnf8l" style={{ backgroundColor: 'white', fill: '#007bff' }}>
//                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
//                                     <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
//                                     <path d="M0 0h24v24H0z" fill="none"></path>
//                                 </svg>
//                             </button>
//                             <button onClick={() => { this.paginationDataTableForClients(this.state.pageInfoForClients.pages) }} id="pagination-last-page" aria-label="Last Page" aria-disabled="false" className="sc-fzXfNd fZHyDG" fdprocessedid="bxu9i" style={{ backgroundColor: 'white', fill: '#007bff' }}>
//                                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
//                                     <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
//                                     <path fill="none" d="M0 0h24v24H0V0z"></path>
//                                 </svg>
//                             </button>
//                         </div>
//                     </nav>
//                 </div>
//             </div>
//         );
//         return paginationItems;
//     };

//     render() {
//         return (
//             <div className="content-wrapper">
//                 <section className="content">
//                     <div className="container-fluid">
//                         <div className="card">
//                             <div className="card-header">
//                                 <h3 className="card-title">Search</h3>
//                             </div>
//                             <div className="card-body">
//                                 <div className='row'>
//                                     <div className='col-md-3'>
//                                         <div className='form-group'>
//                                             <label>Username</label>
//                                             <input type="text" className='form-control' name="username" placeholder='Username' onChange={this.props.handleChange} value={this.props.username} />
//                                         </div>
//                                     </div>
//                                     <div className='col-md-3'>
//                                         <div className='form-group'>
//                                             <label>Email</label>
//                                             <input type="text" className='form-control' name="email" placeholder='Email' onChange={this.props.handleChange} value={this.props.email} />
//                                         </div>
//                                     </div>
//                                     <div className='col-md-4'>
//                                         <div className='form-group'>
//                                             <label>&nbsp;</label><br />
//                                             <button type='button' className='btn btn-default float-sm-right clientSearchButton' onClick={this.props.resetButton}>Reset</button>
//                                             <button type='button' className='btn btn-primary float-sm-right btnmargin clientSearchButton' onClick={this.props.searchResult}>Search</button>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='card'>
//                             <div className='card-header'>
//                                 <p className="lead float-left"><b className="text-green">User List</b></p>
//                                 <button className='btn btn-success float-right' onClick={() => this.handleButtonEdit()}>Add User</button>
//                             </div>
//                             <div className='card-body'>
//                                 <DataTable
//                                     className={'table_Data'}
//                                     columns={this.state.columns}
//                                     data={this.props.userList}
//                                     customStyles={global.customTableStyles}
//                                     striped={true}
//                                     // pagination
//                                     paginationComponentOptions={global.paginationComponentOptions}
//                                     highlightOnHover
//                                     pointerOnHover
//                                     noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
//                                 />
//                                 <br />
//                                 <div className='row'>
//                                     <ul class="pagination paginationClient pagination-lg">
//                                         {this.generatePagination()}
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </section >
//                 {
//                     this.state.editModal ?
//                         <form>
//                             <Modal
//                                 showOverlay={true}
//                                 show={this.state.editModal}
//                                 disableOverlayClick={true}
//                                 showCloseButton={false}
//                                 size="lg" // Increased size
//                                 className="modal-dialog"
//                                 style={{ lineHeight: "1.5", letterSpacing: "inherit", minWidth: "800px" }} // Ensure a minimum width
//                             >
//                                 <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
//                                     <Modal.Title>
//                                         <div className="row">
//                                             <div className="col-sm-12">
//                                             <p className="lead float-left" style={{ margin: 0 }}>
//                                                     <b className="text-green">{this.state.user_id == '' ? 'Add user' : 'Edit user'}</b>
//                                                 </p>
//                                             </div>
//                                         </div>
//                                     </Modal.Title>
//                                 </Modal.Header>
//                                 <Modal.Body>
//                                     <div className='row'>
//                                         <div className='col-md-6 form-group'> {/* Increased col size */}
//                                             <label>First Name</label>
//                                             <input type="text" placeholder='First name' readOnly={this.state.user_id !== ''} className='form-control' name="first_name" onChange={this.handleChange} required value={this.state.first_name} />
//                                         </div>
//                                         <div className='col-md-6 form-group'> {/* Increased col size */}
//                                             <label>Last Name</label>
//                                             <input type="text" placeholder='Last name' readOnly={this.state.user_id !== ''} className='form-control' name="last_name" onChange={this.handleChange} required value={this.state.last_name} />
//                                         </div>
//                                         <div className='col-md-6 form-group'> {/* Increased col size */}
//                                             <label>Email</label>
//                                             <input type="email" placeholder='Email' className='form-control' name="email" onChange={this.handleChange} required value={this.state.email} />
//                                         </div>
//                                         <div className='col-md-6 form-group'> {/* Increased col size */}
//                                             <label>Username</label>
//                                             <input type="text" placeholder='Username' readOnly={this.state.user_id !== ''} className='form-control' name="user_name" onChange={this.handleChange} required value={this.state.user_name} />
//                                         </div>
//                                         <div className='col-md-6 form-group'> {/* Increased col size */}
//                                             <label>Role</label>
//                                             <select className='form-control' name="role" onChange={this.handleChange} required>
//                                                 <option>Select</option>
//                                                 {this.props?.rolesList?.map((role) => (
//                                                     <option key={role.id} value={role.id} selected={role.id == this.state.role}>{role.name}</option>
//                                                 ))}
//                                             </select>
//                                         </div>
//                                         {global.is_admin ? (
//                                             <div className='col-md-6 form-group'> {/* Increased col size */}
//                                                 <label>Organization</label>
//                                                 <input
//                                                     type="text"
//                                                     className='form-control'
//                                                     value={sessionStorage.getItem('selected_organization')}
//                                                     readOnly
//                                                 />
//                                             </div>
//                                         ) : (
//                                             <div className='col-md-6 form-group'> {/* Increased col size */}
//                                                 <label>Organization</label>
//                                                 <select
//                                                     className='form-control'
//                                                     name="selectedOrganization"
//                                                     onChange={this.handleChange}
//                                                     required
//                                                     value={this.state.selectedOrganization}
//                                                 >
//                                                     <option value="">Select Organization</option>
//                                                     {this.state.organizationList.map((org) => (
//                                                         <option key={org.id} value={org.id}>
//                                                             {org.name}
//                                                         </option>
//                                                     ))}
//                                                 </select>
//                                             </div>
//                                         )}
//                                         <div className='col-md-12 text-center' style={{ paddingTop: "15px" }}>
//                                             <p>Note: An email will be sent to the user at the email address provided</p>
//                                         </div>
//                                     </div>
//                                 </Modal.Body>
//                                 <Modal.Footer>
//                                     <div className="row">
//                                         <div className="col-md-12">
//                                             <div className="justify-content-between">
//                                                 <button className="btn btn-primary float-left btnmargin" type="button" onClick={this.closeModal}>Close</button>
//                                             </div>
//                                             <div className="justify-content-between">
//                                                 <button className="btn btn-success float-right btnmargin" type="submit" disabled={this.state.submitDisabled} onClick={this.addUser}>{this.state.submitDisabled ? 'Submitting...' : 'Submit'}</button>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </Modal.Footer>
//                             </Modal>
//                         </form> :
//                         null
//                 }
//                 {
//                     this.state.clientsModal &&
//                     <form>
//                         <Modal
//                             showOverlay={true}
//                             show={this.state.clientsModal}
//                             disableOverlayClick={true}
//                             showCloseButton={false}
//                             size="sm"
//                             className="modal-sm modal-dialog"
//                             style={{ lineHeight: "1.5", letterSpacing: "inherit" }}
//                         >
//                             <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
//                                 <Modal.Title>
//                                     <div className="row" >
//                                         <div className="col-sm-12">
//                                             <p className="lead float-left" style={{ margin: 0 }}><b className="text-green">Re-assign</b></p>
//                                             <i className='fa fa-times float-right' style={{ marginTop: '3%', cursor: 'pointer' }} onClick={this.closeModal}></i>
//                                         </div>
//                                     </div>
//                                 </Modal.Title>
//                             </Modal.Header>
//                             <Modal.Body style={{ minHeight: '70px' }}>
//                                 <div className='row'>
//                                     <div className='col-md-12'>
//                                         <h4>Do you want to re-assign clients of this user?</h4><br />
//                                     </div>
//                                 </div>
//                             </Modal.Body>
//                             <Modal.Footer >
//                                 <div className='row' style={{ width: '100%' }}>
//                                     <div className='col-md-6'>
//                                         <button className='btn btn-default float-left' onClick={this.closeModal}>No</button>
//                                         {/* // () => this.deActivateUser(this.state.selected_user.id) */}
//                                     </div>
//                                     <div className='col-md-6'>
//                                         <button className='btn btn-success' onClick={this.showClientList}>Yes</button>
//                                     </div>
//                                 </div>
//                             </Modal.Footer>
//                         </Modal>
//                     </form>
//                 }

//                 {this.state.clientListModal &&
//                     <Modal
//                         showOverlay={true}
//                         disableOverlayClick={true}
//                         showCloseButton={false}
//                         style={{ lineHeight: "1.5", letterSpacing: "inherit" }}
//                     >
//                         <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
//                             <Modal.Title>
//                                 <div className="row" >
//                                     <div className="col-sm-12">
//                                         <p className="lead float-left" style={{ margin: 0 }}><b className="text-green">Re-assign Client List</b></p>
//                                         <i className='fa fa-times float-right' style={{ cursor: 'pointer' }} onClick={this.closeClientListModal}></i>
//                                     </div>
//                                 </div>
//                             </Modal.Title>
//                         </Modal.Header>
//                         <Modal.Body style={{ minHeight: '140px' }}>
//                             <div className={this.state.selectedUser == undefined ? "card-body" : ""}>
//                                 <DataTable
//                                     className={'table_Data'}

//                                     columns={this.state.columnsForClientList}
//                                     data={this.state.clientList}
//                                     customStyles={global.customTableStyles}
//                                     striped={true}
//                                     selectableRowSelected={this.selectedRowDecision}
//                                     // pagination
//                                     // paginationComponentOptions={global.paginationComponentOptions}
//                                     highlightOnHover
//                                     pointerOnHover
//                                     selectableRows
//                                     onSelectedRowsChange={this.onCheckboxCheck}
//                                     clearSelectedRows={this.state.clearRows}
//                                     noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
//                                 />
//                                 <br />
//                                 <div className='row'>
//                                     <ul class="pagination paginationClient pagination-lg">
//                                         {this.generatePaginationForClients()}
//                                     </ul>
//                                 </div>
//                                 {typeof (this.state.userList.length) != "object" && this.state.clientList.length > 0 && <>
//                                     <div className='form-group'>
//                                         <div className='row'>
//                                             <div className='col-md-2'>
//                                                 <label>Re-assign To:</label>
//                                                 <select className='form-control' onChange={this.selectReAssignUser}>
//                                                     <option>Select</option>
//                                                     {this.state.selectedUser != undefined ?
//                                                         this.state.userList?.filter(user => user.name != this.state.selectedUser).map((Filtereduser) => (
//                                                             <option value={Filtereduser.firstname + ' ' + Filtereduser.lastname}>{Filtereduser.full_name}</option>
//                                                         )) :
//                                                         this.state.userList.map((user) => (
//                                                             <option value={user.firstname + ' ' + user.lastname}>{user.full_name}</option>
//                                                         ))
//                                                     }
//                                                 </select>
//                                             </div>
//                                             <div className='col-md-7'></div>
//                                             <div className='col-md-3'>
//                                                 <button className='btn btn-success float-right' style={{ marginLeft: '6%', marginTop: '9%' }} onClick={this.reAssignClients}>Re-assign</button>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </>}
//                             </div>
//                         </Modal.Body>
//                         <Modal.Footer >
//                             <div className="row">
//                                 <div className="col-md-12">
//                                     <div className="justify-content-between">
//                                         <button className="btn btn-primary float-left btnmargin" type="button" onClick={this.closeClientListModal}>Close</button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </Modal.Footer>
//                     </Modal>
//                 }

//             </div >
//         )
//     }
// }





















import React, { Component } from 'react'
import { Alert } from '../toastify';
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom';
import services from '../../services';
import ClientListing from '../ClientManagement/clientListing';
import Modal from '../Modals/Modal';
import swal from 'sweetalert';
const axios = require('axios').default;

// let userNameForClientList = "";

export default class UserListing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editModal: false,
            checkbox: [],
            organizationList: [],
            selectedOrganization: '',
            selectedOrganizationName: '',
            submitDisabled: false,
            userLimit: 10,
            columns: [
                {
                    name: 'First Name',
                    selector: 'firstname',
                    style: {
                    },
                },
                {
                    name: 'Last Name',
                    selector: 'lastname',
                    style: {
                    },
                },
                {
                    name: 'Email',
                    selector: 'email',
                    style: {
                    },
                },
                {
                    name: 'Role',
                    selector: 'role_name',
                    style: {
                    },
                },
                {
                    name: 'Username',
                    selector: 'name',
                    style: {
                    },
                },
                {
                    name: 'Active',
                    selector: 'active',
                },
                {
                    name: 'Action',
                    cell: (row) => (
                        <div>
                            <Link to="#"><i className="fas fa-edit" id={JSON.stringify(row)} aria-hidden="true" data-toggle="tooltip" title="Edit" onClick={(e, row) => this.handleButtonEdit(e, row, 'edit')}></i></Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {row.active == "Active" ?
                                <Link to="#"><i className="fas fa-user-times" id={row.firstname + ' ' + row.lastname} data-param={JSON.stringify(row)} aria-hidden="true" data-toggle="tooltip" title="Deactivate" onClick={(e) => this.openClientModal(e)}></i></Link> :
                                <Link to="#"><i className="fas fa-user-times text-gray" id={row.id} aria-hidden="true" data-toggle="tooltip" title="Activate" onClick={(e, row) => this.activateUser(e, row, 'edit')}></i></Link>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                    ),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                }
            ],
            columnsForClientList: [
                {
                    name: 'Client Name',
                    selector: 'Full_name',
                    style: {
                    },
                },
                {
                    name: 'Client ID',
                    selector: 'Client_id',
                    style: {
                    },
                },
                {
                    name: 'Date of Birth',
                    selector: 'Dob',
                    style: {
                    },
                },
                {
                    name: 'Intake Date',
                    selector: 'Intake_date',
                    style: {
                    },
                },
                {
                    name: 'Phone',
                    selector: 'Primary_phone',
                    style: {
                    },
                },
                {
                    name: 'Email',
                    selector: 'Primary_email',
                },
                {
                    name: 'Last Updated By',
                    selector: 'Last_updated_by',
                },
                {
                    name: 'Peer Support Specialist',
                    selector: 'PeerSupportSpecialist',
                    style: {
                    },
                },
                // {
                //     name: 'Action',
                //     cell: (row) => (
                //         <div>
                //             {/* <Link to="#"><i className="fas fa-edit" id={JSON.stringify(row)} aria-hidden="true" data-toggle="tooltip" title="Edit" onClick={(e, row) => this.handleButtonEdit(e, row, 'edit')}></i></Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                //             {row.published == false ?
                //                 <Link to="#"><i className="fas fa-user-times text-gray" id={row.Client_id} aria-hidden="true" data-toggle="tooltip" title="Publish" onClick={(e) => this.publishClient(e)}></i></Link> :
                //                 <Link to="#"><i className="fas fa-user-times" id={row.Client_id} aria-hidden="true" data-toggle="tooltip" title="Unpublish" onClick={(e) => this.unPublishClient(e)}></i></Link>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                //         </div>
                //     ),
                //     ignoreRowClick: true,
                //     allowOverflow: true,
                //     button: true,
                // }
            ],
            reAssignUser: "",
            user_id: '',
            first_name: '',
            last_name: '',
            email: '',
            user_name: '',
            role: '',
            username: '',
            clientsModal: false,
            clientListModal: false,
            clientList: {},
            selectedUser: '',
            selectedUserName: '',
            selected_user: {},
            listNext: 1,


            clientList: [],
            // search: "",
            userList: {},
            // startDateintakedate: '',
            // dateRange: '',
            listNextForClientsListing: 1,
            pageInfo: {
                pageString: "",
                pages: 0,
                records: 0
            },
            pageInfoForClients: {
                pageString: "",
                pages: 0,
                records: 0
            }
        }
        this.handleButtonEdit = this.handleButtonEdit.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }



    updateListNext = (pageNum) => {
        this.setState({
            listNext: pageNum
        })
    }

    stateSattlement = (a = [], b = "") => {
        this.setState({
            clientList: a,
            pageInfo: b,
        });
    }

    CryptoJSAesDecrypt(passphrase, encrypted_json_string) {
        const CryptoJS = require("crypto-js");
        var obj_json = JSON.parse(encrypted_json_string);
        var encrypted = obj_json.ciphertext;
        var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
        var iv = CryptoJS.enc.Hex.parse(obj_json.iv);
        var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999 });
        var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    parseResponse = (promise) => {
        if (promise != true) {
            return promise.then(data => {
                return data;
            }).catch(err => {
                console.log(err);
            });
        }
    };

    selectReAssignUser = (e) => {
        this.setState({ reAssignUser: e.target.value });
    }

    getOrganizationsList = async () => {
        axios.post(global.restApiURL + 'customRest/getOrganizationsAllList', JSON.stringify({
            'apikey': sessionStorage.getItem('__TOKEN__'),
        }))
            .then(async (data) => {
                this.setState({ organizationList: data.data.data });
            })
            .catch((error) => {
                console.error('Error fetching organization list:', error);
            });
    }

    handleButtonEdit(e, row, type = 'add') {
        this.getOrganizationsList();
        if (type == 'edit') {
            let data = JSON.parse(e.target.id);
            this.setState({ 'user_id': data.id, 'first_name': data.firstname, 'last_name': data.lastname, 'email': data.email, 'user_name': data.name, 'role': data.role_id });
        }
        this.setState({ editModal: true });
    }

    closeModal() {
        this.setState({ 'user_id': '', 'first_name': '', 'last_name': '', 'email': '', 'user_name': '', 'role': '', 'selectedOrganization': '' });
        this.setState({ editModal: false, clientsModal: false });
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "selectedOrganization") {
            const selectedOrg = this.state.organizationList.find(function (element) {
                return element.id == value;
            })
            const selectedOrgName = selectedOrg ? selectedOrg.name : '';
            this.setState({
                selectedOrganization: value,
                selectedOrganizationName: selectedOrgName
            });
        } else {
            this.setState({
                [name]: value
            });
        }
    }


    getPeerSupportSpecialistList = async () => {
        let requestData = {
            'apikey': sessionStorage.getItem('__TOKEN__'),
            'organization': sessionStorage.getItem('selected_organization')
        }
        let userListApi = await this.parseResponse(services.getPeerListing(requestData));
        userListApi.map((user, index) => {
            if (user.active == "Inactive") {
                userListApi.splice(index, 1)
            }
        }
        )
        this.setState({ userList: userListApi });
    }







    addUser = async () => {
        // Validate required fields
        if (this.state.first_name === "" || this.state.last_name === "" || this.state.email === "" || this.state.user_name === "" || (this.state.role === "" || this.state.role === "select") || (!global.is_admin && (this.state.selectedOrganization === '' || this.state.selectedOrganization === undefined))) {
            Alert('error', 'All fields are mandatory')
            return;
        }

        this.setState({ submitDisabled: true });

        try {
            let requestData = {
                'apikey': sessionStorage.getItem('__TOKEN__'),
                'type': 'user',
                'name': this.state.user_name,
                'firstName': this.state.first_name,
                'Lastname': this.state.last_name,
                'Email': this.state.email,
                'active': 1,
                'rid': this.state.role,
                'parentId': '0'
            };

            // Set organization data based on admin status
            if (global.is_admin) {
                requestData['organization'] = `/Organization/${sessionStorage.getItem('selected_organization')}`;
                requestData['organization_id'] = sessionStorage.getItem('organization_id');
            } else {
                requestData['organization'] = `/Organization/${this.state.selectedOrganizationName}`;
                requestData['organization_id'] = this.state.selectedOrganization;
            }

            let response;
            if (this.state.user_id !== "") {
                if ((this.state.role === '' || this.state.role === 'Select') || (!global.is_admin && (this.state.selectedOrganization === '' || this.state.selectedOrganization === undefined))) {
                    Alert('warning', 'Please select a role and organization');
                    return;
                }
                // Editing an existing user
                requestData['userId'] = this.state.user_id;

                response = await this.parseResponse(services.editUser(requestData));
                let message = response.message ? 'User not updated' : 'User updated successfully';
                let type = response.message ? 'error' : 'success';
                Alert(type, message);
            } else {
                // Creating a new user
                response = await this.parseResponse(services.addUser(requestData));

                let message = response.message ? response.response.data.message : 'User created successfully';
                let type = response.message ? 'error' : 'success';
                Alert(type, message);
            }

            if (!response.message) {
                this.closeModal();
            }

            this.setState({ 'user_id': '' });
            this.props.getUserList();

        } catch (error) {
            console.error('Error:', error);
            Alert('error', 'An error occurred. Please try again.');
        } finally {
            // Re-enable the submit button
            this.setState({ submitDisabled: false });
        }
    };


    onCheckboxCheck = (e) => {
        let temparr = [];
        let checkbox1 = this.state.checkbox;
        let check = [];
        let newarr = []

        e.selectedRows.map(row => {
            check.push(row.Client_id)
            if (checkbox1.includes(row.Client_id)) {

            } else {
                checkbox1.push(row.Client_id);
            }

        })

        this.state.clientList.map((e) => {
            if (check.includes(e.Client_id)) {

            } else {
                temparr.push(e.Client_id)
            }

        })

        checkbox1.map((val) => {
            if (!temparr.includes(val)) {
                newarr.push(val)
            }
        })
        this.setState({ checkbox: newarr });
    }

    reAssignClients = async () => {
        if (this.state.reAssignUser == "" || this.state.reAssignUser == undefined || this.state.reAssignUser == "Select") {
            // Alert('warning', 'Please select a User for Re-assign To');
            Alert('warning', 'Please select a User you want to Re-assign To');
        } else if (!this.state.checkbox.length > 0) {
            Alert('warning', 'Please select a client');
        } else if (this.state.checkbox.length > 0 && this.state.reAssignUser != "" && this.state.reAssignUser != undefined) {
            let requestData = {
                'apikey': sessionStorage.getItem('__TOKEN__'),
                'client_id': this.state.checkbox,
                'username': this.state.reAssignUser
            }
            let reAssignClient = axios.post(global.restApiURL + 'customRest/reAssignClient', JSON.stringify(requestData))//await this.props.parseResponse(services.reAssignClients(requestData));
                .then(
                    res => {
                        this.setState({ clearRows: !this.state.clearRows, clientListModal: false, checkbox: [], reAssignUser: "" });
                        this.props.changePagination(1);
                        Alert("success", "Re Assigned Successfully and User deactivated");
                        if (this.state.selected_user && this.state.selectedUserName) {
                            this.deActivateUser(this.state.selected_user.id)
                            this.getClientList(this.state.selectedUserName);
                        } else {
                            // alert("AAA");
                            // this.getClientList(); 
                            // this.changePagination(1);
                            // this.paginationDataTable(1);
                        }
                    }
                )
                .catch(
                    err => {
                        Alert('warning', 'Something went wrong');
                        console.log(err);
                    }
                )
        }
        // else {
        //     Alert('warning', 'Please select a client');
        // }
    }

    getClientList = async (e) => {
        let requestData = {
            'apikey': sessionStorage.getItem('__TOKEN__'),
            "is_published": true,
            'filters': {
                "organization": sessionStorage.getItem('selected_organization')
            }
        }
        if (typeof (e) == 'string') {
            requestData["user_id"] = e
        } else {
            requestData["user_id"] = e.target.id
        }
        let clientListApi = await this.parseResponse(services.getClientsList(requestData));
        console.log(requestData);
        this.setState({ clientList: JSON.parse(this.CryptoJSAesDecrypt("tactionData", clientListApi.data)), pageInfoForClients: clientListApi.pageInfo });

    }

    openClientModal = async (e) => {
        let user = JSON.parse(e.target.dataset.param);
        this.setState({ clientsModal: true, selectedUser: user.name, selectedUserName: e.target.id, selected_user: user });
        this.getPeerSupportSpecialistList();
        this.getClientList(e);
    }

    deActivateUser = async (id) => {
        let requestData = {
            'apikey': sessionStorage.getItem('__TOKEN__'),
            'userId': id,
            'active': false
        }
        let editUserApi = await this.parseResponse(services.editUser(requestData));
        console.log(editUserApi);
        Alert('success', 'User deactivated successfully');
        this.setState({userLimit : 10});
        this.props.getUserList();
        this.closeModal();
    }

    activateUser = async (e) => {
        let id = e.target.id;
        this.props.changePagination(1);
        let requestData = {
            'apikey': sessionStorage.getItem('__TOKEN__'),
            'userId': id,
            'active': true
        }
        let editUserApi = await this.parseResponse(services.editUser(requestData));
        console.log(editUserApi);
        this.setState({userLimit : 10});
        this.props.getUserList();
        Alert('success', 'User activated successfully');
    }

    showClientList = () => {
        this.setState({ clientListModal: true });
    }

    closeClientListModal = () => {
        this.setState({ clientListModal: false, reAssignUser: "Select", checkbox: [] });
        this.closeModal();
    }

    paginationDataTable = async (pageNum) => {
        this.props.changePagination(pageNum);
        var jsonData = {
            'apikey': sessionStorage.getItem('__TOKEN__'),
            "organization": sessionStorage.getItem('selected_organization'),
            "page": pageNum,
            'limit': this.state.userLimit
        };
        if (this.props.wholeState.search == true) {
            this.props.searchResult(pageNum);
        } else {
            let userData = await axios.post(global.restApiURL + 'customRest/getUserList', JSON.stringify(jsonData))
            this.props.stateSattlement(userData.data.data, userData.data.pageInfo);
        }
    }

    paginationDataTableForClients = async (pageNum) => {
        this.setState({ listNextForClientsListing: pageNum });
        let payLoad = {
            "apikey": sessionStorage.getItem('__TOKEN__'),
            is_published: true,
            filters: { organization: sessionStorage.getItem('selected_organization') },
            "user_id": this.state.selectedUserName,
            "page": pageNum
        }
        let clientListApi = await this.parseResponse(services.getClientsList(payLoad));
        this.setState({ clientList: JSON.parse(this.CryptoJSAesDecrypt("tactionData", clientListApi.data)), pageInfoForClients: clientListApi.pageInfo });
    }

    selectedRowDecision = (row) => {
        if (this.state.checkbox.length > 0 && this.state.clientList.length > 0) {
            return this.state.checkbox.includes(row.Client_id);
        }
    }

    resetFunction = () => {
        this.setState({ userLimit: 10 });
        this.props.resetButton();
    }

    handleUserOnPage = (e) => {
        this.props.changePagination(1);
        this.setState({ userLimit: e.target.value });
        if (this.props.username != "" || this.props.email != "") {
            this.props.searchResult(e);
        } else {
            this.props.getUserList(e.target.value);
        }
    }

    generatePagination = () => {
        const paginationItems = [];
        paginationItems.push(
            <div className="card-body">
                <div className="sc-fzXfMB tnaXA">
                    <nav className="sc-fzXfNc reOZF rdt_Pagination row">
                        <span class="sc-fzXfNf sc-fzXfNg fsxVqc mt-1">{this.props.wholeState.pageInfo.pageString} of {this.props.wholeState.pageInfo.records}</span>
                        <div className="sc-fzXfNe cAfIxA ml-5">
                            <button onClick={() => { this.paginationDataTable(1) }} id="pagination-first-page" aria-label="First Page" aria-disabled="true" disabled="" className="sc-fzXfNd fZHyDG" style={{ backgroundColor: 'white', fill: '#007bff' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                                </svg>
                            </button>
                            <button onClick={() => { if (this.props.wholeState.listNext <= 1) { } else { this.paginationDataTable((this.props.wholeState.listNext - 1)) } }} id="pagination-previous-page" aria-label="Previous Page" aria-disabled="true" disabled="" className="sc-fzXfNd fZHyDG" style={{ backgroundColor: 'white', fill: '#007bff' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                            </button>
                            <button onClick={() => { if (this.props.wholeState.listNext >= this.props.wholeState.pageInfo.pages) { } else { this.paginationDataTable((this.props.wholeState.listNext + 1)) } }} id="pagination-next-page" aria-label="Next Page" aria-disabled="false" className="sc-fzXfNd fZHyDG" fdprocessedid="qnf8l" style={{ backgroundColor: 'white', fill: '#007bff' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                            </button>
                            <button onClick={() => { this.paginationDataTable(this.props.wholeState.pageInfo.pages) }} id="pagination-last-page" aria-label="Last Page" aria-disabled="false" className="sc-fzXfNd fZHyDG" fdprocessedid="bxu9i" style={{ backgroundColor: 'white', fill: '#007bff' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                </svg>
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
        );
        return paginationItems;
    };

    generatePaginationForClients = () => {
        const paginationItems = [];
        paginationItems.push(
            <div className="card-body">
                <div className="sc-fzXfMB tnaXA">
                    <nav className="sc-fzXfNc reOZF rdt_Pagination row">
                        <span class="sc-fzXfNf sc-fzXfNg fsxVqc mt-1">{this.state.pageInfoForClients.pageString} of {this.state.pageInfoForClients.records}</span>
                        <div className="sc-fzXfNe cAfIxA ml-5">
                            <button onClick={() => { this.paginationDataTableForClients(1) }} id="pagination-first-page" aria-label="First Page" aria-disabled="true" disabled="" className="sc-fzXfNd fZHyDG" style={{ backgroundColor: 'white', fill: '#007bff' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                                </svg>
                            </button>
                            <button onClick={() => { if (this.state.listNextForClientsListing <= 1) { } else { this.paginationDataTableForClients((this.state.listNextForClientsListing - 1)) } }} id="pagination-previous-page" aria-label="Previous Page" aria-disabled="true" disabled="" className="sc-fzXfNd fZHyDG" style={{ backgroundColor: 'white', fill: '#007bff' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                            </button>
                            <button onClick={() => { if (this.state.listNextForClientsListing >= this.state.pageInfoForClients.pages) { } else { this.paginationDataTableForClients((this.state.listNextForClientsListing + 1)) } }} id="pagination-next-page" aria-label="Next Page" aria-disabled="false" className="sc-fzXfNd fZHyDG" fdprocessedid="qnf8l" style={{ backgroundColor: 'white', fill: '#007bff' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                            </button>
                            <button onClick={() => { this.paginationDataTableForClients(this.state.pageInfoForClients.pages) }} id="pagination-last-page" aria-label="Last Page" aria-disabled="false" className="sc-fzXfNd fZHyDG" fdprocessedid="bxu9i" style={{ backgroundColor: 'white', fill: '#007bff' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                </svg>
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
        );
        return paginationItems;
    };

    render() {
        return (
            <div className="content-wrapper">
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Search</h3>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label>Username</label>
                                            <input type="text" className='form-control' name="username" placeholder='Username' onChange={this.props.handleChange} value={this.props.username} />
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label>Email</label>
                                            <input type="text" className='form-control' name="email" placeholder='Email' onChange={this.props.handleChange} value={this.props.email} />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='form-group'>
                                            <label>&nbsp;</label><br />
                                            <button type='button' className='btn btn-default float-sm-right clientSearchButton' onClick={() => { this.resetFunction() }}>Reset</button>
                                            <button type='button' className='btn btn-primary float-sm-right btnmargin clientSearchButton' onClick={this.props.searchResult}>Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='card-header'>
                                <p className="lead float-left"><b className="text-green">User List</b></p>
                                <button className='btn btn-success float-right' onClick={() => this.handleButtonEdit()}>Add User</button>
                            </div>
                            <div className='card-body'>
                                <DataTable
                                    className={'table_Data'}
                                    columns={this.state.columns}
                                    data={this.props.userList}
                                    customStyles={global.customTableStyles}
                                    striped={true}
                                    // pagination
                                    paginationComponentOptions={global.paginationComponentOptions}
                                    highlightOnHover
                                    pointerOnHover
                                    noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                                />
                                <br />
                                <div className='row'>
                                    <div className='col-6'>
                                        <ul className="pagination paginationClient pagination-lg">
                                            {this.generatePagination()}
                                        </ul>
                                    </div>
                                    <div className='col-6'>
                                        <ul className="float-right mt-4">
                                            <span className="sc-fzXfNf sc-fzXfNg fsxVqc mt-1">User per Page :- </span>
                                            {/* <h1>{this.state.userLimit}</h1> */}
                                            <select style={{ color: 'rgb(0, 123, 255)' }} onChange={(e) => this.handleUserOnPage(e)} name="userOnPage" id='userOnPage' value={this.state.userLimit}>
                                                <option value={10}>10</option>
                                                <option value={20}>20</option>
                                                <option value={30}>30</option>
                                                <option value={40}>40</option>
                                                <option value={50}>50</option>
                                                {/* <option value={60}>60</option>
                                                    <option value={70}>70</option>
                                                    <option value={80}>80</option>
                                                    <option value={90}>90</option>
                                                    <option value={100}>100</option> */}
                                            </select>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                {
                    this.state.editModal ?
                        <form>
                            <Modal
                                showOverlay={true}
                                show={this.state.editModal}
                                disableOverlayClick={true}
                                showCloseButton={false}
                                size="lg" // Increased size
                                className="modal-dialog"
                                style={{ lineHeight: "1.5", letterSpacing: "inherit", minWidth: "800px" }} // Ensure a minimum width
                            >
                                <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                    <Modal.Title>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <p className="lead float-left" style={{ margin: 0 }}>
                                                    <b className="text-green">{this.state.user_id == '' ? 'Add user' : 'Edit user'}</b>
                                                </p>
                                            </div>
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='row'>
                                        <div className='col-md-6 form-group'> {/* Increased col size */}
                                            <label>First Name</label>
                                            <input type="text" placeholder='First name' readOnly={this.state.user_id !== ''} className='form-control' name="first_name" onChange={this.handleChange} required value={this.state.first_name} />
                                        </div>
                                        <div className='col-md-6 form-group'> {/* Increased col size */}
                                            <label>Last Name</label>
                                            <input type="text" placeholder='Last name' readOnly={this.state.user_id !== ''} className='form-control' name="last_name" onChange={this.handleChange} required value={this.state.last_name} />
                                        </div>
                                        <div className='col-md-6 form-group'> {/* Increased col size */}
                                            <label>Email</label>
                                            <input type="email" placeholder='Email' className='form-control' name="email" onChange={this.handleChange} required value={this.state.email} />
                                        </div>
                                        <div className='col-md-6 form-group'> {/* Increased col size */}
                                            <label>Username</label>
                                            <input type="text" placeholder='Username' readOnly={this.state.user_id !== ''} className='form-control' name="user_name" onChange={this.handleChange} required value={this.state.user_name} />
                                        </div>
                                        <div className='col-md-6 form-group'> {/* Increased col size */}
                                            <label>Role</label>
                                            <select className='form-control' name="role" onChange={this.handleChange} required>
                                                <option>Select</option>
                                                {this.props?.rolesList?.map((role) => (
                                                    <option key={role.id} value={role.id} selected={role.id == this.state.role}>{role.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {global.is_admin ? (
                                            <div className='col-md-6 form-group'> {/* Increased col size */}
                                                <label>Organization</label>
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    value={sessionStorage.getItem('selected_organization')}
                                                    readOnly
                                                />
                                            </div>
                                        ) : (
                                            <div className='col-md-6 form-group'> {/* Increased col size */}
                                                <label>Organization</label>
                                                <select
                                                    className='form-control'
                                                    name="selectedOrganization"
                                                    onChange={this.handleChange}
                                                    required
                                                    value={this.state.selectedOrganization}
                                                >
                                                    <option value="">Select Organization</option>
                                                    {this.state.organizationList.map((org) => (
                                                        <option key={org.id} value={org.id}>
                                                            {org.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                        <div className='col-md-12 text-center' style={{ paddingTop: "15px" }}>
                                            <p>Note: An email will be sent to the user at the email address provided</p>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="justify-content-between">
                                                <button className="btn btn-primary float-left btnmargin" type="button" onClick={this.closeModal}>Close</button>
                                            </div>
                                            <div className="justify-content-between">
                                                <button className="btn btn-success float-right btnmargin" type="submit" disabled={this.state.submitDisabled} onClick={this.addUser}>{this.state.submitDisabled ? 'Submitting...' : 'Submit'}</button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Footer>
                            </Modal>


                        </form> :
                        null
                }
                {
                    this.state.clientsModal &&
                    <form>
                        <Modal
                            showOverlay={true}
                            show={this.state.clientsModal}
                            disableOverlayClick={true}
                            showCloseButton={false}
                            size="sm"
                            className="modal-sm modal-dialog"
                            style={{ lineHeight: "1.5", letterSpacing: "inherit" }}
                        >
                            <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                <Modal.Title>
                                    <div className="row" >
                                        <div className="col-sm-12">
                                            <p className="lead float-left" style={{ margin: 0 }}><b className="text-green">Re-assign</b></p>
                                            <i className='fa fa-times float-right' style={{ marginTop: '3%', cursor: 'pointer' }} onClick={this.closeModal}></i>
                                        </div>
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ minHeight: '70px' }}>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <h4>Do you want to re-assign clients of this user?</h4><br />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer >
                                <div className='row' style={{ width: '100%' }}>
                                    <div className='col-md-6'>
                                        <button className='btn btn-default float-left' onClick={this.closeModal}>No</button>
                                        {/* // () => this.deActivateUser(this.state.selected_user.id) */}
                                    </div>
                                    <div className='col-md-6'>
                                        <button className='btn btn-success' onClick={this.showClientList}>Yes</button>
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </form>
                }

                {this.state.clientListModal &&
                    <Modal
                        showOverlay={true}
                        disableOverlayClick={true}
                        showCloseButton={false}
                        style={{ lineHeight: "1.5", letterSpacing: "inherit" }}
                    >
                        <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                            <Modal.Title>
                                <div className="row" >
                                    <div className="col-sm-12">
                                        <p className="lead float-left" style={{ margin: 0 }}><b className="text-green">Re-assign Client List</b></p>
                                        <i className='fa fa-times float-right' style={{ cursor: 'pointer' }} onClick={this.closeClientListModal}></i>
                                    </div>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ minHeight: '140px' }}>
                            {/* <ClientListing
                                clientList={this.state.clientList}
                                parseResponse={this.parseResponse}
                                userList={this.props.userList}
                                closeModal={this.closeModal}
                                deActivateUser={this.deActivateUser}
                                selectedUser={this.state.selectedUser}
                                getClientList={this.getClientList}
                                selectedUserName={this.state.selectedUserName}
                                selected_user={this.state.selected_user}
                                wholeState = {this.state}
                                changePagination={this.updateListNext}
                                stateSattlement={this.stateSattlement}
                            /> */}
                            <div className={this.state.selectedUser == undefined ? "card-body" : ""}>
                                <DataTable
                                    className={'table_Data'}
                                    columns={this.state.columnsForClientList}
                                    data={this.state.clientList}
                                    customStyles={global.customTableStyles}
                                    striped={true}
                                    selectableRowSelected={this.selectedRowDecision}
                                    // pagination
                                    // paginationComponentOptions={global.paginationComponentOptions}
                                    highlightOnHover
                                    pointerOnHover
                                    selectableRows
                                    onSelectedRowsChange={this.onCheckboxCheck}
                                    clearSelectedRows={this.state.clearRows}
                                    noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                                />
                                <br />
                                <div className='row'>
                                    <ul class="pagination paginationClient pagination-lg">
                                        {this.generatePaginationForClients()}
                                    </ul>
                                </div>
                                {typeof (this.state.userList.length) != "object" && this.state.clientList.length > 0 && <>
                                    <div className='form-group'>
                                        <div className='row'>
                                            <div className='col-md-2'>
                                                <label>Re-assign To:</label>
                                                <select className='form-control' onChange={this.selectReAssignUser}>
                                                    <option>Select</option>
                                                    {this.state.selectedUser != undefined ?
                                                        this.state.userList?.filter(user => user.name != this.state.selectedUser).map((Filtereduser) => (
                                                            <option value={Filtereduser.firstname + ' ' + Filtereduser.lastname}>{Filtereduser.full_name}</option>
                                                        )) :
                                                        this.state.userList.map((user) => (
                                                            <option value={user.firstname + ' ' + user.lastname}>{user.full_name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className='col-md-7'></div>
                                            <div className='col-md-3'>
                                                <button className='btn btn-success float-right' style={{ marginLeft: '6%', marginTop: '9%' }} onClick={this.reAssignClients}>Re-assign</button>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </Modal.Body>
                        <Modal.Footer >
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="justify-content-between">
                                        <button className="btn btn-primary float-left btnmargin" type="button" onClick={this.closeClientListModal}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                }

            </div >
        )
    }
}
