import React, { Component } from 'react'
import Tooltip from '../../ReusableComponents/Tooltip';
import axios from 'axios';
import moment from "moment";


export default class PrintUpdateAssesment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      printData: [
        {
          form_type: "",
          // Client information
          client_name: '',
          legal_guardian: '',
          dob: '',
          days_in_treatment: '',
          primary_phone: '',
          address_lns: '',
          gender: '',
          ssn: '',
          relationship: '',
          last_school: '',
          race: [],
          assesssed_level_of_care: [],
          medical_provider_review: [],
          priority_population: '',
          client_seeking_treatment: '',
          primary_referral: '',
          princepal_source_of_income: '',
          insurance_type: '',
          payment_source: '',
          dependent_children: '',
          pregnant: '',
          military_status: '',
          bio_medication1: '',
          participating_in_mat: '',
          client_seeking_explain: '',
          dependent_children_number: '',
          number_of_Pregnancies: "",
          prenatal_care: "",
          weeks: "",
          due_date: "",
          saved: true,
          visitType: '',

          // Client Characteristics
          maritial_status: '',
          employment_status: '',
          educational_status: '',
          hearing_status: '',
          language_preference: '',
          linguistic_status: '',
          indicate_living_arrangement: '',
          // Co Occouring
          arrest_last_30_days: '',
          client_attended_self_help_groups_number: '',
          participated_in_self_care_groups: '',
          co_occuring_disorders_identified: '',
          prior_treatment_episodes: '',
          co_occuring_disorder_screen: '',
          co_occuring_disorder_assesment: '',
          // substanceUse
          substances: [{
            class_of_substance: '',
            specific_substance: '',
            problem_type: '',
            route_of_administration: '',
            other_route_of_administration: '',
            age_of_1st_use: '',
            date_last_used: '',
            how_long_used: '',
            how_long_used_days: '',
            how_long_used_months: '',
            frequency_of_use: '',
            age_of_regular_use: '',
            // periods_of_abstinence: '',
            periods_of_abstinence_days: '',
            knownAbstinence: false,
            periods_of_abstinence_months: '',
            periods_of_abstinence_years: '',
            withdrawl_symptoms: "",

            amount_of_regular_use: '',
            rank_order: 1,
          },
          ],
          substance_comments: '',
          old_symptoms: [],
          new_symptoms: "",
          new_symptoms_explain: "",

          // Criteria Questions
          Larger_amount_of_aod: "",
          Larger_amount_aod_explain: "",
          previous_effort_to_control_aod: "",
          previous_effort_to_cut_down_explain: "",
          spend_great_deal_of_time: "",
          spend_great_deal_of_time_explain: "",
          strong_desire_to_use_aod: "",
          strong_desire_to_use_aod_explain: "",
          neglected_important_social_obligations: "",
          neglected_important_social_obligations_explain: "",
          continue_to_use_despite_negative_consequences: "",
          continue_to_use_despite_negative_consequences_explain: "",
          affected_you_socially: "",
          affected_you_socially_explain: "",
          give_up_on_participate: "",
          give_up_on_participate_explain: "",
          placing_yourself_in_danger: "",
          placing_yourself_in_danger_explain: "",
          contribute_to_physical_problem: "",
          contribute_to_physical_problem_explain: "",
          feel_same_effect: "",
          feel_same_effect_explain: "",
          //Risk ratings 
          acute_risk_rating: "",
          acute_risk_rating_information: "",
          biomedical_risk_rating: "",
          biomedical_risk_rating_information: "",
          emotional_risk_rating: "",
          emotional_risk_rating_information: "",
          readiness_risk_rating: "",
          readiness_risk_rating_information: "",
          relapse_risk_rating: "",
          relapse_risk_rating_information: "",
          recovery_risk_rating: "",
          recovery_risk_rating_information: "",

          acute_risk_rating_summury: "",
          biomedical_comments: "",
          emotional_comments: "",
          readiness_comments: "",
          problem_comments2: "",
          enviornment_comments: "",
          isCheckedAcute: false,
          isCheckedEmotional: false,
          isCheckedBiomedical: false,
          isCheckedReadiness: false,
          isCheckedRelapse: false,
          isCheckedRecovery: false,


          // substanceUseDisorder
          substanceUseDisorder: [
            {
              disorder: "",
              icd_10_code: "",
              specifier: "",
            },
          ],


          // Disorder Problem
          problems: [{
            problem_type: "",
            specific_substance: "",
            route_of_administration: "",
            frequency_of_use: "",
            age_of_1st_use: "",
            rank_order: "",
          }],

          // Level of care
          // placed_level_of_care: '',
          reason_for_difference: '',
          assessed_comments: '',
          client_seeking_explain: '',
          explaination_of_the_above: '',
          release_of_information: false,
          appropriate_release_of_information: '',
          reffered_from_drug_court: '',
          indigent_offender: '',
          client_start_date: '',
          refered_for_case: '',
          assesed_level: '',
          no_service_availaible1: '',
          no_service_availaible2: '',
          refused_further_services: '',
          client_seeking_treatment: '',
          medical_provider_review: [],
          assesssed_level_of_care: [],
          placed_level_of_care: [],

          // Signature

          client_name: '',
          assesment_staff: '',
          staff_name: '',
          physician_name: '',

          client_sign_uploaded_time: "",
          client_sign_uploaded_time_Assesment_Staff: "",
          client_sign_uploaded_time_Physician: "",
          client_sign_uploaded_time_Staff: "",

          uploaded_by: "",

          preview1: '',
          preview2: '',
          preview3: '',
          preview4: '',

        }
      ],

      disorderOptions: [],
      categoryOptions: [],
      Class_of_Substances_Options: [],
      specific_substance_options: [],
      problem_type_options: [],

      dataListBody: {
        apikey: sessionStorage.getItem("__TOKEN__"),
        client_id: sessionStorage.getItem("clientId"),
      }
    }
  }

  specifierOption = [
    { value: 'In_Early_Remission', label: 'In Early Remission' },
    { value: 'In_Sustained_Remission', label: 'In Sustained Remission' },
    { value: 'On_Maintenance_Therapy', label: 'On Maintenance Therapy' },
    { value: 'In_a_Controlled_Environment', label: 'In a Controlled Environment' },
    { value: 'N/A', label: 'N/A' },
  ]
  reasonDifferenceOptions = [
    { value: "transportation_or_logistical_problem", label: "Transportation or Logistical Problem" },
    { value: "court_order", label: "Court Order" },
    { value: "consumer_preference", label: "Consumer Preference" },
    { value: "clinician_supervisor_override", label: "Clinician/Supervisor Override" },
    { value: "intensive_outpatient_treatment", label: "Intensive Outpatient Treatment" },
    { value: "waiting_for_indicated_level", label: "Waiting for Indicated Level" },
    { value: "service_not_available", label: "Service not available" },
    { value: "na", label: "N/A No Difference" },
    { value: "other", label: "Other" }
  ]

  co_occuring_disorder_option = [
    { value: "not_performed", label: "Not performed" },
    { value: "administered_results(-)", label: " Administered/Results (-)" },
    { value: "administered_results(+)", label: "Administered/Results (+)" },

  ]


  referralSource = [
    {
      value: "A_and_or_D_Treatment_Inpatient_Res",
      label: "A and/or D Treatment, Inpatient Res",
    },
    {
      value: "A_and_or_D_Treatment_Not_Inpatient",
      label: "A and/or D Treatment, Not Inpatient",
    },
    { value: "Clergy", label: "Clergy" },
    {
      value: "Court_Correctional_Agency",
      label: "Court/Correctional Agency",
    },
    { value: "dhr", label: "DHR" },
    {
      value: "Diversionary_Program_TASC",
      label: "Diversionary Program/TASC",
    },
    { value: "DUI_DWI", label: "DUI/DWI" },
    { value: "Educational_Agency", label: "Educational Agency" },
    { value: "Employer_EAP", label: "Employer/EAP" },
    { value: "Family", label: "Family" },
    {
      value: "Formal_Adjudication_Process",
      label: "Formal Adjudication Process",
    },
    { value: "Friend", label: "Friend" },
    {
      value: "General_Psychiatric_Hospital",
      label: "General/Psychiatric Hospital",
    },
    { value: "Guardian", label: "Guardian" },
    { value: "ID_310_Program", label: "ID 310 Program" },
    { value: "ID_arc", label: "ID ARC" },
    { value: "ID_Regional_Office", label: "ID Regional Office" },
    { value: "Multi_Service_MH_Agency", label: "Multi-Service MH Agency" },
    { value: "OP_Psych_Ser_Clinic", label: "OP Psych Ser/Clinic" },
    {
      value: "Nursing_Home_Extended_Care",
      label: "Nursing Home/Extended Care",
    },
    { value: "Parent", label: "Parent" },
    { value: "Partial_Day_Organization", label: "Partial Day Organization" },
    {
      value: "Personal_Care_Boarding_Home",
      label: "Personal Care/Boarding Home",
    },
    { value: "Physician", label: "Physician" },
    { value: "Police", label: "Police" },
    { value: "Prison", label: "Prison" },
    { value: "Private_Psychiatrist", label: "Private Psychiatrist" },
    { value: "Probation_Parole", label: "Probation/Parole" },
    { value: "Recognized_Legal_Entity", label: "Recognized Legal Entity" },
    { value: "School_System", label: "School System" },
    { value: "Self", label: "Self" },
    { value: "Shelter_for_the_Abused", label: "Shelter for the Abused" },
    { value: "Shelter_for_the_Homeless", label: "Shelter for the Homeless" },
    { value: "Spouse", label: "Spouse" },
    {
      value: "State_County_Psych_Hospital",
      label: "State/County Psych Hospital",
    },
    { value: "State_Federal_Court", label: "State/Federal Court" },
    { value: "Voc_Rehab_Services", label: "Voc. Rehab Services" },
    { value: "If_Other_Specify_below", label: "Other" },
  ];

  sourceOfPayment_Option = [
    { value: "blue_cross", label: "Blue Cross/Blue Shield" },
    { value: "dmh", label: "DMH" },
    { value: "health_ins", label: "Health Ins (Not BCBS)" },
    { value: "medicaid", label: "Medicaid" },
    { value: "medicare", label: "Medicare" },
    { value: "no_charge", label: "No Charge(free...)" },
    { value: "other_gov_payments", label: "Other Gov. Payments" },
    { value: "personal_resourses", label: "Personal Resourses(Self/Family)" },
    { value: "service_contract", label: "Service Contract(EAP,HMO etc)" },
    { value: "workers_compensation", label: "Workers Compensation" },
  ];
  insurancetype = [
    { value: "Blue_Cross_Blue_Shield", label: "Blue Cross/Blue Shield" },
    {
      value: "Health_Maintenance_Organization_(HMO)",
      label: "Health Maintenance Organization (HMO)",
    },
    { value: "Medicaid", label: "Medicaid" },
    { value: "Medicare", label: "Medicare" },
    { value: "Private_Insurance", label: "Private Insurance" },
    { value: "Workers_Compensation", label: "Workers Compensation" },
    { value: "Unknown", label: "Unknown" },
    { value: "None", label: "None" },
    { value: "Other", label: "Other" },
  ];
  linguisticOption = [
    { value: "cognitive_disability", label: "Cognitive Disability" },
    { value: "english_proficiency", label: "English Proficiency" },
    { value: "limited_english_proficiency", label: "Limited English Proficiency" },
    { value: "low_literacy _level", label: "Low Literacy Level" },
    { value: "not_literate", label: "Not Literate" },
    { value: "other_disability", label: "Other Disability" },
  ];
  componentDidMount = async () => {
    this.loadData();
  }
  loadData = async () => {
    let res = await axios.post(global.restApiURL + "customRest/getStateAssessmentUpdatesPdf", JSON.stringify({
      apikey: sessionStorage.getItem("__TOKEN__"),
      client_id: sessionStorage.getItem("clientId"),
    }));
    if (res.data.success) {
      this.setState({
        printData: res.data.data?.map((val, index) => {
          return (
            {
              form_type: val.form_type,
              // Client information
              client_name: val.client_name,
              legal_guardian: val.legal_guardian,
              dob: val.dob,
              days_in_treatment: val.days_in_treatment,
              primary_phone: val.primary_phone,
              address_lns: val.address_lns,
              gender: val.gender,
              ssn: val.ssn,
              relationship: val.relationship,
              last_school: val.last_school,
              assesssed_level_of_care: [],
              medical_provider_review: [],
              priority_population: val.priority_population,
              client_seeking_treatment: val.client_seeking_treatment,
              primary_referral: val.primary_referral,
              princepal_source_of_income: val.princepal_source_of_income,
              insurance_type: val.insurance_type,
              payment_source: val.payment_source,
              dependent_children: val.dependent_children,
              dependent_children_number: val.dependent_children_number,
              pregnant: val.pregnant,
              military_status: val.military_status,
              current_prescribed_medications: val.bio_medication1,
              participating_in_mat: val.participating_in_mat,
              client_seeking_explain: val.client_seeking_explain,
              number_of_Pregnancies: val.number_of_Pregnancies,
              prenatal_care: val.prenatal_care,
              weeks: val.weeks,
              due_date: val.due_date,
              visitType: val.visitType,
              // Client Characteristics
              maritial_status: val.maritial_status,
              employment_status: val.employment_status,
              educational_status: val.educational_status,
              hearing_status: val.hearing_status,
              language_preference: val.language_preference,
              linguistic_status: val.linguistic_status,
              indicate_living_arrangement: val.indicate_living_arrangement,
              // Co Occouring
              arrest_last_30_days: val.arrest_last_30_days,
              client_attended_self_help_groups_number: val.client_attended_self_help_groups_number,
              participated_in_self_care_groups: val.participated_in_self_care_groups,
              co_occuring_disorders_identified: val.co_occuring_disorders_identified,
              prior_treatment_episodes: val.prior_treatment_episodes,
              co_occuring_disorder_screen: val.co_occuring_disorder_screen,
              co_occuring_disorder_assesment: val.co_occuring_disorder_assesment,

              // AcuteIntoxication
              Larger_amount_of_aod: val.Larger_amount_of_aod,
              Larger_amount_aod_explain: val.Larger_amount_aod_explain,
              previous_effort_to_control_aod: val.previous_effort_to_control_aod,
              previous_effort_to_cut_down_explain: val.previous_effort_to_cut_down_explain,
              spend_great_deal_of_time: val.spend_great_deal_of_time,
              spend_great_deal_of_time_explain: val.spend_great_deal_of_time_explain,
              strong_desire_to_use_aod: val.strong_desire_to_use_aod,
              strong_desire_to_use_aod_explain: val.strong_desire_to_use_aod_explain,
              neglected_important_social_obligations: val.neglected_important_social_obligations,
              neglected_important_social_obligations_explain: val.neglected_important_social_obligations_explain,
              continue_to_use_despite_negative_consequences: val.continue_to_use_despite_negative_consequences,
              continue_to_use_despite_negative_consequences_explain: val.continue_to_use_despite_negative_consequences_explain,
              affected_you_socially: val.affected_you_socially,
              affected_you_socially_explain: val.affected_you_socially_explain,
              give_up_on_participate: val.give_up_on_participate,
              give_up_on_participate_explain: val.give_up_on_participate_explain,
              placing_yourself_in_danger: val.placing_yourself_in_danger,
              placing_yourself_in_danger_explain: val.placing_yourself_in_danger_explain,
              contribute_to_physical_problem: val.contribute_to_physical_problem,
              contribute_to_physical_problem_explain: val.contribute_to_physical_problem_explain,
              feel_same_effect: val.feel_same_effect,
              feel_same_effect_explain: val.feel_same_effect_explain,

              // substanceUse
              substances: val.substances.map((val2, index) => {
                return ({
                  class_of_substance: val2.class_of_substance,
                  specific_substance: val2.specific_substance,
                  problem_type: val2.problem_type,
                  route_of_administration: val2.route_of_administration,
                  other_route_of_administration: val2.other_route_of_administration,
                  age_of_1st_use: val2.age_of_1st_use,
                  date_last_used: val2.date_last_used,
                  how_long_used: val2.how_long_used,
                  how_long_used_days: val2.how_long_used_days,
                  how_long_used_months: val2.how_long_used_months,
                  frequency_of_use: val2.frequency_of_use,
                  age_of_regular_use: val2.age_of_regular_use,
                  // periods_of_abstinence: val2.periods_of_abstinence,
                  periods_of_abstinence_days: val2.periods_of_abstinence_days,
                  knownAbstinence: val2.knownAbstinence,
                  periods_of_abstinence_months: val2.periods_of_abstinence_months,
                  periods_of_abstinence_years: val2.periods_of_abstinence_years,
                  amount_of_regular_use: val2.amount_of_regular_use,
                  rank_order: val2.rank_order,

                })
              }),
              withdrawl_symptoms: val.withdrawl_symptoms,
              substance_comments: val.substance_comments,
              // old_symptoms:[],
              old_symptoms: val.old_symptoms?.length > 0 ? JSON.parse(val.old_symptoms).map((old, index) => {
                return ({
                  label: old.label, value: old.value,
                })
              }) : [],
              new_symptoms: val.new_symptoms,
              new_symptoms_explain: val.new_symptoms_explain,

              //Risk ratings 
              acute_risk_rating: val.acute_risk_rating,
              acute_risk_rating_information: val.acute_risk_rating_information,
              biomedical_risk_rating: val.biomedical_risk_rating,
              biomedical_risk_rating_information: val.biomedical_risk_rating_information,
              emotional_risk_rating: val.emotional_risk_rating,
              emotional_risk_rating_information: val.emotional_risk_rating_information,
              readiness_risk_rating: val.readiness_risk_rating,
              readiness_risk_rating_information: val.readiness_risk_rating_information,
              relapse_risk_rating: val.relapse_risk_rating,
              relapse_risk_rating_information: val.relapse_risk_rating_information,
              recovery_risk_rating: val.recovery_risk_rating,
              recovery_risk_rating_information: val.recovery_risk_rating_information,

              acute_risk_rating_summury: val.acute_risk_rating_summury,
              biomedical_comments: val.biomedical_comments,
              emotional_comments: val.emotional_comments,
              readiness_comments: val.readiness_comments,
              problem_comments2: val.problem_comments2,
              enviornment_comments: val.enviornment_comments,
              isCheckedAcute:val.isCheckedAcute,
              isCheckedBiomedical:val.isCheckedBiomedical,
              isCheckedEmotional:val.isCheckedEmotional,
              isCheckedReadiness:val.isCheckedReadiness,
              isCheckedRecovery:val.isCheckedRecovery,
              isCheckedRelapse:val.isCheckedRelapse,
              // DSM 5 Diagnosis
              // substanceUseDisorder: [
              substanceUseDisorder: val.substances.map((subDis) => {
                return ({
                  disorder: subDis.disorder,
                  icd_10_code: subDis.icd_10_code,
                  specifier: subDis.specifier,
                })
              }),
              // Disorder Problem
              problems: val.substances.map((val2, index) => {
                return ({
                  problem_type: val2.problem_type,
                  specific_substance: val2.specific_substance,
                  route_of_administration: val2.route_of_administration,
                  frequency_of_use: val2.frequency_of_use,
                  age_of_1st_use: val2.age_of_1st_use,
                  rank_order: val2.rank_order,

                })
              }),

              // Level of care
              // placed_level_of_care: val.placed_level_of_care,
              reason_for_difference: val.reason_for_difference,
              assessed_comments: val.assessed_comments,
              client_seeking_explain: val.client_seeking_explain,
              explaination_of_the_above: val.explaination_of_the_above,
              appropriate_release_of_information: val.release_of_information === true ? 1 : val.release_of_information === false ? 0 : "",
              // Assessed.appropriate_release_of_information = res.data.data.Assessed.release_of_information === true ? 1 : res.data.data.Assessed.release_of_information === false ? 0 : "";
              reffered_from_drug_court: val.reffered_from_drug_court,
              indigent_offender: val.indigent_offender,
              client_start_date: val.client_start_date,
              refered_for_case: val.refered_for_case,
              assesed_level: val.assesed_level,
              no_service_availaible1: val.no_service_availaible1,
              no_service_availaible2: val.no_service_availaible2,
              refused_further_services: val.refused_further_services,
              client_seeking_treatment: val.client_seeking_treatment,
              medical_provider_review: val.medical_provider_review.length > 0 ? JSON.parse(val.medical_provider_review).map((medical, index) => {
                return ({
                  label: medical.label, value: medical.value,
                })
              }) : [],
              assesssed_level_of_care: val.assesssed_level_of_care.length > 0 ? JSON.parse(val.assesssed_level_of_care).map((assesssed, index) => {
                return ({
                  label: assesssed.label, value: assesssed.value,
                })
              }) : [],

              placed_level_of_care: val.placed_level_of_care?.length > 0 ? JSON.parse(val.placed_level_of_care).map((assesssed, index) => {
                return ({
                  label: assesssed.label, value: assesssed.value,
                })
              }) : [],

              // race: val.race,

              race: val.race.length > 0 ? JSON.parse(val.race).map((race, index) => {
                return ({
                  label: race.label, value: race.value,
                })
              }) : [],


              // Signature

              client_name: val.client_name,
              assesment_staff: val.assesment_staff,
              staff_name: val.staff_name,
              physician_name: val.physician_name,

              client_sign_uploaded_time: val.client_uploaded_date,
              client_sign_uploaded_time_Assesment_Staff: val.assesment_staff_uploaded_date,
              client_sign_uploaded_time_Physician: val.physician_uploaded_date,
              client_sign_uploaded_time_Staff: val.staff_uploaded_date,

              uploaded_by: val.uploaded_by,

              preview1: val.client_signature,
              preview2: val.assesment_staff_signature,
              preview3: val.staff_signature,
              preview4: val.physician_signature,


            }
          )
        })
      })
    }


    // Specific substance Options
    let res_specific_options = await axios.post(global.restApiURL + "customRest/getSubstanceProblemType", JSON.stringify({
      dataListBody: {
        apikey: sessionStorage.getItem("__TOKEN__"),
        client_id: sessionStorage.getItem("clientId"),
      },
      "problem_type": "",
    }));
    if (res_specific_options.status == 200 && res_specific_options.data.success == true) {
      this.setState({ specific_substance_options: res_specific_options.data.data.problem_type.substance });
    }

    // Problem type Options
    let substanceProblemType_res = await axios.post(global.restApiURL + "customRest/getProblems", JSON.stringify({ dataListBody: this.state.dataListBody }));
    if (substanceProblemType_res.status == 200 && substanceProblemType_res.data.success == true) {
      let options = substanceProblemType_res.data.data.problemType.length > 0 ? JSON.parse(substanceProblemType_res.data.data.problemType) : []
      this.setState({ problem_type_options: options })
    }

    // Load Disorder  Options
    let final_data = { dataListBody: this.state.dataListBody, }
    let disorder_res = await axios.post(global.restApiURL + "customRest/getSubstanceuseDisorderData", JSON.stringify(final_data))
    if (disorder_res.status == "200" && disorder_res.data.success) {
      if (disorder_res.data.data.substanceUseDisordersDrop.length > 0) {
        let tempVal = JSON.parse(disorder_res.data.data.substanceUseDisordersDrop)
        this.setState({ disorderOptions: tempVal })
      }

    }

    //  category Options
    let getCategories = await axios.post(global.restApiURL + "customRest/getCategories", JSON.stringify({ dataListBody: this.state.dataListBody }));
    if (getCategories.data.success && getCategories.status == 200) {
      this.setState({ categoryOptions: getCategories.data.data.categories })
    }

    // Class of Substance
    let classSubstance_res = await axios.post(global.restApiURL + "customRest/getSubstancesClass", JSON.stringify({ dataListBody: this.state.dataListBody }));
    if (classSubstance_res.status == 200 && classSubstance_res.data.success == true) {
      let options = classSubstance_res.data.data.substanceClass.length > 0 ? JSON.parse(classSubstance_res.data.data.substanceClass) : []
      this.setState({ Class_of_Substances_Options: options, })
    }
  }
  render() {
    return (
      <>
        {this.state.printData.map((val, index) => {
          return (
            <div className="hold-transition sidebar-mini text-sm" >
              <div className="wrapper">
                <div >
                  <section className="content-body mx-2 ">
                    <div style={{ paddingBottom: "40px" }}>
                      <h2 className="text-green text-center" style={{ pageBreakBefore: "always" }}>{val.form_type == "Adult_updateAdmh" ? "Assessment Update (Adult)" : val.form_type == "Adolescent_updateAdmh" ? "Assessment Update (Adolescent)" : ""}</h2>
                    </div>
                    {/* ================================== PRINT CLIENT INFORMATION START ==================================== */}

                    <div className="card " >
                      <div className='card-body' style={{ width: "100%" }}>
                        <div className="">
                          <p className="lead mx-auto" style={{ marginBottom: "1rem" }} >
                            <b className="text-green">Client Information</b>
                          </p>
                        </div>
                        <div>
                          <div className='row'>
                            <div className='col-6'>
                              <div className='form-group'>
                                <label>Client Name</label>
                                <input type="text" disabled name="client_name" value={val.client_name} className='form-control' />
                              </div>
                            </div>
                            <div className='col-6'>
                              <div className='form-group'>
                                <label>Legal Guardian</label>
                                <input type="text" name="legal_guardian" disabled value={val.legal_guardian} className='form-control' />
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-4'>
                              <div className='form-group'>
                                <label>Date of Birth</label>
                                <input type="text" name="dob" disabled value={moment.utc(val.dob).format('MM/DD/YYYY')} className='form-control' />
                              </div>
                            </div>
                            <div className='col-4'>
                              <div className='form-group'>
                                <label>Days in Treatment</label>
                                <input type="text" name="days_in_treatment" disabled value={val.days_in_treatment} className='form-control' />
                              </div>
                            </div>
                            <div className='col-4'>
                              <div className='form-group'>
                                <label>Phone</label>
                                <input type="text" name="primary_phone" disabled value={val.primary_phone} className='form-control' />
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-12'>
                              <div className='form-group'>
                                <label>Address</label>
                                <input type="text" disabled name="address_lns" value={val.address_lns} className='form-control' />
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-4'>
                              <div className='form-group'>
                                <label>Sex</label>
                                <select className='form-control' disabled name="gender" value={val.gender} >
                                  <option value=""></option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Trans MTF"> Trans MTF</option>
                                  <option value="Trans FTM">Trans FTM</option>
                                  <option value="Doesn’t identify as M/F/T">Doesn’t identify as M/F/T</option>
                                </select>
                              </div>
                            </div>
                            <div className='col-4'>
                              <div className='form-group'>
                                <label>SSN</label>
                                <input 
                                  type="text" 
                                  id="ssn_view" 
                                  disabled 
                                  name="ssn_text" 
                                  className="form-control" 
                                  value={val.ssn == undefined || val.ssn == null || val.ssn === "" ? "" : `***-**-` + val.ssn.substr(val.ssn.length - 4)} 
                                />
                              </div>
                            </div>

                            <div className='col-4'>
                              <div className='form-group'>
                                <label>Visit Type</label>
                                <select className='form-control' disabled name="visitType" value={val.visitType}>
                                  <option value=""></option>
                                  <option value="In-Person">In-Person</option>
                                  <option value="Telehealth">Telehealth</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-6'>
                              <div className='form-group'>
                                <label>Last School/Grade</label>
                                <input type="text" name="last_school" disabled value={val.last_school} className='form-control' />
                              </div>
                            </div>
                            <div className='col-6'>
                              <div className='form-group'>
                                <label>Race</label>
                                {/* <select className='form-control' disabled name="race" value={val.race}>
                                <option value=""></option>
                                <option value="alaskan_native">Alaskan Native</option>
                                <option value="american_indian">American Indian</option>
                                <option value="asian">Asian</option>
                                <option value="black_african_american">Black/African American</option>
                                <option value="caucasian_white">Caucasian/White</option>
                                <option value="multi_Racial">Multi-Racial</option>
                                <option value="native_hawaiian_other_pac_island">Native Hawaiian/Other Pac Island</option>
                                <option value="other">Other</option>
                              </select> */}
                                <div className="form-control heightRemove PdfBackgroundColor" name="race" style={{ backgroundColor: "#e9ecef" }}>{val.race.map((val) => { return (val.label) }).join(", ")}</div>
                                {/* <TextareaAutosize disabled rows={3} value={val.race.map((val) => { return (val.label) }).join(", ")} className="form-control" name="race"
                                  style={{ overflow: "hidden" }}
                                /> */}
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-12'>
                              <div className='form-group'>
                                <label>Current LOC</label>
                                <div className="form-control heightRemove PdfBackgroundColor" name="assesssed_level_of_care" style={{ backgroundColor: "#e9ecef" }}>{val.assesssed_level_of_care.map((val) => { return (val.label) }).join(", ")}</div>
                                {/* <TextareaAutosize disabled rows={2} value={val.assesssed_level_of_care.map((val) => { return (val.label) }).join(", ")} className="form-control" name="assesssed_level_of_care"
                                  style={{ overflow: "hidden" }} /> */}
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-12'>
                              <div className='form-group'>
                                <label>Recommended LOC</label>
                                <div className="form-control heightRemove PdfBackgroundColor" name="medical_provider_review" style={{ backgroundColor: "#e9ecef" }}>{val.medical_provider_review.map((val) => { return (val.label) }).join(", ")}</div>
                                {/* <TextareaAutosize disabled rows={2} value={val.medical_provider_review.map((val) => { return (val.label) }).join(", ")} className="form-control" name="medical_provider_review"
                                  style={{ overflow: "hidden" }} /> */}
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-6'>
                              <div className='form-group'>
                                <label>Priority IV User</label>
                                <select className='form-control' disabled name="priority_population" value={val.priority_population}>
                                  <option value=""></option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                            </div>
                            <div className='col-6'>
                              <div className='form-group'>
                                <label>Co-Dependent/Collateral</label>
                                <select name="client_seeking_treatment" disabled value={val.client_seeking_treatment} className='form-control'>
                                  <option value=""></option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          {val.client_seeking_treatment == "yes" &&
                            <>
                              <div className='row'>
                                <div className='col-12'>
                                  <label>Explain</label>
                                  <div className="form-control heightRemove PdfBackgroundColor" style={{ backgroundColor: "#e9ecef" }}>{val.client_seeking_explain}</div>
                                  {/* <TextareaAutosize
                                    minRows={(val.client_seeking_explain?.length / 131) + val.client_seeking_explain?.split(/\r\n|\r|\n/).length}
                                    disabled name="client_seeking_explain" 
                                    value={val.client_seeking_explain} 
                                    className='form-control'
                                    style={{ overflow: "hidden", textAlign: "justify" }}
                                  /> */}
                                </div>
                              </div>
                            </>
                          }
                          <div className='row'>
                            <div className='col-6'>
                              <div className='form-group'>
                                <label>Principal Source of Referral</label>
                                <select className="form-control" disabled={true} name="primary_referral" value={val.primary_referral}   >
                                  <option value=""></option>
                                  {this.referralSource.map((referal) => (
                                    <option value={referal.value}>
                                      {referal.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className='col-6'>
                              <div className='form-group'>
                                <label>Source of Financial Support</label>
                                <select className='form-control' disabled={true} name="princepal_source_of_income" value={val.princepal_source_of_income}>
                                  <option value=""></option>
                                  <option value="wages_salary">Wages/Salary</option>
                                  <option value="public_assistance"> Public Assistance</option>
                                  <option value="retirement_pension">Retirement/Pension</option>
                                  <option value="disablity">Disablity</option>
                                  <option value="unknown">UnKnown</option>
                                  <option value="none">None</option>
                                  <option value="other">Other</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-6'>
                              <div className='form-group'>
                                <label>Insurance Type</label>
                                <select className='form-control' disabled name="insurance_type" value={val.insurance_type}>
                                  <option value=""></option>
                                  {this.insurancetype.map((insurance) => (
                                    <option value={insurance.value}>
                                      {insurance.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className='col-6'>
                              <div className='form-group'>
                                <label>Source of Payment</label>
                                <select className='form-control' disabled={true} name="payment_source" value={val.payment_source}>
                                  <option value=""></option>
                                  {this.sourceOfPayment_Option.map(
                                    (insurance) => (
                                      <option value={insurance.value}>
                                        {insurance.label}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className={val.dependent_children === "Yes" ? 'col-4' : 'col-6'}>
                              <div className='form-group'>
                                <label>Number of dependent children at admission</label>
                                <select className="form-control" disabled name="dependent_children" value={val.dependent_children} >
                                  <option value=""> </option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>
                            {val.dependent_children === "Yes" && <>
                              <div className="col-4">
                                <label>How many?</label>
                                <input type="text" disabled name="dependent_children_number" value={val.dependent_children_number} className="form-control" />
                              </div>
                            </>}

                            <div className={val.dependent_children === "Yes" ? 'col-4' : 'col-6'}>
                              <div className="form-group">
                                <label>Pregnant?</label>
                                <select className="form-control" disabled name="pregnant" value={val.pregnant}>
                                  <option value=""> </option>
                                  <option value="yes" >Yes</option>
                                  <option value="no">No</option>
                                  <option value="na">NA</option>
                                  <option value="unknown">Unknown</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          {val.pregnant == "yes" && <>
                            <div className='row'>
                              <div className="col-6">
                                <div className="form-group">
                                  <label>Due date </label>
                                  <input type="text" disabled name="due_date" value={val.due_date} className="form-control" />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group">
                                  <label>If yes, how many weeks?</label>
                                  <input type="text" disabled name="weeks" value={val.weeks} className="form-control" />
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className="col-6">
                                <div className="form-group">
                                  <label>If yes, are you receiving prenatal care? </label>
                                  <select name="prenatal_care" disabled value={val.prenatal_care} className="form-control">
                                    <option value=""></option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group">
                                  <label>Total Number of Pregnancies</label>
                                  <input type="text" name="number_of_Pregnancies" disabled value={val.number_of_Pregnancies} className="form-control" />
                                </div>
                              </div>
                            </div>
                          </>}
                          <div className='row'>
                            <div className='col-4'>
                              <div className='form-group'>
                                <label>Military Status</label>
                                <select className='form-control' disabled={true} name="military_status" value={val.military_status} >
                                  <option value=""></option>
                                  <option value="na">NA</option>
                                  <option value="active">Active</option>
                                  <option value="non_active_honorable_dis">Non-Active Honorable Dis</option>
                                  <option value="non_active_dishonorable_dis">Non-Active Dishonorable Dis</option>
                                  <option value="military_dependent">Military Dependent</option>
                                </select>
                              </div>
                            </div>
                            <div className='col-8'>
                              <div className='form-group'>
                                <label>Current prescribed medications</label>
                                <div className="form-control heightRemove PdfBackgroundColor" style={{ backgroundColor: "#e9ecef" }}>{val.current_prescribed_medications}</div>
                                {/* <TextareaAutosize
                                  minRows={(val.current_prescribed_medications?.length / 80.33) + val.current_prescribed_medications?.split(/\r\n|\r|\n/).length}
                                  disabled name="current_prescribed_medications"
                                  value={val.current_prescribed_medications}
                                  className='form-control'
                                  style={{ overflow: "hidden", textAlign: "justify" }}
                                /> */}
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-4'>
                              <div className='form-group'>
                                <label>Is the client participating in MAT for an OUD?</label>
                                <select className='form-control' disabled name="participating_in_mat" value={val.participating_in_mat}>
                                  <option value=""> </option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />

                    {/* ================================== PRINT CLIENT INFORMATION End ==================================== */}
                    {/* ################################************************************################################ */}
                    {/* ================================== PRINT CLIENT CHARACTERISTICS START ==================================== */}
                    {/* <div className='row'> */}
                    <div className="card shadow-none" style={{ width: "100%" }}>
                      <div className="">
                        <p className="lead mx-auto" style={{ marginBottom: "1rem" }} >
                          <b className="text-green">Client Characteristics and Services</b>
                        </p>
                      </div>
                      <div className='row'>
                        <div className='col-6'>
                          <div className='form-group'>
                            <label>Marital Status</label>
                            <select className='form-control' disabled={true} value={val.maritial_status} name="maritial_status">
                              <option value=""></option>
                              <option value="Common Law">Common Law </option>
                              <option value="Single">Single</option>
                              <option value="Living Together">Living Together</option>
                              <option value="Married">Married</option>
                              <option value="Separated">Separated</option>
                              <option value="Divorced">Divorced</option>
                              <option value="Widowed">Widowed</option>

                            </select>
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='form-group'>
                            <label>Employment Status</label>
                            <select className='form-control' disabled={true} name="employment_status" value={val.employment_status}>
                              <option value=""></option>
                              <option value="confined_to_institution_correctional_facility">Confined to Institution/Correctional Facility</option>
                              <option value="disabled">Disabled</option>
                              <option value="full_time">Full-time</option>
                              <option value="homemaker">Homemaker</option>
                              <option value="part_time">Part-time</option>
                              <option value="retired">Retired</option>
                              <option value="student">Student</option>
                              <option value="supported_employment">Supporte d Employment</option>
                              <option value="unemployed_looking">Unemployed,looking</option>
                              <option value="unemployed_not_looking">Unemployed, not looking for 30 days</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-6'>
                          <div className='form-group'>
                            <label>Education</label>
                            <select className='form-control' disabled={true} name="educational_status" value={val.educational_status}>
                              <option value=""></option>
                              <option value="none">None</option>
                              <option value="student">Student</option>
                              <option value="graduated_hs">Graduated HS</option>
                              <option value="ged">GED</option>
                              <option value="dropped_out">Dropped Out</option>
                              <option value="college_graduate">College Graduate</option>
                              <option value="some_education_after_hs">Some education after HS</option>
                              <option value="other">Other</option>

                            </select>
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='form-group'>
                            <label>Hearing Status</label>
                            <select className='form-control' disabled={true} name="hearing_status" value={val.hearing_status} >
                              <option value=""></option>
                              <option value="hearing">Hearing</option>
                              <option value="hard_of_hearing">Hard of Hearing</option>
                              <option value="deaf">Deaf</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-6'>
                          <div className='form-group'>
                            <label>Language Preference</label>
                            <select className='form-control' disabled={true} name="language_preference" value={val.language_preference}>
                              <option value=""></option>
                              <option value="english">English</option>
                              <option value="american_sign_language">American Sign Language</option>
                              <option value="arabic">Arabic</option>
                              <option value="chinese">Chinese</option>
                              <option value="french_and_french_creole">French And French Creole(Including Cajun)</option>
                              <option value="german">German</option>
                              <option value="hindi">Hindi</option>
                              <option value="italian">Italian</option>
                              <option value="japanese">Japanese</option>
                              <option value="korean">Korean</option>
                              <option value="laotian">Laotian</option>
                              <option value="other_african_languages">Other African Languages</option>
                              <option value="other_asian_languages">Other Asian Languages</option>
                              <option value="other_europian_languages">Other Europian Languages</option>
                              <option value="other_indic_languages">Other Indic Languages</option>
                              <option value="persian">Persian</option>
                              <option value="portuguese">Portuguese</option>
                              <option value="russian">Russian</option>
                              <option value="portuguese">Spanish And Spanish Creole</option>
                              <option value="tagalog">Tagalog</option>
                              <option value="vietnamese">Vietnamese</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='form-group'>
                            <label>Linguistic Status</label>
                            <select className='form-control' disabled={true} name="linguistic_status" value={val.linguistic_status} >
                              {this.linguisticOption.map((linguistic) => (
                                <option value={linguistic.value}>
                                  {linguistic.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-6'>
                          <div className='form-group'>
                            <label>Living Arrangements <Tooltip name="indicate_living_arrangement" text="(Independent living is for adults age 18+ (lives alone OR Resides with family (18+). Resides with Family is ONLY a choice for clients 17 and under)" /></label>
                            <select className='form-control' disabled={true} name="indicate_living_arrangement" value={val.indicate_living_arrangement}  >
                              <option value=""></option>
                              <option value="alabama_housing_finance_authority">Alabama Housing Authority</option>
                              <option value="center_residential_program">Center Operated/Contracted Residential Program</option>
                              <option value="homeless_shelter">Homeless Shelter</option>
                              {/* {this.props.age > 18 ? <option value="Independent lives alone OR resides w/Family and is 18+ (Independent)">Independent lives alone OR resides w/Family and is 18+ (Independent)</option> : <option value="Independent lives alone OR resides w/Family">Independent lives alone OR resides w/Family</option>} */}
                              {val.form_type == "Adult_updateAdmh" ? <option value="Client_lives_alone"> Client Lives Alone AND is 18 + (Independent)</option> : <option value="resides_with_family">Resides with Family</option>}
                              <option value="jail_correctional_facility">Jail/Correctional Facility</option>
                              <option value="group_home_or_residential_setting">Group Home or Residential Setting</option>
                              <option value="center_subsidized_housing">Center Subsidized Housing</option>
                              <option value="other">Other</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    <hr />

                    {/* ================================== PRINT CLIENT CHARACTERISTICS END ==================================== */}
                    {/* ################################************************************################################ */}
                    {/* ================================== CO-OCCOURING START ==================================== */}

                    <div className="card shadow-none" style={{ width: "100%" }}>
                      <div className="">
                        <p className="lead mx-auto" style={{ marginBottom: "1rem" }} >
                          <b className="text-green">Co-Occurring</b>
                        </p>
                      </div>
                      <div>
                        {/*  */}
                        <div className="row">
                          <div className='col-6'>
                            <div className='form-group'>
                              <label>Co-Occurring Disorders Screen Results</label>
                              <select name="co_occuring_disorder_screen" disabled={true} value={val.co_occuring_disorder_screen} className='form-control'>
                                <option></option>
                                {this.co_occuring_disorder_option.map(option => (
                                  <option value={option.value}>{option.label}</option>
                                ))}
                              </select>

                            </div>
                          </div>
                          <div className='col-6'>
                            <div className='form-group'>
                              <label>Co-Occurring Disorders Assessment performed?</label>
                              <select name="co_occuring_disorder_assesment" disabled={true} value={val.co_occuring_disorder_assesment} className='form-control' onChange={this.handleChange}>
                                <option></option>
                                {this.co_occuring_disorder_option.map(option => (
                                  <option value={option.value}>{option.label}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className='col-6'>
                            <div className='form-group'>
                              <label>Co-Occurring Disorder Identified</label>
                              <input type="text" className='form-control' disabled value={val.co_occuring_disorders_identified} name="co_occuring_disorders_identified" />
                            </div>
                          </div>
                          <div className='col-6'>
                            <div className='form-group'>
                              <label>Number of Prior Treatment Episodes</label>
                              <input type="text" className='form-control' disabled value={val.prior_treatment_episodes} name="prior_treatment_episodes" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className='col-12'>
                            <div className='form-group'>
                              <label>Number of arrests 30 days prior to interview date</label>
                              <input type="text" className='form-control' disabled value={val.arrest_last_30_days} name="arrest_last_30_days" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className='col-12'>
                            <div className='form-group'>
                              <label>Has the client participated in self-help groups or support groups in the last 30 days?</label>
                              <select className='form-control' disabled={true} value={val.participated_in_self_care_groups} name="participated_in_self_care_groups">
                                <option value=""></option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        {val.participated_in_self_care_groups == "yes" &&
                          <div className="row">
                            <div className='col-6'>
                              <div className='form-group'>
                                <label>How often has the client attended self-help groups or support groups in the last 30 days?</label>
                                <select className='form-control' disabled={true} value={val.client_attended_self_help_groups_number} name="client_attended_self_help_groups_number">
                                  <option value=""></option>
                                  <option value="1_3_times_per_month">1-3 times per month</option>
                                  <option value="1_2_times_per_week">1-2 times per week</option>
                                  <option value="3_6_times_per_week">3-6 times per week</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        }
                        {/*  */}
                      </div>
                    </div>

                    <hr />
                    {/* ================================== CO-OCCOURING END ==================================== */}
                    {/* ################################************************************################################ */}
                    {/* ================================== PRINT SUBSTANCE-USE START ==================================== */}

                    <div className="row">
                      <div className="col-md-12">
                        <div className="card shadow-none">
                          <div className="">
                            <p
                              className="lead mx-auto"
                              style={{ marginBottom: "1rem" }}
                            >
                              <b className="text-green">Substance Use Background</b>
                            </p>
                          </div>
                          <div>
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <label>Do you have a history of withdrawal symptoms when
                                    you haven't been able to obtain alcohol and or
                                    other drugs (AOD), cut down on your use, or
                                    stopped using?</label>
                                  <input type="text" name="withdrawl_symptoms" disabled className="form-control" value={val.withdrawl_symptoms} />
                                </div>
                              </div>
                            </div>
                            {val.withdrawl_symptoms == "Yes" && (
                              <>
                                <div className="row">
                                  <div className="col-12">
                                    <label> Which symptoms have you had? </label>
                                    <div className="form-control heightRemove PdfBackgroundColor" name="old_symptoms" style={{ backgroundColor: "#e9ecef" }}>{val.old_symptoms.map((val) => { return (val.label) }).join(", ")}</div>
                                    {/* <TextareaAutosize disabled rows={2} value={val.old_symptoms.map((val) => { return (val.label) }).join(", ")} className="form-control" name="old_symptoms"
                                      style={{ overflow: "hidden" }} /> */}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 mt-3">
                                    <div className="form-group">
                                      <label> Are you currently experiencing any of the above? </label>
                                      <input type="text" name="new_symptoms" disabled className="form-control" value={val.new_symptoms} />
                                    </div>
                                  </div>
                                </div>
                                {val.new_symptoms == "Yes" &&
                                  (
                                    <>
                                      <div className="row">
                                        <div className="col-12">
                                          <div className="form-group">
                                            <label>Please explain</label>
                                            <div className="form-control heightRemove PdfBackgroundColor" name="new_symptoms_explain" style={{ backgroundColor: "#e9ecef" }}>{val.new_symptoms_explain}</div>
                                            {/* <TextareaAutosize
                                              disabled
                                              minRows={(val.new_symptoms_explain?.length / 131) + val.new_symptoms_explain?.split(/\r\n|\r|\n/).length}
                                              value={val.new_symptoms_explain}
                                              className="form-control"
                                              name="new_symptoms_explain"
                                              style={{ overflow: "hidden", textAlign: "justify" }}
                                            /> */}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                }

                              </>
                            )}
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <label> Have you used larger amounts of AOD than you intended or for longer than you intended?</label>
                                  <input type="text" name="Larger_amount_of_aod" disabled className="form-control" value={val.Larger_amount_of_aod} />
                                </div>
                              </div>
                            </div>
                            {val.Larger_amount_of_aod ==
                              "Yes" && (
                                <>
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="form-group">
                                        <label>Please explain</label>
                                        <div className="form-control heightRemove PdfBackgroundColor" name="Larger_amount_aod_explain" style={{ backgroundColor: "#e9ecef" }}>{val.Larger_amount_aod_explain}</div>
                                        {/* <TextareaAutosize
                                          disabled
                                          minRows={(val.Larger_amount_aod_explain?.length / 131) + val.Larger_amount_aod_explain?.split(/\r\n|\r|\n/).length}
                                          className="form-control"
                                          name="Larger_amount_aod_explain"
                                          value={val.Larger_amount_aod_explain}
                                          style={{ overflow: "hidden", textAlign: "justify" }}
                                        /> */}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            }
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <label> Have previous efforts to cut down or control AOD use been unsuccessful? </label>
                                  <input type="text" name="previous_effort_to_control_aod" disabled className="form-control" value={val.previous_effort_to_control_aod} />
                                </div>
                              </div>
                            </div>

                            {val.previous_effort_to_control_aod ==
                              "Yes" && (
                                <>
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="form-group">
                                        <label>Please explain</label>
                                        <div className="form-control heightRemove PdfBackgroundColor" name="previous_effort_to_cut_down_explain" style={{ backgroundColor: "#e9ecef" }}>{val.previous_effort_to_cut_down_explain}</div>
                                        {/* <TextareaAutosize
                                          disabled
                                          minRows={(val.previous_effort_to_cut_down_explain?.length / 131) + val.previous_effort_to_cut_down_explain?.split(/\r\n|\r|\n/).length}
                                          value={val.previous_effort_to_cut_down_explain}
                                          className="form-control"
                                          name="previous_effort_to_cut_down_explain"
                                          style={{ overflow: "hidden", textAlign: "justify" }}
                                        /> */}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            }


                            {val.substances && val.substances.map((row, index) => {
                              return (<>
                                <div className="card">
                                  <div className="card-body">
                                    <div className='marginBottom1'>
                                      <div className="row">
                                        <div className='col-6'>
                                          <div className='form-group'>
                                            <label>Class of Substances</label>
                                            <select className="form-control" disabled={true} name="class_of_substance" value={row.class_of_substance} >
                                              <option value=""> </option>
                                              {this.state.Class_of_Substances_Options.map((val) => {
                                                return (<option value={val.value}>{val.label}</option>)
                                              })}
                                            </select>
                                          </div>
                                        </div>

                                        <div className='col-6'>
                                          <div className='form-group'>
                                            <label>Substance/Problem Type  { } </label>
                                            <select className="form-control" disabled={true} name="problem_type" value={row.problem_type} >
                                              <option value=""> </option>
                                              {this.state.problem_type_options.map((val) => {
                                                return (<option value={val.value}>{val.label}</option>)
                                              })}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12">
                                          <div className="form-group">
                                            <label>Specific Substance</label>
                                            <select className="form-control" disabled={true} name="specific_substance" value={row.specific_substance} >
                                              <option value=""> </option>
                                              {this.state.specific_substance_options.map((val) => {
                                                return (<option value={val.value}>{val.label}</option>)
                                              })}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className={row.route_of_administration === "other_specify" ? 'col-6' : 'col-12'}>
                                          <div className='form-group'>
                                            <label>Route of Administration</label>
                                            <select name="route_of_administration" className="form-control" value={row.route_of_administration} disabled={true}>
                                              <option value=""></option>
                                              <option value="oral">Oral</option>
                                              <option value="smoking">Smoking</option>
                                              <option value="inhalation">Inhalation</option>
                                              <option value="injection">Injection/IV</option>
                                              <option value="injection_intramuscular">Injection/Intramuscular</option>
                                              <option value="other_specify">Other (Specify)</option>
                                            </select>
                                          </div>
                                        </div>
                                        {row.route_of_administration === "other_specify" &&
                                          <div className="col-6">
                                            <div className='form-group'>
                                              <label>Please Specify</label>
                                              <input type="text" name="other_route_of_administration" disabled className="form-control" value={row.other_route_of_administration} />
                                            </div>
                                          </div>
                                        }
                                      </div>

                                      <div className="row">
                                        <div className='col-6'>
                                          <div className='form-group'>
                                            <label>Age of 1st Use</label>
                                            <input type="text" name="age_of_1st_use" disabled className="form-control" value={row.age_of_1st_use} />
                                          </div>
                                        </div>
                                        <div className='col-6'>
                                          <div className='form-group'>
                                            <label>Date Last Used</label>
                                            <input type="text" name="date_last_used" disabled className="form-control" value={row.date_last_used} />
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className='col-6'>
                                      <div className='form-group'>
                                        <label>How Long Used(Years)</label>
                                        <input type="text" name="how_long_used" disabled className="form-control" value={row.how_long_used} />
                                      </div>
                                    </div> */}
                                      <div className="row">
                                        <div className='col-6'>
                                          <div className='form-group'>
                                            <label>Frequency of Use</label>
                                            {/* <input type="text" name="frequency_of_use" disabled className="form-control" value={row.frequency_of_use} /> */}
                                            <select className='form-control' disabled name="frequency_of_use" value={row.frequency_of_use} >
                                              <option value=""></option>
                                              <option value="no_use_in_the_past_month ">No use in the past month      </option>
                                              <option value="1_to_3_times_in_past_month ">1 to 3 times in past month     </option>
                                              <option value="1_to_2_times_in_the_past_week">1 to 2 times in the past week</option>
                                              <option value="3_to_6_times_in_the_past_week">3 to 6 times in the past week</option>
                                              <option value="daily">Daily</option>
                                              <option value="other">Other</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className='col-6'>
                                          <div className='form-group'>
                                            <label>Age of Regular Use</label>
                                            <input type="text" name="age_of_regular_use" disabled className="form-control" value={row.age_of_regular_use} />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className='col-6'>
                                          <div className='form-group'>
                                            <label>Amount of Regular Use</label>
                                            <input type="text" name="amount_of_regular_use" disabled className="form-control" value={row.amount_of_regular_use} />
                                          </div>
                                        </div>
                                        <div className='col-6'>
                                          <div className='form-group'>
                                            <label>Rank Order</label>
                                            <input type="text" name="rank_order" disabled className="form-control" value={row.rank_order} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div row className="marginBottom1">
                                      <div className="row">
                                        <div className='col-12'> How long Used&nbsp;&nbsp;&nbsp;
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className='col-4'>
                                          <div className='form-group'>
                                            <label>Day</label>
                                            <input type="text" name="how_long_used_days" disabled className="form-control" value={row.how_long_used_days} />
                                          </div>
                                        </div>
                                        <div className='col-4'>
                                          <div className='form-group'>
                                            <label>Month</label>
                                            <input type="text" name="how_long_used_months" disabled className="form-control" value={row.how_long_used_months} />
                                          </div>
                                        </div>
                                        <div className='col-4'>
                                          <div className='form-group'>
                                            <label>Year</label>
                                            <input type="text" name="how_long_used" disabled className="form-control" value={row.how_long_used} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div row className="marginBottom1">
                                      <div className="row">
                                        <div className='col-12'> Periods of Abstinence(Years)&nbsp;&nbsp;&nbsp;
                                          <span><input type="checkbox" name="knownAbstinence" disabled checked={row.knownAbstinence} /> Unknown </span>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className='col-4'>
                                          <div className='form-group'>
                                            <label>Day</label>
                                            <input type="text" name="periods_of_abstinence_days" disabled className="form-control" value={row.periods_of_abstinence_days} />
                                          </div>
                                        </div>
                                        <div className='col-4'>
                                          <div className='form-group'>
                                            <label>Month</label>
                                            <input type="text" name="periods_of_abstinence_months" disabled className="form-control" value={row.periods_of_abstinence_months} />
                                          </div>
                                        </div>
                                        <div className='col-4'>
                                          <div className='form-group'>
                                            <label>Year</label>
                                            <input type="text" name="periods_of_abstinence_years" disabled className="form-control" value={row.periods_of_abstinence_years} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                    {/*  */}
                                  </div>
                                </div>
                                <hr />
                              </>
                              )
                            })}
                            <div className="row">
                              <div className='col-12'>
                                <div className='form-group'>
                                  <label>Comments</label>
                                  <div className="form-control heightRemove PdfBackgroundColor" name="comments" style={{ backgroundColor: "#e9ecef" }}>{val.substance_comments}</div>
                                  {/* <TextareaAutosize name="comments" minRows={(val.substance_comments?.length / 131) + val.substance_comments?.split(/\r\n|\r|\n/).length} disabled className='form-control' value={val.substance_comments}
                                    style={{ overflow: "hidden", textAlign: "justify" }} /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    {/* =================================== PRINT SUBSTANCE-USE END =========================================*/}
                    {/* ################################************************************################################ */}
                    {/* ================================== PRINT CRITERIA QUESTIONS START ==================================== */}
                    <div className="row ">
                      <div className="col-12">
                        <div className="card shadow-none">
                          <div className="">
                            <p className="lead mx-auto" style={{ marginBottom: "1rem" }} >
                              <b className="text-green">Criteria Questions</b>
                            </p>
                          </div>
                          <div>
                            <div>
                              <div className="row ">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label> Do you spend a great deal of time in activities to obtain AOD and/or feeling its effects? </label>
                                    <input type="text" name="spend_great_deal_of_time" disabled className="form-control" value={val.spend_great_deal_of_time} />
                                  </div>
                                </div>
                              </div>
                              {val.spend_great_deal_of_time ==
                                "Yes" && (
                                  <>
                                    <div className="row ">
                                      <div className="col-12">
                                        <div className="form-group">
                                          <label>Please explain</label>
                                          <div className="form-control heightRemove PdfBackgroundColor" name="spend_great_deal_of_time_explain" style={{ backgroundColor: "#e9ecef" }}>{val.spend_great_deal_of_time_explain}</div>
                                          {/* <TextareaAutosize
                                            disabled
                                            minRows={(val.spend_great_deal_of_time_explain?.length / 131) + val.spend_great_deal_of_time_explain?.split(/\r\n|\r|\n/).length}
                                            className="form-control"
                                            value={val.spend_great_deal_of_time_explain}
                                            name="spend_great_deal_of_time_explain"
                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              <div className="row ">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label>  Have you had cravings or a strong desire to use AOD? </label>
                                    <input type="text" name="strong_desire_to_use_aod" disabled className="form-control" value={val.strong_desire_to_use_aod} />
                                  </div>
                                </div>
                              </div>
                              {val.strong_desire_to_use_aod ==
                                "Yes" && (
                                  <>
                                    <div className="row ">
                                      <div className="col-12">
                                        <div className="form-group">
                                          <label>Please explain</label>
                                          <div className="form-control heightRemove PdfBackgroundColor" name="strong_desire_to_use_aod_explain" style={{ backgroundColor: "#e9ecef" }}>{val.strong_desire_to_use_aod_explain}</div>
                                          {/* <TextareaAutosize
                                            disabled
                                            minRows={(val.strong_desire_to_use_aod_explain?.length / 131) + val.strong_desire_to_use_aod_explain?.split(/\r\n|\r|\n/).length}
                                            className="form-control"
                                            value={val.strong_desire_to_use_aod_explain}
                                            name="strong_desire_to_use_aod_explain"
                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              <div className="row ">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label> Have you used AOD to the point that you have neglected important social obligations (work, school, home)? </label>
                                    <input type="text" name="neglected_important_social_obligations" disabled className="form-control" value={val.neglected_important_social_obligations}
                                      style={{ overflow: "hidden" }} />
                                  </div>
                                </div>
                              </div>

                              {val.neglected_important_social_obligations ==
                                "Yes" && (
                                  <>
                                    <div className="row ">
                                      <div className="col-12">
                                        <div className="form-group">
                                          <label>Please explain</label>
                                          <div className="form-control heightRemove PdfBackgroundColor" name="neglected_important_social_obligations_explain" style={{ backgroundColor: "#e9ecef" }}>{val.neglected_important_social_obligations_explain}</div>
                                          {/* <TextareaAutosize
                                            disabled
                                            minRows={(val.neglected_important_social_obligations_explain?.length / 131) + val.neglected_important_social_obligations_explain?.split(/\r\n|\r|\n/).length}
                                            className="form-control"
                                            value={val.neglected_important_social_obligations_explain}
                                            name="neglected_important_social_obligations_explain"
                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              <div className="row ">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label>  Have you continued to use despite the negative consequences (at work, school, home)? </label>
                                    <input type="text" name="continue_to_use_despite_negative_consequences" disabled className="form-control" value={val.continue_to_use_despite_negative_consequences} />
                                  </div>
                                </div>
                              </div>

                              {val.continue_to_use_despite_negative_consequences ==
                                "Yes" && (
                                  <>
                                    <div className="row ">
                                      <div className="col-12">
                                        <div className="form-group">
                                          <label>Please explain</label>
                                          <div className="form-control heightRemove PdfBackgroundColor" name="continue_to_use_despite_negative_consequences_explain" style={{ backgroundColor: "#e9ecef" }}>{val.continue_to_use_despite_negative_consequences_explain}</div>
                                          {/* <TextareaAutosize
                                            disabled
                                            minRows={(val.continue_to_use_despite_negative_consequences_explain?.length / 131) + val.continue_to_use_despite_negative_consequences_explain?.split(/\r\n|\r|\n/).length}
                                            className="form-control"
                                            value={val.continue_to_use_despite_negative_consequences_explain}
                                            name="continue_to_use_despite_negative_consequences_explain"
                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              <div className="row ">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label> Have you continued using AOD even though your  use affected you socially (fights, problems in relationships, etc.)? </label>
                                    <input type="text" name="affected_you_socially" disabled className="form-control" value={val.affected_you_socially} />
                                  </div>
                                </div>
                              </div>
                              {val.affected_you_socially ==
                                "Yes" && (
                                  <>
                                    <div className="row ">
                                      <div className="col-12">
                                        <div className="form-group">
                                          <label>Please explain</label>
                                          <div className="form-control heightRemove PdfBackgroundColor" name="affected_you_socially_explain" style={{ backgroundColor: "#e9ecef" }}>{val.affected_you_socially_explain}</div>
                                          {/* <TextareaAutosize
                                            disabled
                                            minRows={(val.affected_you_socially_explain?.length / 131) + val.affected_you_socially_explain?.split(/\r\n|\r|\n/).length}
                                            className="form-control"
                                            value={val.affected_you_socially_explain}
                                            name="affected_you_socially_explain"
                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              <div className="row ">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label> Has your AOD use caused you to give up or not participate in social, occupational or recreational activities that you once enjoyed? </label>
                                    <input type="text" name="give_up_on_participate" disabled className="form-control" value={val.give_up_on_participate} />
                                  </div>
                                </div>
                              </div>

                              {val.give_up_on_participate ==
                                "Yes" && (
                                  <>
                                    <div className="row ">
                                      <div className="col-12">
                                        <div className="form-group">
                                          <label>Please explain</label>
                                          <div className="form-control heightRemove PdfBackgroundColor" name="give_up_on_participate_explain" style={{ backgroundColor: "#e9ecef" }}>{val.give_up_on_participate_explain}</div>
                                          {/* <TextareaAutosize
                                            disabled
                                            minRows={(val.give_up_on_participate_explain?.length / 131) + val.give_up_on_participate_explain?.split(/\r\n|\r|\n/).length}
                                            className="form-control"
                                            value={val.give_up_on_participate_explain}
                                            name="give_up_on_participate_explain"
                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              <div className="row ">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label> Have you continued to use despite placing yourself and/or others in dangerous or unsafe  situations?</label>
                                    <input type="text" name="placing_yourself_in_danger" disabled className="form-control" value={val.placing_yourself_in_danger} />
                                  </div>
                                </div>
                              </div>

                              {val.placing_yourself_in_danger ==
                                "Yes" && (
                                  <>
                                    <div className="row ">
                                      <div className="col-12">
                                        <div className="form-group">
                                          <label>Please explain</label>
                                          <div className="form-control heightRemove PdfBackgroundColor" name="placing_yourself_in_danger_explain" style={{ backgroundColor: "#e9ecef" }}>{val.placing_yourself_in_danger_explain}</div>
                                          {/* <TextareaAutosize
                                            disabled
                                            minRows={(val.placing_yourself_in_danger_explain?.length / 131) + val.placing_yourself_in_danger_explain?.split(/\r\n|\r|\n/).length}
                                            className="form-control"
                                            value={val.placing_yourself_in_danger_explain}
                                            name="placing_yourself_in_danger_explain"
                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              <div className="row ">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label>Have you continued to use AOD after knowing it caused or contributed to physical and/or psychological problems?</label>
                                    <input type="text" name="contribute_to_physical_problem" disabled className="form-control" value={val.contribute_to_physical_problem} />
                                  </div>
                                </div>
                              </div>

                              {val.contribute_to_physical_problem ==
                                "Yes" && (
                                  <>
                                    <div className="row ">
                                      <div className="col-12">
                                        <div className="form-group">
                                          <label>Please explain</label>
                                          <div className="form-control heightRemove PdfBackgroundColor" name="contribute_to_physical_problem_explain" style={{ backgroundColor: "#e9ecef" }}>{val.contribute_to_physical_problem_explain}</div>
                                          {/* <TextareaAutosize
                                            disabled
                                            minRows={(val.contribute_to_physical_problem_explain?.length / 131) + val.contribute_to_physical_problem_explain?.split(/\r\n|\r|\n/).length}
                                            className="form-control"
                                            value={val.contribute_to_physical_problem_explain}
                                            name="contribute_to_physical_problem_explain"
                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              <div className="row ">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label> Do you need more AOD to get the same high or feel the same effect?{" "} </label>
                                    <input type="text" name="feel_same_effect" disabled className="form-control" value={val.feel_same_effect} />
                                  </div>
                                </div>
                              </div>

                              {val.feel_same_effect == "Yes" && (
                                <>
                                  <div className="row ">
                                    <div className="col-12">
                                      <div className="form-group">
                                        <label>Please explain</label>
                                        <div className="form-control heightRemove PdfBackgroundColor" name="feel_same_effect_explain" style={{ backgroundColor: "#e9ecef" }}>{val.feel_same_effect_explain}</div>
                                        {/* <TextareaAutosize
                                          disabled
                                          minRows={(val.feel_same_effect_explain?.length / 131) + val.feel_same_effect_explain?.split(/\r\n|\r|\n/).length}
                                          className="form-control"
                                          value={val.feel_same_effect_explain}
                                          name="feel_same_effect_explain"
                                          style={{ overflow: "hidden", textAlign: "justify" }}
                                        /> */}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {/*  */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    {/* ================================== PRINT CRITERIA QUESTIONS END ==================================== */}
                    {/* ################################************************************################################ */}
                    {/* ================================== PRINT RISK RATING START ==================================== */}
                    <div className="row ">
                      <div className="col-12">
                        <div className="card shadow-none">
                          <div className="">
                            <p className="lead mx-auto" style={{ marginBottom: "1rem" }} >
                              <b className="text-green">Risk Ratings</b>
                            </p>
                          </div>
                          <div>
                            {/*  */}
                            <div className='card ' style={{ padding: "10px" }}>
                              <div className="row ">
                                <div className='col-12'>
                                  <div className='form-group'>
                                    <label>ACUTE INTOXICATION AND/OR WITHDRAWAL POTENTIAL</label>
                                    <input type="checkbox" style={{ marginLeft: 8 }} name="isCheckedAcute" checked={val.isCheckedAcute}/> No Update
                                    <select disabled={true} name="acute_risk_rating" value={val.acute_risk_rating} className='form-control'>
                                      <option value="0">0</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="row ">
                                <div className='col-12'>
                                  <div className="form-control heightRemove PdfBackgroundColor" name='acute_risk_rating_information' style={{ backgroundColor: "#e9ecef" }}>{val.acute_risk_rating_information}</div>
                                  {/* <TextareaAutosize
                                    disabled
                                    className='form-control'
                                    minRows={(val.acute_risk_rating_information?.length / 127) + val.acute_risk_rating_information?.split(/\r\n|\r|\n/).length}
                                    value={val.acute_risk_rating_information}
                                    name='acute_risk_rating_information'
                                    style={{ overflow: "hidden", textAlign: "justify" }}
                                  /> */}
                                </div>
                              </div>
                              <div className="row ">
                                <div className='col-12 mt-2'>
                                  <div className="form-control heightRemove PdfBackgroundColor" name='acute_risk_rating_summury' style={{ backgroundColor: "#e9ecef" }}>{val.acute_risk_rating_summury}</div>
                                  {/* <TextareaAutosize
                                    disabled
                                    className='form-control'
                                    minRows={(val.acute_risk_rating_summury?.length / 127) + val.acute_risk_rating_summury?.split(/\r\n|\r|\n/).length}
                                    value={val.acute_risk_rating_summury}
                                    name='acute_risk_rating_summury'
                                    style={{ overflow: "hidden", textAlign: "justify" }} /> */}
                                </div>
                              </div>
                            </div>
                            <div className='card' style={{ padding: "10px " }}>
                              <div className="row ">
                                <div className='col-12'>
                                  <div className='form-group'>
                                    <label>BIOMEDICAL CONDITIONS AND COMPLICATIONS</label>
                                    <input type="checkbox" style={{ marginLeft: 8 }} name="isCheckedBiomedical" checked={val.isCheckedBiomedical }/>{" "} No Update
                                    <select className='form-control' disabled={true} value={val.biomedical_risk_rating} name="biomedical_risk_rating">
                                      <option value="0">0</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="row ">
                                <div className='col-12'>
                                  <div className="form-control heightRemove PdfBackgroundColor" name="biomedical_risk_rating_information" style={{ backgroundColor: "#e9ecef" }}>{val.biomedical_risk_rating_information}</div>
                                  {/* <TextareaAutosize
                                    className='form-control'
                                    disabled
                                    minRows={(val.biomedical_risk_rating_information?.length / 127) + val.biomedical_risk_rating_information?.split(/\r\n|\r|\n/).length}
                                    value={val.biomedical_risk_rating_information}
                                    name="biomedical_risk_rating_information"
                                    style={{ overflow: "hidden", textAlign: "justify" }} /> */}
                                </div>
                              </div>
                              <div className="row ">
                                <div className='col-12 mt-2'>
                                  <div className="form-control heightRemove PdfBackgroundColor" name="biomedical_comments" style={{ backgroundColor: "#e9ecef" }}>{val.biomedical_comments}</div>
                                  {/* <TextareaAutosize
                                    className='form-control'
                                    disabled
                                    minRows={(val.biomedical_comments?.length / 127) + val.biomedical_comments?.split(/\r\n|\r|\n/).length}
                                    value={val.biomedical_comments}
                                    name="biomedical_comments"
                                    style={{ overflow: "hidden", textAlign: "justify" }} /> */}
                                </div>
                              </div>
                            </div>

                            <div className='card ' style={{ padding: "10px" }}>
                              <div className="row ">
                                <div className='col-12'>
                                  <div className='form-group'  >
                                    <label >EMOTIONAL, BEHAVIORAL, COGNITIVE CONDITIONS & COMPLICATIONS</label>
                                    <input type="checkbox" style={{ marginLeft: 8 }} checked={val.isCheckedEmotional} name="isCheckedEmotional"  />{" "}No Update
                                    <select className='form-control' disabled={true} name="emotional_risk_rating" value={val.emotional_risk_rating} >
                                      <option value="0">0</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="row ">
                                <div className='col-12'>
                                  <div className="form-control heightRemove PdfBackgroundColor" name="emotional_risk_rating_information" style={{ backgroundColor: "#e9ecef" }}>{val.emotional_risk_rating_information}</div>
                                  {/* <TextareaAutosize
                                    className='form-control'
                                    disabled
                                    minRows={(val.emotional_risk_rating_information?.length / 127) + val.emotional_risk_rating_information?.split(/\r\n|\r|\n/).length}
                                    value={val.emotional_risk_rating_information}
                                    name="emotional_risk_rating_information"
                                    style={{ overflow: "hidden", textAlign: "justify" }} /> */}
                                </div>
                              </div>
                              <div className="row ">
                                <div className='col-12 mt-2'>
                                  <div className="form-control heightRemove PdfBackgroundColor" name="emotional_comments" style={{ backgroundColor: "#e9ecef" }}>{val.emotional_comments}</div>
                                  {/* <TextareaAutosize
                                    className='form-control'
                                    disabled
                                    minRows={(val.emotional_comments?.length / 127) + val.emotional_comments?.split(/\r\n|\r|\n/).length}
                                    value={val.emotional_comments}
                                    name="emotional_comments"
                                    style={{ overflow: "hidden", textAlign: "justify" }} /> */}
                                </div>
                              </div>
                            </div>


                            <div className='card ' style={{ padding: "10px" }}>
                              <div className="row ">
                                <div className='col-12'>
                                  <div className='form-group'>
                                    <label>READINESS FOR CHANGE</label>
                                    <input  type="checkbox" style={{ marginLeft: 8 }}  checked={val.isCheckedReadiness} name="isCheckedReadiness"  />{" "}No Update
                                    <select className='form-control' disabled={true} value={val.readiness_risk_rating} name='readiness_risk_rating'>
                                      <option value="0">0</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="row ">
                                <div className='col-12'>
                                  <div className="form-control heightRemove PdfBackgroundColor" name="readiness_risk_rating_information" style={{ backgroundColor: "#e9ecef" }}>{val.readiness_risk_rating_information}</div>
                                  {/* <TextareaAutosize
                                    className='form-control'
                                    disabled
                                    minRows={(val.readiness_risk_rating_information?.length / 127) + val.readiness_risk_rating_information?.split(/\r\n|\r|\n/).length}
                                    value={val.readiness_risk_rating_information}
                                    name="readiness_risk_rating_information"
                                    style={{ overflow: "hidden", textAlign: "justify" }} /> */}
                                </div>
                              </div>
                              <div className="row ">
                                <div className='col-12 mt-2'>
                                  <div className="form-control heightRemove PdfBackgroundColor" name="readiness_comments" style={{ backgroundColor: "#e9ecef" }}>{val.readiness_comments}</div>
                                  {/* <TextareaAutosize
                                    className='form-control'
                                    disabled
                                    minRows={(val.readiness_comments?.length / 127) + val.readiness_comments?.split(/\r\n|\r|\n/).length}
                                    value={val.readiness_comments}
                                    name="readiness_comments"
                                    style={{ overflow: "hidden", textAlign: "justify" }} /> */}
                                </div>
                              </div>
                            </div>

                            <div className='card ' style={{ padding: "10px " }}>
                              <div className="row ">
                                <div className='col-12'>
                                  <div className='form-group'>
                                    <label>RELAPSE, CONTINUED USE OR CONTINUED PROBLEMS</label>
                                    <input  type="checkbox"  style={{ marginLeft: 8 }}  checked={val.isCheckedRelapse} name="isCheckedRelapse" />{" "}No Update
                                    <select className='form-control' disabled={true} name="relapse_risk_rating" value={val.relapse_risk_rating} >
                                      <option value="0">0</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="row ">
                                <div className='col-12'>
                                  <div className="form-control heightRemove PdfBackgroundColor" name="relapse_risk_rating_information" style={{ backgroundColor: "#e9ecef" }}>{val.relapse_risk_rating_information}</div>
                                  {/* <TextareaAutosize
                                    className='form-control'
                                    disabled
                                    minRows={(val.relapse_risk_rating_information?.length / 127) + val.relapse_risk_rating_information?.split(/\r\n|\r|\n/).length}
                                    value={val.relapse_risk_rating_information}
                                    name="relapse_risk_rating_information"
                                    style={{ overflow: "hidden", textAlign: "justify" }} /> */}
                                </div>
                              </div>
                              <div className="row ">
                                <div className='col-12 mt-2'>
                                  <div className="form-control heightRemove PdfBackgroundColor" name="problem_comments2" style={{ backgroundColor: "#e9ecef" }}>{val.problem_comments2}</div>
                                  {/* <TextareaAutosize
                                    className='form-control'
                                    disabled rows={"auto"}
                                    minRows={(val.problem_comments2?.length / 127) + val.problem_comments2?.split(/\r\n|\r|\n/).length}
                                    value={val.problem_comments2}
                                    name="problem_comments2"
                                    style={{ overflow: "hidden", textAlign: "justify" }} /> */}
                                </div>
                              </div>
                            </div>
                            <div className='card ' style={{ padding: "10px" }}>
                              <div className="row ">
                                <div className='col-12'>
                                  <div className='form-group'>
                                    <label>RECOVERY LIVING ENVIRONMENT</label>
                                    <input type="checkbox" style={{ marginLeft: 8 }} checked={val.isCheckedRecovery} name="isCheckedRecovery" />{" "} No Update
                                    <select className='form-control' disabled={true} name="recovery_risk_rating" value={val.recovery_risk_rating} >
                                      <option value="0">0</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="row ">
                                <div className='col-12'>
                                  <div className="form-control heightRemove PdfBackgroundColor" name="recovery_risk_rating_information" style={{ backgroundColor: "#e9ecef" }}>{val.recovery_risk_rating_information}</div>
                                  {/* <TextareaAutosize
                                    disabled
                                    className='form-control'
                                    minRows={(val.recovery_risk_rating_information?.length / 127) + val.recovery_risk_rating_information?.split(/\r\n|\r|\n/).length}
                                    value={val.recovery_risk_rating_information}
                                    name="recovery_risk_rating_information"
                                    style={{ overflow: "hidden", textAlign: "justify" }} /> */}
                                </div>
                              </div>
                              <div className="row ">
                                <div className='col-12 mt-2'>
                                  <div className="form-control heightRemove PdfBackgroundColor" name="enviornment_comments" style={{ backgroundColor: "#e9ecef" }}>{val.enviornment_comments}</div>
                                  {/* <TextareaAutosize
                                    disabled className='form-control'
                                    minRows={(val.enviornment_comments?.length / 127) + val.enviornment_comments?.split(/\r\n|\r|\n/).length}
                                    value={val.enviornment_comments}
                                    name="enviornment_comments"
                                    style={{ overflow: "hidden", textAlign: "justify" }}
                                  /> */}
                                </div>
                              </div>
                            </div>
                            {/*  */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />

                    {/* ================================== PRINT RISK RATING END ==================================== */}
                    {/* ################################************************************################################ */}
                    {/* ================================== PRINT DSM DIAGNOSIS START ==================================== */}
                    <div div className="row ">
                      <div className="col-12">
                        <div className="card shadow-none">
                          <div className="">
                            <p className="lead mx-auto" style={{ marginBottom: "1rem" }} > <b className="text-green">DSM 5 Diagnosis</b></p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {
                      val.substanceUseDisorder.map((row, index) => {
                        return (
                          <>
                            <div className="card">
                              <div className="card-body">
                                <div div className="row ">
                                  <div className="col-12" >
                                    <div className="form-group">
                                      <label>Disorder</label>
                                      <select className="form-control" name="disorder" value={row.disorder} disabled={true} >
                                        <option value=""></option>
                                        {this.state.disorderOptions.map((val) => {
                                          return <option value={val.value}>{val.label}</option>
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="row ">
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label>ICD 10 Code</label>
                                      <input type="text" disabled className="form-control" name="icd_10_code" id={index} value={row.icd_10_code} />
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-group">
                                      <label>Specifier</label>
                                      <select name="specifier" className="form-control" id={index} value={row.specifier} disabled={true} >
                                        <option value=""></option>
                                        {this.specifierOption.map((specifier) => (
                                          <option value={specifier.value}>{specifier.label}</option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </>
                        )
                      })
                    }
                    {/* </div> */}
                    <hr />
                    {/* ================================== PRINT DSM DIAGNOSIS  END ==================================== */}
                    {/* ################################************************************################################ */}
                    {/* ================================== PRINT  PROBLEM DISORDER START ==================================== */}
                    <div div className="row ">
                      <div className="col-md-12 mt-2">
                        <div className="card shadow-none">
                          <div className="">
                            <p className="lead mx-auto" style={{ marginBottom: "1rem" }} > <b className="text-green">Problem Disorder</b></p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {val.problems.map((problem, key) => {
                      return (
                        <>
                          {key < 3 ? <>

                            <div className="card-header" id="headingOne">
                              <h5>{key == 0 ? "Primary" : key == 1 ? "Secondary" : key == 2 ? "Tertiary" : ""}</h5>
                            </div>

                            <div className='card-body'>
                              <div className="row ">
                                <div className='col-12'>
                                  <div className='form-group'>
                                    <label>{key == 0 ? "Primary" : key == 1 ? "Secondary" : key == 2 ? "Tertiary" : ""} Substance Problem Type</label>
                                    <select name="problem_type" disabled={true} className='form-control' value={problem.problem_type}>
                                      <option value=""></option>
                                      {this.state.problem_type_options.map((val) => {
                                        return (<option value={val.value}>{val.label}</option>)
                                      })}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="row ">
                                <div className='col-12'>
                                  <div className='form-group'>
                                    <label>{key == 0 ? "Primary" : key == 1 ? "Secondary" : key == 2 ? "Tertiary" : ""} Substance Detail  </label>
                                    <select name="specific_substance" className='form-control' disabled={true} value={problem.specific_substance}>
                                      <option value=""></option>
                                      {this.state.specific_substance_options.map((val) => {
                                        return (<option value={val.value}>{val.label}</option>)
                                      })}
                                    </select>

                                  </div>
                                </div>
                              </div>
                              <div className="row ">
                                <div className='col-6'>
                                  <div className='form-group'>
                                    <label>{key == 0 ? "Primary" : key == 1 ? "Secondary" : key == 2 ? "Tertiary" : ""} Substance Route</label>
                                    <select name="route_of_administration" className='form-control' disabled={true} value={problem.route_of_administration} >
                                      <option value=""> </option>
                                      <option value="oral">Oral</option>
                                      <option value="smoking">Smoking</option>
                                      <option value="inhalation">Inhalation</option>
                                      <option value="injection">Injection/IV</option>
                                      <option value="injection_intramuscular">Injection/Intramuscular</option>
                                      <option value="other_specify">Other</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='col-6'>
                                  <div className='form-group'>
                                    <label>{key == 0 ? "Primary" : key == 1 ? "Secondary" : key == 2 ? "Tertiary" : ""} Substance Frequency</label>
                                    <select name="frequency_of_use" className='form-control' disabled={true} value={problem.frequency_of_use}>
                                      <option value=""></option>
                                      <option value="no_use_in_the_past_month ">No use in the past month      </option>
                                      <option value="1_to_3_times_in_past_month ">1 to 3 times in past month     </option>
                                      <option value="1_to_2_times_in_the_past_week">1 to 2 times in the past week</option>
                                      <option value="3_to_6_times_in_the_past_week">3 to 6 times in the past week</option>
                                      <option value="daily">Daily</option>
                                      <option value="other">Other</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="row ">
                                <div className='col-6'>
                                  <div className='form-group'>
                                    <label>{key == 0 ? "Primary" : key == 1 ? "Secondary" : key == 2 ? "Tertiary" : ""} Substance Age of 1st Use</label>
                                    <input type="text" name="age_of_1st_use" disabled className='form-control' value={problem.age_of_1st_use} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </> : <></>
                          }
                        </>
                      )
                    })}

                    {/* ================================== PRINT  ASSESSED LEVEL OF CARE START ==================================== */}
                    <div className="row ">
                      <div className="col-12">
                        <div className="card shadow-none">
                          <div className="">
                            <p className="lead mx-auto" style={{ marginBottom: "1rem", }} > <b className="text-green">Assessed Level of Care</b></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className=""> */}
                    <div className="card  ">
                      <div className="card-body">
                        {/* <div className='row marginBottom1'> */}
                        <div className="row ">
                          <div className='col-12'>
                            <div className='form-group'>
                              <label>Assessed Level of Care </label>
                              <div className="form-control heightRemove PdfBackgroundColor" name="assesssed_level_of_care" style={{ backgroundColor: "#e9ecef" }}>{val.assesssed_level_of_care.map((val) => { return (val.label) }).join(", ")}</div>
                              {/* <TextareaAutosize rows={2} name="assesssed_level_of_care" value={val.assesssed_level_of_care.map((val) => { return (val.label) }).join(", ")} disabled={true} className='form-control' style={{ overflow: "hidden" }} /> */}

                            </div>
                          </div>
                        </div>
                        <div className="row ">
                          <div className='col-12'>
                            <div className='form-group'>
                              <label>Placed Level of Care</label>
                              <div className="form-control heightRemove PdfBackgroundColor" name="placed_level_of_care" style={{ backgroundColor: "#e9ecef" }}>{val.placed_level_of_care.map((val) => { return (val.label) }).join(", ")}</div>
                              {/* <TextareaAutosize rows={3} name="placed_level_of_care" value={val.placed_level_of_care.map((val) => { return (val.label) }).join(", ")} disabled={true} className='form-control' style={{ overflow: "hidden" }} /> */}
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                        {/* <div className='row marginBottom1'> */}
                        <div className="row ">
                          <div className='col-12'>
                            <div className='form-group'>
                              <label>Reason for difference</label>
                              <select name="reason_for_difference" className='form-control' disabled={true} value={val.reason_for_difference} >
                                <option value=""></option>
                                {this.reasonDifferenceOptions.length > 0 && this.reasonDifferenceOptions.map(option => (
                                  <option value={option.value}>{option.label}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row ">
                          <div className='col-12'>
                            <div className='form-group'>
                              <label>Comment</label>
                              <div className="form-control heightRemove PdfBackgroundColor" name="assessed_comments" style={{ backgroundColor: "#e9ecef" }}>{val.assessed_comments}</div>
                              {/* <TextareaAutosize
                                minRows={(val.assessed_comments?.length / 122.33) + val.assessed_comments?.split(/\r\n|\r|\n/).length}
                                name="assessed_comments"
                                disabled={true}
                                value={val.assessed_comments}
                                className='form-control'
                                style={{ overflow: "hidden", textAlign: "justify" }}
                              /> */}
                            </div>
                          </div>
                        </div>

                        {/* <div className='row marginBottom1'> */}
                        <div className="row ">
                          <div className='col-6'>
                            <div className='form-group'>
                              <label>An appropriate release for this information in on file for this client?</label>
                              <select className='form-control' disabled={true} value={val.appropriate_release_of_information} name="appropriate_release_of_information">
                                <option value=''> </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                          </div>

                          <div className='col-6'>
                            <div className='form-group'>
                              <label>Was this client referred from a Drug Court?</label>
                              <select className='form-control' disabled={true} value={val.reffered_from_drug_court} name="reffered_from_drug_court" >
                                <option value=''> </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        {/* </div> */}
                        {/* <div className='row marginBottom1'> */}
                        <div className="row ">
                          <div className='col-6'>
                            <div className='form-group'>
                              <label>Has a court of law established this client to be an indigent offender?</label>
                              <select className='form-control' disabled={true} name="indigent_offender" value={val.indigent_offender} >
                                <option value=''> </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    <hr />
                    {/* ================================== PRINT  ASSESSED LEVEL OF CARE  END ==================================== */}
                    {/* ################################************************************################################ */}
                    {/* ================================== PRINT  SIGNATURE START ==================================== */}
                    {/* table start */}
                    <table className="table table-bordered table-striped">
                      <thead>
                        <th>Person</th>
                        <th>Name</th>
                        <th colSpan={2}>Signature</th>
                        <th>Uploaded</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input type="text" name="Client" disabled value="Client" className="form-control" />
                          </td>
                          <td>
                            <input type="text" name="" disabled value={val.client_name} className="form-control" />
                          </td>
                          <td colSpan={2}>
                            {val.preview1 && <img src={val.preview1} height="40px" width="80%" />}

                            {/* {val.client_signature} */}
                          </td>
                          <td> {val.preview1 == "" ? "" : val.client_sign_uploaded_time} {val.preview1 == "" ? "" : val.client_sign_uploaded_time && "by " + val.preview1 == "" ? "" : val.uploaded_by}   </td>
                        </tr>
                        <tr>
                          <td>
                            <input type="text" name="assesment_staff" disabled value="Assesment Staff" className="form-control" />
                          </td>
                          <td>
                            <input type="text" name="" disabled value={val.assesment_staff} className="form-control" />
                          </td>
                          <td colSpan={2}>
                            {val.preview2 && <img src={val.preview2} height="40px" width="80%" />}
                          </td>
                          <td> {val.preview2 == "" ? "" : val.client_sign_uploaded_time_Assesment_Staff} {val.preview2 == "" ? "" : val.client_sign_uploaded_time_Assesment_Staff && "by " + val.preview2 == "" ? "" : val.uploaded_by} </td>
                        </tr>
                        <tr>
                          <td>
                            <input type="text" name="staff" disabled value="Staff" className="form-control" />
                          </td>
                          <td>
                            <input type="text" name="" disabled value={val.staff_name} className="form-control" />
                          </td>
                          <td colSpan={2}>
                            {val.preview3 && <img src={val.preview3} height="40px" width="80%" />}
                          </td>
                          <td>{val.preview3 == "" ? "" : val.client_sign_uploaded_time_Staff}  {val.preview3 == "" ? "" : val.client_sign_uploaded_time_Staff && "by " + val.preview3 == "" ? "" : val.uploaded_by} </td>
                        </tr>
                        <tr>
                          <td>
                            <input type="text" name="physician" disabled value="Physician" className="form-control" />
                          </td>
                          <td>
                            <input type="text" name="physician_name" disabled value={val.physician_name} className="form-control" />
                          </td>
                          <td colSpan={2}>
                            {val.preview4 && <img src={val.preview4} height="40px" width="80%" />}
                          </td>
                          <td>{val.preview4 == "" ? "" : val.client_sign_uploaded_time_Physician} {val.preview4 == "" ? "" : val.client_sign_uploaded_time_Physician && "by " + val.preview4 == "" ? "" : val.uploaded_by} </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                    {/* table end */}

                    {/* ================================== PRINT  SIGNATURE END ==================================== */}
                    {/* ################################************************************################################ */}
                    {/* </div> */}
                  </section>
                </div>
              </div>
            </div>
          )
        })}
      </>
    )
  }
}

