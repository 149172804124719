import axios from 'axios';
import React, { Component } from 'react';
import Select from 'react-select';
import TextAreaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from '../../ReusableComponents/Tooltip';

export default class Level_of_care extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Assessed: {
                // placed_level_of_care: '',
                reason_for_difference: '',
                assessed_comments: '',
                co_occuring_disorder_screen: '',
                co_occuring_disorder_screen_explain: '',
                co_occuring_disorder_assesment: '',
                co_occuring_disorder_assesment_explain: '',
                client_seeking_explain: '',
                explaination_of_the_above: '',
                Admitted_to: '',
                // Referred_to: '',
                release_of_information: false,
                appropriate_release_of_information: '',
                reffered_from_drug_court: '',
                indigent_offender: '',
                client_start_date: '',
                // refered_for_case: '',
                // assesed_level: '',
                // no_service_availaible1: '',


                no_service_availaible2: '',
                refused_further_services: '',
                client_seeking_treatment: '',
                interim_level_of_care: '',
            },
            saved: true,
            disposition: [],
            medical_provider_review: [],
            assesssed_level_of_care: [],
            interim_assessed_level_of_care: [],

            // New updation
            placed_level_of_care: [],
            Referred_to: [],
            refered_for_case: [],
            assesed_level: [],
            no_service_availaible1: [],

            refferedToDropdownOptions: [],

            dataListBody: {
                apikey: sessionStorage.getItem("__TOKEN__"),
                client_id: sessionStorage.getItem("clientId"),
                form_type: props.form_type,
                uploaded_by: sessionStorage.getItem("username"),
            },
            isSaveAndComplete: false,
        }
    }

    assesedLevelOptions = [
        { value: "early_intervention_services", label: "0.5 - Early Intervention Services" },
        { value: "outpatient_treatment", label: "1 - Outpatient Treatment" },
        { value: "awm_without_extended_monitoring", label: "1-WM - AWM Without extended on-site Monitoring" },
        { value: "opioid_treatment_services", label: "1-O - Opioid Treatment Services" },
        { value: "intensive_outpatient_treatment", label: "2.1 - Intensive Outpatient Treatment" },
        { value: "partial_hospitalization", label: "2.5 - Partial Hospitalization" },
        { value: "awm_extended_monitoring", label: "2-WM - AWM with extended on-site Monitoring" },
        { value: "transitional_residential_treatment", label: "3.01 - Transitional Residential Treatment" },
        { value: "clinically_managed_low_intensity", label: "3.1 - Clinically Managed Low Intensity Residental Treatment" },
        { value: "clinically_managed_population_specific_high_intensity_residential_treatment", label: "3.3 - Clinically Managed Population Specific High Intensity Residential Treatment" },
        { value: "clinically_managed_high_intensity", label: "3.5 - Clinically Managed High Intensity Residental Treatment" },
        { value: "clinically_monitored_high_intensity", label: "3.7 - Medically Monitored High Intensity Inpatient Treatment" },
        { value: "medically_monitored_inpatient_withdrawal", label: "3.7-WM - Medically Monitored Inpatient Withdrawal Management" },
    ];

    reasonDifferenceOptions = [
        { value: "transportation_or_logistical_problem", label: "Transportation or Logistical Problem" },
        { value: "court_order", label: "Court Order" },
        { value: "consumer_preference", label: "Consumer Preference" },
        { value: "clinician_supervisor_override", label: "Clinician/Supervisor Override" },
        { value: "intensive_outpatient_treatment", label: "Intensive Outpatient Treatment" },
        { value: "waiting_for_indicated_level", label: "Waiting for Indicated Level" },
        { value: "service_not_available", label: "Service not available" },
        { value: "na", label: "N/A No Difference" },
        { value: "other", label: "Other" }
    ]
    dispositionsOptions = [
        { value: "Admitted_to", label: "Admitted to" },
        { value: "Referred_to", label: " Referred to" },
        { value: "Referred_for_case_management_services_to_assist", label: "Referred for case management services to assist" },
        { value: "Assessed_level_not_available_referred_to", label: "Assessed level not available, referred to" },
        { value: "No_service_available_referred_to", label: "No service available, referred to" },
        { value: "Refused_further_services_client_discharged.", label: "Refused further services, client discharged" },
    ]
    co_occuring_disorder_option = [
        { value: "not_performed", label: "Not performed" },
        { value: "administered_results(-)", label: " Administered/Results (-)" },
        { value: "administered_results(+)", label: "Administered/Results (+)" },

    ]
    co_occuring_disorder_option = [
        { value: "not_performed", label: "Not performed" },
        { value: "administered_results(-)", label: " Administered/Results (-)" },
        { value: "administered_results(+)", label: "Administered/Results (+)" },
    ];
    medicalProviderOptions = [
        { value: "agree_with_diagnostic", label: "Agree with diagnostic impression" },
        { value: "agree_with_level_of_care", label: "Agree with the level of care determination" },
        { value: "agree_with_recommended_admission", label: "Agree with recommended admission to level of care" },
        { value: "agree_with_the_preliminary", label: "Agree with the preliminary plan" },
        { value: "treatment_authorization", label: "Treatment authorization" },
        { value: "number_of_days_hours_approved", label: "Number of days/hours approved" },
        { value: "recommended_additional_services", label: "Recommended additional services" },
        { value: "need_additional_information", label: "Need additional information" },
    ]
    Admitted_to_options = [
        { value: "dummy1", label: "Dummy data 1" },
        { value: "dummy2", label: " Dummy data 2" },
        { value: "dummy3", label: "Dummy data 3" },
        { value: "dummy4", label: "Dummy data 4" },
    ]
    Referred_to_options = [
        { value: "dummy1", label: "Dummy data 1" },
        { value: "dummy2", label: " Dummy data 2" },
        { value: "dummy3", label: "Dummy data 3" },
        { value: "dummy4", label: "Dummy data 4" },
    ]
    handleMultipleSelect = (e) => {

    }
    async componentDidMount() {
        this.loadData();
    }

    handleChange = (e) => {
        this.props.tabEditOrNot(true);
        if (this.props.form_type == "Adult_Assessment") {
            this.props.saveAndExit("", "", "", "", "", "", "", "", "", "", "", this.handleSubmit, "");
        }
        if(this.props.form_type == "Adult_updateAdmh"){
            this.props.saveAndExit("", "", "", "", "", "", "", this.handleSubmit, "");
        }
        if (!this.state.isSaveAndComplete) {
            this.setState({ saved: false })
        } else {
            this.setState({ saved: true })
        }
        if (e.target.name == "appropriate_release_of_information") {
            let xyz = this.state.Assessed;
            if (e.target.value == "1") {
                xyz["release_of_information"] = true;
            } else if (e.target.value == "0") {
                xyz["release_of_information"] = false;
            }
            this.setState({ Assessed: xyz });
        }
        if (e.target.name == "release_of_information") {
            let xyz = this.state.Assessed;
            xyz["release_of_information"] = !this.state.Assessed.release_of_information;
            this.setState({ Assessed: xyz });

        } else {
            let xyz = this.state.Assessed;
            xyz[e.target.name] = e.target.value;
            this.setState({ Assessed: xyz });
        }



        setTimeout(() => {
            this.props.childToParent(
                this.state.Assessed,
                this.state.disposition,
                this.state.medical_provider_review,
                this.state.assesssed_level_of_care,
                this.state.placed_level_of_care,
                this.state.Referred_to,
                this.state.refered_for_case,
                this.state.assesed_level,
                this.state.no_service_availaible1,
                this.state.interim_assessed_level_of_care,
            );
        }, 500)

        // setTimeout(
        //     ()=>{
        //         this.props.childToParent(
        //             this.state.Assessed,
        //             this.state.disposition,
        //             this.state.medical_provider_review,
        //             this.state.interim_assessed_level_of_care);
        //     },500
        // )

    }

    handleDispositionChange = (e) => {
        this.setState({ disposition: e });
    }

    handleAdmittedandRefferedTo = (e) => {
    }

    handleSubmit = async (e, shouldToasterShow) => {
        e.preventDefault();
        this.props.tabEditOrNot(false);
        let final_data = {
            Assessed: this.state.Assessed,
            disposition: this.state.disposition,
            medical_provider_review: this.state.medical_provider_review,
            assesssed_level_of_care: this.state.assesssed_level_of_care,
            placed_level_of_care: this.state.placed_level_of_care,
            Referred_to: this.state.Referred_to,
            refered_for_case: this.state.refered_for_case,
            assesed_level: this.state.assesed_level,
            no_service_availaible1: this.state.no_service_availaible1,
            interim_assessed_level_of_care: this.state.interim_assessed_level_of_care,
            dataListBody: this.state.dataListBody,
        };
        let res = await axios.post(
            global.restApiURL + "customRest/saveAssessed",
            JSON.stringify(final_data)
        );
        if (res.data.success == true && shouldToasterShow == undefined) {
            this.setState({ saved: true })
            this.loadData();
            toast.success('Data added successfully!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: 'success_levelofcare',
                theme:"colored"
            })
        }else if(res.data.success == true && shouldToasterShow == 'saveNexit'){
            // msg should be show on parent componnt;
        }
    }

    loadData = async (e) => {
        if (!this.props.isSaveAndComplete) {
            let res = await axios.post(
                global.restApiURL + "customRest/getAssessed",
                JSON.stringify(this.state.dataListBody)
            );
            if (res.data.success == true && res.status == 200) {
                let Assessed = this.state.Assessed;
                // Assessed.placed_level_of_care = res.data.data.Assessed.placed_level_of_care;
                Assessed.reason_for_difference = res.data.data.Assessed.reason_for_difference;
                Assessed.assessed_comments = res.data.data.Assessed.assessed_comments;
                Assessed.co_occuring_disorder_screen = res.data.data.Assessed.co_occuring_disorder_screen;
                Assessed.co_occuring_disorder_screen_explain = res.data.data.Assessed.co_occuring_disorder_screen_explain;
                Assessed.co_occuring_disorder_assesment = res.data.data.Assessed.co_occuring_disorder_assesment == "" ? "not_performed" : res.data.data.Assessed.co_occuring_disorder_assesment;
                Assessed.co_occuring_disorder_assesment_explain = res.data.data.Assessed.co_occuring_disorder_assesment_explain;
                Assessed.client_seeking_explain = res.data.data.Assessed.client_seeking_explain;
                Assessed.explaination_of_the_above = res.data.data.Assessed.explaination_of_the_above;
                Assessed.release_of_information = res.data.data.Assessed.release_of_information;
                Assessed.appropriate_release_of_information = res.data.data.Assessed.release_of_information === true ? 1 : res.data.data.Assessed.release_of_information === false ? 0 : "";
                Assessed.reffered_from_drug_court = res.data.data.Assessed.reffered_from_drug_court;
                Assessed.indigent_offender = res.data.data.Assessed.indigent_offender;
                Assessed.client_start_date = res.data.data.Assessed.client_start_date;
                // Assessed.refered_for_case = res.data.data.Assessed.refered_for_case;
                // Assessed.assesed_level = res.data.data.Assessed.assesed_level;
                // Assessed.no_service_availaible1 = res.data.data.Assessed.no_service_availaible1;
                Assessed.no_service_availaible2 = res.data.data.Assessed.no_service_availaible2;
                Assessed.refused_further_services = res.data.data.Assessed.refused_further_services;
                Assessed.client_seeking_treatment = res.data.data.Assessed.client_seeking_treatment;
                Assessed.Admitted_to = res.data.data.Assessed.Admitted_to;
                // Assessed.Referred_to = res.data.data.Assessed.Referred_to;

                Assessed.interim_level_of_care = res.data.data.Assessed.interim_level_of_care;




                this.setState({ Assessed: Assessed });
                if (res.data.data.disposition.length !== 0) {
                    let data = JSON.parse(res.data.data.disposition);
                    this.setState({ disposition: data })
                }
                if (res.data.data.medical_provider_review.length !== 0) {
                    let data = JSON.parse(res.data.data.medical_provider_review);
                    this.setState({ medical_provider_review: data })
                }
                if (res.data.data.assesssed_level_of_care.length !== 0) {
                    let data = JSON.parse(res.data.data.assesssed_level_of_care);
                    this.setState({ assesssed_level_of_care: data })
                }
                if (res.data.data.placed_level_of_care?.length !== 0) {
                    let data = JSON.parse(res.data.data.placed_level_of_care);
                    // let data = res.data.data.placed_level_of_care;
                    this.setState({ placed_level_of_care: data })
                }
                if (res.data.data.Referred_to?.length > 0) {
                    let data = JSON.parse(res.data.data.Referred_to);
                    this.setState({ Referred_to: data })
                }
                if (res.data.data.refered_for_case?.length > 0) {
                    let data = JSON.parse(res.data.data.refered_for_case);
                    this.setState({ refered_for_case: data })
                }
                if (res.data.data.assesed_level?.length > 0) {
                    let data = JSON.parse(res.data.data.assesed_level);
                    this.setState({ assesed_level: data })
                }
                if (res.data.data.no_service_availaible1?.length > 0) {
                    let data = JSON.parse(res.data.data.no_service_availaible1);
                    this.setState({ no_service_availaible1: data })
                }

                if (res.data.data.interim_assessed_level_of_care.length !== 0) {
                    let data = JSON.parse(res.data.data.interim_assessed_level_of_care);
                    this.setState({ interim_assessed_level_of_care: data })
                }

                setTimeout(() => {
                    this.props.childToParent(
                        this.state.Assessed,
                        this.state.disposition,
                        this.state.medical_provider_review,
                        this.state.assesssed_level_of_care,
                        this.state.placed_level_of_care,
                        this.state.Referred_to,
                        this.state.refered_for_case,
                        this.state.assesed_level,
                        this.state.no_service_availaible1,
                        this.state.interim_assessed_level_of_care,
                    );
                }, 500)
            }
        } else {
            if (this.props.form_type == "Adolescent_updateAdmh" || this.props.form_type == "Adult_updateAdmh") {
                let final_data = { dataListBody: this.state.dataListBody }
                let res = await axios.post(
                    global.restApiURL + "customRest/getAssessed",
                    JSON.stringify(final_data)
                );
                if (res.data.success == true && res.status == 200) {
                    let Assessed = this.state.Assessed;
                    // Assessed.placed_level_of_care = res.data.data.Assessed.placed_level_of_care;
                    Assessed.reason_for_difference = res.data.data.Assessed.reason_for_difference;
                    Assessed.assessed_comments = res.data.data.Assessed.assessed_comments;
                    Assessed.co_occuring_disorder_screen = res.data.data.Assessed.co_occuring_disorder_screen;
                    Assessed.co_occuring_disorder_screen_explain = res.data.data.Assessed.co_occuring_disorder_screen_explain;
                    Assessed.co_occuring_disorder_assesment = res.data.data.Assessed.co_occuring_disorder_assesment;
                    Assessed.co_occuring_disorder_assesment_explain = res.data.data.Assessed.co_occuring_disorder_assesment_explain;
                    Assessed.client_seeking_explain = res.data.data.Assessed.client_seeking_explain;
                    Assessed.explaination_of_the_above = res.data.data.Assessed.explaination_of_the_above;

                    Assessed.release_of_information = res.data.data.Assessed.release_of_information;

                    Assessed.appropriate_release_of_information = res.data.data.Assessed.release_of_information == true ? 1 : res.data.data.Assessed.release_of_information == false ? 0 : "";

                    Assessed.reffered_from_drug_court = res.data.data.Assessed.reffered_from_drug_court;
                    Assessed.indigent_offender = res.data.data.Assessed.indigent_offender;
                    Assessed.client_start_date = res.data.data.Assessed.client_start_date;
                    // Assessed.refered_for_case = res.data.data.Assessed.refered_for_case;
                    // Assessed.assesed_level = res.data.data.Assessed.assesed_level;
                    // Assessed.no_service_availaible1 = res.data.data.Assessed.no_service_availaible1;
                    Assessed.no_service_availaible2 = res.data.data.Assessed.no_service_availaible2;
                    Assessed.refused_further_services = res.data.data.Assessed.refused_further_services;
                    Assessed.client_seeking_treatment = res.data.data.Assessed.client_seeking_treatment;
                    Assessed.Admitted_to = res.data.data.Assessed.Admitted_to;
                    // Assessed.Referred_to = res.data.data.Assessed.Referred_to;
                    Assessed.interim_level_of_care = res.data.data.Assessed.interim_level_of_care;

                    this.setState({ Assessed: Assessed, isSaveAndComplete: true });
                    if (res.data.data.assesssed_level_of_care.length !== 0) {
                        let data = JSON.parse(res.data.data.assesssed_level_of_care);
                        this.setState({ assesssed_level_of_care: data })
                    }
                    if (res.data.data.placed_level_of_care?.length > 0) {
                        let data = JSON.parse(res.data.data.placed_level_of_care);
                        this.setState({ placed_level_of_care: data })
                    }
                    if (res.data.data.Referred_to?.length > 0) {
                        let data = JSON.parse(res.data.data.Referred_to);
                        this.setState({ Referred_to: data })
                    }
                    if (res.data.data.refered_for_case?.length > 0) {
                        let data = JSON.parse(res.data.data.refered_for_case);
                        this.setState({ refered_for_case: data })
                    }
                    if (res.data.data.assesed_level?.length > 0) {
                        let data = JSON.parse(res.data.data.assesed_level);
                        this.setState({ assesed_level: data })
                    }
                    if (res.data.data.no_service_availaible1?.length > 0) {
                        let data = JSON.parse(res.data.data.no_service_availaible1);
                        this.setState({ no_service_availaible1: data })
                    }

                    if (res.data.data.interim_assessed_level_of_care.length > 0) {
                        let data = JSON.parse(res.data.data.interim_assessed_level_of_care);
                        this.setState({ interim_assessed_level_of_care: data })
                    }


                }
            } else {
                let res = await axios.post(
                    global.restApiURL + "customRest/getAssessedTemp",
                    JSON.stringify(this.state.dataListBody)
                );
                if (res.data.success == true && res.status == 200) {
                    let Assessed = this.state.Assessed;
                    // Assessed.placed_level_of_care = res.data.data.Assessed.placed_level_of_care;
                    Assessed.reason_for_difference = res.data.data.Assessed.reason_for_difference;
                    Assessed.assessed_comments = res.data.data.Assessed.assessed_comments;
                    Assessed.co_occuring_disorder_screen = res.data.data.Assessed.co_occuring_disorder_screen;
                    Assessed.co_occuring_disorder_screen_explain = res.data.data.Assessed.co_occuring_disorder_screen_explain;
                    Assessed.co_occuring_disorder_assesment = res.data.data.Assessed.co_occuring_disorder_assesment;
                    Assessed.co_occuring_disorder_assesment_explain = res.data.data.Assessed.co_occuring_disorder_assesment_explain;
                    Assessed.client_seeking_explain = res.data.data.Assessed.client_seeking_explain;
                    Assessed.explaination_of_the_above = res.data.data.Assessed.explaination_of_the_above;

                    Assessed.release_of_information = res.data.data.Assessed.release_of_information;

                    Assessed.appropriate_release_of_information = res.data.data.Assessed.release_of_information == true ? 1 : res.data.data.Assessed.release_of_information == false ? 0 : "";

                    Assessed.reffered_from_drug_court = res.data.data.Assessed.reffered_from_drug_court;
                    Assessed.indigent_offender = res.data.data.Assessed.indigent_offender;
                    Assessed.client_start_date = res.data.data.Assessed.client_start_date;
                    // Assessed.refered_for_case = res.data.data.Assessed.refered_for_case;
                    // Assessed.assesed_level = res.data.data.Assessed.assesed_level;
                    // Assessed.no_service_availaible1 = res.data.data.Assessed.no_service_availaible1;
                    Assessed.no_service_availaible2 = res.data.data.Assessed.no_service_availaible2;
                    Assessed.refused_further_services = res.data.data.Assessed.refused_further_services;
                    Assessed.client_seeking_treatment = res.data.data.Assessed.client_seeking_treatment;
                    Assessed.Admitted_to = res.data.data.Assessed.Admitted_to;
                    // Assessed.Referred_to = res.data.data.Assessed.Referred_to;
                    Assessed.interim_level_of_care = res.data.data.Assessed.interim_level_of_care;

                    this.setState({ Assessed: Assessed, isSaveAndComplete: true });
                    if (res.data.data.placed_level_of_care?.length !== 0) {
                        let data = JSON.parse(res.data.data.placed_level_of_care);
                        this.setState({ placed_level_of_care: data })
                    }
                    // assesssed_level_of_care
                    if (this.props.form_type == "Adolescent_updateAdmh" || this.props.form_type == "Adult_updateAdmh") {
                        if (res.data.data.assesssed_level_of_care.length !== 0) {
                            let data = JSON.parse(res.data.data.assesssed_level_of_care);
                            this.setState({ assesssed_level_of_care: data })
                        }

                    } else {
                        if (res.data.data.disposition.length !== 0) {
                            let data = JSON.parse(res.data.data.disposition);
                            this.setState({ disposition: data })
                        }
                        if (res.data.data.medical_provider_review.length !== 0) {
                            let data = JSON.parse(res.data.data.medical_provider_review);
                            this.setState({ medical_provider_review: data })
                        }
                        if (res.data.data.assesssed_level_of_care.length !== 0) {
                            let data = JSON.parse(res.data.data.assesssed_level_of_care);
                            this.setState({ assesssed_level_of_care: data })
                        }

                        if (res.data.data.Referred_to?.length > 0) {
                            let data = JSON.parse(res.data.data.Referred_to);
                            this.setState({ Referred_to: data })
                        }
                        if (res.data.data.refered_for_case?.length > 0) {
                            let data = JSON.parse(res.data.data.refered_for_case);
                            this.setState({ refered_for_case: data })
                        }
                        if (res.data.data.assesed_level?.length > 0) {
                            let data = JSON.parse(res.data.data.assesed_level);
                            this.setState({ assesed_level: data })
                        }
                        if (res.data.data.no_service_availaible1?.length > 0) {
                            let data = JSON.parse(res.data.data.no_service_availaible1);
                            this.setState({ no_service_availaible1: data })
                        }
                        if (res.data.data.interim_assessed_level_of_care.length > 0) {
                            let data = JSON.parse(res.data.data.interim_assessed_level_of_care);
                            this.setState({ interim_assessed_level_of_care: data })
                        }
                    }
                }
            }
        }

        let reffered_to_res = await axios.post(
            global.restApiURL + "customRest/getReferralsAgency",
            JSON.stringify({ dataListBody: this.state.dataListBody })
        );
        if (reffered_to_res.data.success && reffered_to_res.status == 200) {
            console.log(JSON.parse(reffered_to_res.data.data.referralsAgency).map((v) => v.value))
            reffered_to_res.data.data.referralsAgency.length > 0 && this.setState({ refferedToDropdownOptions: JSON.parse(reffered_to_res.data.data.referralsAgency) })
        }
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        // let dispositionColumn = this.state.disposition != null && this.state.disposition.length > 0 && this.state.disposition.map(disposition => {
        //     if (disposition.value == "Admitted_to" || disposition.value == "Referred_to") {
        //         return (<>
        //             <div className='col-md-3 marginBottom1' >
        //                 <label>{disposition.label}</label>
        //             </div>

        //             {
        //                 disposition.value == "Admitted_to" && <>
        //                     <div className='col-md-3 marginBottom1'>
        //                         <TextAreaAutosize
        //                             rows={1}
        //                             name="Admitted_to"
        //                             style={{ overflow: 'hidden' }}
        //                             value={this.state.Assessed.Admitted_to}
        //                             disabled={this.state.isSaveAndComplete}
        //                             className='form-control'
        //                             onChange={this.handleChange}
        //                         />
        //                     </div>
        //                 </>
        //             }
        //             {
        //                 disposition.value == "Referred_to" && <>
        //                     <div className='col-md-3 marginBottom1'>
        //                         {/* <TextAreaAutosize rows={1} name="Referred_to" disabled={this.state.isSaveAndComplete} value={this.state.Assessed.Referred_to} className='form-control' onChange={this.handleChange} /> */}

        //                         <Select
        //                             isMulti
        //                             closeMenuOnSelect={false}
        //                             isDisabled={this.state.isSaveAndComplete}
        //                             placeholder="Referred to"
        //                             value={this.state.Referred_to}
        //                             name="Referred_to"
        //                             options={this.state.refferedToDropdownOptions}
        //                             onChange={(e) => {
        //                                 this.props.tabEditOrNot(true);
        //                                 if (this.props.form_type == "Adult_Assessment") {
        //                                     this.props.saveAndExit("", "", "", "", "", "", "", "", "", "", "", this.handleSubmit, "");
        //                                 }
        //                                 if(this.props.form_type == "Adult_updateAdmh"){
        //                                     this.props.saveAndExit("", "", "", "", "", "", "", this.handleSubmit, "");
        //                                 }
        //                                 this.setState({ Referred_to: e, saved: false });
        //                                 this.props.childToParent(
        //                                     this.state.Assessed,
        //                                     this.state.disposition,
        //                                     this.state.medical_provider_review,
        //                                     this.state.assesssed_level_of_care,
        //                                     this.state.placed_level_of_care,
        //                                     e,
        //                                     this.state.refered_for_case,
        //                                     this.state.assesed_level,
        //                                     this.state.no_service_availaible1,
        //                                     this.state.interim_assessed_level_of_care,
        //                                 );
        //                             }}
        //                         />
        //                     </div>
        //                 </>
        //             }

        //             {
        //                 disposition.value == "Admitted_to" && <>
        //                     {/* <div className='col-md-1'> </div> */}
        //                     <div className='col-md-3 marginBottom1'>
        //                         <label>Client Start Date</label>
        //                     </div>
        //                     <div className='col-md-3 marginBottom1'>
        //                         <input type="date" className="form-control" disabled={this.state.isSaveAndComplete} value={this.state.Assessed.client_start_date} name="client_start_date" onChange={this.handleChange} />
        //                         {/* <input type="date" name="client_start_date" value={this.state.Assessed.client_start_date} className="form-control"   onChange={this.handleChange} /> */}
        //                     </div>
        //                 </>
        //             }
        //         </>)
        //     } else if (disposition.value == "Referred_for_case_management_services_to_assist") {
        //         return (<>
        //             {/* <div className='col-md-1 marginBottom1'></div> */}
        //             <div className='col-md-3 marginBottom1'>
        //                 <label>{disposition.label}</label>
        //             </div>
        //             <div className='col-md-3 marginBottom1'>
        //                 {/* <input type="text" name="refered_for_case" disabled={this.state.isSaveAndComplete} value={this.state.Assessed.refered_for_case} className='form-control' onChange={this.handleChange} /> */}
        //                 <Select
        //                     isMulti
        //                     closeMenuOnSelect={false}
        //                     isDisabled={this.state.isSaveAndComplete}
        //                     placeholder=""
        //                     value={this.state.refered_for_case}
        //                     name="refered_for_case"
        //                     options={this.state.refferedToDropdownOptions}
        //                     onChange={(e) => {
        //                         this.props.tabEditOrNot(true);
        //                         if (this.props.form_type == "Adult_Assessment") {
        //                             this.props.saveAndExit("", "", "", "", "", "", "", "", "", "", "", this.handleSubmit, "");
        //                         }
        //                         if(this.props.form_type == "Adult_updateAdmh"){
        //                             this.props.saveAndExit("", "", "", "", "", "", "", this.handleSubmit, "");
        //                         }
        //                         this.setState({ refered_for_case: e, saved: false });
        //                         this.props.childToParent(
        //                             this.state.Assessed,
        //                             this.state.disposition,
        //                             this.state.medical_provider_review,
        //                             this.state.assesssed_level_of_care,
        //                             this.state.placed_level_of_care,
        //                             this.state.Referred_to,
        //                             e,
        //                             this.state.assesed_level,
        //                             this.state.no_service_availaible1,
        //                             this.state.interim_assessed_level_of_care,

        //                         );
        //                     }}
        //                 />

        //             </div>
        //         </>)
        //     } else if (disposition.value == "Assessed_level_not_available_referred_to") {
        //         return (<>
        //             <div className='col-md-3 marginBottom1'>
        //                 <label>{disposition.label}</label>
        //             </div>
        //             <div className='col-md-3 marginBottom1'>
        //                 {/* <input type="text" name="assesed_level" disabled={this.state.isSaveAndComplete} value={this.state.Assessed.assesed_level} className='form-control' onChange={this.handleChange} /> */}
        //                 <Select
        //                     isMulti
        //                     closeMenuOnSelect={false}
        //                     isDisabled={this.state.isSaveAndComplete}
        //                     placeholder=""
        //                     value={this.state.assesed_level}
        //                     name="assesed_level"
        //                     options={this.state.refferedToDropdownOptions}
        //                     onChange={(e) => {
        //                         this.props.tabEditOrNot(true);
        //                         if (this.props.form_type == "Adult_Assessment") {
        //                             this.props.saveAndExit("", "", "", "", "", "", "", "", "", "", "", this.handleSubmit, "");
        //                         }
        //                         if(this.props.form_type == "Adult_updateAdmh"){
        //                             this.props.saveAndExit("", "", "", "", "", "", "", this.handleSubmit, "");
        //                         }
        //                         this.setState({ assesed_level: e, saved: false });
        //                         this.props.childToParent(
        //                             this.state.Assessed,
        //                             this.state.disposition,
        //                             this.state.medical_provider_review,
        //                             this.state.assesssed_level_of_care,
        //                             this.state.placed_level_of_care,
        //                             this.state.Referred_to,
        //                             this.state.refered_for_case,
        //                             e,//assesed_level
        //                             this.state.no_service_availaible1,
        //                             this.state.interim_assessed_level_of_care,
        //                         );
        //                     }}
        //                 />

        //             </div>
        //         </>)
        //     } else if (disposition.value == "No_service_available_referred_to") {
        //         return (<>
        //             {/* <div className='col-md-1'></div> */}
        //             <div className='col-md-3 marginBottom1'>
        //                 <label>{disposition.label}</label>
        //             </div>
        //             <div className='col-md-3 marginBottom1'>
        //                 {/* <input type="text" name="no_service_availaible1" disabled={this.state.isSaveAndComplete} value={this.state.Assessed.no_service_availaible1} className='form-control' onChange={this.handleChange} /> */}
        //                 <Select
        //                     isMulti
        //                     closeMenuOnSelect={false}
        //                     isDisabled={this.state.isSaveAndComplete}
        //                     placeholder=""
        //                     value={this.state.no_service_availaible1}
        //                     name="no_service_availaible1"
        //                     options={this.state.refferedToDropdownOptions}
        //                     onChange={(e) => {
        //                         this.props.tabEditOrNot(true);
        //                         if (this.props.form_type == "Adult_Assessment") {
        //                             this.props.saveAndExit("", "", "", "", "", "", "", "", "", "", "", this.handleSubmit, "");
        //                         }
        //                         if(this.props.form_type == "Adult_updateAdmh"){
        //                             this.props.saveAndExit("", "", "", "", "", "", "", this.handleSubmit, "");
        //                         }
        //                         this.setState({ no_service_availaible1: e, saved: false });
        //                         this.props.childToParent(
        //                             this.state.Assessed,
        //                             this.state.disposition,
        //                             this.state.medical_provider_review,
        //                             this.state.assesssed_level_of_care,
        //                             this.state.placed_level_of_care,
        //                             this.state.Referred_to,
        //                             this.state.refered_for_case,
        //                             this.state.assesed_level,
        //                             e,
        //                             this.state.interim_assessed_level_of_care,
        //                         );
        //                     }}
        //                 />


        //             </div>
        //             {/* <div className='col-md-1'> </div> */}
        //             <div className='col-md-3 marginBottom1'>
        //                 <label>Second referral</label>
        //             </div>
        //             <div className='col-md-3 marginBottom1'>
        //                 <input type="text" name="no_service_availaible2" disabled={this.state.isSaveAndComplete} value={this.state.Assessed.no_service_availaible2} className='form-control' onChange={this.handleChange} />
        //             </div>
        //         </>)
        //     }
        // })
        let dispositionColumn = this.state.disposition != null && this.state.disposition.length > 0 && this.state.disposition.map(disposition => {
            if (disposition.value == "Admitted_to") {
                return (<>
                    <div className='col-md-3 marginBottom1' >
                        <label>{disposition.label}</label>
                    </div>
                    {
                        disposition.value == "Admitted_to" && <>
                            <div className='col-md-3 marginBottom1'>
                                <TextAreaAutosize
                                    rows={1}
                                    name="Admitted_to"
                                    style={{ overflow: 'hidden' }}
                                    value={this.state.Assessed.Admitted_to}
                                    disabled={this.state.isSaveAndComplete}
                                    className='form-control'
                                    onChange={this.handleChange}
                                />
                            </div>
                        </>
                    }
                    {
                        disposition.value == "Admitted_to" && <>
                            <div className='col-md-3 marginBottom1'>
                                <label>Client Start Date</label>
                            </div>
                            <div className='col-md-3 marginBottom1'>
                                <input type="date" className="form-control" disabled={this.state.isSaveAndComplete} value={this.state.Assessed.client_start_date} name="client_start_date" onChange={this.handleChange} />
                            </div>
                        </>
                    }
                </>)
            }
        })

        let dispositionColumn1 = this.state.disposition != null && this.state.disposition.length > 0 && this.state.disposition.map(disposition => {
            if (disposition.value == "Referred_to") {
                return (<>
                    <div className='col-md-3 marginBottom1' >
                        <label>{disposition.label}</label>
                    </div>
                    {
                        disposition.value == "Referred_to" && <>
                            <div className='col-md-3 marginBottom1'>
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    isDisabled={this.state.isSaveAndComplete}
                                    placeholder="Referred to"
                                    value={this.state.Referred_to}
                                    name="Referred_to"
                                    options={this.state.refferedToDropdownOptions}
                                    onChange={(e) => {
                                        this.props.tabEditOrNot(true);
                                        if (this.props.form_type == "Adult_Assessment") {
                                            this.props.saveAndExit("", "", "", "", "", "", "", "", "", "", "", this.handleSubmit, "");
                                        }
                                        if (this.props.form_type == "Adult_updateAdmh") {
                                            this.props.saveAndExit("", "", "", "", "", "", "", this.handleSubmit, "");
                                        }
                                        this.setState({ Referred_to: e, saved: false });
                                        this.props.childToParent(
                                            this.state.Assessed,
                                            this.state.disposition,
                                            this.state.medical_provider_review,
                                            this.state.assesssed_level_of_care,
                                            this.state.placed_level_of_care,
                                            e,
                                            this.state.refered_for_case,
                                            this.state.assesed_level,
                                            this.state.no_service_availaible1,
                                            this.state.interim_assessed_level_of_care,
                                        );
                                    }}
                                />
                            </div>
                        </>
                    }
                </>)
            }
        })

        let dispositionColumn2 = this.state.disposition != null && this.state.disposition.length > 0 && this.state.disposition.map(disposition => {
            if (disposition.value == "Referred_for_case_management_services_to_assist") {
                return (<>
                    <div className='col-md-3 marginBottom1'>
                        <label>{disposition.label}</label>
                    </div>
                    <div className='col-md-3 marginBottom1'>
                        <Select
                            isMulti
                            closeMenuOnSelect={false}
                            isDisabled={this.state.isSaveAndComplete}
                            placeholder=""
                            value={this.state.refered_for_case}
                            name="refered_for_case"
                            options={this.state.refferedToDropdownOptions}
                            onChange={(e) => {
                                this.props.tabEditOrNot(true);
                                if (this.props.form_type == "Adult_Assessment") {
                                    this.props.saveAndExit("", "", "", "", "", "", "", "", "", "", "", this.handleSubmit, "");
                                }
                                if (this.props.form_type == "Adult_updateAdmh") {
                                    this.props.saveAndExit("", "", "", "", "", "", "", this.handleSubmit, "");
                                }
                                this.setState({ refered_for_case: e, saved: false });
                                this.props.childToParent(
                                    this.state.Assessed,
                                    this.state.disposition,
                                    this.state.medical_provider_review,
                                    this.state.assesssed_level_of_care,
                                    this.state.placed_level_of_care,
                                    this.state.Referred_to,
                                    e,
                                    this.state.assesed_level,
                                    this.state.no_service_availaible1,
                                    this.state.interim_assessed_level_of_care,

                                );
                            }}
                        />
                    </div>
                </>)
            }
        })

        let dispositionColumn3 = this.state.disposition != null && this.state.disposition.length > 0 && this.state.disposition.map(disposition => {
            if (disposition.value == "Assessed_level_not_available_referred_to") {
                return (<>
                    <div className='col-md-3 marginBottom1'>
                        <label>{disposition.label}</label>
                    </div>
                    <div className='col-md-3 marginBottom1'>
                        <Select
                            isMulti
                            closeMenuOnSelect={false}
                            isDisabled={this.state.isSaveAndComplete}
                            placeholder=""
                            value={this.state.assesed_level}
                            name="assesed_level"
                            options={this.state.refferedToDropdownOptions}
                            onChange={(e) => {
                                this.props.tabEditOrNot(true);
                                if (this.props.form_type == "Adult_Assessment"){
                                    this.props.saveAndExit("", "", "", "", "", "", "", "", "", "", "", this.handleSubmit, "");
                                }
                                if (this.props.form_type == "Adult_updateAdmh") {
                                    this.props.saveAndExit("", "", "", "", "", "", "", this.handleSubmit, "");
                                }
                                this.setState({ assesed_level: e, saved: false });
                                    this.props.childToParent(
                                        this.state.Assessed,
                                        this.state.disposition,
                                        this.state.medical_provider_review,
                                        this.state.assesssed_level_of_care,
                                        this.state.placed_level_of_care,
                                        this.state.Referred_to,
                                        this.state.refered_for_case,
                                        e,//assesed_level
                                        this.state.no_service_availaible1,
                                        this.state.interim_assessed_level_of_care,
                                    );
                                }
                            }
                        />
                    </div>
                </>)
            }
        })

        let dispositionColumn4 = this.state.disposition != null && this.state.disposition.length > 0 && this.state.disposition.map(disposition => {
            if (disposition.value == "No_service_available_referred_to") {
                return (<>
                    <div className='col-md-3 marginBottom1'>
                        <label>{disposition.label}</label>
                    </div>
                    <div className='col-md-3 marginBottom1'>
                        <Select
                            isMulti
                            closeMenuOnSelect={false}
                            isDisabled={this.state.isSaveAndComplete}
                            placeholder=""
                            value={this.state.no_service_availaible1}
                            name="no_service_availaible1"
                            options={this.state.refferedToDropdownOptions}
                            onChange={(e) => {
                                this.props.tabEditOrNot(true);
                                if (this.props.form_type == "Adult_Assessment") {
                                    this.props.saveAndExit("", "", "", "", "", "", "", "", "", "", "", this.handleSubmit, "");
                                }
                                if (this.props.form_type == "Adult_updateAdmh") {
                                    this.props.saveAndExit("", "", "", "", "", "", "", this.handleSubmit, "");
                                }
                                this.setState({ no_service_availaible1: e, saved: false });
                                this.props.childToParent(
                                    this.state.Assessed,
                                    this.state.disposition,
                                    this.state.medical_provider_review,
                                    this.state.assesssed_level_of_care,
                                    this.state.placed_level_of_care,
                                    this.state.Referred_to,
                                    this.state.refered_for_case,
                                    this.state.assesed_level,
                                    e,
                                    this.state.interim_assessed_level_of_care,
                                );
                            }}
                        />


                    </div>
                    <div className='col-md-3 marginBottom1'>
                        <label>Second referral</label>
                    </div>
                    <div className='col-md-3 marginBottom1'>
                        <input type="text" name="no_service_availaible2" disabled={this.state.isSaveAndComplete} value={this.state.Assessed.no_service_availaible2} className='form-control' onChange={this.handleChange} />
                    </div>
                </>)
            }
        })

        
        return (
            <div className="hold-transition sidebar-mini text-sm">
                <div className="wrapper">
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className='row marginBottom1'>
                                            <div className='col-md-3'>
                                                <label>Assessed Level of Care <Tooltip name="level_of_care" text="(Check one unless also receiving OTS)" /> </label>
                                                </div>
                                            <div className='col-md-3'>
                                                <Select
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    isDisabled={this.state.isSaveAndComplete}
                                                    placeholder="Assessed level of care"
                                                    value={this.state.assesssed_level_of_care}
                                                    name="assesssed_level_of_care"
                                                    options={this.assesedLevelOptions}
                                                    onChange={(e) => {
                                                        this.props.tabEditOrNot(true);
                                                        if (this.props.form_type == "Adult_Assessment") {
                                                            this.props.saveAndExit("", "", "", "", "", "", "", "", "", "", "", this.handleSubmit, "");
                                                        }
                                                        if(this.props.form_type == "Adult_updateAdmh"){
                                                            this.props.saveAndExit("", "", "", "", "", "", "", this.handleSubmit, "");
                                                        }
                                                        this.setState({ assesssed_level_of_care: e, saved: false });
                                                        this.props.childToParent(
                                                            this.state.Assessed,
                                                            this.state.disposition,
                                                            this.state.medical_provider_review,
                                                            this.state.e,
                                                            this.state.placed_level_of_care,
                                                            this.state.Referred_to,
                                                            this.state.refered_for_case,
                                                            this.state.assesed_level,
                                                            this.state.no_service_availaible1,
                                                            this.state.interim_assessed_level_of_care,
                                                        );
                                                    }} />

                                            </div>
                                            {/* <div className='col-md-1'></div> */}
                                            <div className='col-md-3'>
                                                <label>Placed Level of Care</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <Select
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    isDisabled={this.state.isSaveAndComplete}
                                                    value={this.state.placed_level_of_care}
                                                    name="placed_level_of_care"
                                                    options={this.assesedLevelOptions}
                                                    onChange={(e) => {
                                                        this.props.tabEditOrNot(true);
                                                        if (this.props.form_type == "Adult_Assessment") {
                                                            this.props.saveAndExit("", "", "", "", "", "", "", "", "", "", "", this.handleSubmit, "");
                                                        }
                                                        if(this.props.form_type == "Adult_updateAdmh"){
                                                            this.props.saveAndExit("", "", "", "", "", "", "", this.handleSubmit, "");
                                                        }
                                                        this.setState({ placed_level_of_care: e, saved: false });
                                                        this.props.childToParent(
                                                            this.state.Assessed,
                                                            this.state.disposition,
                                                            this.state.medical_provider_review,
                                                            this.state.assesssed_level_of_care,
                                                            e,
                                                            this.state.Referred_to,
                                                            this.state.refered_for_case,
                                                            this.state.assesed_level,
                                                            this.state.no_service_availaible1,
                                                            this.state.interim_assessed_level_of_care,
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='row marginBottom1'>
                                            <div className='col-md-3'>
                                                <label>Reason for difference</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <select name="reason_for_difference" disabled={this.state.isSaveAndComplete} className='form-control' value={this.state.Assessed.reason_for_difference} onChange={this.handleChange}>
                                                    <option value="">Select</option>
                                                    {this.reasonDifferenceOptions.length > 0 && this.reasonDifferenceOptions.map(option => (
                                                        <option value={option.value}>{option.label}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* <div className='col-md-1'></div> */}
                                            <div className='col-md-3'>
                                                <label>Comment</label>
                                            </div>
                                            <div className='col-md-3'>
                                                {/* <TextAreaAutosize rows={1} name="assessed_comments" disabled={this.state.isSaveAndComplete} value={this.state.Assessed.assessed_comments} className='form-control' onChange={this.handleChange} /> */}
                                                <TextAreaAutosize
                                                    style={{ overflow: 'hidden' }}
                                                    name="assessed_comments"
                                                    disabled={this.state.isSaveAndComplete}
                                                    value={this.state.Assessed.assessed_comments}
                                                    className='form-control'
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        {this.props.updateForm != true ? <>
                                            <div className='row marginBottom1'>
                                                <div className='col-md-3'>
                                                    <label>Interim level of care</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <select value={this.state.Assessed.interim_level_of_care} className='form-control' onChange={this.handleChange} name="interim_level_of_care" id='interim_level_of_care' disabled={this.state.isSaveAndComplete} >
                                                        <option value="">Select</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label></label>
                                                </div>
                                                <div className='col-md-3'>
                                                    {this.state.Assessed.interim_level_of_care == "yes" ? <>
                                                        <Select
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            isDisabled={this.state.isSaveAndComplete}
                                                            placeholder="Interim level of care"
                                                            value={this.state.interim_assessed_level_of_care}
                                                            name="interim_assessed_level_of_care"
                                                            options={this.assesedLevelOptions}
                                                            // onChange={this.handleChange.bind(this)}
                                                            onChange={(e) => {
                                                                this.props.tabEditOrNot(true);
                                                                if (this.props.form_type == "Adult_Assessment") {
                                                                    this.props.saveAndExit("", "", "", "", "", "", "", "", "", "", "", this.handleSubmit, "");
                                                                }
                                                                if(this.props.form_type == "Adult_updateAdmh"){
                                                                    this.props.saveAndExit("", "", "", "", "", "", "", this.handleSubmit, "");
                                                                }
                                                                this.setState({ interim_assessed_level_of_care: e, saved: false });
                                                                this.props.childToParent(
                                                                    this.state.Assessed,
                                                                    this.state.disposition,
                                                                    this.state.medical_provider_review,
                                                                    this.state.assesssed_level_of_care,
                                                                    this.state.placed_level_of_care,
                                                                    this.state.Referred_to,
                                                                    this.state.refered_for_case,
                                                                    this.state.assesed_level,
                                                                    this.state.no_service_availaible1,
                                                                    e,
                                                                );
                                                            }}
                                                        />
                                                    </> : <></>}
                                                </div>

                                            </div>



                                            <div className='row marginBottom1'>
                                                <div className='col-md-3'>
                                                    <label>Disposition</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <Select isMulti
                                                        isDisabled={this.state.isSaveAndComplete}
                                                        value={this.state.disposition}
                                                        closeMenuOnSelect={false}
                                                        name="disposition"
                                                        options={this.dispositionsOptions}
                                                        onChange={(e) => {
                                                            this.props.tabEditOrNot(true);
                                                            if (this.props.form_type == "Adult_Assessment") {
                                                                this.props.saveAndExit("", "", "", "", "", "", "", "", "", "", "", this.handleSubmit, "");
                                                            }
                                                            if(this.props.form_type == "Adult_updateAdmh"){
                                                                this.props.saveAndExit("", "", "", "", "", "", "", this.handleSubmit, "");
                                                            }
                                                            this.setState({ disposition: e, saved: false });
                                                            this.props.childToParent(
                                                                this.state.Assessed,
                                                                e,
                                                                this.state.medical_provider_review,
                                                                this.state.assesssed_level_of_care,
                                                                this.state.placed_level_of_care,
                                                                this.state.Referred_to,
                                                                this.state.refered_for_case,
                                                                this.state.assesed_level,
                                                                this.state.no_service_availaible1,
                                                                this.state.interim_assessed_level_of_care,
                                                            );
                                                        }} />
                                                </div>
                                                {dispositionColumn1}

                                            </div>
                                            <div className='row'>
                                                {dispositionColumn}
                                            </div>
                                            <div className='row'>
                                                {dispositionColumn2}
                                                {dispositionColumn3}
                                            </div>
                                            <div className='row'>
                                                {dispositionColumn4}
                                            </div>
                                            <div className='row marginBottom1'>
                                                <div className='col-md-3'>
                                                    <label>Co-occurring disorder screen administered? </label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <select name="co_occuring_disorder_screen" disabled={this.state.isSaveAndComplete} value={this.state.Assessed.co_occuring_disorder_screen} className='form-control' onChange={this.handleChange}>
                                                        <option>Select</option>
                                                        {this.co_occuring_disorder_option.map(option => (
                                                            <option value={option.value}>{option.label}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {/* <div className='col-md-1'></div> */}
                                                <div className='col-md-3'>
                                                    <label>Note</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <TextAreaAutosize
                                                        rows={1}
                                                        disabled={this.state.isSaveAndComplete}
                                                        style={{ overflow: 'hidden' }}
                                                        name="co_occuring_disorder_screen_explain"
                                                        value={this.state.Assessed.co_occuring_disorder_screen_explain}
                                                        className='form-control'
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row marginBottom1'>
                                                <div className='col-md-3'>
                                                    <label>Co-occurring disorder assessment administered?</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <select name="co_occuring_disorder_assesment" disabled={this.state.isSaveAndComplete} value={this.state.Assessed.co_occuring_disorder_assesment} className='form-control' onChange={this.handleChange}>
                                                        {/* <option>Select</option> */}
                                                        {this.co_occuring_disorder_option.map(option => (
                                                            <option value={option.value}>{option.label}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {/* <div className='col-md-1'></div> */}
                                                <div className='col-md-3'>
                                                    <label>Note</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <TextAreaAutosize
                                                        rows={1}
                                                        disabled={this.state.isSaveAndComplete}
                                                        style={{ overflow: 'hidden' }}
                                                        value={this.state.Assessed.co_occuring_disorder_assesment_explain}
                                                        name="co_occuring_disorder_assesment_explain"
                                                        className='form-control'
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row marginBottom1'>
                                                <div className='col-md-3'>
                                                    <label>Is client seeking treatment due to Co-dependent/Collateral?</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <select name="client_seeking_treatment" disabled={this.state.isSaveAndComplete} value={this.state.Assessed.client_seeking_treatment} className='form-control' onChange={this.handleChange}>
                                                        <option value="">Select</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                </div>
                                                {/* <div className='col-md-1'></div> */}
                                                {this.state.Assessed.client_seeking_treatment == "yes" && <>
                                                    <div className='col-md-3'>
                                                        <label>Please explain</label>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <TextAreaAutosize 
                                                            type='text' 
                                                            style={{overflow:"hidden"}}
                                                            disabled={this.state.isSaveAndComplete} 
                                                            name="client_seeking_explain" 
                                                            value={this.state.Assessed.client_seeking_explain} 
                                                            className='form-control' 
                                                            onChange={this.handleChange} 
                                                        />
                                                    </div></>
                                                }
                                            </div>
                                            <div className='row marginBottom1'>
                                                <div className='col-md-3'>
                                                    <label>Explanation of the above to include outcome</label>
                                                </div>
                                                <div className='col-md-9'>
                                                    <TextAreaAutosize
                                                        rows={2}
                                                        style={{ overflow: 'hidden' }}
                                                        disabled={this.state.isSaveAndComplete}
                                                        name="explaination_of_the_above"
                                                        value={this.state.Assessed.explaination_of_the_above}
                                                        className='form-control'
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row marginBottom1'>
                                                <div className='col-md-3'>
                                                    <label>Medical provider review of LOC Assessment</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <Select
                                                        name="medical_provider_review"
                                                        isDisabled={this.state.isSaveAndComplete}
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        options={this.medicalProviderOptions}
                                                        value={this.state.medical_provider_review}
                                                        onChange={(e) => {
                                                            this.props.tabEditOrNot(true);
                                                            if (this.props.form_type == "Adult_Assessment") {
                                                                this.props.saveAndExit("", "", "", "", "", "", "", "", "", "", "", this.handleSubmit, "");
                                                            }
                                                            if(this.props.form_type == "Adult_updateAdmh"){
                                                                this.props.saveAndExit("", "", "", "", "", "", "", this.handleSubmit, "");
                                                            }
                                                            this.setState({ medical_provider_review: e, saved: false });
                                                            this.props.childToParent(
                                                                this.state.Assessed,
                                                                this.state.disposition,
                                                                e,
                                                                this.state.assesssed_level_of_care,
                                                                this.state.placed_level_of_care,
                                                                this.state.Referred_to,
                                                                this.state.refered_for_case,
                                                                this.state.assesed_level,
                                                                this.state.no_service_availaible1,
                                                                this.state.interim_assessed_level_of_care,
                                                            );
                                                        }} />
                                                </div>
                                            </div>
                                            <div className='row marginBottom1'>
                                                <div className='col-md-12'>
                                                    <h6>Release of Information</h6>
                                                </div>
                                                <div className='col-md-0.5'>
                                                    {this.state.Assessed.release_of_information && <>
                                                        {React.createElement('input', { type: 'checkbox', defaultChecked: true, name: "release_of_information", className: 'ml-2', onChange: this.handleChange })}
                                                    </>
                                                    }
                                                    {this.state.Assessed.release_of_information == false ? <>
                                                        {React.createElement('input', { type: 'checkbox', defaultChecked: false, name: "release_of_information", className: 'ml-2', onChange: this.handleChange })}
                                                    </> : <></>}
                                                </div>
                                                <div className='col-md-11'>
                                                    <label>An appropriate release for this information is on file for this client.</label>
                                                </div>
                                            </div>
                                        </> : <>
                                            <div className='row marginBottom1'>
                                                <div className='col-md-3'>
                                                    <label>An appropriate release for this information in on file for this client?{this.state.Assessed.appropriate_release_of_information}</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <select className='form-control' disabled={this.state.isSaveAndComplete} value={this.state.Assessed.appropriate_release_of_information} name="appropriate_release_of_information" onChange={this.handleChange}>
                                                        <option value=''>Select</option>
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label>Was this client referred from a Drug Court?</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <select className='form-control' disabled={this.state.isSaveAndComplete} value={this.state.Assessed.reffered_from_drug_court} name="reffered_from_drug_court" onChange={this.handleChange}>
                                                        <option value=''>Select</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='row marginBottom1'>
                                                <div className='col-md-3'>
                                                    <label>Has a court of law established this client to be an indigent offender?</label>
                                                </div>
                                                <div className='col-md-3'>
                                                    <select className='form-control' disabled={this.state.isSaveAndComplete} name="indigent_offender" value={this.state.Assessed.indigent_offender} onChange={this.handleChange}>
                                                        <option value=''>Select</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </>}
                                        <div className='row marginBottom1'>
                                            <div className='col-md-12'>
                                                <button disabled={this.state.saved} className='btn btn-success float-right'><i className='fas fa-save'></i> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
