import React, { useEffect, useState } from "react";
import SubstanceUseDisorder from "./AssesmentSummary/substanceUseDisorder";
import Disorder_problems from "./Disorder_problems";
import axios from "axios";

export default function Dsm_diagnosis(props) {
  const [substanceUseArrow, setSubstanceUseArrow] = useState(true);
  const [mentalHealthDisorderArrow, setmentalHealthDisorderArrow] =
    useState(false);
  const [otherDisorderArrow, setotherDisorderArrow] = useState(false);
  const [psychologicalArrow, setpsychologicalArrow] = useState(false);
  const [problemLength, setproblemLength] = useState([]);
  const [problem_type_options, setproblem_type_options] = useState([
    { value: "In_Early_Remission", label: "In Early Remission" },
    { value: "In_Sustained_Remission", label: "In Sustained Remission" },
    { value: "On_Maintenance_Therapy", label: "On Maintenance Therapy" },
    {
      value: "In_a_Controlled_Environment",
      label: "In a Controlled Environment",
    },
    { value: "N/A", label: "N/A" },
  ]);

  const specifier = [
    { value: "In_Early_Remission", label: "In Early Remission" },
    { value: "In_Sustained_Remission", label: "In Sustained Remission" },
    { value: "On_Maintenance_Therapy", label: "On Maintenance Therapy" },
    {
      value: "In_a_Controlled_Environment",
      label: "In a Controlled Environment",
    },
    { value: "N/A", label: "N/A" },
  ];
  useEffect(() => {
    loadData();
  }, []);
  const dataListBody = {
    apikey: sessionStorage.getItem("__TOKEN__"),
    client_id: sessionStorage.getItem("clientId"),
    form_type: props.form_type,
  };
  const loadData = async (e) => {
    if (!props.isSaveAndComplete) {
      let res = await axios.post(
        global.restApiURL + "customRest/getSubstanceinfo",
        JSON.stringify({ dataListBody: dataListBody })
      );
      if (res.data.success == true && res.status == 200) {
        setproblemLength(res.data.data.substances);
      }
    } else {
      let res = await axios.post(
        global.restApiURL + "customRest/getSubstanceinfo",
        JSON.stringify({ dataListBody: dataListBody })
      );
      if (res.data.success == true && res.status == 200) {
        setproblemLength(res.data.data.substances);
      }
    }
  };

  return (
    <div className="accordion" id="accordionExample">
      <div className="card">
        <div className="card-header" id="headingOne">
          <a
            className="btn-link w-100 text-left accordain-heading"
            type="button"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <h5>
              DSM 5 Diagnosis
              <i
                className={
                  substanceUseArrow
                    ? "fas fa-angle-up rotate-icon  float-right"
                    : "fas fa-angle-down"
                }
              ></i>
            </h5>
          </a>
        </div>
        <div
          id="collapseOne"
          className="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <SubstanceUseDisorder
            tabEditOrNot={props.tabEditOrNot}
            saveAndExit={props.saveAndExit}
            isSaveAndComplete={props.isSaveAndComplete}
            route_of_admin={props.route_of_admin}
            form_type={props.form_type}
            frequency_of_use={props.frequency_of_use}
            specifier={specifier}
            updateForm={true}
            childToParent={props.childToParent[0]}
          />
        </div>
      </div>

      {problemLength.map((val, key) => {
        return (
          <>
            {key < 3 ? (
              <>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <a
                      className="btn-link w-100 text-left accordain-heading"
                      type="button"
                      data-toggle="collapse"
                      data-target={
                        key == 0
                          ? "#collapseTwo"
                          : key == 1
                          ? "#collapseThree"
                          : key == 2
                          ? "#collapseFour"
                          : ""
                      }
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <h5>
                        {key == 0
                          ? "Primary Problems"
                          : key == 1
                          ? "Secondary Problems"
                          : key == 2
                          ? "Tertiary Problems"
                          : ""}
                        <i
                          className={
                            substanceUseArrow
                              ? "fas fa-angle-up rotate-icon  float-right"
                              : "fas fa-angle-down"
                          }
                        ></i>
                      </h5>
                    </a>
                  </div>
                  <div
                    id={
                      key == 0
                        ? "collapseTwo"
                        : key == 1
                        ? "collapseThree"
                        : key == 2
                        ? "collapseFour"
                        : ""
                    }
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <Disorder_problems
                    tabEditOrNot={props.tabEditOrNot}
                    saveAndExit={props.saveAndExit}
                      problem_type={val.problem_type}
                      specific_substance={val.specific_substance}
                      route_of_administration={val.route_of_administration}
                      frequency_of_use={val.frequency_of_use}
                      age_of_1st_use={val.age_of_1st_use}
                      rank_order={val.rank_order}
                      specific_substance_options={
                        val.specific_substance_options.problem_type.substance
                      }
                      specific_substance_options2="jadgja"
                      type={
                        key == 0
                          ? "Primary"
                          : key == 1
                          ? "Secondary"
                          : key == 2
                          ? "Tertiary"
                          : ""
                      }
                      form_type={props.form_type}
                      isSaveAndComplete={props.isSaveAndComplete}
                      childToParent={props.childToParent[1]}
                    />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        );
      })}
      {/* <div className="card">
                <div className="card-header" id="headingOne">
                    <a className="btn-link w-100 text-left accordain-heading" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseOne">
                        <h5>Primary Problems
                            <i className={substanceUseArrow ? "fas fa-angle-up rotate-icon  float-right" : "fas fa-angle-down"}></i>
                        </h5>
                    </a>
                </div>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <Disorder_problems type="Primary" form_type={props.form_type} />
                </div>
            </div> */}
    </div>
  );
}
